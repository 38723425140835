import React from 'react';
import { placeholderFunc } from '../../utils/commonFunctions';
import IconMenuButtonPrefix from './subComponents/IconMenuButtonPrefix';
import IconMenuButtonBar from './subComponents/IconMenuButtonBar';
import { IconMenuButtonStyle } from './styles/IconMenuButtonStyles';

const IconMenuButton = ({ text = 'default', icon = '+', onClick = placeholderFunc, active }) => (
  <IconMenuButtonStyle role={'IconMenuButton'} className={active && 'active'} onClick={onClick}>
    <IconMenuButtonPrefix icon={icon} />
    <IconMenuButtonBar text={text} />
  </IconMenuButtonStyle>
);

export default IconMenuButton;
