import styled from 'styled-components';

export const WelcomePasswordWrap = styled.div`
  display: flex;
  flex: 12;
  flex-direction: column;
  align-items: center;
`;

export const WelcomePasswordContainer = styled.div`
  background: ${props => props.theme.sButtonBgColor};
  color: ${props => props.theme.textColor};
  height: fit-content;
  max-height: 100%;
  width: 450px;
  border: none;
  border-radius: 5px;
  text-align: center;
  z-index: 10;
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
`;
