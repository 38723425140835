import React, { Fragment, useEffect } from 'react';
import { TextInput } from '../../../common/forms/TextInput';
import { SplitWrapper } from '../../../common/forms/styles/SplitTextStyles';
import { getSecretFormat } from '../subFunctions/FormFunctions';
import { errorCheck, removeError } from '../../../utils/formFunctions';
import { numberRegex } from '../../../utils/validationSuite/validationRegex';
import NoteArea from '../../../common/forms/NoteArea';

const PhoneNumber = ({ secret, data, setData, notificationText, setNotificationText }) => {
  const { country, number, extension, note } = getSecretFormat(secret);

  useEffect(() => {
    setData({ country, number, extension, note });
  }, [setData, country, number, extension, note]);

  const handleChange = (value, key) => {
    if (notificationText) setNotificationText(removeError(key, notificationText));
    if (numberRegex.test(value) || value === '' || key === 'note') {
      setData(prevData => {
        return {
          ...prevData,
          [key]: value,
        };
      });
    }
  };

  return (
    <Fragment>
      <span className="custom-title">Phone Number</span>
      <SplitWrapper role={'PhoneNumber'}>
        <TextInput
          role={'TextInput'}
          placeholder="Country Code"
          label="Country Code"
          value={data.country || ''}
          handleChange={value => handleChange(value, 'country')}
          className={errorCheck('country', notificationText)}
        />
        <TextInput
          placeholder="Number"
          label="Number"
          value={data.number || ''}
          handleChange={value => handleChange(value, 'number')}
          className={errorCheck('number', notificationText)}
        />
        <TextInput
          placeholder="Extension"
          label="Extension"
          value={data.extension || ''}
          handleChange={value => handleChange(value, 'extension')}
          className={`no-right-margin ${errorCheck('extension', notificationText)}`}
        />
      </SplitWrapper>
      <NoteArea
        placeholder="Note"
        label="Note"
        value={data.note || ''}
        onChange={e => handleChange(e.target.value, 'note')}
      />
    </Fragment>
  );
};

export default PhoneNumber;
