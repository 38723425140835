import styled from 'styled-components';

export const LoginInputWrap = styled.fieldset`
  display: flex;
  margin: 0 0 1.75rem 0;
  border-radius: 5px;
  border: 1px solid #98a2bb;
  padding: 0;
  position: relative;
  transition: all 0.3s ease-in-out;

  &.active legend {
    display: flex;
    opacity: 1;
  }

  &:focus-within {
    border-color: #40dae1;
    legend {
      color: #40dae1;
    }
  }
`;

export const LoginInputLegend = styled.legend`
  background-color: ${props => props.theme.tableBgColor};
  color: ${props => props.theme.textOutlineColor};
  top: -0.65rem;
  font-size: 0.9rem;
  width: fit-content;
  line-height: normal;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: 0.75rem;
  opacity: 0;
  position: absolute;
  transition: all 0.1s ease-in-out;
`;

export const LoginInputArea = styled.input`
  background-color: inherit;
  font-family: 'Lato';
  font-size: 1rem;
  width: 100%;
  border: none;
  border-radius: 5px;
  padding: 1rem 1.25rem;
  box-sizing: border-box;
  color: ${props => props.theme.fieldTextColor};
  appearance: none;
  outline: none;

  ::placeholder {
    color: ${props => props.theme.placeholderColor};
  }

  &:focus {
    border-color: ${props => props.theme.highlightColor};
    outline: none;
  }

  &:focus ~ label {
    color: ${props => props.theme.highlightColor};
  }

  &:focus ~ i {
    visibility: visible;
    color: ${props => props.theme.highlightColor};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: ${props => props.theme.fieldTextColor};
    -webkit-box-shadow: 0 0 0px 1000px ${props => props.theme.tableBgColor} inset;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

export const PasswordWrap = styled.div`
  display: flex;
  flex: 12;
`;

export const IconWrap = styled.div`
  display: flex;
  flex: 1;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1.25rem;
  transition: all 0.3s ease-in-out;
  color: ${({ theme }) => theme.textColor};

  &:hover {
    color: #40dae1;
  }
`;

export const Icon = styled.i``;
