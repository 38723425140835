export const getSearchable = context => keyHeaderPairs[context] || [];

const _secretSearchable = ['Name', 'Chest'];
const _secretShowSearchable = ['Secret', 'Chest'];

const _chestSearchable = ['Name'];

const _teamSearchable = ['Name', 'Chests'];
const _teamShowSearchable = ['User', 'Email'];

const _userSearchable = ['Name', 'Email', 'Teams'];

const keyHeaderPairs = {
  Secrets: _secretSearchable,
  SecretsShow: _secretShowSearchable,
  Chests: _chestSearchable,
  Teams: _teamSearchable,
  TeamsShow: _teamShowSearchable,
  Users: _userSearchable,
};
