import styled from 'styled-components';

export const StepSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const TwoStepFooter = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TwoStepButton = styled.button`
  margin-bottom: 1rem;
  margin-top: 1rem;
  height: 2.75rem;
  background-color: ${props => props.theme.hoverRowBgColor};
  color: ${props => props.theme.hoverButtonColor};
  border: 1px solid ${props => props.theme.hoverRowBgColor};
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  font-family: 'Lato';
  appearance: none;
  outline: none;
  opacity: 1;
  pointer-events: all;
  transition: all 0.3s ease-in-out;

  &:focus {
    background-color: ${({ theme }) => theme.bgColor};
    color: ${({ theme }) => theme.highlightColor};
  }

  &:disabled {
    background-color: #98a2bb;
    border-color: transparent;
    color: white;
    pointer-events: none;
  }
`;

export const TwoStepText = styled.span`
  display: flex;
  margin-top: 3rem;
  margin-bottom: 2rem;
  font-size: 1rem;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  svg {
    width: 20px;
    height: 20px;
  }
  span {
    margin-left: 0.75rem;
  }
  &.reduced-margin {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    justify-content: flex-start;
  }
`;

export const SubTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
`;

export const TwoStepSubText = styled.span`
  text-align: center;
  font-size: 1rem;
  span {
    text-decoration: underline;
  }
  .tab {
    padding-left: 0.5rem;
  }
  .double-tab {
    padding-left: 1.25rem;
  }
  &.success {
    color: #252a41;
  }
`;

export const BackLink = styled.button`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  font-size: 1rem;
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
  color: ${({ theme }) => theme.textColor};

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.highlightColor};
    text-decoration: underline;
  }
`;
