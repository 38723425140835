import styled from 'styled-components';

export const BillingHistoryLink = styled.button`
  border: none;
  border-bottom: 1px solid ${props => props.theme.textColor};
  font-size: 1rem;
  font-family: 'Lato';
  cursor: pointer;
  background-color: inherit;
  padding: 0;
  color: ${props => props.theme.textColor};

  &:focus {
    outline-color: ${props => props.theme.highlightColor};
  }
`;

export const BillingMainText = styled.div`
  font-size: 1.2rem;
  margin-bottom: 0.75rem;
`;

export const BillingSubText = styled.div`
  font-size: 1rem;
  > div {
    transition: color 0.3s ease-in-out;
    color: ${props => props.theme.highlightColor};
  }
`;

export const BillingDivider = styled.div`
  margin: 1rem 0 1rem 0;
  border: 1px solid ${props => props.theme.fieldBorderColor};
  opacity: 0.2;
`;

export const BillingButton = styled.button`
  cursor: pointer;
  font-family: 'Lato';
  font-size: 1rem;
  background-color: inherit;
  padding: 0;
  border: none;
  color: ${props => props.theme.highlightColor};

  &:focus {
    outline-color: ${props => props.theme.highlightColor};
  }

  &.delete-subscription {
    color: ${props => props.theme.fieldTextColor};
    &:hover {
      color: ${props => props.theme.error};
      svg path,
      svg rect {
        fill: ${props => props.theme.error};
      }
    }
  }
  &:hover {
    color: ${props => props.theme.highlightColor};
  }

  svg {
    vertical-align: middle;
    margin: 0 0.5rem 0.3rem 0;
    path,
    rect {
      fill: ${props => props.theme.fieldTextColor};
      transition: all 0.3s ease-in-out;
    }
  }
`;

export const PlanWrap = styled.div`
  flex: 3;
  background-color: ${props => props.theme.fieldBgColor};
  border: 1px solid ${props => props.theme.highlightColor};
  color: ${props => props.theme.fieldTextColor};
  padding: 1.25rem;
  border-radius: 5px;
  text-align: center;
  margin: 0 1rem 0 0;
  position: relative;

  &::before {
    content: 'Your plan';
    display: block;
    position: absolute;
    top: -2.25rem;
    font-weight: 600;
    left: 0;
    font-size: 1rem;
    color: ${props => props.theme.textColor};
  }

  @media screen and (max-width: 767px) {
    margin: 0 0 1rem 0;
  }

  @media (-webkit-device-pixel-ratio: 1.5) {
    svg {
      width: 16px;
    }
  }
`;

export const SubscriptionWrap = styled.div`
  display: flex;
  flex: 8;
  flex-direction: column;
  justify-content: center;
  background-color: ${props => props.theme.fieldBgColor};
  border: 1px solid ${props => props.theme.bgColor};
  color: ${props => props.theme.fieldTextColor};
  padding: 1.25rem;
  border-radius: 5px;
  text-align: center;
  position: relative;

  @media screen and (max-width: 767px) {
    margin-top: 3rem;
  }

  &::before {
    content: 'Next payment';
    display: block;
    position: absolute;
    top: -2.25rem;
    font-weight: 600;
    left: 0;
    font-size: 1rem;
    color: ${props => props.theme.textColor};
  }
`;

export const BillingSummaryWrap = styled.div`
  display: flex;
  flex: 12;
  flex-wrap: nowrap;
  justify-content: space-between;
`;
