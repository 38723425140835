// Any common "support" code (used in multiple places) should be stored here.
export class Conversion {
  // Convert an ArrayBuffer into a string of Base64 text.
  static bin2txt(data) {
    return btoa(String.fromCharCode.apply(null, new Uint8Array(data)));
  }

  // Convert a string of Base64 text into an ArrayBuffer.
  static txt2bin(text) {
    return Uint8Array.from(atob(text), c => c.charCodeAt(0));
  }

  // Treat a string of text as a Uint8Array of byte values.
  static str2buf(text) {
    return new TextEncoder('utf-8').encode(text);
  }

  // Treat a Uint8Array of byte values as a string of text.
  static buf2str(text) {
    return new TextDecoder('utf-8').decode(text);
  }
}
