import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { LoginContainer, LoginFormContainer, RegisterLink, RegisterLinkArea } from './styles/LoginStyles';
import { Notification, useNotification } from '../common/notification/Notification';
import { Icon } from '../common/Icon';
import { extractFromUrl } from '../utils/commonFunctions';
import ApiRequest from '../api/ApiRequest';
import { formatParams } from '../api/apiHelpers';
import { getForm } from './login/getLoginForm';
import LoginReq from '../api/unique/LoginReq';
import TwoFactorController from '../api/controllers/TwoFactorController';
import { UserContext } from '../context/UserProvider';

const Login = () => {
  const [loginDetails, setLoginDetails] = useState({
    email: '',
    password: '',
    otp_attempt: '',
  });
  const [form, setForm] = useState('Login');
  // disable the Verify button to prevent users spamming the 2FA API
  const [verifyButtonDisabled, setVerifyButtonDisabled] = useState(true);
  const { setUser } = useContext(UserContext);
  const { notificationText, setNotificationText } = useNotification();
  const history = useHistory();

  useEffect(() => {
    let isMounted = true;
    const token = extractFromUrl('token');
    if (isMounted && token !== null) {
      const params = { confirmation_token: token };
      ApiRequest('GET', `users/confirmation.json${formatParams(params)}`);
    }
    return () => (isMounted = false);
  }, []);

  const getLogin = async e => {
    e.preventDefault();
    const { email, password, otp_attempt } = loginDetails;
    const handler = new LoginReq(email, password, otp_attempt);
    const twoFactor = new TwoFactorController();
    const { status, json } = await twoFactor.check({ email, password });

    if (status !== 200) return console.log('Two-factor call failed.');
    const res = await submitLogin(handler, json);
    if (res) {
      setUser(res);
    }
  };
  /*
    Explanation submitLogin:
      json['2faRequired'] is whether or not that user needs 2fa to login
      json['valid'] is whether the email and password combination is correct (2faRequired === null means the same thing)

    Note: On the backend a change was made to avoid probing that makes the system
          act as if no user was found if the details are incorrect for the TwoFactorController.
  */
  const submitLogin = async (handler, json) => {
    // If 2fa && valid credentials && the user is on the basic login page then travel to the otp page
    if (json['2faRequired'] && json['valid'] && form === 'Login') return setForm('2FA');
    // If 2fa is null or invalid credentials entered then report an error in console
    // Also hit the sign_in endpoint to elicit a lockout if too many tries
    if (json['2faRequired'] === null || json['valid'] === false) {
      const signInReq = await handler.perform();
      const errorText = signInReq['json']['error'] || 'Invalid login credentials.';
      return setNotificationText({ type: 'error', text: errorText });
    }
    // If 2fa is false or they are submitting from the otp form then attempt login
    if (json['2faRequired'] === false || form === '2FA' || form === 'Recovery') return await userContextInit(handler);
  };

  const userContextInit = async handler => {
    const { status, json } = await handler.perform();
    if (status !== 201) {
      if (form === 'Recovery')
        return setNotificationText({
          type: 'error',
          text: 'Invalid recovery codes - please check that you’ve entered the right codes and try again. If you still experience issues, please contact your organisation’s administrator.',
        });
      if (json['error']) {
        if (json['error'] === 'Invalid Email or password.' && form !== 'login')
          return setNotificationText({ type: 'error', text: 'Invalid OTP or recovery code' });
        return setNotificationText({ type: 'error', text: json['error'] });
      }
    }
    return json;
  };

  const handleChange = (e, mode) => setLoginDetails({ ...loginDetails, [mode]: e.target.value });

  const formParams = {
    loginDetails,
    handleChange,
    getLogin,
    setForm,
    verifyButtonDisabled,
    setVerifyButtonDisabled,
  };

  return (
    <LoginContainer>
      <Notification notificationText={notificationText} />
      <Icon name="logo" width="200px" className="logo" />
      <LoginFormContainer> {getForm(form)(formParams)} </LoginFormContainer>
      {form === 'Login' && (
        <RegisterLinkArea>
          <span>
            Don't have an account? <RegisterLink onClick={() => history.push('/register')}>Sign up</RegisterLink>
          </span>
          <span className={'forgot-pass'}>Forgotten your password? </span>
          <span>Contact an administrator in your organisation.</span>
        </RegisterLinkArea>
      )}
      <p className="login-copyright">© Copyright {new Date().getFullYear()} Recrypt</p>
    </LoginContainer>
  );
};

export default Login;
