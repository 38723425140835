import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  margin: 0.5rem auto 0 auto;
  padding: 0.75rem;
  border-radius: 4px;
  border: none;
  align-items: center;
  color: ${props => props.theme.fieldTextColor};
  background: repeating-linear-gradient(
    135deg,
    ${({ theme }) => theme.fieldBgColor} 3px,
    ${({ theme }) => theme.fieldBgColor} 3px,
    ${({ theme }) => theme.formFooterBgColor} 5px,
    ${({ theme }) => theme.formFooterBgColor} 10px
  );
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 0.7;
  }

  @media (-webkit-device-pixel-ratio: 1.5) {
    svg {
      max-width: 12px;
    }
  }
`;

export const IconWrap = styled.div`
  margin: 0 0.75rem 0 0;
  .a {
    fill: ${props => props.theme.fieldTextColor};
  }
`;

export const Text = styled.div`
  margin-top: -2px;
`;
