import styled from 'styled-components';

export const EditLink = styled.button`
  font-family: 'Lato';
  font-size: 1rem;
  background-color: inherit;
  padding: 0;
  border: none;
  cursor: pointer;
  margin: 0 1.5rem 0 0;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
  span {
    font-size: 1rem;
    color: ${props => props.theme.highlightColor};
  }
  svg {
    margin-right: 0.5rem;
    width: 16px;
    height: 16px;

    @media (-webkit-device-pixel-ratio: 1.5) {
      width: 12px;
      height: 12px;
    }
  }
  &:hover {
    opacity: 0.7;
  }
`;

export const PaymentButton = styled.button`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  background-color: ${props => props.theme.settingsBgColor};
  color: ${props => props.theme.fieldTextColor};
  border: 1px solid ${props => props.theme.bgColor};
  border-radius: 3px;
  width: 100%;
  padding: 1rem 0;
  font-size: 1rem;
  font-weight: 700;
  font-family: 'Lato';
  align-items: center;
  cursor: pointer;
`;

export const PaymentCardIconWrap = styled.div`
  margin: 2px 0.5rem 0 0;
  svg {
    height: 14px;
    .st0 {
      fill: ${props => props.theme.fieldTextColor};
    }
  }
`;

export const PaymentDetails = styled.div`
  border: 1px solid ${props => props.theme.bgColor};
  padding: 0.75rem 1.25rem 1rem 1.25rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 1.5rem;
  }
`;

export const PaymentDetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-self: flex-start;

  > p {
    margin: 0 0.5rem;
    color: ${props => props.theme.bgColor};
    font-size: 1.2rem;
  }
  > span {
    b {
      margin-right: 0.5rem;
      font-size: 1rem;
      font-weight: 700;
    }
    font-weight: 400;
    font-size: 0.9rem;
  }
`;

export const CardIconWrap = styled.div`
  margin: 0.18rem 0.75rem 0 0;

  @media (-webkit-device-pixel-ratio: 1.5) {
    svg {
      width: 26px;
      height: 16.5px;
    }
  }
`;

export const DeleteLink = styled.button`
  font-family: 'Lato';
  font-size: 1rem;
  background-color: inherit;
  padding: 0;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
  span {
    font-size: 1rem;
    color: ${props => props.theme.error};
  }
  svg {
    margin-right: 0.5rem;
    path,
    rect {
      fill: ${props => props.theme.error};
    }

    @media (-webkit-device-pixel-ratio: 1.5) {
      width: 12px;
      height: 12px;
    }
  }
  &:hover {
    opacity: 0.7;
  }
`;

export const Coupon = styled.div`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.textColor};
  margin: 1rem 0 0 0;
`;

export const CouponLink = styled.a`
  color: ${({ theme }) => theme.highlightColor};
  cursor: pointer;
`;

export const PenIcon = styled.i`
  color: ${({ theme }) => theme.highlightColor};
  height: 5px;
  margin: 0 0.5rem 0.5rem 0;
`;
