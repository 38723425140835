import React, { useContext } from 'react';
import { Icon } from '../../../common/Icon';
import { ChestHeaderContainer, Close, IconWrap, TitleWrap } from './styles/ChestHeaderStyles';
import { ThemeContext } from 'styled-components';
import { placeholderFunc } from '../../../utils/commonFunctions';

export const ChestHeader = ({ name, onClick = placeholderFunc }) => {
  const themeContext = useContext(ThemeContext);
  const themeIcon = themeContext.scheme === 'dark' ? 'chest_white' : 'chest';
  return (
    <ChestHeaderContainer role={'chest-header-container'}>
      <TitleWrap>
        <IconWrap>
          <Icon name={themeIcon} width="21px" />
        </IconWrap>
        <span>{name}</span>
      </TitleWrap>
      <TitleWrap role={'chest-header-icon-button'} className={'link'} onClick={onClick}>
        <IconWrap className={'escape'}>
          <Icon name={'escape'} width="28px" />
        </IconWrap>
        <Close>Close</Close>
      </TitleWrap>
    </ChestHeaderContainer>
  );
};
