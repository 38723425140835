export const getPlanObj = key => _keyValuePair[key] || _keyValuePair['Default'];

const _recryptPaidObj = {
  icon: 'team',
  name: 'Team Plan',
  price: '£3 per user / per month',
  priceNum: '3',
  billingDetails: 'Billed monthly / cancel any time',
  unit: '/ user',
  features: ['Up to 50 users', 'Unlimited secrets', 'Unlimited chests / team sharing', 'AES-256 encryption and MFA'],
};

const _freePlanObj = {
  icon: 'user',
  name: 'For Individuals',
  price: 'Free',
  priceNum: '0',
  billingDetails: 'Not billed',
  unit: '',
  features: ['1 user / 1 device', '25 secrets', '1 chest', 'AES-256 encryption and MFA'],
};

const _keyValuePair = {
  RecryptPaid: _recryptPaidObj,
  Default: _freePlanObj,
};
