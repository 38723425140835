import styled from 'styled-components';

export const LockCellWrap = styled.div`
  visibility: hidden;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    min-width: 16px;
    max-width: 24px;
  }

  @media screen and (max-width: 615px) {
    display: none;
  }
`;
