import React, { useEffect } from 'react';
import Mousetrap from 'mousetrap';
import { NavContent, NavBar } from './styles/CenterNavStyle';

const CenterNav = ({
  activeLink,
  setDashboard,
  setDisplayDashboard,
  setEditSecret,
  setViewSecret,
  viewSecret,
  setAddSecret,
  setDisplay,
}) => {
  const linksArray = ['Secrets', 'Chests'];

  const moveRight = () => {
    const currentPosition = linksArray.findIndex(link => link === activeLink);
    if (currentPosition < linksArray.length - 1) {
      let newPosition = currentPosition + 1;
      setDisplayDashboard(false);
      setAddSecret(false);
      setDashboard(linksArray[newPosition]);
    }
  };

  const moveLeft = () => {
    const currentPosition = linksArray.findIndex(link => link === activeLink);
    if (currentPosition > 0) {
      let newPosition = currentPosition - 1;
      setDisplayDashboard(false);
      setAddSecret(false);
      setDashboard(linksArray[newPosition]);
    }
  };
  const checkTabButtonPress = event => {
    //check button pressed is the Secrets tab button
    if (event?.target?.innerText === 'Secrets') {
      if (viewSecret) {
        //if viewSecret is truthy = vewSecret component visible
        setViewSecret(null);
      } else {
        setEditSecret(null); //else edit Secret component is visible
      }

      setTimeout(() => setDisplay(false), 250);
      setDashboard('Secrets');
    } else {
      setTimeout(() => setDisplay(false), 250);
      setDashboard('Chests');
    }
  };
  useEffect(() => {
    Mousetrap.bind('shift+right', moveRight);
    Mousetrap.bind('shift+left', moveLeft);
    return () => {
      Mousetrap.unbind('shift+right');
      Mousetrap.unbind('shift+left');
    };
  });

  return (
    <NavContent>
      <NavBar id="navbar">
        <span
          id={'nav-bar-secrets'}
          className={activeLink === 'Secrets' ? 'active' : 'non-active'}
          onClick={event => checkTabButtonPress(event)}
        >
          Secrets
        </span>
        <span
          className={activeLink === 'Chests' ? 'active' : 'non-active'}
          onClick={event => checkTabButtonPress(event)}
        >
          Chests
        </span>
      </NavBar>
    </NavContent>
  );
};

export default CenterNav;
