import ApiRequest from '../ApiRequest';

class TwoFactorApi {
  static check(params) {
    return ApiRequest('POST', `api/v1/two-factor-check.json`, params);
  }

  static show() {
    return ApiRequest('GET', `api/v1/two-factor-code.json`);
  }

  static enableOtp(params) {
    return ApiRequest('PUT', `api/v1/two-factor-active.json`, params);
  }

  static disableOtp() {
    return ApiRequest('PUT', `api/v1/two-factor-disable.json`);
  }

  static backup() {
    return ApiRequest('GET', `api/v1/two-factor-backup.json`);
  }
}

export default TwoFactorApi;
