import styled from 'styled-components';

export const Wrap = styled.div`
  position: relative;
`;

export const Search = styled.input`
  position: relative;
  max-width: 20vw;
  width: 20vw;
  border: 1px solid ${({ theme }) => theme.highlightColor};
  border-bottom: ${({ open }) => open && `1px solid rgba(255, 255, 255, 0)`};
  border-radius: ${({ open }) => (open ? '3px 3px 0 0' : '3px')};
  padding: 0.75rem 1.25rem;
  background-color: ${({ theme }) => theme.fieldBgColor};
`;

export const ArrowWrap = styled.div`
  position: absolute;
  top: 0.7rem;
  right: 1.5rem;

  > div > div::before,
  div::after {
    background-color: ${({ theme }) => theme.highlightColor};
  }
`;

export const Content = styled.div`
  display: ${({ closed }) => closed && 'none'};
  position: absolute;
  width: 99.5%;
  max-height: 6rem;
  background-color: ${({ theme }) => theme.fieldBgColor};
  border-left: 1px solid ${({ theme }) => theme.highlightColor};
  border-right: 1px solid ${({ theme }) => theme.highlightColor};
  border-bottom: 1px solid ${({ theme }) => theme.highlightColor};
  border-radius: 0 0 3px 3px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10;
`;

export const Option = styled.div`
  padding: 0.5rem;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.bgColor};
  }
`;
