import React, { useMemo, useContext, useState } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { AdminChestsWrap } from '../adminChests/styles/AdminChestsWrap';
import { ViewSecret } from '../../secrets/ViewSecret';
import AdminSecretsShowWrap from './styles/AdminSecretsShowWrap';
import { UserContext } from '../../../context/UserProvider';

const AdminSecretsShow = () => {
  const location = useLocation();
  const { user } = useContext(UserContext);
  const [secretData, setSecretData] = useState();
  const { administrator } = user;
  const row = useMemo(() => location['row'] || (location['state'] && location['state']['row']) || [], [location]);
  if (row.length === 0) return <Redirect to={'/admin/chests'} />;

  return (
    <AdminChestsWrap>
      <AdminSecretsShowWrap>
        <ViewSecret secretData={secretData} setSecretData={setSecretData} secret={row} isAdmin={administrator} />
      </AdminSecretsShowWrap>
    </AdminChestsWrap>
  );
};

export default AdminSecretsShow;
