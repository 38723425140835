import styled from 'styled-components';

export const AdminUsersShowWrap = styled.div`
  display: flex;
  flex: 12;
  flex-direction: column;
  background-color: ${props => props.theme.BgColor};
`;

export const UserShowTableWrap = styled.div`
  display: flex;
  flex: 8;
  flex-direction: column;
  border-radius: 5px;
  line-height: 50px;
  overflow-y: auto;
  background-color: ${props => props.theme.tableBgColor};

  @media screen and (max-width: 767px) {
    overflow-y: visible;
  }

  @media (-webkit-device-pixel-ratio: 1.5) {
    line-height: 35px;
  }
`;

export const AdminUsersShowBottomWrap = styled.div`
  display: flex;
  flex: 12;
  flex-direction: column;
  background-color: ${props => props.theme.tableBgColor};
  border-radius: 5px 5px 0 0;
`;

export const AdminUsersShowTopWrap = styled.div`
  display: flex;
  flex: 12;
  background-color: ${props => props.theme.tableBgColor};
  border-radius: 0 0 5px 5px;
  margin-bottom: 1rem;

  p {
    line-height: 170%;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const UserLoadingWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-height: 15rem;
`;

export const UserLogsText = styled.span`
  color: ${props => props.theme.textColor};
  margin-left: 2rem;
  margin-top: 2rem;
  font-size: 1.5rem;
`;
