export const StripeCountryList = [
  { label: 'Australia', value: 'AU', id: 'AU' },
  { label: 'Austria', value: 'AT', id: 'AT' },
  { label: 'Belgium', value: 'BE', id: 'BE' },
  { label: 'Bulgaria', value: 'BG', id: 'BG' },
  { label: 'Brazil ', value: 'BR', id: 'BR' },
  { label: 'Canada', value: 'CA', id: 'CA' },
  { label: 'Cyprus', value: 'CY', id: 'CY' },
  { label: 'Czech Republic', value: 'CZ', id: 'CZ' },
  { label: 'Denmark', value: 'DK', id: 'DK' },
  { label: 'Estonia', value: 'EE', id: 'EE' },
  { label: 'Finland', value: 'FI', id: 'FI' },
  { label: 'France', value: 'FR', id: 'FR' },
  { label: 'Germany', value: 'DE', id: 'DE' },
  { label: 'Greece', value: 'GR', id: 'GR' },
  { label: 'Hong Kong', value: 'HK', id: 'HK' },
  { label: 'India', value: 'IN', id: 'IN' },
  { label: 'Ireland', value: 'IE', id: 'IE' },
  { label: 'Italy', value: 'IT', id: 'IT' },
  { label: 'Japan', value: 'JP', id: 'JP' },
  { label: 'Latvia', value: 'LV', id: 'LV' },
  { label: 'Lithuania', value: 'LT', id: 'LT' },
  { label: 'Luxembourg', value: 'LU', id: 'LU' },
  { label: 'Malaysia', value: 'MY', id: 'MY' },
  { label: 'Malta', value: 'MT', id: 'MT' },
  { label: 'Mexico ', value: 'MX', id: 'MX' },
  { label: 'Netherlands', value: 'NL', id: 'NL' },
  { label: 'New Zealand', value: 'NZ', id: 'NZ' },
  { label: 'Norway', value: 'NO', id: 'NO' },
  { label: 'Poland', value: 'PL', id: 'PL' },
  { label: 'Portugal', value: 'PT', id: 'PT' },
  { label: 'Romania', value: 'RO', id: 'RO' },
  { label: 'Singapore', value: 'SG', id: 'SG' },
  { label: 'Slovakia', value: 'SK', id: 'SK' },
  { label: 'Slovenia', value: 'SI', id: 'SI' },
  { label: 'Spain', value: 'ES', id: 'ES' },
  { label: 'Sweden', value: 'SE', id: 'SE' },
  { label: 'Switzerland', value: 'CH', id: 'CH' },
  { label: 'United Kingdom', value: 'GB', id: 'GB' },
  { label: 'United States', value: 'US', id: 'US' },
  { id: 'blank', value: '', label: '' },
];
