import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${props => props.theme.fieldBgColor};
  min-width: 40vh;
  min-height: 50vh;
  max-width: 360px;
  margin: 0 2rem 0 0;
  padding: 1rem 0 2rem 0;
  border-radius: 3px;
  overflow: hidden;
  box-sizing: border-box;

  &.selected {
    border: 1px solid ${props => props.theme.highlightColor};
  }

  &.selected::before {
    content: '';
    position: absolute;
    background-color: ${props => props.theme.highlightColor};
    top: -40px;
    right: -42px;
    width: 5rem;
    height: 5rem;
    transform: rotate(45deg);

    @media (-webkit-device-pixel-ratio: 1.5) {
      top: -38px;
      right: -33px;
      width: 6rem;
      height: 6rem;
    }
  }

  @media screen and (max-width: 767px) {
    margin-right: 0;
    margin-bottom: 2rem;
  }
`;

export const IconWrap = styled.div`
  display: none;
  content: '';
  position: absolute;
  top: 8px;
  right: -24px;
  width: 5rem;
  height: 5rem;

  @media (-webkit-device-pixel-ratio: 1.5) {
    right: -16px;
  }

  .st0 {
    fill: white;
  }

  &.selected {
    display: initial;
  }
`;

export const TitleWrap = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  line-height: 4rem;
`;

export const PlanIcon = styled.div`
  line-height: 4.4rem;
  margin: 0 0.5rem 0 0;

  svg g {
    fill: ${props => props.theme.fieldTextColor};
  }

  @media (-webkit-device-pixel-ratio: 1.5) {
    margin: 0 0.75rem 0 0;
    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

export const Name = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
`;

export const PriceWrap = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  flex-wrap: nowrap;
`;

export const PriceHighlight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 1rem;
`;

export const Currency = styled.div`
  font-weight: bold;
  font-size: 1.3rem;
  margin-top: 1rem;
  transform: translateX(-9px);
`;

export const Price = styled.div`
  font-size: 4.6rem;
  font-weight: 700;
`;

export const Unit = styled.div`
  font-weight: bold;
  font-size: 1.1rem;
  margin-top: 4px;
  margin-left: 5px;
  align-self: flex-end;
  transform: translateY(-12px);
`;

export const SubtitleWrap = styled.div``;

export const Subtitle = styled.div`
  line-height: 180%;
  opacity: 0.6;
`;

export const Divider = styled.hr`
  margin: 1rem 0;
  align-self: center;
  color: ${props => props.theme.fieldTextColor};
  width: 80%;
  opacity: 0.25;
`;

export const FeatureWrap = styled.div`
  display: flex;
  flex: 4;
  flex-direction: column;
  margin: 0 0 2rem 0;
`;

export const Feature = styled.div`
  margin: 0.5rem 0 0 0;
`;

export const ButtonWrap = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
  align-items: center;
`;

export const PlanButton = styled.button`
  font-family: 'Lato';
  background-color: inherit;
  width: 230px;
  padding: 0.8rem;
  margin: 1rem 10% 0 10%;
  border: 1px solid ${props => props.theme.textColor};
  color: ${props => props.theme.textColor};
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 700;
  transition: all 0.3s ease-in-out;

  &.disabled {
    pointer-events: none;
  }

  &.selected::before {
    content: 'Cancel Plan';
  }

  &.selected {
    background-color: ${props => props.theme.highlightColor};
    border: transparent;
    color: ${props => props.theme.enabledButtonColor};
  }

  &:hover {
    background-color: #cd5555;
    color: ${props => props.theme.enabledButtonColor};
    border-color: transparent;
  }
`;

export const TrialButton = styled.div`
  padding: 0.2rem;
  font-weight: bold;
  width: 230px;
  height: 22px;
  background-color: ${({ theme }) => theme.bgColor};
  cursor: pointer;
`;
