import React, { Fragment, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { Arrow } from '../arrow/Arrow';
import {
  FullWrapperFieldset,
  HalfWrapperFieldset,
  QuarterWrapperFieldset,
  Legend,
  InnerWrapper,
  Input,
  ErrorMessage,
} from './styles/TextInputStyles';
import { DropdownOptions } from './DropdownOptions';
import { DropdownChoiceTag } from './DropdownChoiceTag';
import { DropdownArrowWrapper, MultipleChoiceWrapper } from './styles/NewDropdownStyles';

// There should be a useEffect in the parent component which sets the options array
// If a default value is required, it should be set in the useEfect of the parent
// The options array may require having the following in the array:
//   { id: 'blank', value: '', label: '' },
// If setting select = true, this is required in the options array:
//   { id: 'custom', value: custom, label: custom }, where custom is a state set in the parent component
// If multiple=true, the parent component should handle array

// This renders different sized wrappers if desired
const Fieldset = ({ width, fieldSetProps, children }) => {
  switch (width) {
    case 'half':
      return (
        <HalfWrapperFieldset role={'half-wrapper-fieldset'} {...fieldSetProps}>
          {children}
        </HalfWrapperFieldset>
      );
    case 'quarter':
      return (
        <QuarterWrapperFieldset role={'quarter-wrapper-fieldset'} {...fieldSetProps}>
          {children}
        </QuarterWrapperFieldset>
      );
    default:
      return (
        <FullWrapperFieldset role={'full-wrapper-fieldset'} {...fieldSetProps}>
          {children}
        </FullWrapperFieldset>
      );
  }
};
const handleClickAway = setDropdownVisibility => {
  setDropdownVisibility(false);
};
export const NewDropdown = ({
  className,
  id,
  width,
  label,
  // Array of objects passed through from parent component: [{ id, value, label } etc]
  dropdownOptions,
  // These are passed through so that the parent component always knows whether the dropdown is open
  dropdownVisibility,
  setDropdownVisibility,
  // This should be known by the parent component, and should be set to blank if no default set
  optionSelected,
  // This isn't necessarily setOptionSelected (parent component), although it can be
  handleDropdownChange,
  error,
  // This should be true if wanting ability to type in / filter options
  select = false,
  // This should be true if wanting ability to multiselect
  multiple = false,
  hideLegend = false,
}) => {
  const [customText, setCustomText] = useState('');
  const [choices, setChoices] = useState([]);
  const dropDownInputRef = React.createRef();
  const setFocus = () => {
    dropDownInputRef.current && dropDownInputRef.current.focus();
  };
  const setBlur = () => {
    dropDownInputRef.current && dropDownInputRef.current.blur();
  };
  // This renders the choice tags for multiple selections
  const renderChoices = () => {
    return choices.map(choice => (
      <DropdownChoiceTag role={'dropdown-choice-tag'} key={choice.id} choice={choice} remove={removeChoice} />
    ));
  };

  const removeChoice = id => {
    const newChoices = choices.filter(choice => choice.id !== id);
    setChoices(newChoices);
  };

  // This handles custom text input when select === true
  const handleCustom = text => {
    handleDropdownChange({ id: '', value: '', label: '' });
    setCustomText(text);
  };

  // This is to handle custom options when dropdown loses focus
  const handleBlur = () => {
    if (customText !== '' && optionSelected.label === '') {
      setCustomText(customText);
      handleDropdownChange({
        id: '',
        value: customText,
        label: customText,
      });
    }
  };

  const renderInternals = () => (
    /* eslint-disable max-len */
    <Fragment>
      {!hideLegend && (
        <Legend
          className={
            (optionSelected.value === '' || optionSelected.value === undefined) &&
            choices.length === 0 &&
            customText === '' &&
            'empty'
          }
        >
          {label}
        </Legend>
      )}
      <InnerWrapper>
        {multiple ? (
          <MultipleChoiceWrapper role={'multiple-choice-wrapper'}>
            {choices.length > 0 ? renderChoices() : renderChoices()}
          </MultipleChoiceWrapper>
        ) : (
          <Input
            role={'internal-input'}
            placeholder={label}
            value={optionSelected.label !== '' ? optionSelected.label : customText}
            onChange={e => handleCustom(e.target.value)}
            readOnly={select ? false : true}
            onBlur={select ? () => handleBlur() : null}
            id={id}
            className="dropdown mousetrap"
            autoComplete="off"
            ref={dropDownInputRef}
          />
        )}
        <DropdownArrowWrapper>
          {/*bugfix/P4SS-1483-bug-auto-logout-dropdown-arrow: bug fix arrow state not refactored/or was reverted after a previous PR */}
          <Arrow arrowState={dropdownVisibility} />
        </DropdownArrowWrapper>
      </InnerWrapper>
    </Fragment>
  );

  const renderDropdown = () => (
    <ClickAwayListener onClickAway={() => handleClickAway(setDropdownVisibility)}>
      <DropdownOptions
        dropdownOptions={dropdownOptions}
        handleDropdownChange={handleDropdownChange}
        choices={choices}
        setChoices={setChoices}
        customText={customText}
        setCustomText={setCustomText}
        setDropdownVisibility={setDropdownVisibility}
        multiple={multiple}
        className={className}
      />
    </ClickAwayListener>
  );

  const handleEnterPress = () => {
    setDropdownVisibility(!dropdownVisibility);
  };

  const fieldSetProps = {
    onClick: () => {
      !dropdownVisibility && select ? setFocus() : setBlur();
      setDropdownVisibility(!dropdownVisibility);
    },
    onKeyPress: e => (e.key === 'Enter' ? handleEnterPress() : null),
    onKeyDown: e => (e.key === 'Tab' ? setDropdownVisibility(false) : null),
    className: `dropdown ${dropdownVisibility && 'dropdown-opened'} ${className}`,
  };

  return (
    <>
      <Fieldset role={'field-set-container'} width={width} fieldSetProps={fieldSetProps}>
        {optionSelected && renderInternals()}
        {dropdownVisibility && renderDropdown()}
      </Fieldset>
      {error && <ErrorMessage role={'error-message'}>{error}</ErrorMessage>}
    </>
  );
};
