import styled from 'styled-components';

export const FormBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: ${props => props.theme.searchBgColor};
  box-sizing: border-box;
  width: 100%;
  padding: 1.75rem 1.75rem 0 1.75rem;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const FormDropDowns = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: inherit;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    > div {
      flex-direction: row;
      width: 100%;
      margin-right: 0;
    }
  }
`;

export const PasswordInput = styled.input`
  background-color: inherit;
  font-family: 'Lato';
  width: 100%;
  border: none;
  border-radius: 5px;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  color: ${props => props.theme.fieldTextColor};

  ::placeholder {
    color: ${props => props.theme.placeholderColor};
  }

  &:focus {
    border-color: ${props => props.theme.highlightColor};
    outline: none;
  }

  &:focus ~ label {
    color: ${props => props.theme.highlightColor};
  }

  &:focus ~ i {
    visibility: visible;
    color: ${props => props.theme.highlightColor};
  }

  @media screen and (max-width: 767px) {
    box-sizing: border-box;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: ${props => props.theme.fieldTextColor};
    -webkit-box-shadow: 0 0 0px 1000px ${props => props.theme.tableBgColor} inset;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

export const Icon = styled.i`
  color: ${({ theme }) => theme.textColor};
  margin: 0 20px 0 0;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.highlightColor};
  }
`;

export const FormCustomFields = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.sButtonBgColor};
  padding: 1.75rem 1.75rem 0 1.75rem;
  margin-left: -1.75rem;
  width: 100%;
  height: 100%;

  .custom-title {
    font-size: 1rem;
    color: ${props => props.theme.textColor};
    padding-bottom: 2rem;
  }
`;
