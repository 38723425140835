import React from 'react';
import { Icon } from '../../common/Icon';
import { SuccessSubContainer, SuccessContent, SuccessTitle, SecurityWrap } from './styles/SuccessStyle';
import { TwoStepSubText, SubTextContainer } from './styles/CommonStyles';

const TwoStepSuccess = () => {
  return (
    <SuccessSubContainer>
      <SuccessContent>
        <SecurityWrap>
          <Icon name="security_white" width="50px" />
        </SecurityWrap>
        <SuccessTitle>Two-step verification is on</SuccessTitle>
        <SubTextContainer>
          <TwoStepSubText className="success">
            We'll now ask for a login code anytime you log in on a phone or computer we don't recognise.
          </TwoStepSubText>
        </SubTextContainer>
      </SuccessContent>
    </SuccessSubContainer>
  );
};

export default TwoStepSuccess;
