import styled from 'styled-components';

export const AdminChestsWrap = styled.div`
  display: flex;
  flex: 12;
  flex-direction: column;
  overflow-y: scroll;
  background-color: ${props => props.theme.tableBgColor};
  &.form-footer {
    border-radius: 0 0 5px 5px;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  &.admin-chests {
    flex-direction: column;
  }

  &.team-form {
    flex-direction: row;

    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }
  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
`;

export const AdminChestsFormWrap = styled.div`
  display: flex;
  flex: 12;
  flex-direction: column;
  background-color: ${props => props.theme.tableBgColor};
  &.form-footer {
    border-radius: 0 0 5px 5px;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  &.admin-chests {
    flex-direction: column;
  }

  &.team-form {
    flex-direction: row;

    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }
  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
`;
