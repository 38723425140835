import styled from 'styled-components';
import Moon from '../../../images/moon.svg';
import Sun from '../../../images/sun.svg';

const ThemeCheckBox = styled.div`
  .toggle-switch {
    width: 50px;

    @media (-webkit-device-pixel-ratio: 1.5) {
      width: 50px;
    }
  }

  .toggle-switch-checkbox {
    display: none;
  }

  .toggle-switch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #4c5374;
    border-radius: 20px;
    margin: 0;
    position: relative;
  }

  .toggle-switch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0s ease-in 0s;
  }

  .toggle-switch-inner::before,
  .toggle-switch-inner::after {
    display: block;
    float: left;
    width: 50%;
    height: 24px;
    padding: 0;
    box-sizing: border-box;

    @media (-webkit-device-pixel-ratio: 1.5) {
      height: 24px;
      line-height: 24px;
    }
  }

  .toggle-switch-inner::before {
    content: '';
    background-color: #e7e9ee;
    color: #98a2bb;
  }

  .toggle-switch-inner::after {
    content: '';
    background-color: #252a41;
    color: #98a2bb;
    text-align: right;
  }

  .toggle-switch-switch {
    display: block;
    width: 24px;
    height: 24px;
    margin: 0;
    background-color: ${({ theme }) => theme.themeBg};
    background-image: url(${Moon});
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    border: 0 solid #ccc;
    border-radius: 20px;
    transition: all 0.1s ease-in 0.1s;
    left: 0;

    @media (-webkit-device-pixel-ratio: 1.5) {
      width: 16px;
      height: 16px;
      background-size: 50%;
      margin: 4px 0 5px 0;
      left: 5px;
    }
  }

  .toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
    margin-left: 0;
  }

  .toggle-switch-checkbox:checked + .toggle-switch-label {
    border: 1px solid ${({ theme }) => theme.themeBg};

    .toggle-switch-switch {
      left: 50%;
      background-color: ${({ theme }) => theme.themeBg};
      background-image: url(${Sun});
      background-repeat: no-repeat;
      background-position: center;
    }

    @media (-webkit-device-pixel-ratio: 1.5) {
      .toggle-switch-switch {
        left: 60%;
      }
    }
  }

  @media screen and (max-width: 991px) {
    .toggle-switch {
      transform: scale(0.9);
    }
  }

  @media screen and (max-width: 767px) {
    .toggle-switch {
      transform: scale(0.825);
    }
  }

  @media screen and (max-width: 575px) {
    .toggle-switch {
      transform: scale(0.75);
    }
  }
`;

export default ThemeCheckBox;
