import styled from 'styled-components';

export const SearchWrap = styled.div`
  display: flex;
  flex: 1;
  border-bottom: 1px ${props => props.theme.bgColor} solid;

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
  }
  @media screen and (min-width: 1921px) {
    flex: 0.5;
  }
`;

export const SquaredButtonStyle = styled.div`
  display: inline-flex;
  background-color: ${props => (props.context ? props.theme.adminSelectionColor : props.theme.fieldBgColor)};
  padding: 1.25rem;
  color: ${props => (props.context ? '#ffffff' : props.theme.fieldTextColor)};
  cursor: pointer;
  align-items: center;

  &:hover {
    background-color: ${props => props.theme.adminSelectionColor};
    color: #ffffff;

    > div > svg {
      path {
        fill: #ffffff;
      }
    }
  }

  @media screen and (max-width: 767px) {
    border-bottom: 1px solid ${props => props.theme.bgColor};
    border-radius: 0 5px 0 0;
    flex: 0 0 42.4%;
    max-width: 42.4%;
    justify-content: center;
  }
`;

export const RoundedButtonStyle = styled.div`
  display: inline-flex;
  background-color: ${props => (props.context ? props.theme.adminSelectionColor : props.theme.fieldBgColor)};
  padding: 1.25rem;
  color: ${props => (props.context ? '#ffffff' : props.theme.fieldTextColor)};
  border-radius: 5px 0 0 0;
  cursor: pointer;
  align-items: center;

  &:hover {
    background-color: ${props => props.theme.adminSelectionColor};
    color: #ffffff;

    > div > svg {
      path {
        fill: #ffffff;
      }
    }
  }

  @media screen and (max-width: 767px) {
    border-bottom: 1px solid ${props => props.theme.bgColor};
    border-radius: 5px 0 0 0;
    flex: 0 0 38.1%;
    max-width: 38.1%;
    justify-content: center;
  }
`;

export const IconStyle = styled.div`
  margin-right: 0.75rem;
  svg {
    display: block;
    width: 21px;
    height: 21px;
    path {
      fill: ${props => (props.context ? '#ffffff' : props.theme.fieldTextColor)};
    }

    @media (-webkit-device-pixel-ratio: 1.5) {
      width: 16px;
      height: 16px;
    }
  }
`;

export const AdminSearchBarWrap = styled.div`
  display: flex;
  flex: 2;
  position: relative;

  > input {
    width: 100%;
    box-sizing: border-box;
    font-family: 'Lato';
    color: ${props => props.theme.searchColor};
    border: none;
    font-size: 1.2rem;
    padding: 1.25rem 1.75rem;
    border-left: 1px ${props => props.theme.bgColor} solid;
    background-color: ${props => props.theme.searchBgColor};
    border-radius: 0 5px 0 0;

    ::placeholder {
      opacity: 0.5;
      color: ${props => props.theme.searchColor};
    }

    &:focus {
      outline: none;
    }

    @media screen and (max-width: 767px) {
      border-left: none;
      border-radius: 0;
    }
  }

  > i {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 24px;
    font-size: 24px;
    color: ${props => props.theme.placeholderColor};
    top: 0;
    right: 1.75rem;
    height: 100%;

    @media (-webkit-device-pixel-ratio: 1.5) {
      min-width: 18px;
      font-size: 18px;
    }

    @media screen and (max-width: 767px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
`;
