import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Mousetrap from 'mousetrap';
import { TextInput } from '../../common/forms/TextInput';
import {
  FormWrapper,
  SectionWrapper,
  SubSectionWrapper,
  SectionHeadingWrapper,
  SectionSwitchWrapper,
} from './styles/AdminFormStyle';
import { Icon } from '../../common/Icon';
import ToggleSwitch from '../../common/checkboxes/ToggleSwitch';
import { blur } from '../../utils/commonFunctions';
import { ModalContext } from '../../context';
import LinkFormSection from './subComponents/LinkFormSection';
import { reverseAll } from '../../utils/arrayFunctions';

export const UserForm = ({
  edit,
  owner,
  userName,
  setUserName,
  userEmail,
  setUserEmail,
  admin,
  setAdmin,
  teams,
  setTeams,
  user,
  userId,
}) => {
  const { setModal } = useContext(ModalContext);
  const history = useHistory();

  // useEffect for keyboard shortcuts
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      Mousetrap.bind('esc', () => escShortcut(document.activeElement.id));
      Mousetrap.bind('i', focus, 'keyup');
    }
    return () => {
      isMounted = false;
      Mousetrap.unbind('esc');
      Mousetrap.unbind('i', 'keyup');
    };
  });

  const exit = () =>
    history.push({
      pathname: '/admin/teams',
      prevPathname: history.location.pathname,
    });

  // Function for escape key
  const escShortcut = id =>
    id === 'user-name-input' ||
    id === 'user-email-input' ||
    id === 'user-admin-toggle' ||
    id === 'user-notifications-toggle'
      ? blur(id)
      : exit();

  // Function for name input to gain focus
  const focus = () =>
    document.getElementById('user-name-input') !== null &&
    document.activeElement !== document.getElementById('user-email-input')
      ? document.getElementById('user-name-input').focus()
      : null;

  const addedTeams = teams.filter(team => team.added === true);

  const handleTeamsModalConfirm = array => {
    const reversedVals = reverseAll(teams, array, 'added');
    setTeams(reversedVals);
    setModal();
  };

  const teamLinkModal = () =>
    setModal({
      key: 'Link',
      params: {
        headerText: 'Link: Teams',
        data: teams,
        confirmAction: handleTeamsModalConfirm,
        cancelAction: () => setModal(),
      },
    });

  const renderTeams = () => <LinkFormSection selectedArr={teams} setSelectedArr={setTeams} name={'Team'} />;

  return (
    <FormWrapper>
      <SectionWrapper className="input-padding">
        <TextInput
          placeholder="Full Name"
          label="Full Name"
          value={userName}
          handleChange={value => setUserName(value)}
          id="user-name-input"
          mousetrap
          autoFocus
        />
        <SubSectionWrapper>
          <TextInput
            className={!edit && 'non-edit'}
            readOnly={!edit}
            placeholder="Email"
            label="Email"
            value={userEmail}
            handleChange={value => setUserEmail(value.toLowerCase())}
            id="user-email-input"
            mousetrap
          />

          <SectionSwitchWrapper>
            <span>Administrator</span>
            <ToggleSwitch
              id="user-admin-toggle"
              disabled={user.id === userId || owner}
              onClick={() => setAdmin(!admin)}
              checked={admin}
            />
          </SectionSwitchWrapper>
        </SubSectionWrapper>
      </SectionWrapper>
      <SectionWrapper>
        <SectionHeadingWrapper
          role={'section-heading-wrapper'}
          onClick={teamLinkModal}
          tabIndex="0"
          onKeyPress={e => (e.key === 'Enter' ? teamLinkModal() : null)}
        >
          <Icon name="add" width="21px" />
          Add this User to a Team (added to 'Everyone' as default)
        </SectionHeadingWrapper>
        {addedTeams.length > 0 && renderTeams()}
      </SectionWrapper>
    </FormWrapper>
  );
};
