import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import Mousetrap from 'mousetrap';
import { AdminChestsWrap } from './styles/AdminChestsWrap';
import AdminTableWrap from '../adminTable/styles/AdminTableWrap';
import Table from '../../table/Table';
import { getHeaders } from '../adminHeader/AdminHeaderInfo';
import { getSearchable } from '../../table/subFunctions/searchableFunctions';
import { goToLocation } from '../adminFunctions/redirectByUrl';
import { GetChestSecrets } from '../../../api/chests/ChestApiFunctions';
import LogoutRedirect from '../../../common/nav/LogoutRedirect';
import { AdminChestContext, AdminContext } from '../../../context/AdminContext';
import { ModalContext } from '../../../context/index';
import TableHeader from '../../../common/admin/header/TableHeader';
import Request from '../../../api/Request';
import { reshareData } from '../../../api/secretKeys/SecretKeyApiFunctions';

const AdminChestsShow = ({ searchText, context, history }) => {
  const [data, setData] = useState([]);
  const setSearchPlaceholder = useContext(AdminContext).searchPlaceholder[1];
  const { modal } = useContext(ModalContext);
  const setContext = useContext(AdminContext).context[1];
  const { location } = history;
  const pathname = location['pathname'] || '/admin/teams';
  const row = useMemo(() => {
    if (location['row']) return location['row'];
    if (location['state'] && location['state']['row']) return location['state']['row'];
    return [];
  }, [location]);
  const headers = getHeaders('Secrets')();
  const searchable = getSearchable('SecretsShow');
  const url = goToLocation(context, pathname);
  const columnWidths = ['25%', '10%', '15%', '30%', '15%', '5%'];

  const adminTable = {
    adminChestTable: [data, setData],
  };

  useEffect(() => {
    let isMounted = true;
    if (row.id !== undefined) {
      GetChestSecrets(row.id, null, true).then(result => {
        if (isMounted) {
          setData(result);
          setSearchPlaceholder('Search for a secret');
          setContext('Chests');
        }
      });
    }
    return () => (isMounted = false);
  }, [row, setSearchPlaceholder, setContext]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      Mousetrap.bind('esc', () => !modal && history.push({ pathname: '/admin/chests', prevPathname: pathname }));
    }
    return () => {
      isMounted = false;
      Mousetrap.unbind('esc');
    };
  });

  if (row.length === 0) return <Redirect to={'/admin/chests'} />;
  const redirectTo = newRow =>
    history.push({
      pathname: url,
      row: newRow,
      prevRow: row,
      context,
      prevPathname: pathname,
    });

  const onRemove = async (selRow, rowType) => {
    const assignType = rowType === 'teams' ? 'team_id' : 'chest_id';
    const removeAssignment = row['assignments'].map(ass =>
      ass[assignType] === selRow['id'] ? { ...ass, _destroy: 1 } : ass,
    );
    const params = {
      vault: {
        name: row['name'],
        assignments_attributes: removeAssignment,
      },
      options: ['users', 'teams'],
    };
    const handler = new Request('PATCH', `/api/v1/vaults/${row['id']}`, params);
    const res = await handler.perform();
    if (res['status'] === 200) {
      res['json'].users.forEach(async user => await reshareData(user.id, null));
      history.push({
        state: {
          row: {
            ...row,
            ['assignments']: res['json'].assignments,
            ['teams']: res['json'].teams,
            ['users']: res['json'].users,
          },
        },
      });
    }
  };

  if (!data) return <LogoutRedirect />;
  return (
    <AdminChestContext.Provider value={adminTable}>
      <AdminChestsWrap>
        <TableHeader row={row} icon={'chest'} onRemove={onRemove} />
        <AdminTableWrap id="Table">
          <Table
            columnWidths={columnWidths}
            headers={headers}
            data={data}
            searchable={searchable}
            searchText={searchText}
            onClick={redirectTo}
          />
        </AdminTableWrap>
      </AdminChestsWrap>
    </AdminChestContext.Provider>
  );
};

export default AdminChestsShow;
