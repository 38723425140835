import React, { useState, Fragment, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Mousetrap from 'mousetrap';
import { AdminTeamFormWrap } from './styles/AdminTeamsWrap';
import { TeamForm } from '../../forms/TeamForm';
import { AddTeamOptions } from './AddTeamOptions';
import { AdminChestAddWrap, FakeArea } from '../adminChests/styles/AdminChestAddStyles';
import { SaveFooter } from '../adminFooter/footers/SaveFooter';
import { CreateTeam, TeamTableData } from '../../../api/teams/TeamApiFunctions';
import { getValidation } from '../../../utils/validationSuite/validationIndex';
import { useNotification } from '../../../common/notification/Notification';
import { ChestTableData } from '../../../api/chests/ChestApiFunctions';
import { UserTableData } from '../../../api/users/UserApiFunctions';
import LogoutRedirect from '../../../common/nav/LogoutRedirect';
import { reshareData } from '../../../api/secretKeys/SecretKeyApiFunctions';
import GenericFormLoading from '../../../common/genericLoading/GenericFormLoading';
import { AdminContext } from '../../../context/AdminContext';
import { checkActiveElement } from '../../../utils/commonFunctions';

/* Component for adding in a new team, with assigned chests and users
   with the team form. */
export const AdminTeamAdd = () => {
  const [teamName, setTeamName] = useState('');
  const [chests, setChests] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const setTeamTags = useContext(AdminContext).teamTags[1];
  const history = useHistory();

  const { setNotificationText } = useNotification();

  /* useEffect responsible for retrieving all chests and prepping them
      for the forms selection */
  useEffect(() => {
    let isMounted = true;
    ChestTableData({ everything: true }).then(result => {
      if (result) {
        const chestsArray = result.map(chest => {
          return {
            id: chest.id,
            name: chest.name,
            secrets: chest.secrets,
            added: false,
            selected: false,
            edit_access: false,
          };
        });
        if (isMounted) setChests(chestsArray);
      } else {
        if (isMounted) setRedirect(true);
      }
    });
    return () => (isMounted = false);
  }, []);

  /* useEffect responsible for retrieving all users and prepping them
      for the forms selection */
  useEffect(() => {
    let isMounted = true;
    UserTableData({ everything: true }).then(result => {
      if (result) {
        const usersArray = result.map(user => {
          return {
            id: user.id,
            name: user.name,
            email: user.email,
            added: false,
            selected: false,
          };
        });
        if (isMounted) setUsers(usersArray);
      } else {
        if (isMounted) setRedirect(true);
      }
    });
    return () => (isMounted = false);
  }, []);

  // useEffect for keyboard shortcuts
  useEffect(() => {
    let isMounted = true;
    if (isMounted) Mousetrap.bind('shift+s', shiftS);
    Mousetrap.bind('enter', enter);
    return () => {
      isMounted = false;
      Mousetrap.unbind('shift+s');
      Mousetrap.unbind('enter');
    };
  });

  const shiftS = () => {
    return checkActiveElement('team-name-input') ? null : createTeam();
  };
  const enter = () => {
    if (document.activeElement === document.getElementsByClassName('footer-button')[0]) {
      createTeam();
    } else if (document.activeElement === document.getElementsByClassName('footer-button')[1]) {
      history.goBack();
    }
  };
  /* Function that handles the prepping of the JSON data object to be sent
    through the API, the creation and the resharing of secret keys after */
  const createTeam = () => {
    const validationId = 'TeamForm';
    const addedChests = chests.filter(chest => chest.added === true);
    const addedUsers = users.filter(user => user.added === true);
    const params = {
      data: { teamName, addedChests, addedUsers },
      setNotificationText,
    };
    if (getValidation(validationId)(params)) {
      setLoading(true);
      const chestData = addedChests.map(chest => ({
        vault_id: chest.id,
        edit_access: chest.edit_access,
      }));
      const userData = addedUsers.map(user => ({ user_id: user.id }));
      const data = {
        team: {
          name: teamName,
          memberships_attributes: userData,
          assignments_attributes: chestData,
        },
      };
      CreateTeam(data).then(async () => {
        userData.forEach(async user => {
          await reshareData(user.user_id);
        });
        setTeamTags(await TeamTableData({ everything: true }));
        history.push({
          pathname: '/admin/teams',
          prevPathname: history.location.pathname,
        });
      });
    }
  };

  if (redirect) return <LogoutRedirect />;
  return (
    <Fragment>
      {loading ? (
        <GenericFormLoading />
      ) : (
        <Fragment>
          <AdminTeamFormWrap role={'admin-team-add'} className="form-footer team-form">
            <AdminChestAddWrap>
              <TeamForm
                teamName={teamName}
                setTeamName={setTeamName}
                chests={chests}
                setChests={setChests}
                users={users}
                setUsers={setUsers}
              />
              <SaveFooter handleSubmit={() => createTeam()} />
            </AdminChestAddWrap>
            <AddTeamOptions />
          </AdminTeamFormWrap>
          <FakeArea></FakeArea>
        </Fragment>
      )}
    </Fragment>
  );
};
