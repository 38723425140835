import { exclusiveConcat } from './mergeFunctions';

export const remove = (arr, val) => arr && arr.filter(obj => obj !== val);
export const reverseAll = (arr, altArr, attr) =>
  exclusiveConcat(
    arr,
    altArr.map(team => reverse(arr, team, attr)),
    'id',
  );
export const reverse = (arr, val, attr) => {
  let obj = _findVal(arr, val);
  obj[attr] = !obj[attr];
  return obj;
};
export const reverseOne = (arr, val, attr) =>
  arr.map(obj => {
    if (obj.id === val.id) return reverse(arr, val, attr);
    return obj;
  });

const _findVal = (arr, val) => arr.filter(obj => obj.id === val.id)[0];
