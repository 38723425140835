export const getUniqueMessage = key => keyMessagePairs[key] || null;

const keyMessagePairs = {
  vault_id: { field: 'dropdown', error: 'Must select Chest from dropdown. ' },
  firstTeam: {
    field: 'dropdown',
    error: 'Please select at least one Team using the dropdown provided.',
  },
  firstChest: {
    field: 'dropdown',
    error: 'Please select at least one Chest using the dropdown provided.',
  },
};
