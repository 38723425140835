import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Mousetrap from 'mousetrap';
import { useNotification } from '../../common/notification/Notification';
import {
  ProfileContainer,
  ProfileHeader,
  ProfileHeadText,
  ProfileFooter,
  ProfileBody,
} from './styles/ProfileSettingsStyles';
import FooterTag from '../../common/links/FooterTag';
import { Icon } from '../../common/Icon';
import Setting from '../../common/settings/Setting';
import ProfileApi from '../../api/profiles/ProfileApi';
import { ThemeContext } from 'styled-components';
import { placeholderFunc } from '../../utils/commonFunctions';
import TwoFactorApi from '../../api/twofactor/TwoFactorApi';
import { UserContext } from '../../context/UserProvider';
import { ModalContext } from '../../context';

const ProfileSettings = () => {
  const themeContext = useContext(ThemeContext);
  const { setModal } = useContext(ModalContext);
  const { user, update, setUser } = useContext(UserContext);
  const { setNotificationText } = useNotification();
  const [profileName, setProfileName] = useState(user.name);
  const [activeSetting, setActiveSetting] = useState('');

  const history = useHistory();
  const goBack = () => history.goBack();

  const handleEsc = () =>
    history.push({
      pathname: history.location.prevPathname,
      prevPathname: history.location.pathname,
    });

  // Keyboard shortcuts
  const profileMousetrap = new Mousetrap();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      profileMousetrap.bind('esc', handleEsc, 'keyup');
    }
    return () => {
      isMounted = false;
      profileMousetrap.unbind('esc', 'keyup');
    };
  });

  const editProfileName = () => {
    const params = {
      user: { name: profileName },
    };
    update('name', profileName);
    ProfileApi.update(params).then(async () => setUser(await ProfileApi.show()));
  };

  const twoStepText = () => {
    if (!user) return '';
    if (!user['otp_required_for_login']) return 'Enable two-step verification';

    return 'Disable two-step verification';
  };

  const twoStepFunc = () => {
    if (!user) return placeholderFunc();
    if (user['otp_required_for_login'])
      return TwoFactorApi.disableOtp().then(res => {
        if (res['2fa'].includes('Success')) {
          setNotificationText({ text: res['2fa'] });
          setModal();
          return update('otp_required_for_login', false);
        }
        setNotificationText({ type: 'error', text: res['2fa'] });
        setModal();
      });
    history.push({
      pathname: '/profile/twofactor',
    });
    setModal();
  };

  const twoStepModal = () =>
    user['otp_required_for_login']
      ? setModal({
          key: 'Confirm',
          params: {
            icon: 'alert',
            question: `Are you sure you want to disable 2FA?`,
            subtext: 'This will allow anybody with your account password to access the account.',
            confirmAction: twoStepFunc,
            cancelAction: () => setModal(),
          },
        })
      : twoStepFunc();

  return (
    <ProfileContainer>
      <ProfileHeader>
        <ProfileHeadText>
          {themeContext.scheme === 'dark' ? (
            <Icon name="settings_white" width="20px" />
          ) : (
            <Icon name="settings" width="20px" />
          )}
          Account Settings
        </ProfileHeadText>
        <hr />
      </ProfileHeader>
      <ProfileBody>
        <Setting
          name="Display Name"
          activeSetting={activeSetting}
          setActiveSetting={setActiveSetting}
          type="input"
          text={profileName}
          setText={setProfileName}
          buttonName="Edit"
          onClick={() => editProfileName()}
        />
        <Setting
          name="Email"
          activeSetting={activeSetting}
          setActiveSetting={setActiveSetting}
          type="input"
          text={user.email}
          buttonName="Edit"
          readOnly={true}
          onClick={() => setModal({ key: 'Email', params: { user, setModal } })}
        />
        <Setting
          name="Password"
          activeSetting={activeSetting}
          setActiveSetting={setActiveSetting}
          type="input"
          text={'*************'}
          buttonName="Change Password"
          readOnly={true}
          onClick={() => setModal({ key: 'Password', params: { user, setModal } })}
        />
        <hr />
        <Setting
          name="Two-Step Verification"
          activeSetting={activeSetting}
          setActiveSetting={setActiveSetting}
          type="text"
          text={'Protect your account from un-authorized access by enabling Two-Factor Verification.'}
          buttonName={twoStepText()}
          readOnly={true}
          onClick={twoStepModal}
        />
      </ProfileBody>
      <ProfileFooter>
        <FooterTag
          onClick={goBack}
          fontIcon="fas fa-arrow-alt-circle-left"
          text="Go back"
          keyText=""
          icon={<Icon name="esc" className="esc-key" width="65px" />}
        />
      </ProfileFooter>
    </ProfileContainer>
  );
};

export default ProfileSettings;
