import styled from 'styled-components';

export const UsersName = styled.div`
  margin-right: 1rem;

  &:empty {
    display: none;
  }
`;

export const SubText = styled.div`
  font-size: 0.9rem;
  opacity: 0.5;
  margin: 0;
`;
