import styled from 'styled-components';

export const Input = styled.input`
  box-sizing: border-box;
  background-color: inherit;
  font-family: 'Lato';
  border: 1px ${({ theme }) => theme.highlightColor} solid;
  border-radius: 3px;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  color: ${props => props.theme.fieldTextColor};
  outline: none;
  margin: 1rem 1rem 0 1rem;

  ::placeholder {
    color: ${props => props.theme.placeholderColor};
  }
`;
