import React from 'react';
import { Icon } from '../../../../common/Icon';
import { IconWrap, BarWrap, UnlockWrap, Wrap, Title, Unlock } from './styles/LockedSidebarStyles';

const LockedSidebar = ({ icon = 'team', barText = 'Teams & Users', history }) => {
  const handleClick = () => history.push('/plans');

  return (
    <Wrap>
      <IconWrap>
        <Icon name={icon} width={'20px'} />
      </IconWrap>
      <BarWrap>
        <Title>{barText}</Title>
        <UnlockWrap role={'unlock-wrap'} onClick={handleClick}>
          <Icon name={'locked'} width={'18px'} />
          <Unlock>Unlock</Unlock>
        </UnlockWrap>
      </BarWrap>
    </Wrap>
  );
};

export default LockedSidebar;
