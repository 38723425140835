import { Password } from '../../steganography/password';

interface PrehashParams {
  email: string;
  password: string;
  [key: string]: any;
}

/*
  The idea of "controllers" is for when the Request class requires specific
  changes to the request such as encryption, take the prehash function below;
  some requests require that the password of the user be encrypted, now this
  class can be inherited from and the code becomes much simpler.
*/
class BaseController {
  async prehash(params: PrehashParams) {
    const { email, password } = params;
    const passHash = await Password.prehash(email, password);
    return { ...params, password: passHash };
  }
}

export default BaseController;
