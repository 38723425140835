import styled from 'styled-components';

export const DropdownArrowWrapper = styled.div`
  padding-right: 2rem;
  display: flex;
`;

export const MultipleChoiceWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  background-color: inherit;
  font-family: 'Lato';
  border: none;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  color: #6a6e7f;
  outline: none;
  display: flex;
  cursor: inherit;
`;
