import styled from 'styled-components';

export const ValidationWrap = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  padding: 2rem;
  width: 100%;
  background-color: ${({ error, theme }) => (error ? theme.error : theme.highlightColor)};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #ffffff;
  line-height: 1.5rem;
  font-size: 1.15rem;
  box-sizing: border-box;
  z-index: 9999;
  text-align: center;
`;
