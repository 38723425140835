import styled from 'styled-components';

export const AdminFooterContainer = styled.div`
  display: flex;
  flex: 0 1 auto;
  background-color: ${props => props.theme.formFooterBgColor};
  border-radius: 0 0 5px 5px;

  &.leftSplit {
    border-radius: 0 0 0 5px;
  }
`;

export const AdminViewFooterContainer = styled.div`
  display: flex;
  flex: 1;

  .sub-foot:nth-child(1) {
    flex: 1.578;
    margin-right: 0;
    border-radius: 0 0 0 5px;
  }

  .sub-foot:nth-child(2) {
    flex: 1;
    margin-right: 0;
    border-radius: 0 0 5px 0;
    background-color: ${props => props.theme.sButtonBgColor};
  }

  @media screen and (max-width: 767px) {
    .sub-foot {
      flex: 1 !important;
    }
  }
`;
