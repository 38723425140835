import React from 'react';
import { ReactComponent as VisaIcon } from '../images/cardIcons/visa.svg';
import { ReactComponent as AmexIcon } from '../images/cardIcons/amex.svg';
import { ReactComponent as DinersIcon } from '../images/cardIcons/diners.svg';
import { ReactComponent as DiscoverIcon } from '../images/cardIcons/discover.svg';
import { ReactComponent as JcbIcon } from '../images/cardIcons/jcb.svg';
import { ReactComponent as MasterCardIcon } from '../images/cardIcons/mastercard.svg';
import { ReactComponent as UnionIcon } from '../images/cardIcons/unionpay.svg';
import { ReactComponent as BlankIcon } from '../images/cardIcons/recrypt_card.svg';
import { ReactComponent as GenericIcon } from '../images/cardIcons/generic.svg';

const CardIcon = ({ name, width = '14px' }) => {
  switch (name) {
    case 'visa':
      return <VisaIcon role={'VisaIcon'} width={width} />;
    case 'Amex':
      return <AmexIcon role={'AmexIcon'} width={width} />;
    case 'Diners Club':
      return <DinersIcon role={'DinersIcon'} width={width} />;
    case 'Discover':
      return <DiscoverIcon role={'DiscoverIcon'} width={width} />;
    case 'JCB':
      return <JcbIcon role={'JcbIcon'} width={width} />;
    case 'Mastercard':
      return <MasterCardIcon role={'MasterCardIcon'} width={width} />;
    case 'UnionPay':
      return <UnionIcon role={'UnionIcon'} width={width} />;
    case 'blank':
      return <BlankIcon role={'BlankIcon'} width={width} />;
    default:
      return <GenericIcon role={'GenericIcon'} width={width} />;
  }
};

export default CardIcon;
