export const mergeArrays = arrays => cleanArray(arrays.flat(2));

export const cleanArray = arr => arr.filter(entry => entry !== null);

export const exclusiveConcat = (arrOne, arrTwo, attr) =>
  arrOne.map(objOne => {
    const match = arrTwo.filter(objTwo => objOne[attr] === objTwo[attr])[0];
    if (match) return match;
    return objOne;
  });
