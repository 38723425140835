import React, { Fragment } from 'react';
import Loading from '../../images/loading_01-1.gif';
import { LoadingFormArea, LoadingFormImage, LoadingFakeArea } from '../forms/styles/Loading';

const GenericFormLoading = () => {
  return (
    <Fragment>
      <LoadingFormArea role={'GenericFormLoading'}>
        <LoadingFormImage role={'LoadingFormImage'} src={Loading} alt="Loading..." />
      </LoadingFormArea>
      <LoadingFakeArea role={'LoadingFakeArea'} />
    </Fragment>
  );
};

export default GenericFormLoading;
