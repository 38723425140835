import styled from 'styled-components';

export const TitleWrap = styled.div`
  display: flex;
  flex: 0 1 10%;
  text-align: left;
  line-height: 140%;
  flex-direction: row;
  font-size: 1.5rem;
`;

export const Action = styled.div`
  padding-right: 7px;
  font-weight: 400;
`;

export const Name = styled.div`
  font-weight: 400;
`;

export const SubTitle = styled.div`
  flex: 0 1 10%;
  font-size: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0;
  text-align: left;
`;

export const Footer = styled.div`
  flex: 0 1 12.5%;
  border-top: 1px solid ${props => props.theme.tableHeaderBorder};
  padding: 0.75rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const Confirm = styled.div`
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.highlightColor};
    .st0 {
      fill: ${({ theme }) => theme.highlightColor};
    }
  }

  #action {
    margin-left: 10px;
  }

  svg {
    vertical-align: text-top;
    .st0 {
      fill: ${({ theme }) => theme.textColor};
    }
  }
`;

export const Shortcut = styled.div`
  span {
    margin-right: 0.5rem;
  }
  svg {
    vertical-align: text-bottom;
  }
`;
