import styled from 'styled-components';

export const FooterTagWrap = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  margin: 0 auto;
  width: 50%;
  border-radius: 0px 0px 5px 5px;
  box-sizing: border-box;
  background-color: ${props => props.theme.formFooterBgColor};

  @media screen and (max-width: 767px) {
    width: 95%;
  }
`;

export const FooterTagBar = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 1.1rem 1.75rem 1.1rem 1.75rem;
`;

export const LinkWrap = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;

  .footer-button {
    margin: 0 1rem 0 0;
  }
`;

export const FooterButton = styled.span`
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 1rem;
  text-align: left;
  color: #98a2bb;
  cursor: pointer;

  > i {
    padding-right: 10px;
  }

  .expandable {
    flex: 12;
  }

  > svg {
    margin-left: 0.35rem;

    @media (-webkit-device-pixel-ratio: 1.5) {
      max-width: 12px;
      &.esc-key {
        max-width: 38px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    &:not(.footer-button) {
      display: none;
      + p {
        display: none;
      }
    }
  }
`;
