/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';

const defaultInactivityLength = 10;

export const InactivityTimeoutContext = React.createContext({
  inactivityLength: defaultInactivityLength,
  setInactivityLength: () => {},
});

export const InactivityTimeoutContextProvider = props => {
  const setInactivityLength = inactivityLength => {
    setState({ ...state, inactivityLength: inactivityLength });
  };

  const initState = {
    inactivityLength: defaultInactivityLength,
    setInactivityLength: setInactivityLength,
  };

  const [state, setState] = useState(initState);
  return <InactivityTimeoutContext.Provider value={state}>{props.children}</InactivityTimeoutContext.Provider>;
};
