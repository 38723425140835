import styled from 'styled-components';

export const WelcomeButtonWrap = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  max-height: 2.5rem;
  height: fit-content;
  margin: 0 0 1rem 0;
  align-self: center;
  justify-content: center;
  background-color: ${props => props.theme.tableBgColor};
  border-radius: 0.3rem;
  padding: 1rem;
  cursor: pointer;
  border: 1px solid ${props => props.theme.fieldBorderColor};
  color: ${props => props.theme.fieldTextColor};
  line-height: 1.5rem;
  overflow: hidden;
  align-items: center;
  transition: all 0.3s ease-in-out;
  outline: none;

  &:hover,
  &:focus {
    background-color: ${props => props.theme.welcomeButtonHover};
  }

  &[disabled] {
    opacity: 0.5;

    svg path {
      fill: ${props => props.theme.fieldTextColor};
    }
  }
`;

export const TextWrap = styled.div`
  display: inline-flex;
  font-size: 1.1rem;
`;
export const IconWrap = styled.div`
  display: inline-flex;
  line-height: 2rem;
  margin: 0 0.75rem 0 0;
`;
