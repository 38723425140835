import React, { useState, useEffect, useContext, useMemo } from 'react';
import Mousetrap from 'mousetrap';
import { TableWrap } from '../table/styles/TableStyles';
import { ChestHeader } from './subComponents/ChestHeader';
import TableHeader from '../table/subComponents/TableHeader';
import TableBody from '../table/subComponents/TableBody';
import { searchFunction } from '../../utils/searchFunctions';
import { sortTable } from '../../utils/sortFunctions';
import { determineColumnSort } from '../table/subFunctions/tableFunctions';
import { searchableHeaders } from '../table/subFunctions/headerFunctions';
import { GetChestSecrets } from '../../api/chests/ChestApiFunctions';
import LogoutRedirect from '../../common/nav/LogoutRedirect';
import TableLoading from '../table/subComponents/TableLoading';
import { ChestWrap } from './styles/ViewChestStyles';
import { ModalContext } from '../../context';
import { OptionsModalProvider } from '../../context/OptionsModalContext';
import { DashboardViewChestContext } from '../../context/DashboardContext';
import LocalStore from '../../api/LocalStore';
import { UserContext } from '../../context/UserProvider';

export const ViewChest = ({ chest, setViewSecret, setViewChest }) => {
  const { name, id } = chest;
  const [redirect, setRedirect] = useState(false);
  const [chestSecrets, setChestSecrets] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useContext(UserContext);
  const { modal } = useContext(ModalContext);
  const searchText = '';
  const headers = {
    Icon: 'lock',
    Name: 'name',
    Type: 'type',
    Chest: 'chest',
    Actions: 'secretActions',
  };
  const writeHeaders = useMemo(
    () => ({
      Icon: 'lock',
      Name: 'name',
      Type: 'type',
      Chest: 'chest',
      Actions: 'managerChestActions',
    }),
    [],
  );
  const columnWidths = ['5%', '30%', '20%', '30%', '15%'];
  const searchable = ['Name', 'Chest'];
  const columnHeaders = Object.keys(headers);
  const [jsonKeys, setJsonKeys] = useState(Object.values(headers));
  const [selectedHeader, setSelectedHeader] = useState({
    header: null,
    reverse: false,
  });
  const sortType = determineColumnSort(headers, selectedHeader, chestSecrets);
  const searchHeaders = searchableHeaders(headers, searchable);
  const searchResults = searchFunction(searchHeaders, chestSecrets, searchText, selectedHeader);
  const searchedData = sortTable(sortType)(headers[selectedHeader['header']], selectedHeader, searchResults);

  const dashboardChestInfo = {
    chestSecrets: [chestSecrets, setChestSecrets],
  };

  useEffect(() => {
    let isMounted = true;
    const getChestData = () => {
      GetChestSecrets(id, user.id).then(results => {
        if (isMounted) {
          // map over the api results & add the associated chest's assignments to each secret
          const assignedSecrets = results.map(secret => {
            return { ...secret, chest: { ...secret.chest, assignments: chest.assignments } };
          });
          setChestSecrets(assignedSecrets);
          setLoading(false);
          if (results[0] !== undefined && (results[0].chest.manager || user.administrator)) {
            setJsonKeys(Object.values(writeHeaders));
          }
        } else {
          if (isMounted) setRedirect(true);
        }
      });
    };
    if (loading) getChestData();
    return () => (isMounted = false);
  }, [id, user, loading, setLoading, writeHeaders]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      Mousetrap.bind('esc', () => !modal && setViewChest(null));
    }
    return () => {
      isMounted = false;
      Mousetrap.unbind('esc');
    };
  });

  // Focuses the first row when mounted
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const tableEl = document.getElementById('table');
      if (tableEl) tableEl.focus();
    }
    return () => {
      isMounted = false;
    };
  });

  const handleClose = () => {
    new LocalStore().remove('lastChest');
    setViewChest();
  };

  const renderContent = () => {
    return loading ? (
      <TableLoading />
    ) : (
      <DashboardViewChestContext.Provider value={dashboardChestInfo}>
        <TableHeader
          headers={columnHeaders}
          headerWidths={columnWidths}
          selectedHeader={selectedHeader}
          setSelectedHeader={setSelectedHeader}
        />
        <OptionsModalProvider>
          <TableBody columnWidths={columnWidths} handleRow={setViewSecret} data={searchedData} jsonKeys={jsonKeys} />
        </OptionsModalProvider>
      </DashboardViewChestContext.Provider>
    );
  };

  if (redirect) return <LogoutRedirect />;
  return (
    <ChestWrap>
      <ChestHeader name={name} onClick={handleClose} />
      <TableWrap id="Table">{renderContent()}</TableWrap>
    </ChestWrap>
  );
};
