import React, { useState, useEffect, useContext, useRef } from 'react';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import { AdminPage, AdminSideBar, AdminMainArea } from './styles/AdminStyle';
import { AdminSidebar } from '../components/admin/adminSidebar/AdminSidebar';
import AdminRoutes from '../components/nav/AdminRoutes';
import AdminSearch from '../components/admin/adminSearch/AdminSearch';
import { getHeaderComponent } from '../components/admin/adminHeader/AdminHeaderAlt';
import { determineContext } from '../components/admin/adminFunctions/pageFunctions';
import { getFooterComponent } from '../components/admin/adminFooter/AdminFooterAlt';
import { ChestTableData } from '../api/chests/ChestApiFunctions';
import { TeamTableData } from '../api/teams/TeamApiFunctions';
import LogoutRedirect from '../common/nav/LogoutRedirect';
import { AdminContext } from '../context/AdminContext';
import StripeApi from '../api/stripe/StripeApi';
import { UserContext } from '../context/UserProvider';
import OrganisationApi from '../api/organisation/OrganisationApi';

export const Admin = () => {
  const { user, setUser } = useContext(UserContext);
  const { first_time, administrator } = user;
  const [chestTags, setChestTags] = useState([]);
  const [teamTags, setTeamTags] = useState([]);
  const [subscription, setSubscription] = useState();
  const [searchText, setSearchText] = useState('');
  const [searchPlaceholder, setSearchPlaceholder] = useState('');
  const history = useHistory();
  const { pathname } = useLocation();
  const [context, setContext] = useState(determineContext(pathname));
  const headerComponent = getHeaderComponent(pathname);
  const footerComponent = getFooterComponent(pathname, context);

  const searchBarRef = useRef();
  const contextInfo = {
    context: [context, setContext],
    searchPlaceholder: [searchPlaceholder, setSearchPlaceholder],
    chestTags: [chestTags, setChestTags],
    teamTags: [teamTags, setTeamTags],
    subState: { subscription, setSubscription },
  };

  useEffect(() => {
    ChestTableData({ everything: true }).then(result => {
      setChestTags(result);
    });
    TeamTableData({ everything: true }).then(result => {
      setTeamTags(result);
    });
    StripeApi.getSubscription().then(res => {
      setSubscription(res);
    });
    OrganisationApi.show().then(result => {
      setUser({ ...user, owner_id: result.owner_id });
    });
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (context === 'Chests' && pathname === '/admin/chests') if (isMounted) setSearchPlaceholder('Search for a chest');
    if (context === 'Teams' && pathname === '/admin/teams') if (isMounted) setSearchPlaceholder('Search for a team');
    if (context === 'Chests' && pathname === '/admin/chests/deleted')
      if (isMounted) setSearchPlaceholder('Search for a deleted secret');
    return () => (isMounted = false);
  }, [context, pathname]);

  useEffect(() => {
    searchBarRef.current ? (searchBarRef.current.value = '') : null;
    setSearchText('');
  }, [context]);

  if (!chestTags || !teamTags) return <LogoutRedirect />;
  if (first_time) return <Redirect to={{ pathname: '/welcome' }} />;
  if (administrator === false) return <Redirect to={{ pathname: '/' }} />;

  const renderSidebar = () => {
    return <AdminSidebar chestTags={chestTags} teamTags={teamTags} setContext={setContext} context={context} />;
  };

  const renderSearch = () => (
    <AdminSearch
      placeholder={searchPlaceholder}
      searchText={searchText}
      setSearchText={setSearchText}
      context={context}
      setContext={setContext}
      searchBarRef={searchBarRef}
    />
  );

  return (
    <AdminContext.Provider value={contextInfo}>
      <AdminPage>
        <AdminSideBar>{renderSidebar()}</AdminSideBar>
        <AdminMainArea>
          {headerComponent === null ? renderSearch() : headerComponent()}
          <AdminRoutes searchText={searchText} context={context} history={history} />
          {footerComponent()}
        </AdminMainArea>
      </AdminPage>
    </AdminContext.Provider>
  );
};
