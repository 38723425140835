import React from 'react';
import DropdownOption from '../adminSidebar/adminDropdown/DropdownOption';

export const generateTags = (tags, redirectTo, currentActiveTag) => {
  if (tags === null) return null;
  return tags.map((tag, i) => {
    if (i < tagLimit)
      return (
        <DropdownOption
          key={i}
          tag={tag || 'N/A'}
          onClick={() => redirectTo(tag)}
          currentActive={currentlyActive(tag, currentActiveTag)}
        />
      );
    return null;
  });
};

export const droppedStatus = (linkTo, pathname) => (pathname.includes(linkTo) ? true : false);

export const dropdownMetric = {
  Chests: 'Secrets',
  Teams: 'Users',
};

const currentlyActive = (tag, currentActive) => tag['name'] === currentActive['name'];

const tagLimit = 50;
