import React from 'react';
import { Arrow } from '../../../../../common/arrow/Arrow';
import { DropdownButtonTitleStyle, DropdownButtonText } from './styles/DropdownButtonTitleStyles';

const DropdownButtonTitle = ({ barText, dropped }) => (
  <DropdownButtonTitleStyle>
    <DropdownButtonText role={'dropdown-button-title-text'}>{barText}</DropdownButtonText>
    <div className={'sidebar-arrow'}>
      <Arrow arrowState={dropped} />
    </div>
  </DropdownButtonTitleStyle>
);

export default DropdownButtonTitle;
