import { AES } from '../../steganography/aes';
import { Password } from '../../steganography/password';
import LocalStore from '../LocalStore';
import Request from '../Request';

class LoginReq {
  email: string;
  password: string;
  otp_attempt?: string;

  constructor(email: string, password: string, otp_attempt?: string) {
    this.email = email;
    this.password = password;
    this.otp_attempt = this._cleanOtp(otp_attempt);
  }

  async perform() {
    const params = await this._getParams();
    const handler = new Request('POST', '/users/sign_in.json', params);
    const response = await handler.perform();
    if (response['status'] === 201) await this._setLocalVars(response);
    return response;
  }

  async _getParams() {
    const passHash = await Password.prehash(this.email, this.password);
    return { user: { email: this.email, password: passHash, otp_attempt: this.otp_attempt } };
  }

  async _setLocalVars(response: RecryptRequest) {
    const { headers, json } = response;
    const handler = new LocalStore();
    const raw = await this._getRaw();
    handler.set('raw', raw);
    handler.set('token', headers.get('Authorization'));
    handler.set('privateKey', json['encrypted_private_key']);
    handler.set('iv', json['private_key_iv']);
    handler.set('dark_mode', json['dark_mode']);
  }

  async _getRaw() {
    const key = await Password.masterKey(this.email, this.password);
    return await AES.exportKey(key);
  }

  _cleanOtp(otp_attempt: string | undefined) {
    if (!otp_attempt) return otp_attempt;
    if (otp_attempt.length > 6) return otp_attempt;
    const noSpaces = otp_attempt.replace(' ', '');
    return noSpaces.slice(0, 6);
  }
}

export default LoginReq;
