import React from 'react';
import { SectionHeading, SectionHR } from '../billing/styles/BillingStyles';
import { AccountsTable } from './styles/AccountsSectionStyles';
import Table from '../../../table/Table';

export const AccountsSection = ({ accounts, setLoading }) => {
  // Status of user (accepted / pending) needs to be added to the table when API data provides it
  const headers = {
    User: 'name',
    Email: 'email',
    Status: 'confirmed',
    AccountsActions: 'billingAccountsActions',
  };

  const columnWidths = ['32%', '32%', '32%', '4%'];

  const pendingUsers = () => accounts.filter(user => user['confirmed'] === 'Pending').length;

  return (
    <div className="not-first-section">
      <SectionHeading className="accounts-heading">
        <b>Accounts</b>
        {accounts && (
          <span>
            {accounts.length} users - {pendingUsers()} Pending
          </span>
        )}
      </SectionHeading>
      <SectionHR />
      <AccountsTable className="accounts-table">
        {accounts && <Table columnWidths={columnWidths} headers={headers} data={accounts} params={{ setLoading }} />}
      </AccountsTable>
    </div>
  );
};
