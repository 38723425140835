import styled from 'styled-components';

export const SecretHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.theme.highlightColor};
  width: 100%;
  color: ${props => props.theme.hoverRowColor};
  padding: 0.15rem 1.25rem;
  box-sizing: border-box;

  svg {
    margin-right: 10px;

    @media (-webkit-device-pixel-ratio: 1.5) {
      max-width: 16px;
    }
  }
`;

export const TitleWrapper = styled.div``;

export const CopyText = styled.div`
  color: ${props => props.theme.hoverRowColor};
  font-size: 0.9rem;
  margin-right: 1.25rem;
`;

export const IconButton = styled.i`
  color: ${props => props.theme.hoverRowColor};
  margin-right: 1.25rem;
  cursor: pointer;
  transition: color 0.3s ease-in-out;

  &:active,
  &:hover {
    color: ${props => props.theme.hoverRowColor};
    visibility: visible;
  }
`;
