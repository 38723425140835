import React, { Fragment } from 'react';
import {
  FullWrapperFieldset,
  HalfWrapperFieldset,
  QuarterWrapperFieldset,
  Legend,
  InnerWrapper,
  Input,
  IconWrap,
} from './styles/TextInputStyles';
import { Icon } from '../Icon';

export const TextInput = ({
  handleChange,
  value,
  label,
  placeholder,
  width,
  rightIcon,
  leftIcon,
  rightIconClick,
  leftIconClick,
  type,
  readOnly,
  className,
  id,
  autoFocus,
  mousetrap,
  maxLength,
}) => {
  const renderRightIcon = () => {
    return typeof rightIcon === 'string' ? (
      <IconWrap role={'right-icon'} onClick={() => rightIconClick()}>
        <Icon name={rightIcon} width="25px" />
      </IconWrap>
    ) : (
      rightIcon()
    );
  };

  const renderLeftIcon = () => {
    return typeof leftIcon === 'string' ? (
      <IconWrap role={'left-icon'} onClick={() => leftIconClick()}>
        <Icon name={leftIcon} width="18px" />
      </IconWrap>
    ) : (
      leftIcon()
    );
  };

  const renderWrapper = () => {
    if (width === 'half') {
      return (
        <Fragment>
          <HalfWrapperFieldset role={'half-wrapper-fieldset'} className={className}>
            {renderInternals()}
          </HalfWrapperFieldset>
        </Fragment>
      );
    }
    if (width === 'quarter') {
      return (
        <Fragment>
          <QuarterWrapperFieldset role={'quarter-wrapper-fieldset'} className={className}>
            {renderInternals()}
          </QuarterWrapperFieldset>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <FullWrapperFieldset role={'full-wrapper-fieldset'} className={className}>
          {renderInternals()}
        </FullWrapperFieldset>
      </Fragment>
    );
  };

  const legendClassName = () => {
    let classname = '';
    if (value === '') classname = classname.concat('empty ');
    if (className === 'error') classname = classname.concat('error');
    return classname;
  };

  const renderInternals = () => (
    <Fragment>
      <Legend role={'legend'} className={legendClassName()}>
        {label}
      </Legend>
      <InnerWrapper>
        <Input
          role={'TextInput'}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={e => handleChange(e.target.value)}
          readOnly={readOnly}
          autoFocus={autoFocus}
          id={id}
          className={mousetrap && 'mousetrap'}
          maxLength={maxLength}
          autoComplete={'off'}
        />
        {leftIcon && renderLeftIcon()}
        {rightIcon && renderRightIcon()}
      </InnerWrapper>
    </Fragment>
  );

  return renderWrapper();
};
