import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Mousetrap from 'mousetrap';
import { AdminFooterContainer } from '../styles/AdminFooterStyle';
import { Icon } from '../../../../common/Icon';
import { blur } from '../../../../utils/commonFunctions';
import { ModalContext } from '../../../../context/index';
import ExpandableFooterTag from '../../../../common/links/ExpandableFooterTag';

export const CloseFooter = () => {
  const { modal } = useContext(ModalContext);
  const history = useHistory();
  const { location } = history;
  const { row } = location;
  const { prevPathname, pathname } = location;

  const redirectTo = () => history.push({ pathname: prevPathname, prevPathname: pathname });

  const redirectToEdit = () =>
    history.push({
      pathname: '/admin/teams/user/edit',
      row,
      prevPathname: pathname,
    });

  // Function for escape key
  const escShortcut = id => {
    return !modal && id === 'block-user-toggle' ? blur(id) : redirectTo();
  };

  useEffect(() => {
    Mousetrap.bind('enter', enterSC);
    Mousetrap.bind('esc', () => escShortcut(document.activeElement.id));
    return () => {
      Mousetrap.unbind('esc');
      Mousetrap.unbind('enter');
    };
  });
  const enterSC = () => {
    if (document.activeElement === document.getElementsByClassName('footer-button')[0]) {
      redirectToEdit();
    } else if (document.activeElement === document.getElementsByClassName('footer-button')[1]) {
      redirectTo();
    }
  };
  const tagArr = [
    { tabIndex: '0', fontIcon: 'fa fa-pen', text: 'Edit', onClick: redirectToEdit },
    { tabIndex: '0', fontIcon: 'fas fa-minus-circle', text: 'Close', onClick: redirectTo },
  ];

  return (
    <AdminFooterContainer role={'CloseFooter'}>
      <ExpandableFooterTag tagArr={tagArr} icon={<Icon name="esc" className="esc-key" width="48px" />} />
    </AdminFooterContainer>
  );
};
