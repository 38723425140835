import React, { Fragment, useState, useContext, useEffect } from 'react';
import Mousetrap from 'mousetrap';
import { emailRegex } from '../../../utils/validationSuite/validationRegex';
import { CheckPassword } from '../../../utils/AccountFunctions';
import { ErrorMessage } from '../../../common/forms/styles/TextInputStyles';
import { TextInput } from '../../../common/forms/TextInput';
import {
  ProfileModalOverlay,
  ProfileModalContainer,
  ProfileModalButton,
  ProfileModalInputArea,
  ProfileModalButtonArea,
  ProfileModalTitle,
  ProfileModalInfoArea,
  ProfileBackLink,
  ProfileModalText,
  ProfileModalTextArea,
  ProfileSuccessIcon,
} from './styles/ProfileModalStyles';
import { blur } from '../../../utils/commonFunctions';
import { UserContext } from '../../../context/UserProvider';

const ChangeEmail = ({ oldEmail, handleConfirm, handleCancel }) => {
  const [emailState, setEmailState] = useState('EMAIL');
  const [newEmail, setNewEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [newError, setNewError] = useState('');
  const [matchError, setMatchError] = useState('');
  const [passError, setPassError] = useState('');
  const [confirmVisible, toggleConfirmVisibility] = useState(false);
  const { logout } = useContext(UserContext);

  // Keyboard shortcuts
  const changeEmailMousetrap = new Mousetrap();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      changeEmailMousetrap.bind('esc', () => handleEscapeKey(document.activeElement.id), 'keyup');
      changeEmailMousetrap.bind('enter', handleEnterKey);
    }
    return () => {
      isMounted = false;
      changeEmailMousetrap.unbind('enter');
      changeEmailMousetrap.unbind('esc', 'keyup');
    };
  });

  const handleEscapeKey = id => {
    if (id === 'new-email-input' || id === 'confirm-email-input' || id === 'confirm-password-input') {
      blur(id);
    } else {
      switch (emailState) {
        case 'EMAIL':
          handleCancel();
          break;
        case 'PASSWORD':
          setEmailState('EMAIL');
          break;
        default:
          return;
      }
    }
  };

  const handleEnterKey = () => {
    switch (emailState) {
      case 'EMAIL':
        checkEmail();
        break;
      case 'PASSWORD':
        checkConfirmPass();
        break;
      case 'SUCCESS':
        logout();
        break;
      default:
        return;
    }
  };

  const emailValid = () => {
    setMatchError('');
    setEmailState('PASSWORD');
  };

  const checkEmailMatch = () => (newEmail === confirmEmail ? emailValid() : setMatchError('Emails Do Not Match'));

  const checkEmail = () => {
    const emailValidity = emailRegex.test(newEmail);
    emailValidity ? setNewError('') : setNewError('Invalid New Email');
    emailValidity && checkEmailMatch();
  };

  const checkConfirmPass = () => {
    CheckPassword(oldEmail, confirmPass).then(result => {
      if (result) {
        setPassError('');
        handleConfirm({ password: confirmPass, email: confirmEmail });
        setEmailState('SUCCESS');
      } else {
        setPassError('Incorrect Password');
      }
    });
  };

  const renderInputs = () => {
    if (emailState === 'EMAIL') {
      return (
        <ProfileModalInputArea>
          <TextInput
            className={newError !== '' || matchError !== '' ? 'error' : ''}
            placeholder="New Email"
            label="New Email"
            value={newEmail}
            handleChange={value => setNewEmail(value)}
            id="new-email-input"
            autoFocus
            mousetrap
          />
          <TextInput
            className={matchError !== '' ? 'error' : ''}
            placeholder="Confirm Email"
            label="Confirm Email"
            value={confirmEmail}
            handleChange={value => setConfirmEmail(value)}
            id="confirm-email-input"
            mousetrap
          />
          {newError !== '' && <ErrorMessage>{newError}</ErrorMessage>}
          {matchError !== '' && <ErrorMessage>{matchError}</ErrorMessage>}
        </ProfileModalInputArea>
      );
    }
    if (emailState === 'PASSWORD') {
      return (
        <ProfileModalInputArea>
          <TextInput
            className={passError !== '' ? 'error' : ''}
            placeholder="Password"
            label="Password"
            type={confirmVisible ? 'text' : 'password'}
            value={confirmPass}
            handleChange={value => setConfirmPass(value)}
            id="confirm-password-input"
            rightIcon={confirmVisible ? 'eyeclose' : 'eye'}
            rightIconClick={() => toggleConfirmVisibility(!confirmVisible)}
            mousetrap
          />
          {passError !== '' && <ErrorMessage>{passError}</ErrorMessage>}
        </ProfileModalInputArea>
      );
    }
  };

  const renderButtons = () => {
    if (emailState === 'EMAIL') {
      return (
        <ProfileModalButtonArea>
          <ProfileModalButton className={newEmail === '' || confirmEmail === '' ? 'disable' : ''} onClick={checkEmail}>
            Next
          </ProfileModalButton>
          <ProfileBackLink onClick={handleCancel}>Back</ProfileBackLink>
        </ProfileModalButtonArea>
      );
    }
    if (emailState === 'PASSWORD') {
      return (
        <ProfileModalButtonArea>
          <ProfileModalButton className={confirmPass === '' ? 'disable' : ''} onClick={checkConfirmPass}>
            Save
          </ProfileModalButton>
          <ProfileBackLink onClick={() => setEmailState('EMAIL')}>Back</ProfileBackLink>
        </ProfileModalButtonArea>
      );
    }
  };

  const renderInfoArea = () => {
    if (emailState === 'SUCCESS') {
      return (
        <ProfileModalInfoArea>
          <ProfileSuccessIcon className="fas fa-check-circle fa-3x" />
          <ProfileModalTextArea>
            <ProfileModalTitle className="success">Email Updated</ProfileModalTitle>
            <ProfileModalText>
              Your Email has been changed and a confirmation email has been issued. Log back in to your account to
              continue!
            </ProfileModalText>
          </ProfileModalTextArea>
          <ProfileModalButton onClick={logout}>Login</ProfileModalButton>
        </ProfileModalInfoArea>
      );
    }
    return (
      <ProfileModalInfoArea>
        <ProfileModalTextArea>
          <ProfileModalTitle>Change Email</ProfileModalTitle>
          {emailState === 'PASSWORD' && <ProfileModalText>Please enter your password to continue</ProfileModalText>}
        </ProfileModalTextArea>
        {renderInputs()}
        {renderButtons()}
      </ProfileModalInfoArea>
    );
  };

  return (
    <Fragment>
      <ProfileModalOverlay role={'profile-modal-overlay'} onClick={() => handleCancel()} />
      <ProfileModalContainer>{renderInfoArea()}</ProfileModalContainer>
    </Fragment>
  );
};

export default ChangeEmail;
