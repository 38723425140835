import React, { useEffect, useRef, useState } from 'react';
import { remove } from '../../../../utils/arrayFunctions';
import { Content, Option } from './styles/MultiSelectStyles';
import Mousetrap from 'mousetrap';

const MultiSelect = ({ data, displayValue = 'name', selected, setSelected }) => {
  const [hover, setHover] = useState(0);
  const optionRef = useRef();
  const matchOpt = opt => selected.filter(obj => obj === opt);
  const isSelected = opt => selected && matchOpt(opt).length > 0 && 'selected';

  const handleClick = opt => {
    const match = selected && matchOpt(opt);
    if (match && match.length > 0) return setSelected(remove(selected, opt));
    if (match && match.length <= 0) setSelected([...selected, opt]);
    if (!match) setSelected([opt]);
  };

  const getHoverItem = mod => {
    const hoverMod = hover + mod;
    const dataLength = data.length - 1;
    if (dataLength <= 0) return 0;
    if (hoverMod < 0) return dataLength;
    if (hoverMod > dataLength) return 0;

    return hoverMod;
  };

  const keySelect = mod => {
    optionRef.current.focus();
    const children = optionRef.current.children;
    const hoverItem = getHoverItem(mod);
    setHover(hoverItem);
    children[hover].focus();
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      Mousetrap.bind('down', () => keySelect(1), 'keyup');
      Mousetrap.bind('up', () => keySelect(-1), 'keyup');
      Mousetrap.bind('enter', () => handleClick(data[hover]), 'keyup');
    }
    return () => {
      isMounted = false;
      Mousetrap.unbind('down', 'keyup');
      Mousetrap.unbind('up', 'keyup');
    };
  }, [keySelect, handleClick, hover, data]);

  const purgeClass = children => {
    for (let child of children) {
      child && child.classList.remove('keyHover');
    }
  };

  useEffect(() => {
    const children = optionRef.current.children;
    purgeClass(children);
    children[hover] && children[hover].classList.add('keyHover');
  });

  const generateOptions = () =>
    data &&
    data.map(
      (opt, i) =>
        !opt['added'] && (
          <Option key={i} onClick={() => handleClick(opt)} className={isSelected(opt)}>
            {opt[displayValue]}
          </Option>
        ),
    );
  return (
    <Content ref={optionRef} tabIndex={1}>
      {generateOptions()}
    </Content>
  );
};

export default MultiSelect;
