import { Cryptification } from './internal/cryptification';

// Anything related to asymmetric encryption/decryption lives here:
export class Asymmetric {
  static encrypt(public_key, value) {
    return Cryptification.encrypt(public_key, value, { name: 'RSA-OAEP' });
  }

  static decrypt(private_key, value) {
    return Cryptification.decrypt(private_key, value, { name: 'RSA-OAEP' });
  }
}
