import styled from 'styled-components';

export const InfoModal = styled.div`
  background: ${props => props.theme.sButtonBgColor};
  color: ${props => props.theme.textColor};
  position: absolute;
  bottom: 135%;
  width: 320px;
  text-align: center;
  padding: 30px 10px 20px 10px;
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.bgColor};

  &::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 25%;
    border-width: 10px;
    border-style: solid;
    border-color: ${props => props.theme.sButtonBgColor} transparent transparent transparent;
  }

  @media (-webkit-device-pixel-ratio: 1.5) {
    transform: scale(0.75);
    left: -16%;
    bottom: -50%;
  }
`;
