import React from 'react';
import './styles/DropdownChoiceTag.css';

export const DropdownChoiceTag = ({ choice, remove }) => (
  <div role={'drop-down-choice-tag'} className="tag-wrapper">
    <div className="tag-text">{choice.label}</div>
    <div role={'drop-down-choice-tag-button'} onClick={() => remove(choice.id)}>
      <i className="fas fa-times"></i>
    </div>
  </div>
);
