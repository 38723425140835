import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { ChoiceIcon, ChoiceTitle, ChoiceContainer, ChoiceLink } from './styles/ChoiceStyle';
import {
  StepSubContainer,
  TwoStepFooter,
  TwoStepButton,
  TwoStepText,
  TwoStepSubText,
  BackLink,
} from './styles/CommonStyles';
import { Icon } from '../../common/Icon';
import useCycle from '../../utils/hooks/shortcuts/useCycle';

const TwoStepChoice = ({ setState }) => {
  const history = useHistory();
  useCycle('choice');

  return (
    <StepSubContainer>
      <Fragment>
        <ChoiceIcon>
          <Icon name="security" width="70px" />
        </ChoiceIcon>
        <ChoiceTitle>Two-step verification</ChoiceTitle>
        <TwoStepSubText>Setting up two-step verification is easy.</TwoStepSubText>
        <TwoStepText>
          <Icon name="asterisk" width="15px" />
          <span>Download a two-step verification app</span>
        </TwoStepText>
        <ChoiceContainer>
          <ChoiceLink href={`${process.env.REACT_APP_IOS_AUTH}`} target="_blank" className={'choice'}>
            <span>
              <Icon name="apple" width="20px" />
            </span>
            <span>iPhone, iPod touch, or iPad</span>
          </ChoiceLink>
          <ChoiceLink href={`${process.env.REACT_APP_ANDROID_AUTH}`} target="_blank" className={'choice'}>
            <span>
              <Icon name="android" width="20px" />
            </span>
            <span>Android devices</span>
          </ChoiceLink>
          <ChoiceLink href={`${process.env.REACT_APP_WINDOWS_AUTH}`} target="_blank" className={'choice'}>
            <span>
              <Icon name="windows" width="20px" />
            </span>
            <span>Windows devices</span>
          </ChoiceLink>
        </ChoiceContainer>
      </Fragment>
      <TwoStepFooter>
        <TwoStepButton onClick={() => setState('CODE')} className={'choice'}>
          Next
        </TwoStepButton>
        <BackLink onClick={history.goBack} className={'choice'}>
          Back
        </BackLink>
      </TwoStepFooter>
    </StepSubContainer>
  );
};

export default TwoStepChoice;
