import React, { Fragment, useEffect } from 'react';
import Mousetrap from 'mousetrap';
import { SectionHeading, BackButton, BackWrapper } from './styles/BillingStyles';
import { Icon } from '../../../../common/Icon';
import StripePayment from '../../../forms/StripePayment';

export const PaymentDetails = ({ setView, paymentMethod, formContext }) => {
  const data =
    typeof paymentMethod === 'string' || paymentMethod['data'][0] === undefined
      ? null
      : paymentMethod['data'][0]['billing_details'];

  // useEffect for keyboard shortcuts.
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      Mousetrap.bind('esc', () => setView('summary'));
    }
    return () => {
      isMounted = false;
      Mousetrap.unbind('esc');
    };
  });

  return (
    <Fragment>
      <SectionHeading>
        <BackWrapper>
          <BackButton onClick={() => setView('summary')}>{'< Back'}</BackButton>
          Payment Details
        </BackWrapper>
        <Icon className="stripe-logo" name="stripe" width="120px" />
      </SectionHeading>
      {paymentMethod ? <StripePayment setView={setView} data={data} formContext={formContext} /> : null}
    </Fragment>
  );
};
