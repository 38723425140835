import React, { useState, useContext, useEffect, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { UserContext } from '../context/UserProvider';
import SquaredButton from '../components/admin/adminDashboard/subComponents/SquaredButton';
import { ContentWrap, MainContainer, HeaderContainer } from './styles/DataDashboardStyles';
import TableLoading from '../components/table/subComponents/TableLoading';
import AllTimeView from '../components/admin/adminDashboard/AllTimeView';
// import DateRangeView from '../components/admin/adminDashboard/DateRangeView';
import DataDashboardApi from '../api/dataDashboard/DataDashboardApi';
// TODO: removed mocked data
// import { mockedDateRangeStats } from '../components/admin/adminDashboard/__mocks__/dateRangeStatsMock';

const DataDashboard = () => {
  // const dateRangeText = 'Date range view';
  const allTimeText = 'All time view';
  // const [dateRangeData, setDateRangeData] = useState([]);
  const [allTimeData, setAllTimeData] = useState([]);
  const [dataDashboardView, setDataDashboardView] = useState(allTimeText);
  const [activeView, setActiveView] = useState(allTimeText);
  const [loading, setLoading] = useState(true);
  const { user } = useContext(UserContext);
  const { data_administrator } = user;

  useEffect(() => {
    setLoading(true);
    let promise;
    if (dataDashboardView === allTimeText) {
      promise = DataDashboardApi.getAllTime().then(result => {
        setAllTimeData(result);
      });
    } else {
      promise = DataDashboardApi.getDateRange().then(result => {
        setDateRangeData(result);
      });
    }
    promise.finally(() => {
      setLoading(false);
    });
  }, [dataDashboardView]);

  const handleClick = text => {
    setDataDashboardView(text);
    setActiveView(text);
  };

  const DashboardSwitch = () => {
    if (loading) return <TableLoading />;
    // if (dataDashboardView === dateRangeText) return <DateRangeView data={mockedDateRangeStats} />;
    if (dataDashboardView === allTimeText) return <AllTimeView data={allTimeData} />;
    return <TableLoading />;
  };

  const RenderHeader = () => {
    return (
      <HeaderContainer>
        {/*<SquaredButton text={dateRangeText} onClick={() => handleClick(dateRangeText)} activeView={activeView} />*/}
        <SquaredButton text={allTimeText} onClick={() => handleClick(allTimeText)} activeView={activeView} />
      </HeaderContainer>
    );
  };

  if (data_administrator === false) return <Redirect to={{ pathname: '/' }} />;

  return (
    <Fragment>
      <CSSTransition in={location.pathname === '/admin/datadashboard'} timeout={500} classNames="dashboard" appear>
        <MainContainer role="data-dashboard">
          {RenderHeader()}
          <ContentWrap>{DashboardSwitch()}</ContentWrap>
        </MainContainer>
      </CSSTransition>
    </Fragment>
  );
};

export default DataDashboard;
