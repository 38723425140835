import React from 'react';
import { WelcomeContainerWrap, Title, Subtext, ChildrenWrap } from './styles/WelcomeContainerStyles';

const WelcomeContainer = ({ title = 'Title', subtext, children }) => {
  return (
    <WelcomeContainerWrap>
      <Title>{title}</Title>
      <Subtext>{subtext}</Subtext>
      <ChildrenWrap>{children}</ChildrenWrap>
    </WelcomeContainerWrap>
  );
};

export default WelcomeContainer;
