import React, { useState, useEffect } from 'react';
import PlanPage from '../components/plans/PlanPage';
import PlanPayment from '../components/plans/PlanPayment';
import StripeApi from '../api/stripe/StripeApi';

const Plan = () => {
  const [page, setPage] = useState('summary');
  const [paymentMethod, setPaymentMethod] = useState();

  useEffect(() => {
    StripeApi.getPaymentMethod().then(res => setPaymentMethod(res));
  }, []);

  if (page === 'summary') return <PlanPage setPage={setPage} />;
  if (page === 'payment') return <PlanPayment setPage={setPage} paymentMethod={paymentMethod} />;
  return <div>Uh oh, that wasn't supposed to happen.</div>;
};

export default Plan;
