import React from 'react';
import {
  Wrap,
  TitleWrap,
  TitleIcon,
  Title,
  Currency,
  PriceWrap,
  Price,
  PriceUnit,
  SubtextWrap,
  Subtext,
  Divider,
  FeatureWrap,
  FeatureText,
  PlanButton,
} from '../styles/BillingPlansStyles';
import { Icon } from '../../../../../common/Icon';
import { placeholderFunc } from '../../../../../utils/commonFunctions';

// Plan Container holds data for billing plans
const PlanContainer = ({ selected }) => {
  const buttonText = () => {
    if (selected) return 'Current Plan';
    return 'Choose Plan';
  };

  return (
    <Wrap selected={selected}>
      <TitleWrap>
        <TitleIcon>
          <Icon name={'team'} width={'24px'} />
        </TitleIcon>
        <Title>Team Plan</Title>
      </TitleWrap>
      <PriceWrap>
        <Currency>£</Currency>
        <Price>5</Price>
        <PriceUnit>/user</PriceUnit>
      </PriceWrap>
      <SubtextWrap>
        <Subtext>£2 per user / per month</Subtext>
        <Subtext>Billed monthly / cancel anytime</Subtext>
      </SubtextWrap>
      <Divider />
      <FeatureWrap>
        <FeatureText>Up to 50 users</FeatureText>
        <FeatureText>Unlimited secrets</FeatureText>
        <FeatureText>Unlimited chests / team sharing</FeatureText>
        <FeatureText>AES-256 encryption and MFA</FeatureText>
      </FeatureWrap>
      <PlanButton selected={selected} onClick={placeholderFunc}>
        {buttonText()}
      </PlanButton>
    </Wrap>
  );
};

export default PlanContainer;
