import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import ProfileSettings from '../components/profile/ProfileSettings';
import TwoStep from '../components/twoStep/TwoStep';
import WelcomePassword from '../components/welcome/WelcomePassword';

const Profile = () => {
  return (
    <Fragment>
      <Route exact path={'/profile/personal_password'} component={WelcomePassword} />
      <Route exact path={'/profile/twofactor'} component={TwoStep} />
      <Route exact path={'/profile'} component={ProfileSettings} />
    </Fragment>
  );
};

export default Profile;
