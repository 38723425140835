import React from 'react';
import { MenuOption, OptionWrapper } from './styles/PlusOptionsStyles';
import { Icon } from '../../../../../common/Icon';

export const PlusOptions = ({ options }) => {
  const renderOptions = () => {
    return options.map(option => {
      return (
        <MenuOption key={option.text} onClick={() => option.handleClick()} tabIndex="0">
          <Icon name="add" width="20px" />
          <p>{option.text}</p>
        </MenuOption>
      );
    });
  };

  return <OptionWrapper>{renderOptions()}</OptionWrapper>;
};
