import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import Mousetrap from 'mousetrap';
import AdminTableWrap from '../adminTable/styles/AdminTableWrap';
import { AdminTableHeader } from '../adminChests/subComponents/AdminTableHeader';
import Table from '../../table/Table';
import { getHeaders } from '../adminHeader/AdminHeaderInfo';
import { getSearchable } from '../../table/subFunctions/searchableFunctions';
import AdminTeamsWrap from './styles/AdminTeamsWrap';
import { goToLocation } from '../adminFunctions/redirectByUrl';
import { UserTableData } from '../../../api/users/UserApiFunctions';
import LogoutRedirect from '../../../common/nav/LogoutRedirect';
import { AdminTeamContext, AdminContext } from '../../../context/AdminContext';
import { ModalContext } from '../../../context';
import { ChestTableData } from '../../../api/chests/ChestApiFunctions';
import BaseApi from '../../../api/BaseApi';
import { ViewTeamData, SingleTeamTableData } from '../../../api/teams/TeamApiFunctions';
import { reverseAll } from '../../../utils/arrayFunctions';

const AdminTeamsShow = ({ searchText, context, history }) => {
  const { setModal } = useContext(ModalContext);
  const [prevAssignments, setPrevAssignments] = useState([]);
  const [chests, setChests] = useState([]);
  const [data, setData] = useState([]);
  const setSearchPlaceholder = useContext(AdminContext).searchPlaceholder[1];
  const setContext = useContext(AdminContext).context[1];
  const { modal } = useContext(ModalContext);
  const { location } = history;
  const row = useMemo(() => location['row'] || (location['state'] && location['state']['row']) || [], [location]);
  const headers = getHeaders('Users')();
  const columnWidths = ['20%', '30%', '30%', '15%', '5%'];
  const searchable = getSearchable('TeamsShow');
  const pathname = location['pathname'] || '/admin/teams';
  const url = goToLocation(context, pathname);
  const redirectTo = row => history.push({ pathname: url, row, context, prevPathname: pathname });

  const adminTable = {
    adminTeamTable: [data, setData],
  };

  useEffect(() => {
    UserTableData({ everything: true }).then(result => {
      setData(result.filter(user => user['teams'].filter(team => team['id'] === row['id']).length > 0));
      setSearchPlaceholder('Search for a user');
      setContext('Teams');
    });
  }, [row, setSearchPlaceholder, setContext]);

  useEffect(() => {
    Mousetrap.bind('esc', () => !modal && history.push({ pathname: '/admin/teams', prevPathname: pathname }));

    return () => {
      Mousetrap.unbind('esc');
    };
  });

  /* useEffect that handles the preparation of data based on the row id
      to fill in the form as soon as it is rendered. */
  useEffect(() => {
    // Filters the chests for ones already added based on the assignments.
    const filterChests = (assignments, chestArray) => {
      assignments.forEach(assignment => {
        const chestIndex = chestArray.findIndex(chest => chest.id === assignment.vault_id);
        chestArray[chestIndex].edit_access = assignment.edit_access;
        chestArray[chestIndex].added = true;
      });
      setChests(chestArray);
    };

    if (row.length !== 0) {
      ViewTeamData(row.id).then(async team => {
        if (team) {
          setPrevAssignments(team.assignments);
          const allChests = await ChestTableData({ everything: true });
          const chestsArray = allChests.map(chest => {
            return {
              id: chest.id,
              name: chest.name,
              secrets: chest.secrets,
              added: false,
              selected: false,
              edit_access: false,
            };
          });
          filterChests(team.assignments, chestsArray);
        }
      });
    }
  }, [row]);

  const handleChestsModalConfirm = array => {
    array && setChests(reverseAll(chests, array, 'added'));
    setModal();
    updateTeam();
  };

  /* Function that handles the validation of the data, the team update API call
    and the resharing of secrets */
  const updateTeam = () => {
    const addedChests = chests.filter(chest => chest.added === true);

    const chestData = updateAssignments(addedChests);
    const data = {
      team: {
        name: row['name'],
        assignments_attributes: chestData,
      },
    };
    BaseApi.update('teams', row.id, data).then(async () => {
      const dataRow = await SingleTeamTableData(row.id, { everything: true });
      history.push({
        pathname: '/admin/teams/show',
        row: dataRow,
        prevPathname: pathname,
      });
    });
  };

  const chestLinkModal = () =>
    setModal({
      key: 'Link',
      params: {
        headerText: 'Link: Chests',
        data: chests,
        confirmAction: handleChestsModalConfirm,
        cancelAction: () => setModal(),
      },
    });

  // Function for preparing the JSON data related to the assignments.
  const updateAssignments = addedChests => {
    let chestData = [];
    prevAssignments.forEach(assignment => {
      const chestIndex = addedChests.findIndex(chest => chest.id === assignment.vault_id);
      if (!addedChests[chestIndex]) {
        chestData.push({
          id: assignment.id,
          _destroy: 1,
        });
      } else {
        chestData.push({
          id: assignment.id,
          edit_access: addedChests[chestIndex].edit_access,
        });
      }
      addedChests.splice(chestIndex, 1);
    });
    addedChests.forEach(chest => {
      chestData.push({
        vault_id: chest.id,
        edit_access: chest.edit_access,
      });
    });
    return chestData;
  };

  if (row.length === 0) return <Redirect to={'/admin/teams'} />;
  if (!data) return <LogoutRedirect />;
  return (
    <AdminTeamContext.Provider value={adminTable}>
      <AdminTeamsWrap className={'table'}>
        <AdminTableHeader row={row} chestLinkModal={chestLinkModal} />
        <AdminTableWrap id="Table">
          <Table
            columnWidths={columnWidths}
            headers={headers}
            data={data}
            searchable={searchable}
            searchText={searchText}
            onClick={redirectTo}
          />
        </AdminTableWrap>
      </AdminTeamsWrap>
    </AdminTeamContext.Provider>
  );
};

export default AdminTeamsShow;
