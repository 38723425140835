import React, { useState, useContext, Fragment, useEffect } from 'react';
import Mousetrap from 'mousetrap';
import { AdminChestsWrap } from './styles/AdminChestsWrap';
import { ChestForm } from '../../forms/ChestForm';
import { useHistory } from 'react-router-dom';
import { AddChestOptions } from './subComponents/AddChestOptions';
import { AdminChestAddWrap, FakeArea } from './styles/AdminChestAddStyles';
import { SaveFooter } from '../adminFooter/footers/SaveFooter';
import { CreateChest, ChestTableData } from '../../../api/chests/ChestApiFunctions';
import { getValidation } from '../../../utils/validationSuite/validationIndex';
import { useNotification } from '../../../common/notification/Notification';
import { TeamTableData } from '../../../api/teams/TeamApiFunctions';
import { UserTableData, PromoteUser, DemoteUser } from '../../../api/users/UserApiFunctions';
import LogoutRedirect from '../../../common/nav/LogoutRedirect';
import GenericFormLoading from '../../../common/genericLoading/GenericFormLoading';
import { AdminContext } from '../../../context/AdminContext';
import { checkActiveElement } from '../../../utils/commonFunctions';

/* Component responsbile for adding new chests with assignments and
  specific user permissions specified by the chest form. */
export const AdminChestsAdd = () => {
  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [chestName, setChestName] = useState('');
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const history = useHistory();
  const setChestTags = useContext(AdminContext).chestTags[1];

  const { setNotificationText } = useNotification();

  // useEffect for preparing the list of teams for the form.
  useEffect(() => {
    let isMounted = true;
    TeamTableData({ everything: true }).then(result => {
      if (result) {
        let teamsArray = [];
        result.map(team => {
          teamsArray = teamsArray.concat({
            id: team.id,
            name: team.name,
            users: team.users,
            added: false,
            selected: false,
            edit_access: false,
          });
          return teamsArray;
        });
        if (isMounted) setTeams(teamsArray);
      } else {
        if (isMounted) setRedirect(true);
      }
    });
    return () => (isMounted = false);
  }, []);

  // useEffect preparing the users based on the teams selected.
  useEffect(() => {
    // Function for filtering the users based on the teams added in the first selection.
    const filterUsers = user => {
      const addedTeams = teams.filter(team => team.added === true);
      let validUser = false;
      addedTeams.forEach(team => {
        if (user.teams.filter(userTeam => userTeam.id === team.id).length !== 0 && user.permissions !== 'Admin') {
          validUser = true;
        }
      });
      return validUser;
    };
    let isMounted = true;
    UserTableData({ everything: true }).then(result => {
      if (result) {
        let usersArray = [];
        result.map(user => {
          if (filterUsers(user)) {
            usersArray = usersArray.concat({
              id: user.id,
              name: user.name,
              email: user.email,
              added: false,
              selected: false,
              edit_access: false,
            });
          }
          return usersArray;
        });
        if (isMounted) setUsers(usersArray);
      } else {
        if (isMounted) setRedirect(true);
      }
    });
    return () => (isMounted = false);
  }, [teams]);

  // useEffect for keyboard shortcuts.
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      Mousetrap.bind('shift+s', shiftS);
      Mousetrap.bind('enter', enter);
    }
    return () => {
      isMounted = false;
      Mousetrap.unbind('shift+s');
      Mousetrap.unbind('enter');
    };
  });

  // Function for handling the create chest API call, validation and permissions.
  const createChest = () => {
    const validationId = 'ChestForm';
    const addedTeams = teams.filter(team => team.added === true);
    const addedUsers = users.filter(user => user.added === true);
    const params = {
      data: { chestName, addedTeams, addedUsers },
      setNotificationText,
    };
    if (getValidation(validationId)(params)) {
      setLoading(true);
      const teamData = addedTeams.map(team => ({
        team_id: team.id,
        edit_access: team.edit_access,
      }));
      const data = {
        vault: {
          name: chestName,
          assignments_attributes: teamData,
        },
      };
      CreateChest(data).then(async result => {
        if (result === 'Chests exceed subscription limit.') {
          setLoading(false);
          return setNotificationText({
            type: 'error',
            text: 'You cannot add anymore secrets or chests on the free plan. To add more, you can upgrade to the Teams Plan or remove some secrets.',
          });
        }
        addedUsers.forEach(async user => {
          if (user.edit_access) await PromoteUser(result.id, user.id);
          else await DemoteUser(result.id, user.id);
        });
        setChestTags(await ChestTableData({ everything: true }));
        history.push({
          pathname: '/admin/chests',
          prevPathname: history.location.pathname,
        });
      });
    }
  };

  const shiftS = () => {
    return checkActiveElement('chest-name-input') ? null : createChest();
  };
  const enter = () => {
    if (document.activeElement === document.getElementsByClassName('footer-button')[0]) {
      createChest();
    } else if (document.activeElement === document.getElementsByClassName('footer-button')[1]) {
      history.goBack();
    }
  };
  if (redirect) return <LogoutRedirect />;
  return (
    <Fragment>
      {loading ? (
        <GenericFormLoading />
      ) : (
        <Fragment>
          <AdminChestsWrap role={'admin-chest-add'} className="form-footer team-form">
            <AdminChestAddWrap>
              <ChestForm
                chestName={chestName}
                setChestName={setChestName}
                teams={teams}
                setTeams={setTeams}
                users={users}
                setUsers={setUsers}
              />
              <SaveFooter handleSubmit={() => createChest()} />
            </AdminChestAddWrap>
            <AddChestOptions />
          </AdminChestsWrap>
          <FakeArea></FakeArea>
        </Fragment>
      )}
    </Fragment>
  );
};
