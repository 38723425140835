export const generateHeader = (hStr, selectedHeader) => {
  if (hStr === selectedHeader['header'] && selectedHeader['reverse'])
    return {
      header: null,
      reverse: false,
    };
  return {
    header: hStr,
    reverse: _reverseSort(hStr, selectedHeader),
  };
};

const _reverseSort = (str, selectedHeader) => selectedHeader['header'] === str && !selectedHeader['reverse'];

export const searchableHeaders = (headers, searchable) => {
  if (searchable.length === 0) return headers;
  return Object.keys(headers).reduce((newHeaders, key) => {
    if (searchable.includes(key)) newHeaders[key] = headers[key];
    return newHeaders;
  }, {});
};
