import React, { useState, useEffect, useContext } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { Wrap, Title, PlanContainerWrap, ButtonWrap, CancelButton } from './styles/PlanStyle';
import PlanContainer from './subComponents/PlanContainer';
import StripeApi from '../../api/stripe/StripeApi';
import GenericLoading from '../../common/genericLoading/GenericLoading';
import { ModalContext } from '../../context';

const PlanPage = ({ setPage }) => {
  const history = useHistory();
  const [subscription, setSubscription] = useState();
  const [plans, setPlans] = useState();
  const [loading, setLoading] = useState(true);
  const { setModal } = useContext(ModalContext);
  const toBilling = () => history.push('/admin/settings', { fromPlans: true });

  const noSub = () => subscription === 'No subscription';

  const isSelected = plan =>
    typeof subscription === 'string' ? false : plan['nickname'] === subscription['plan']['nickname'];

  const generatePlans = () =>
    plans.map((plan, i) => (
      <PlanContainer
        key={i}
        plan={plan['nickname']}
        selected={isSelected(plan)}
        setPage={setPage}
        toBilling={toBilling}
        setPlanModal={planModal}
      />
    ));

  const endSub = () =>
    StripeApi.deleteSubscription().then(() => {
      setSubscription('No Subscription');
      toBilling();
      setModal();
    });

  useEffect(() => {
    let isMounted = true;
    StripeApi.getSubscription().then(res => {
      if (isMounted) {
        setSubscription(res);
        StripeApi.getPlans().then(res => {
          if (isMounted) {
            setPlans(res);
            setLoading(false);
          }
        });
      }
    });
    return () => (isMounted = false);
  }, []);

  const planModal = () =>
    setModal({
      key: 'Confirm',
      params: {
        icon: 'alert',
        question: `Are you sure you want to cancel your subscription?`,
        subtext: 'You will not be charged for time after this.',
        confirmAction: endSub,
        cancelAction: () => setModal(),
      },
    });

  if (loading) return <GenericLoading />;
  if (!subscription) return <Redirect path={'/'} />;
  return (
    <Wrap>
      <Title>Choose your best plan</Title>
      <PlanContainerWrap>
        <PlanContainer plan={'Free'} selected={noSub()} setPage={setPage} toBilling={toBilling} setModal={planModal} />
        {generatePlans()}
      </PlanContainerWrap>
      <ButtonWrap>
        <CancelButton onClick={toBilling}>I'm fine with my current Plan</CancelButton>
      </ButtonWrap>
    </Wrap>
  );
};

export default PlanPage;
