import styled from 'styled-components';

export const IconArea = styled.div`
  visibility: hidden;
  font-size: 16px;
  background-color: ${props => props.theme.textColor};
  color: ${props => props.theme.fieldBgColor};
  text-align: center;
  height: 50px;
  align-items: center;
  justify-content: center !important;
  margin-right: -1rem;

  @media (-webkit-device-pixel-ratio: 1.5) {
    height: 35px;
  }
`;

export const MenuOptions = styled.div`
  position: absolute;
  right: -1rem;
  display: block;
  border-radius: 0px 0px 5px 5px;
  background: ${props => props.theme.fieldBgColor};
  transition: all 0.5s;
  z-index: 500;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);

  &.above {
    bottom: 50px;
    border-radius: 5px 5px 0 0;
    box-shadow: 0 -8px 10px rgba(0, 0, 0, 0.1);
  }
`;

export const DropDownOption = styled.div`
  position: relative;
  display: block;
  width: 190px;
  padding: 0 1.25rem 0 1.25rem;
  font-size: 1rem;
  font-weight: 400;
  color: ${({ important, theme }) => (important ? theme.redButtonTextColor : theme.textColor)};
  line-height: 3rem;
  cursor: pointer;
  transition: all 0.5s;
  background-color: ${({ important, theme }) => (important ? theme.redButtonBgColor : theme.fieldBgColor)};

  &:hover {
    cursor: pointer;
    background-color: ${props => props.theme.highlightColor};
    color: ${props => props.theme.hoverRowColor};
  }

  &.highlighted {
    background-color: ${props => props.theme.highlightColor};
    color: ${props => props.theme.hoverRowColor};
  }
`;
