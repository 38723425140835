import React, { useState, useEffect } from 'react';
import { Icon } from '../../common/Icon';
import { TwoStepSubText, SubTextContainer } from './styles/CommonStyles';
import { SuccessFooter, SuccessButton, SuccessSubContainer } from './styles/SuccessStyle';
import {
  RecoveryContent,
  RecoveryCodesContainer,
  RecoveryCode,
  RecoveryActionsContainer,
  RecoveryAction,
} from './styles/RecoverStyle';
import LogoutRedirect from '../../common/nav/LogoutRedirect';
import TwoFactorApi from '../../api/twofactor/TwoFactorApi';
import useCycle from '../../utils/hooks/shortcuts/useCycle';

const TwoStepRecover = ({ setState }) => {
  const [recoveryCodes, setRecoveryCodes] = useState([]);
  const [redirect, setRedirect] = useState(false);
  useCycle('successButton');

  useEffect(() => {
    let isMounted = true;
    TwoFactorApi.backup().then(async response => {
      if (response) {
        if (isMounted) setRecoveryCodes(response['2faCodes']);
      } else {
        if (isMounted) setRedirect(true);
      }
    });
    return () => (isMounted = false);
  }, []);

  const handlePrint = () => {
    window.print();
  };

  const handleDownload = () => {
    const element = document.createElement('a');
    const text = `Recovery Codes: ${recoveryCodes.toString()}`;
    const file = new Blob([text], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = 'codes.txt';
    document.body.appendChild(element);
    element.click();
  };

  if (redirect) return <LogoutRedirect />;
  return (
    <SuccessSubContainer>
      <RecoveryContent>
        <TwoStepSubText className="bold">Save your recovery codes</TwoStepSubText>
        <SubTextContainer>
          <TwoStepSubText className="recover">
            If you lose access to your phone, you won't be able to log in without a recovery code. Print, copy or write
            down your codes and save it somewhere safe.
          </TwoStepSubText>
        </SubTextContainer>
        <RecoveryCodesContainer>
          {recoveryCodes.map((code, i) => (
            <RecoveryCode key={i}>{code}</RecoveryCode>
          ))}
        </RecoveryCodesContainer>
        <RecoveryActionsContainer>
          <RecoveryAction onClick={handlePrint}>
            <Icon name="print" width="15px" />
            Print
          </RecoveryAction>
          <RecoveryAction onClick={handleDownload}>
            <Icon name="download" width="15px" />
            Download
          </RecoveryAction>
        </RecoveryActionsContainer>
      </RecoveryContent>
      <SuccessFooter>
        <SuccessButton onClick={() => setState('CONFIRM')} className={'successButton'}>
          Finish
        </SuccessButton>
      </SuccessFooter>
    </SuccessSubContainer>
  );
};

export default TwoStepRecover;
