import LocalStore from './LocalStore';
import Url from '../utils/Url';

class Request {
  type: RequestMethod; // Example: 'GET'
  url: string; // Example: '/api/v1/profile'
  params?: any; // Example: { options: ['vault'] }

  // TODO: Add a way to pass a test callback for params
  constructor(type: RequestMethod, url: string, params?: any) {
    this.type = type;
    this.url = url;
    this.params = params;
  }

  async perform(): Promise<RecryptRequest> {
    const bodyParams = this.type === 'GET' ? undefined : this.params;
    const body = this._formatBody(this.type, bodyParams, this._getToken());
    const urlHandler = new Url(this.url);
    const url = this.type === 'GET' && this.params ? urlHandler.addParams(this.params) : urlHandler.getFull();
    return await this._request(url, body);
  }

  // Gets the auth token from the localstorage repository
  _getToken(): string {
    const store = new LocalStore();
    return store.get('token');
  }

  // Sends the request and returns an object
  async _request(url: string, body: any): Promise<RecryptRequest> {
    const res = await fetch(url, body);
    const status = res['status'];
    const headers = res.headers;
    const json = await res.json();
    return { status, json, headers };
  }

  _formatBody(type: string, params: any, authorization?: string): RequestInit {
    return {
      method: type,
      mode: 'cors',
      body: JSON.stringify(params),
      headers: this._headers(authorization),
    };
  }

  _headers(authorization?: string): Headers {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    if (!authorization) return headers;
    headers.append('Authorization', authorization);
    return headers;
  }
}

export default Request;
