import React, { useState, useEffect } from 'react';
import { DecryptSecret } from '../../../utils/secretFunctions';
import { ViewSecretData } from '../../../api/secrets/SecretApiFunctions';
import SecretForm from '../../forms/SecretForm';
import TableLoading from '../../table/subComponents/TableLoading';
import { TableWrap } from '../../table/styles/TableStyles';
import SearchBar from '../../../common/searchBar/SearchBar';
import { FooterTagWrap } from '../../../common/links/styles/FooterLinkStyle';
import FooterLink from '../../../common/links/FooterLink';
import LogoutRedirect from '../../../common/nav/LogoutRedirect';

const EditMySecret = ({ stateObject }) => {
  const { setEditSecret, secretData, setSecretData, editSecret, setViewSecret } = stateObject;
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    editSecret.length !== 0 &&
      ViewSecretData(editSecret['id']).then(async secret => {
        if (secret) {
          const decryptedSecret = await DecryptSecret(secret);
          setSecretData({ ...decryptedSecret, ...editSecret });
          setLoading(false);
        } else {
          setRedirect(true);
        }
      });
  }, [editSecret]);

  if (!secretData && redirect) return <LogoutRedirect />;
  if (!secretData)
    return (
      <div>
        <SearchBar />
        <TableWrap>
          <TableLoading />
        </TableWrap>
        <FooterTagWrap>
          <FooterLink text={'Show all secrets'} />
        </FooterTagWrap>
      </div>
    );

  return (
    <div>
      {!loading && secretData ? (
        <SecretForm secret={secretData} setSecret={setEditSecret} edit={true} setViewSecret={setViewSecret} />
      ) : (
        <TableLoading />
      )}
    </div>
  );
};

export default EditMySecret;
