import React from 'react';
import ChangeEmail from '../../../components/profile/subComponents/ChangeEmail';
import { ReencryptAndSubmit } from '../../../utils/AccountFunctions';
import UserApi from '../../../api/users/UserApi';

const editProfileEmail = (password, email, user) => {
  ReencryptAndSubmit(user.email, password, email, password).then(async result => {
    const params = {
      user: {
        email: result.email,
        password: result.password,
        current_password: result.current_password,
        encrypted_private_key: result.encrypted_private_key,
        private_key_iv: result.private_key_iv,
      },
    };
    await UserApi.change(params);
  });
};

const ChangeEmailModal = ({ user, setModal }) => {
  return (
    <ChangeEmail
      oldEmail={user.email}
      handleConfirm={data => editProfileEmail(data.password, data.email, user)}
      handleCancel={() => setModal('')}
    />
  );
};

export default ChangeEmailModal;
