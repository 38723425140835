import React, { useEffect } from 'react';
import Mousetrap from 'mousetrap';
import { SectionHeading, BackWrapper, BackButton } from './styles/BillingStyles';
import { PlanWrapper } from './styles/BillingPlansStyles';
import PlanContainer from './subComponents/PlanContainer';

// Contains the choice of plans for Recrypt
const BillingPlans = ({ setView }) => {
  // useEffect for keyboard shortcuts.
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      Mousetrap.bind('esc', () => setView('summary'));
    }
    return () => {
      isMounted = false;
      Mousetrap.unbind('esc');
    };
  });

  return (
    <div>
      <BackWrapper>
        <BackButton onClick={() => setView('summary')}>{'< Back'}</BackButton>
        Billing Summary
      </BackWrapper>
      <SectionHeading>
        <b>Select Plan</b>
      </SectionHeading>
      <PlanWrapper>
        <PlanContainer />
        <PlanContainer selected />
        <PlanContainer />
      </PlanWrapper>
    </div>
  );
};

export default BillingPlans;
