import styled from 'styled-components';

export const IconArea = styled.div`
  font-size: 1rem;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'Lato';

  i {
    padding-right: 1rem;
    color: ${props => props.theme.textColor};
  }
`;
