import React, { Fragment, useContext } from 'react';
import { Divider } from '../../../../common/divider/Divider';
import { ReactComponent as Bin } from '../../../../images/bin.svg';
import { SectionHeading, SectionHR } from '../billing/styles/BillingStyles';
import {
  EditLink,
  PaymentDetails,
  PaymentDetailsRow,
  PaymentButton,
  CardIconWrap,
  PaymentCardIconWrap,
  DeleteLink,
  Coupon,
  CouponLink,
  PenIcon,
} from './styles/PaymentsSectionStyles';
import CardIcon from '../../../../common/CardIcon';
import StripeApi from '../../../../api/stripe/StripeApi';
import { ModalContext } from '../../../../context';

export const PaymentsSection = ({ setView, paymentDetails, setFormContext, setLoading, subscription }) => {
  const { setModal } = useContext(ModalContext);
  const navigateToPayment = () => {
    setView('payment');
    setFormContext('Update');
  };

  const apiCall = () => {
    setLoading(true);
    StripeApi.destroyPaymentMethods().then(() => setLoading(false));
  };

  const renderDeletion = () =>
    subscription === 'No subscription' && (
      <DeleteLink onClick={apiCall}>
        <Bin />
        <span>Delete</span>
      </DeleteLink>
    );

  const promoConfirm = code => StripeApi.promotionUpdate({ promotion_code: code });

  const promoModal = () =>
    setModal({
      key: 'Input',
      params: {
        icon: 'asterisk',
        question: 'Enter your promo code',
        placeholder: 'Enter code here...',
        confirmText: 'Apply',
        cancelText: 'Cancel',
        confirmAction: promoConfirm,
        cancelAction: () => setModal(),
      },
    });

  return (
    <div className="not-first-section">
      <SectionHeading className="payments-heading">
        <b>Payment Details</b>
        {paymentDetails && <Divider id="payment-divider" />}
        {paymentDetails && (
          <Fragment>
            <EditLink onClick={navigateToPayment}>
              <PenIcon className={'fa fa-pen'} />
              <span>Edit</span>
            </EditLink>
            {renderDeletion()}
          </Fragment>
        )}
      </SectionHeading>
      {paymentDetails ? (
        <PaymentDetails>
          <PaymentDetailsRow>
            <CardIconWrap>
              <CardIcon name={paymentDetails.cardType} width={'36px'} />
            </CardIconWrap>
            <span>
              <b>{paymentDetails.cardType}</b> ending {paymentDetails.cardEnd}
            </span>
            <Divider />
            <span> Expires on {paymentDetails.cardExpire}</span>
          </PaymentDetailsRow>
          <SectionHR id="payment-details" />
          <PaymentDetailsRow>
            <span>{paymentDetails.billingAddress}</span>
          </PaymentDetailsRow>
        </PaymentDetails>
      ) : (
        <PaymentButton onClick={navigateToPayment}>
          <PaymentCardIconWrap>
            <CardIcon name="blank" width="25px" />
          </PaymentCardIconWrap>
          <span>Enter Payment Details</span>
        </PaymentButton>
      )}
      <Coupon>
        Have a coupon? {'  '}
        <CouponLink onClick={promoModal}>Click here to enter your code</CouponLink>
      </Coupon>
    </div>
  );
};
