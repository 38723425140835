import styled from 'styled-components';

export const ChestHeaderContainer = styled.div`
  background-color: ${props => props.theme.searchBgColor};
  width: 50%;
  margin: 0 auto;
  padding: 1.25rem 1.75rem;
  color: ${props => props.theme.searchColor};
  border-radius: 5px 5px 0 0;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  justify-content: space-between;

  svg {
    padding-right: 15px;
    g {
      fill: ${props => props.theme.searchColor};
    }
  }

  span {
    line-height: 20px;
  }

  @media screen and (max-width: 767px) {
    width: 95%;
  }
`;

export const IconWrap = styled.div`
  line-height: 10px;

  &.escape {
    margin: 0 -10px 0 0;
    .st0 {
      fill: ${({ theme }) => theme.textColor};
    }
  }
`;

export const TitleWrap = styled.div`
  display: flex;
  flex-direction: row;

  &.link {
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.highlightColor};
      .st0 {
        fill: ${({ theme }) => theme.highlightColor};
      }
    }
  }
`;

export const Close = styled.div`
  line-height: 28px;
`;
