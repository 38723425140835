import React, { useContext } from 'react';
import {
  BillingMainText,
  BillingSubText,
  BillingButton,
  BillingDivider,
  SubscriptionWrap,
} from '../styles/BillingSummarySectionStyles';
import { secondsToDate, numToPounds } from '../../../../../utils/commonFunctions';
import StripeApi from '../../../../../api/stripe/StripeApi';
import { PlanSubtitle } from './styles/TeamStatusStyles';
import { Icon } from '../../../../../common/Icon';
import { ModalContext } from '../../../../../context';

const SubscriptionStatus = ({ accounts, subscription, setSubscription }) => {
  const { setModal } = useContext(ModalContext);
  const billingAmount =
    subscription &&
    subscription['items'] &&
    numToPounds(`${subscription['items']['data'][0]['price']['unit_amount'] * accounts.length}`);
  const deleteModal = () =>
    setModal({
      key: 'Confirm',
      params: {
        icon: 'alert',
        question: `Are you sure you want to cancel your subscription?`,
        subtext: 'You will not be charged for time after this.',
        confirmAction: deleteSub,
        cancelAction: () => setModal(),
      },
    });

  const deleteSub = () =>
    StripeApi.deleteSubscription().then(() => {
      setModal();
      setSubscription('No Subscription');
    });

  return (
    <SubscriptionWrap>
      {typeof subscription === 'string' ? (
        <PlanSubtitle>No Subscriptions</PlanSubtitle>
      ) : (
        <div>
          <BillingMainText>
            Your next payment for a team of {`${accounts.length}`} is <b>{billingAmount}</b>
          </BillingMainText>
          <PlanSubtitle>
            We will automatically renew your subscription on{' '}
            <b>{`${secondsToDate(subscription['billing_cycle_anchor'])}`}</b>
          </PlanSubtitle>
        </div>
      )}
      {typeof subscription === 'string' ? null : (
        <BillingSubText>
          <BillingDivider />
          <BillingButton className="delete-subscription" onClick={deleteModal}>
            <Icon name={'bin'} width={'14px'} /> Delete Subscription
          </BillingButton>
        </BillingSubText>
      )}
    </SubscriptionWrap>
  );
};

export default SubscriptionStatus;
