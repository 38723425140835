import React from 'react';
import { placeholderFunc } from '../../../../utils/commonFunctions';
import { SquaredButtonStyle, IconStyle } from '../styles/AdminSearchStyles';

const SquaredButton = ({ icon = '+', text = 'default', onClick = placeholderFunc, context = '' }) => (
  <SquaredButtonStyle role={'squared-button'} context={context === text} onClick={onClick}>
    <IconStyle context={context === text}>{icon}</IconStyle>
    <div>{text}</div>
  </SquaredButtonStyle>
);

export default SquaredButton;
