import React from 'react';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { ReactComponent as DarkArrowsIcon } from '../images/keyboardIcons/dark/arrows_01.svg';
import { ReactComponent as DarkKeyDownIcon } from '../images/keyboardIcons/dark/key_down_01.svg';
import { ReactComponent as DarkKeyEnterIcon } from '../images/keyboardIcons/dark/key_enter_01.svg';
import { ReactComponent as DarkKeyEscIcon } from '../images/keyboardIcons/dark/key_esc_01.svg';
import { ReactComponent as DarkKeyHIcon } from '../images/keyboardIcons/dark/key_h_01.svg';
import { ReactComponent as DarkKeyLeftIcon } from '../images/keyboardIcons/dark/key_left_01.svg';
import { ReactComponent as DarkKeyRightIcon } from '../images/keyboardIcons/dark/key_right_01.svg';
import { ReactComponent as DarkKeyNIcon } from '../images/keyboardIcons/dark/key_n_01.svg';
import { ReactComponent as DarkKeySIcon } from '../images/keyboardIcons/dark/key_s_01.svg';
import { ReactComponent as DarkKeyTabIcon } from '../images/keyboardIcons/dark/key_tab_01.svg';
import { ReactComponent as DarkTabIcon } from '../images/keyboardIcons/dark/key_tab.svg';
import { ReactComponent as DarkKeyUpIcon } from '../images/keyboardIcons/dark/key_up_01.svg';
import { ReactComponent as DarkKeyAIcon } from '../images/keyboardIcons/dark/key_A.svg';
import { ReactComponent as DarkKeyEIcon } from '../images/keyboardIcons/dark/key_E.svg';
import { ReactComponent as DarkKeyDIcon } from '../images/keyboardIcons/dark/key_D.svg';
import { ReactComponent as DarkKeyIIcon } from '../images/keyboardIcons/dark/key_i.svg';
import { ReactComponent as DarkKeyLIcon } from '../images/keyboardIcons/dark/key_L.svg';
import { ReactComponent as DarkSlashIcon } from '../images/keyboardIcons/dark/key_slash.svg';

import { ReactComponent as LightArrowsIcon } from '../images/keyboardIcons/light/arrows_02.svg';
import { ReactComponent as LightKeyDownIcon } from '../images/keyboardIcons/light/key_down_02.svg';
import { ReactComponent as LightKeyEnterIcon } from '../images/keyboardIcons/light/key_enter_02.svg';
import { ReactComponent as LightKeyEscIcon } from '../images/keyboardIcons/light/key_esc_02.svg';
import { ReactComponent as LightKeyHIcon } from '../images/keyboardIcons/light/key_h_02.svg';
import { ReactComponent as LightKeyLeftIcon } from '../images/keyboardIcons/light/key_left_02.svg';
import { ReactComponent as LightKeyRightIcon } from '../images/keyboardIcons/light/key_right_02.svg';
import { ReactComponent as LightKeyNIcon } from '../images/keyboardIcons/light/key_n_02.svg';
import { ReactComponent as LightKeySIcon } from '../images/keyboardIcons/light/key_s_02.svg';
import { ReactComponent as LightKeyTabIcon } from '../images/keyboardIcons/light/key_tab_02.svg';
import { ReactComponent as LightTabIcon } from '../images/keyboardIcons/light/key_tab.svg';
import { ReactComponent as LightKeyUpIcon } from '../images/keyboardIcons/light/key_up_02.svg';
import { ReactComponent as LightKeyAIcon } from '../images/keyboardIcons/light/key_A.svg';
import { ReactComponent as LightKeyEIcon } from '../images/keyboardIcons/light/key_E.svg';
import { ReactComponent as LightKeyDIcon } from '../images/keyboardIcons/light/key_D.svg';
import { ReactComponent as LightKeyIIcon } from '../images/keyboardIcons/light/key_i.svg';
import { ReactComponent as LightKeyLIcon } from '../images/keyboardIcons/light/key_L.svg';
import { ReactComponent as LightSlashIcon } from '../images/keyboardIcons/light/key_slash.svg';

import { ReactComponent as CopyIcon } from '../images/icons_copy.svg';
import { ReactComponent as AddIcon } from '../images/recrypt_add.svg';
import { ReactComponent as PadlockClosedIcon } from '../images/recrypt_secret.svg';
import { ReactComponent as WhitePadlockClosedIcon } from '../images/recrypt_secret_white.svg';
import { ReactComponent as PadlockOpenIcon } from '../images/recrypt_open_secret.svg';
import { ReactComponent as WhitePadlockOpenIcon } from '../images/recrypt_open_secret_white.svg';
import { ReactComponent as ShowIcon } from '../images/recrypt_show.svg';
import { ReactComponent as AvatarIcon } from '../images/recrypt_avatar.svg';
import { ReactComponent as ChestIcon } from '../images/recrypt_chest.svg';
import { ReactComponent as WhiteChestIcon } from '../images/recrypt_chest_white.svg';
import { ReactComponent as SettingsIcon } from '../images/recrypt_settings.svg';
import { ReactComponent as WhiteSettingsIcon } from '../images/recrypt_settings_white.svg';
import { ReactComponent as TeamIcon } from '../images/recrypt_team.svg';
import { ReactComponent as WhiteTeamIcon } from '../images/recrypt_team_white.svg';
import { ReactComponent as UserIcon } from '../images/recrypt_user.svg';
import { ReactComponent as WhiteUserIcon } from '../images/recrypt_user_white.svg';
import { ReactComponent as AddVerTwoIcon } from '../images/recrypt_add02.svg';
import { ReactComponent as AsteriskIcon } from '../images/recrypt_asterisk.svg';
import { ReactComponent as SecurityIcon } from '../images/recrypt_security.svg';
import { ReactComponent as WhiteSecurityIcon } from '../images/recrypt_security_white.svg';
import { ReactComponent as DisabledSecurityIcon } from '../images/recrypt_security_disabled.svg';
import { ReactComponent as AppleIcon } from '../images/recrypt_01-04.svg';
import { ReactComponent as AndroidIcon } from '../images/recrypt_01-05.svg';
import { ReactComponent as WindowsIcon } from '../images/recrypt_01-06.svg';
import { ReactComponent as OutlookIcon } from '../images/outlook-icon.svg';
import { ReactComponent as GmailIcon } from '../images/gmail-icon.svg';
import { ReactComponent as YahooIcon } from '../images/yahoo-icon.svg';
import { ReactComponent as LightStripeSolid } from '../images/stripe_solid_light.svg';
import { ReactComponent as DarkStripeOutline } from '../images/stripe_outline_dark.svg';
import { ReactComponent as BinIcon } from '../images/bin.svg';
import { ReactComponent as PrintIcon } from '../images/recrypt_print.svg';
import { ReactComponent as DownloadIcon } from '../images/recrypt_download.svg';
import { ReactComponent as AlertIcon } from '../images/recrypt alert-03.svg';
import { ReactComponent as TickIcon } from '../images/tick.svg';
import { ReactComponent as EyeIcon } from '../images/recrypt_eye.svg';
import { ReactComponent as EyeCloseIcon } from '../images/recrypt_eye copy-02.svg';
import { ReactComponent as EscapeIcon } from '../images/recrypt_esc.svg';
import { ReactComponent as DarkModeLogo } from '../images/recrypt_logo_01_darkmode.svg';
import { ReactComponent as LightModeLogo } from '../images/recrypt_logo_02_lightmode.svg';
import { ReactComponent as Help } from '../images/help_button.svg';

export const Icon = ({ name, width, className = '' }) => {
  const themeContext = useContext(ThemeContext);
  switch (name) {
    case 'arrows':
      return themeContext.scheme === 'dark' ? (
        <DarkArrowsIcon role={'DarkArrowsIcon'} width={width} className={className} />
      ) : (
        <LightArrowsIcon role={'LightArrowsIcon'} width={width} className={className} />
      );
    case 'keydown':
      return themeContext.scheme === 'dark' ? (
        <DarkKeyDownIcon role={'DarkKeyDownIcon'} width={width} className={className} />
      ) : (
        <LightKeyDownIcon role={'LightKeyDownIcon'} width={width} className={className} />
      );
    case 'keyup':
      return themeContext.scheme === 'dark' ? (
        <DarkKeyUpIcon role={'DarkKeyUpIcon'} width={width} className={className} />
      ) : (
        <LightKeyUpIcon role={'LightKeyUpIcon'} width={width} className={className} />
      );
    case 'enter':
      return themeContext.scheme === 'dark' ? (
        <DarkKeyEnterIcon role={'DarkKeyEnterIcon'} width={width} className={className} />
      ) : (
        <LightKeyEnterIcon role={'LightKeyEnterIcon'} width={width} className={className} />
      );
    case 'esc':
      return themeContext.scheme === 'dark' ? (
        <DarkKeyEscIcon role={'DarkKeyEscIcon'} width={width} className={className} />
      ) : (
        <LightKeyEscIcon role={'LightKeyEscIcon'} width={width} className={className} />
      );
    case 'key-h':
      return themeContext.scheme === 'dark' ? (
        <DarkKeyHIcon role={'DarkKeyHIcon'} width={width} className={className} />
      ) : (
        <LightKeyHIcon role={'LightKeyHIcon'} width={width} className={className} />
      );
    case 'keyleft':
      return themeContext.scheme === 'dark' ? (
        <DarkKeyLeftIcon role={'DarkKeyLeftIcon'} width={width} className={className} />
      ) : (
        <LightKeyLeftIcon role={'LightKeyLeftIcon'} width={width} className={className} />
      );
    case 'keyright':
      return themeContext.scheme === 'dark' ? (
        <DarkKeyRightIcon role={'DarkKeyRightIcon'} width={width} className={className} />
      ) : (
        <LightKeyRightIcon role={'LightKeyRightIcon'} width={width} className={className} />
      );
    case 'key-s':
      return themeContext.scheme === 'dark' ? (
        <DarkKeySIcon role={'DarkKeySIcon'} width={width} className={className} />
      ) : (
        <LightKeySIcon role={'LightKeySIcon'} width={width} className={className} />
      );
    case 'key-n':
      return themeContext.scheme === 'dark' ? (
        <DarkKeyNIcon role={'DarkKeyNIcon'} width={width} className={className} />
      ) : (
        <LightKeyNIcon role={'LightKeyNIcon'} width={width} className={className} />
      );
    case 'key-a':
      return themeContext.scheme === 'dark' ? (
        <DarkKeyAIcon role={'DarkKeyAIcon'} width={width} className={className} />
      ) : (
        <LightKeyAIcon role={'LightKeyAIcon'} width={width} className={className} />
      );
    case 'key-e':
      return themeContext.scheme === 'dark' ? (
        <DarkKeyEIcon role={'DarkKeyEIcon'} width={width} className={className} />
      ) : (
        <LightKeyEIcon role={'LightKeyEIcon'} width={width} className={className} />
      );
    case 'key-d':
      return themeContext.scheme === 'dark' ? (
        <DarkKeyDIcon role={'DarkKeyDIcon'} width={width} className={className} />
      ) : (
        <LightKeyDIcon role={'LightKeyDIcon'} width={width} className={className} />
      );
    case 'key-i':
      return themeContext.scheme === 'dark' ? (
        <DarkKeyIIcon role={'DarkKeyIIcon'} width={width} className={className} />
      ) : (
        <LightKeyIIcon role={'LightKeyIIcon'} width={width} className={className} />
      );
    case 'key-l':
      return themeContext.scheme === 'dark' ? (
        <DarkKeyLIcon role={'DarkKeyLIcon'} width={width} className={className} />
      ) : (
        <LightKeyLIcon role={'LightKeyLIcon'} width={width} className={className} />
      );
    case 'slash':
      return themeContext.scheme === 'dark' ? (
        <DarkSlashIcon role={'DarkSlashIcon'} width={width} className={className} />
      ) : (
        <LightSlashIcon role={'LightSlashIcon'} width={width} className={className} />
      );
    case 'tab':
      return themeContext.scheme === 'dark' ? (
        <DarkTabIcon role={'DarkTabIcon'} width={width} className={className} />
      ) : (
        <LightTabIcon role={'LightTabIcon'} width={width} className={className} />
      );
    case 'key-tab':
      return themeContext.scheme === 'dark' ? (
        <DarkKeyTabIcon role={'DarkKeyTabIcon'} width={width} className={className} />
      ) : (
        <LightKeyTabIcon role={'LightKeyTabIcon'} width={width} className={className} />
      );
    case 'logo':
      return themeContext.scheme === 'dark' ? (
        <DarkModeLogo role={'DarkModeLogo'} width={width} className={className} />
      ) : (
        <LightModeLogo role={'LightModeLogo'} width={width} className={className} />
      );
    case 'show':
      return <ShowIcon role={'ShowIcon'} width={width} className={className} />;
    case 'avatar':
      return <AvatarIcon role={'AvatarIcon'} width={width} className={className} />;
    case 'copy':
      return <CopyIcon role={'CopyIcon'} width={width} className={className} />;
    case 'add':
      return <AddIcon role={'AddIcon'} width={width} className={className} />;
    case 'locked':
      return <PadlockClosedIcon role={'PadlockClosedIcon'} width={width} className={className} />;
    case 'locked_white':
      return <WhitePadlockClosedIcon role={'WhitePadlockClosedIcon'} width={width} className={className} />;
    case 'unlocked':
      return <PadlockOpenIcon role={'PadlockOpenIcon'} width={width} className={className} />;
    case 'unlocked_white':
      return <WhitePadlockOpenIcon role={'WhitePadlockOpenIcon'} width={width} className={className} />;
    case 'chest':
      return <ChestIcon role={'ChestIcon'} width={width} className={className} />;
    case 'chest_white':
      return <WhiteChestIcon role={'WhiteChestIcon'} width={width} className={className} />;
    case 'settings':
      return <SettingsIcon role={'SettingsIcon'} width={width} className={className} />;
    case 'settings_white':
      return <WhiteSettingsIcon role={'WhiteSettingsIcon'} width={width} className={className} />;
    case 'user':
      return <UserIcon role={'UserIcon'} width={width} className={className} />;
    case 'user_white':
      return <WhiteUserIcon role={'WhiteUserIcon'} width={width} className={className} />;
    case 'team':
      return <TeamIcon role={'TeamIcon'} width={width} className={className} />;
    case 'team_white':
      return <WhiteTeamIcon role={'WhiteTeamIcon'} width={width} className={className} />;
    case 'addvertwo':
      return <AddVerTwoIcon role={'AddVerTwoIcon'} width={width} className={className} />;
    case 'asterisk':
      return <AsteriskIcon role={'AsteriskIcon'} width={width} className={className} />;
    case 'security':
      return <SecurityIcon role={'SecurityIcon'} width={width} className={className} />;
    case 'security_white':
      return <WhiteSecurityIcon role={'WhiteSecurityIcon'} width={width} className={className} />;
    case 'security_disabled':
      return <DisabledSecurityIcon role={'DisabledSecurityIcon'} width={width} className={className} />;
    case 'apple':
      return <AppleIcon role={'AppleIcon'} width={width} className={className} />;
    case 'android':
      return <AndroidIcon role={'AndroidIcon'} width={width} className={className} />;
    case 'windows':
      return <WindowsIcon role={'WindowsIcon'} width={width} className={className} />;
    case 'outlook':
      return <OutlookIcon role={'OutlookIcon'} width={width} className={className} />;
    case 'gmail':
      return <GmailIcon role={'GmailIcon'} width={width} />;
    case 'yahoo':
      return <YahooIcon role={'YahooIcon'} width={width} />;
    case 'stripe':
      return themeContext.scheme === 'dark' ? (
        <DarkStripeOutline role={'DarkStripeOutline'} width={width} className={className} />
      ) : (
        <LightStripeSolid role={'LightStripeSolid'} width={width} className={className} />
      );
    case 'bin':
      return <BinIcon role={'BinIcon'} width={width} className={className} />;
    case 'tick':
      return <TickIcon role={'TickIcon'} width={width} className={className} />;
    case 'print':
      return <PrintIcon role={'PrintIcon'} width={width} className={className} />;
    case 'download':
      return <DownloadIcon role={'DownloadIcon'} width={width} className={className} />;
    case 'alert':
      return <AlertIcon role={'AlertIcon'} width={width} className={className} />;
    case 'eye':
      return <EyeIcon role={'EyeIcon'} width={width} className={className} />;
    case 'eyeclose':
      return <EyeCloseIcon role={'EyeCloseIcon'} width={width} className={className} />;
    case 'escape':
      return <EscapeIcon role={'EscapeIcon'} width={width} className={className} />;
    case 'help':
      return <Help role={'Help'} width={width} className={className} />;
    default:
      return null;
  }
};
