export const errorCheck = (name, notificationText) => {
  if (!notificationText) return false;
  return notificationText &&
    notificationText['errors'] &&
    notificationText['errors'].map(err => err['field'] === name).filter(Boolean).length > 0
    ? 'error'
    : '';
};

export const removeError = (field, notificationText) => {
  const { type, errors } = notificationText;
  const newErrors = errors && errors.filter(error => field !== error['field']);
  if (!newErrors || newErrors.length === 0) return null;
  return { type, errors: newErrors };
};
