import styled from 'styled-components';

export const WelcomeContainerWrap = styled.div`
  display: flex;
  padding: 3rem 4rem;
  flex-direction: column;
  background-color: ${props => props.theme.tableBgColor};
  border-radius: 0.2rem;
  margin-top: 5rem;
  width: 20vw;
  height: fit-content;
  color: ${props => props.theme.textColor};
  text-align: center;
  overflow-y: auto;

  @media screen and (max-width: 1024px) {
    width: auto;
    max-width: 360px;
    margin: 0 2.5%;
  }
`;

export const Title = styled.div`
  display: flex;
  flex: 2;
  font-size: 2.6rem;
  align-self: center;
  font-weight: 700;
  margin: 0 0 1.5rem 0;
  color: ${props => props.theme.textColor};
`;

export const Subtext = styled.div`
  display: flex;
  flex: 1;
  font-size: 1.2rem;
  align-self: center;
  line-height: 140%;
  margin: 0 0 2rem 0;
`;

export const ChildrenWrap = styled.div`
  display: flex;
  flex: 8;
  flex-direction: column;
`;
