import BaseApi from '../BaseApi';
import ApiRequest from '../ApiRequest';
import { Password } from '../../steganography/password';
import { Keypair } from '../../steganography/keypair';

export const UserTableData = async (params = {}) => {
  const userData = await BaseApi.all('users', params);
  const teamData = await BaseApi.all('teams', { everything: true });
  if (!userData || !teamData) return 0;
  const users = userData.items;
  const teams = teamData.items;

  return users.map(user => ({
    id: user.id,
    name: user.name,
    email: user.email,
    teams: matchTeams(user.id, teams),
    permissions: user.administrator ? 'Admin' : 'User',
    confirmed: user['confirmed_at'] ? 'Accepted' : 'Pending',
    locked: user.locked,
  }));
};

const matchTeams = (userId, teams) =>
  teams.filter(team => {
    const matchedTeam = team.memberships.find(({ user_id }) => user_id === userId);
    if (matchedTeam) return matchedTeam;
  });

export const ViewUserData = async id => {
  const user = await BaseApi.show('users', id);
  return user;
};

export const PromoteUser = async (vaultId, userId) => {
  const params = { user_id: userId };
  const response = await ApiRequest('POST', `api/v1/vaults/${vaultId}/promote`, params);
  return response;
};

export const DemoteUser = async (vaultId, userId) => {
  const params = { user_id: userId };
  const response = await ApiRequest('POST', `api/v1/vaults/${vaultId}/demote`, params);
  return response;
};

export const UpdateUser = async (userId, params) => {
  await BaseApi.update('users', userId, params);
};

export const CreateUser = async ({ name, email, password }) => {
  const aes_key = await Password.masterKey(email, password);
  const passHash = await Password.prehash(email, password);
  const keys = await Keypair.generate(aes_key);

  const params = {
    user: {
      name: name,
      email: email,
      password: passHash,
      public_key: keys.publicKey,
      encrypted_private_key: keys.encryptedPrivateKey,
      private_key_iv: keys.iv,
    },
  };
  return await BaseApi.create('users', params);
};

export const DeleteUser = async id => {
  const user = await BaseApi.destroy('users', id);
  return user;
};
