import ApiRequest from '../ApiRequest';

class ProfileApi {
  static show() {
    return ApiRequest('GET', `api/v1/profile.json`);
  }

  static update(params) {
    return ApiRequest('PATCH', `api/v1/profile.json`, params);
  }
}

export default ProfileApi;
