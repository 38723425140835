import React, { Fragment, useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { WelcomeContext } from '../../context/index';
import { WelcomeWrap } from '../welcome/styles/WelcomeMenuStyles';
import {
  ImageContainer,
  ImageHeader,
  ImageText,
  SlideBox,
  ImageNext,
  DotWrapper,
  Dot,
  LinkWrap,
} from './styles/TutorialStyles';
import { slides } from './Slides';
import Mousetrap from 'mousetrap';
import { UserContext } from '../../context/UserProvider';

export const Tutorial = () => {
  const [slide, setSlide] = useState(0);
  const history = useHistory();
  const { user, update } = useContext(UserContext);
  const { administrator } = user;
  const { welcome, setWelcome } = useContext(WelcomeContext);

  const renderSlide = ({ heading, text, image }) => (
    <Fragment>
      <ImageContainer src={image} alt={heading} />
      <ImageHeader>{heading}</ImageHeader>
      <ImageText>{text}</ImageText>
    </Fragment>
  );

  // Keyboard shortcuts
  useEffect(() => {
    Mousetrap.bind('enter', nextSlide);
    Mousetrap.bind('backspace', () => slide !== 0 && setSlide(slide - 1));
    Mousetrap.bind('left', () => slide !== 0 && setSlide(slide - 1));
    Mousetrap.bind('right', nextSlide);

    return () => {
      Mousetrap.unbind('enter');
      Mousetrap.unbind('backspace');
      Mousetrap.unbind('left');
      Mousetrap.unbind('right');
    };
  });

  const finishSlide = () => {
    update('tutorial', true);
    if (administrator) {
      setWelcome({
        ...welcome,
        tutorial: true,
      });
    } else {
      setWelcome({
        ...welcome,
        password: true,
        tutorial: true,
      });
    }

    if (slide === slides.length - 1 && Object.values(welcome).every(item => item)) {
      update('first_time', false);
      history.push('/');
    } else {
      if (slide === slides.length - 1) return history.push('/welcome');
    }
  };

  const nextSlide = () => {
    if (slide < slides.length - 1) return setSlide(slide + 1);
    else {
      finishSlide();
    }
  };

  const transitionWord = () => {
    if (slide < slides.length - 1) return 'Next';
    return 'Finish';
  };

  return (
    <WelcomeWrap>
      <SlideBox>
        {renderSlide(slides[slide])}
        <LinkWrap>
          {slide !== 0 && <ImageNext onClick={() => setSlide(slide - 1)}>Previous</ImageNext>}
          <ImageNext next onClick={() => nextSlide()}>
            {transitionWord()}
          </ImageNext>
        </LinkWrap>
        <DotWrapper>
          {slides.map((v, index) => (
            <Dot
              role={`dot-${index}`}
              key={v.heading}
              onClick={() => setSlide(index)}
              className={index === slide ? 'selected' : null}
            ></Dot>
          ))}
        </DotWrapper>
      </SlideBox>
    </WelcomeWrap>
  );
};
