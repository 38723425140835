import React, { Fragment } from 'react';
import { TableTr } from '../styles/TableStyles';
import { getCell } from '../subComponents/uniqueCells/UniqueCells';

export const generateRows = (
  jsonKeys,
  data,
  onClick,
  onHighlight,
  params,
  columnWidths,
  highlight,
  role,
  headers,
  owner,
) => {
  const tableRowOnClickStrategies = {
    'secrets-list-table': row => onClick(row),
    'chests-list-table': row => onClick(row),
  };

  const elements = Array.from(document.getElementsByClassName('table-row'));

  const handleMouseEnter = i => {
    // Bug fix P4SS-1480: map over all elements and remove focused
    elements.map(element => {
      element.classList.remove('focused');
    });
    document.activeElement.blur();
    // fix to force previous hovered element to un-focus
    onHighlight(i);
  };
  return data.map((row, i) => {
    return (
      <Fragment key={i}>
        <TableTr
          className={'table-row'}
          role={'table-row'}
          onClick={() => {
            if (tableRowOnClickStrategies[role]) tableRowOnClickStrategies[role](row);
          }}
          onMouseEnter={() => handleMouseEnter(i)}
          onMouseLeave={() => onHighlight()}
          tabIndex="0"
          key={i}
          onFocus={() => onHighlight(i)}
          data-testid={`table-row`}
          headers={headers}
        >
          {_generateCells(jsonKeys, row, onClick, params, columnWidths, highlight, owner)}
        </TableTr>
      </Fragment>
    );
  });
};

const _generateCells = (jsonKeys, row, onClick, params, columnWidths, highlight, owner) =>
  jsonKeys.map((key, idx) => {
    const cellWidth = columnWidths[idx];
    const highlightedRow = highlight;
    return getCell(key)(key, row, params, onClick, idx, cellWidth, highlightedRow, owner);
  });
