import { Conversion } from './conversion';

// Anything related to symmetric encryption/decryption lives here:
export class Cryptification {
  // Encrypt a value (which may or may not be wrapped in a promise).
  static encrypt(key, value, config) {
    return Promise.all([key, value])
      .then(function (result) {
        var real_key = result[0];
        var real_value = Conversion.str2buf(result[1]);
        return window.crypto.subtle.encrypt(config, real_key, real_value);
      })
      .then(function (buffer) {
        return Conversion.bin2txt(buffer);
      });
  }

  // Decrypt a value (which may or may not be wrapped in a promise).
  static decrypt(key, value, config) {
    return Promise.all([key, value])
      .then(function (result) {
        var real_key = result[0];
        var real_val = Conversion.txt2bin(result[1]);
        return window.crypto.subtle.decrypt(config, real_key, real_val);
      })
      .then(function (buffer) {
        return Conversion.buf2str(buffer);
      });
  }
}
