import styled from 'styled-components';

export const SettingsContainer = styled.div`
  display: flex;
  flex: 12;
`;

export const NavContainer = styled.div`
  display: flex;
  flex: 1;
  background-color: ${props => props.theme.settingsBgColor};
`;

export const OptionWrap = styled.div`
  display: flex;
  flex: 3;
  padding: 0 1.5rem 0 1.5rem;
  overflow-y: auto;
  overflow-x: hidden;
  color: ${props => props.theme.textColor};
  background-color: ${props => props.theme.searchBgColor};

  &.noScroll {
    overflow-y: hidden;
  }

  @media (-webkit-device-pixel-ratio: 1.5) {
    .stripe-logo {
      transform: scale(0.7);
    }
  }
`;

export const DownloadButton = styled.button`
  margin-left: 0.5rem;
  width: 50%;
  height: 2rem;
  font-size: 16px;
  background-color: ${props => props.theme.highlightColor};
  color: white;
  border: 1px solid ${props => props.theme.highlightColor};
  border-radius: 5px;
`;

export const AdminSettingsWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 12;
  background-color: ${props => props.theme.fieldBgColor};
  font-size: 1.6rem;

  @media screen and (max-width: 767px) {
    > div {
      flex-direction: column;
    }
    ul li {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }
`;
