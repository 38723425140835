import React, { useContext, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import LocalStore from '../../api/LocalStore';
import { UserContext } from '../../context/UserProvider';

const ProtectedPage = ({ children, ...rest }) => {
  const { logout } = useContext(UserContext);
  const store = new LocalStore();

  useEffect(() => {
    if (!store.get('token')) logout();
  }, [logout]);

  if (store.get('token')) {
    return (
      <Route {...rest}>
        {({ match }) => (
          <CSSTransition in={match != null} timeout={300} classNames="page" unmountOnExit>
            {children}
          </CSSTransition>
        )}
      </Route>
    );
  }

  return <Redirect to={{ pathname: '/login', state: { from: rest.path } }} />;
};

export default ProtectedPage;
