import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Wrap,
  IconWrap,
  TitleWrap,
  PlanIcon,
  Name,
  PriceWrap,
  Currency,
  Price,
  Unit,
  FeatureWrap,
  Feature,
  PriceHighlight,
  Divider,
  SubtitleWrap,
  ButtonWrap,
  Subtitle,
  PlanButton,
  TrialButton,
} from '../styles/PlanContainerStyle';
import { Icon } from '../../../common/Icon';
import { getPlanObj } from '../../admin/adminSettings/subComponents/subComponents/subFunctions/PlanStatusFunctions';
import { ModalContext } from '../../../context';
import { useNotification } from '../../../common/notification/Notification';
import StripeApi from '../../../api/stripe/StripeApi';
import { UserContext } from '../../../context/UserProvider';

const PlanContainer = ({ plan, selected, setPage, setPlanModal }) => {
  const { icon, name, price, priceNum, billingDetails, unit, features } = getPlanObj(plan);
  const { setNotificationText } = useNotification();
  const { setModal } = useContext(ModalContext);
  const { user } = useContext(UserContext);
  const { trialled } = user;
  const history = useHistory();

  const isSelected = () => selected && 'selected';
  const isDisabled = () => (plan === 'Free' && selected ? 'disabled' : '');
  const generateFeatures = () => features.map((feature, i) => <Feature key={i}>{`${feature}`}</Feature>);
  const getButtonText = () => (selected ? '' : 'Choose Plan');
  const getOnClick = () => (selected ? setPlanModal(true) : setPage('payment'));
  const handleClick = () => (plan === 'Free' ? setPlanModal(true) : getOnClick());
  const makeTrial = () =>
    StripeApi.createTrial().then(res => {
      history.push('/admin/settings', { fromPlans: true });
      setModal();
      if (typeof res === 'string') setNotificationText({ type: 'error', text: [res] });
    });

  const trialButton = () => <TrialButton onClick={trialModal}>Take 30 days free on us!</TrialButton>;

  const trialModal = () =>
    setModal({
      key: 'BillingAlert',
      params: {
        title: 'Recrypt For Your Team',
        subtext: 'Start Sharing The Right Way',
        confirmAction: () => makeTrial(),
        cancelAction: () => setModal(),
        escape: true,
      },
    });

  const trialCheck = () => plan === 'RecryptPaid' && !trialled;

  return (
    <Wrap className={`${isSelected()}`}>
      <IconWrap className={`${isSelected()}`}>
        <Icon name={'tick'} width={'14px'} className={`${isSelected()}`} />
      </IconWrap>
      <TitleWrap>
        <PlanIcon>
          <Icon name={`${icon}`} width={'20px'} />
        </PlanIcon>
        <Name>{`${name}`}</Name>
      </TitleWrap>
      <PriceWrap>
        <PriceHighlight>
          <Currency>£</Currency>
          <Price>{`${priceNum}`}</Price>
          <Unit>{`${unit}`}</Unit>
        </PriceHighlight>
        <SubtitleWrap>
          <Subtitle>{`${price}`}</Subtitle>
          <Subtitle>{`${billingDetails}`}</Subtitle>
        </SubtitleWrap>
      </PriceWrap>
      <Divider />
      <FeatureWrap>{generateFeatures()}</FeatureWrap>
      <ButtonWrap>
        {trialCheck() && trialButton()}
        <PlanButton role={'plan-button'} onClick={handleClick} className={`${isSelected()} ${isDisabled()}`}>
          <span>{getButtonText()}</span>
        </PlanButton>
      </ButtonWrap>
    </Wrap>
  );
};

export default PlanContainer;
