import { validateLogin } from './subFunctions/loginValidation';
import { validateRegister } from './subFunctions/registerValidation';
import { validatePasswordSecret } from './subFunctions/passwordSecretValidation';
import { validateTelephoneSecret } from './subFunctions/telephoneSecretValidation';
import { validateAddressSecret } from './subFunctions/addressSecretValidation';
import { validateNoteSecret } from './subFunctions/noteSecretValidation';
import { validatePaymentForm } from './subFunctions/paymentFormValidation';
import { validateChestForm } from './subFunctions/chestFormValidation';
import { validateTeamForm } from './subFunctions/teamFormValidation';
import { validateUserForm } from './subFunctions/userFormValidation';
import { validatePasswordString } from './subFunctions/validatePasswordString';

export const getValidation = key => keyValidationPair[key] || keyValidationPair['default'];

const _loginValidation = params => validateLogin(params);
const _registerValidation = params => validateRegister(params);
const _passwordSecretValidation = params => validatePasswordSecret(params);
const _telephoneSecretValidation = params => validateTelephoneSecret(params);
const _addressSecretValidation = params => validateAddressSecret(params);
const _noteSecretValidation = params => validateNoteSecret(params);
const _paymentFormValidation = params => validatePaymentForm(params);
const _chestFormValidation = params => validateChestForm(params);
const _teamFormValidation = params => validateTeamForm(params);
const _userFormValidation = params => validateUserForm(params);
const _validPasswordString = params => validatePasswordString(params);
const _noValidation = params => {
  console.log('Validation Defaulted', params);
  return true;
};

const keyValidationPair = {
  login: _loginValidation,
  registerInitial: _registerValidation,
  passwordSecret: _passwordSecretValidation,
  telephoneSecret: _telephoneSecretValidation,
  addressSecret: _addressSecretValidation,
  noteSecret: _noteSecretValidation,
  paymentForm: _paymentFormValidation,
  ChestForm: _chestFormValidation,
  TeamForm: _teamFormValidation,
  UserForm: _userFormValidation,
  checkPassword: _validPasswordString,
  default: _noValidation,
};
