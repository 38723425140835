import React, { useEffect } from 'react';
import { Wrap, BubbleWrap, BubbleTag, TagText, BubbleRow } from './styles/BubbleSelectionStyles';
import GenericLoading from '../../../genericLoading/GenericLoading';

const BubbleSelection = ({ selection, setSelection, objList }) => {
  const isSelected = obj => selection && selection === obj;

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      document.getElementById('first-tag') && document.getElementById('first-tag').focus();
    }
    return () => {
      isMounted = false;
    };
  });

  const generateBubbles = () =>
    objList.map((obj, i) => (
      <BubbleRow key={i}>
        <BubbleTag
          id={i === 0 && 'first-tag'}
          tabIndex="0"
          selected={isSelected(obj)}
          onClick={() => setSelection(obj)}
          onKeyPress={e => e.key === 'Enter' && setSelection(obj)}
        >
          {obj && obj['name'].slice(0, 12)}
        </BubbleTag>
        <TagText>{obj && `Secrets ${obj['secrets']}`}</TagText>
      </BubbleRow>
    ));

  if (!objList) return <GenericLoading />;
  return (
    <Wrap>
      <BubbleWrap>{generateBubbles()}</BubbleWrap>
    </Wrap>
  );
};

export default BubbleSelection;
