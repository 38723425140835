import React from 'react';
import ConfirmationModal from './subComponents/ConfirmationModal';
import SelectionModal from './subComponents/SelectionModal';
import BillingAlertModal from './subComponents/BillingAlertModal';
import SecureConfirmationModal from './subComponents/SecureConfirmationModal';
import InputModal from './subComponents/InputModal';
import TransferModal from './subComponents/TransferModal';
import LinkModal from './subComponents/LinkModal';
import ChangeEmailModal from './subComponents/ChangeEmailModal';
import ChangePasswordModal from './subComponents/ChangePasswordModal';

export const getModal = key => _keyValuePair[key];

const _confirmationModal = params => <ConfirmationModal {...params} />;
const _secureConfirmationModal = params => <SecureConfirmationModal {...params} />;
const _transferModal = params => <TransferModal {...params} />;
const _inputModal = params => <InputModal {...params} />;
const _selectionModal = params => <SelectionModal {...params} />;
const _billingAlertModal = params => <BillingAlertModal {...params} />;
const _linkModal = params => <LinkModal {...params} />;
const _changeEmail = params => <ChangeEmailModal {...params} />;
const _changePasswordModal = params => <ChangePasswordModal {...params} />;

const _keyValuePair = {
  Confirm: _confirmationModal,
  SecureConfirm: _secureConfirmationModal,
  Transfer: _transferModal,
  Input: _inputModal,
  Select: _selectionModal,
  BillingAlert: _billingAlertModal,
  Link: _linkModal,
  Email: _changeEmail,
  Password: _changePasswordModal,
};
