import React from 'react';
import { IconArea } from './styles/BillingHistoryActionsStyles';

export const BillingHistoryActions = ({ row }) => {
  const downloadInvoice = () => window.open(row['invoicePdf']);

  return (
    <IconArea role={'icon'} onClick={() => downloadInvoice()} className="billing-history">
      <i className="fas fa-download"></i>
      <span tabIndex="0" onKeyPress={e => e.key === 'Enter' && downloadInvoice()}>
        Download Invoice
      </span>
    </IconArea>
  );
};
