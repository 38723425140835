import styled from 'styled-components';

export const ModalSearchbar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-top: 1px solid ${props => props.theme.textOutlineColor};
  border-bottom: 1px solid ${props => props.theme.textOutlineColor};
  height: 3rem;
  padding-left: 1.25rem;

  i {
    margin-right: 0.75rem;
  }

  input {
    background-color: inherit;
    color: ${props => props.theme.searchColor};
    border: none;
    font-size: 1rem;
    outline: none;
    width: 100%;
    padding-right: 1.25rem;
    font-family: 'Lato';

    ::placeholder {
      opacity: 0.5;
      color: ${props => props.theme.searchColor};
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: inherit;
  padding: 1rem 1.25rem;
  width: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
  outline: none;
  color: ${props => props.theme.fieldTextColor};

  > div div:empty {
    display: none;
  }

  &:focus {
    background-color: ${props => props.theme.highlightColor};
    color: ${props => props.theme.hoverRowColor};
  }

  &.selected {
    background-color: ${props => props.theme.highlightColor};
    color: ${props => props.theme.hoverRowColor};

    > div {
      div:last-of-type {
        color: #252a41;
      }
    }
  }
`;

export const StartWrapper = styled.div`
  background-color: inherit;
  color: inherit;
  display: flex;
  width: 75%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const NameCell = styled.div`
  color: inherit;
  background-color: inherit;
  font-size: 1rem;
  max-width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const SubCell = styled.div`
  color: ${props => props.theme.placeholderColor};
  background-color: inherit;
  font-size: 1rem;
  width: 25%;
  margin-left: 0.5rem;
  text-align: right;
`;
