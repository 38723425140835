import React from 'react';
import { HeaderWrap } from './styles/HeaderWrap';
import { useLocation } from 'react-router-dom';
import { Icon } from '../../../../common/Icon';

export const CreateHeader = () => {
  const { pathname } = useLocation();

  const returnIcon = () => {
    if (pathname.includes('chests/new')) return <Icon name="chest" width="21px" />;
    if (pathname.includes('secret/new')) return <Icon name="unlocked" width="21px" />;
    if (pathname.includes('teams/new')) return <Icon name="team" width="21px" />;
    if (pathname.includes('user/new')) return <Icon name="user" width="21px" />;
  };

  const returnText = () => {
    if (pathname.includes('chests/new')) return 'New Chest';
    if (pathname.includes('secret/new')) return 'New Secret';
    if (pathname.includes('teams/new')) return 'New Team';
    if (pathname.includes('user/new')) return 'New User';
  };

  return (
    <HeaderWrap>
      {returnIcon()}
      <div>{returnText()}</div>
    </HeaderWrap>
  );
};
