import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 45vh;
  color: ${props => props['theme']['textColor']};
`;
