import styled from 'styled-components';

export const SlideBox = styled.div`
  padding: 1.5rem;
  background-color: ${props => props.theme.tableBgColor};
  width: 35vw;
  height: 670px;
  min-width: 600px;
  max-width: 790px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1440px) and (max-width: 1919px) {
    width: 45vw;
    height: 570px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    width: 73vw;
    height: 580px;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    width: 85vw;
    height: 540px;
  }
  @media screen and (max-width: 767px) {
    min-width: 0;
    box-sizing: border-box;
    width: 95%;
    height: 550px;
  }
`;

export const ImageContainer = styled.img`
  width: 100%;
  height: 65%;
  object-fit: cover;

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    height: 55%;
  }
  @media screen and (max-width: 767px) {
    height: 50%;
  }
`;

export const ImageHeader = styled.div`
  color: ${props => props.theme.textColor};
  text-align: center;
  box-sizing: border-box;
  padding: 1.5rem;
  font-size: 1.6rem;
`;

export const ImageText = styled.div`
  color: ${props => props.theme.textColor};
  text-align: center;
  box-sizing: border-box;
  padding: 0 1.5rem 1.5rem 1.5rem;
  font-size: 1rem;
  line-height: 150%;

  @media screen and (max-width: 767px) {
    padding: 0;
  }
`;

export const LinkWrap = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
`;

export const ImageNext = styled.div`
  color: ${props => props.theme.blueText};
  flex: 1;
  margin: 0 10px;
  font-size: 1rem;
  cursor: pointer;
`;

export const DotWrapper = styled.div`
  height: 5%;
  position: absolute;
  bottom: -90px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    bottom: -60px;
  }
  @media screen and (max-width: 767px) {
    bottom: -60px;
  }
`;

export const Dot = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 10px;
  margin: 2px 5px;
  background-color: ${props => props.theme.tableBgColor};
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &.selected {
    background-color: ${props => props.theme.dotHighlight};
  }
`;
