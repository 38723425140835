import { useEffect, useState } from 'react';
import { formatAuditLogDate, formatDate, formatUser } from '../../utils/commonFunctions';
import Request from '../Request';

interface IProps {
  filter?: string;
  limit?: number;
  offset?: number;
  order?: 'desc' | 'asc';
  everything?: boolean;
}

const useAuditLogs = ({ filter = '', limit = 50, offset = 0, order = 'desc', everything = false }: IProps) => {
  const [data, setData] = useState<RecryptRequest>();

  useEffect(() => {
    const fetchData = async () => {
      const handler = new Request('GET', '/api/v1/audit_log_entries', {
        filter,
        limit,
        offset,
        order,
        everything,
      });
      const res = await handler.perform();
      setData(res);
    };
    fetchData();
  }, [filter, offset]);

  const formatAuditData = (data: RecryptRequest | undefined) => {
    if (!data) return;
    const items = data['json']['items'].map((auditLog: RecryptLog) => ({
      date: formatDate(auditLog.created_at),
      styledDate: formatAuditLogDate(auditLog.created_at),
      activity: auditLog.action,
      id: auditLog.id,
      user: formatUser(auditLog.user_string),
    }));
    return { total: data['json']['items'].length, items };
  };

  return formatAuditData(data);
};

export default useAuditLogs;
