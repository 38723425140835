import styled from 'styled-components';

export const ToggleContainer = styled.div`
  .switch {
    position: relative;
    width: 40px;
    height: 12px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .switch[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    height: 12px;
    width: 40px;
    background-color: ${props => props.theme.textColor};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 20px;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    top: -5px;
    background-color: ${props => props.theme.settingsBgColor};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
    border: 1px solid ${props => props.theme.textColor};
  }

  input:checked + .slider {
    background-color: ${props => props.theme.highlightColor};
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #000000;
    border: 1.5px solid ${props => props.theme.settingsTextColor};
  }

  input:focus + .slider:before {
    border: 1.5px solid ${props => props.theme.settingsTextColor};
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
`;
