import { testIf } from '../validationFunctions';
import { mergeArrays } from '../../mergeFunctions';
import { successState, formatResults } from '../validationHelpers';

export const validatePaymentForm = ({ data, setNotificationText }) => {
  const emptyRes = testIf('empty')(data, ['fullName', 'addressOne', 'city', 'country', 'quantity']);
  const numberRes = testIf('number')(data, ['quantity']);
  const testResults = mergeArrays([emptyRes, numberRes]);
  if (testResults.length === 0) return successState(setNotificationText);
  setNotificationText(formatResults(testResults));
};
