import styled from 'styled-components';

export const FormContainer = styled.div`
  position: relative;
  width: 50%;
  max-height: 70vh;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  @media screen and (max-width: 767px) {
    width: 95%;
    height: auto;
    max-height: unset;
  }
`;
