import React from 'react';
import { BackButton } from '../../admin/adminSettings/billing/styles/BillingStyles';
import { Wrap, Subtitle, NavWrap } from '../styles/PlanPaymentHeaderStyles';

const PlanPaymentHeader = ({ setView }) => {
  return (
    <Wrap>
      <NavWrap>
        <BackButton onClick={() => setView('summary')}>{'< Back'}</BackButton>
        <Subtitle>Payment Details</Subtitle>
      </NavWrap>
    </Wrap>
  );
};

export default PlanPaymentHeader;
