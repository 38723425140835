import React, { useEffect, useContext } from 'react';

import { Cross, NotificationContainer } from './NotificationStyles';
import { NotificationContext } from '../../context';

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotification must be used within a NotificationContext.Provider');
  }

  return context;
};

export const Notification = ({ notificationText }) => {
  const { setNotificationText } = useNotification();

  const formatNotification = notificationText => {
    if (typeof notificationText === 'string') return notificationText;
    if (Array.isArray(notificationText['errors'])) return notificationText['errors'].map(err => err['error']);
    if (typeof notificationText['errors'] === 'string') return notificationText['errors'];

    return notificationText['text'];
  };

  useEffect(() => {
    let timer;
    if (notificationText) {
      timer = setTimeout(() => setNotificationText(null), 8000);
    }
    return () => clearTimeout(timer);
  }, [notificationText]);

  if (!notificationText) return null;
  return (
    <NotificationContainer onClick={() => setNotificationText(null)} error={notificationText['type'] === 'error'}>
      {formatNotification(notificationText)}
      <Cross className={'fas fa-times'} />
    </NotificationContainer>
  );
};
