import styled from 'styled-components';

export const MainContentContainer = styled.div`
  display: flex;
  align-content: start;
  flex-wrap: wrap;
  row-gap: 1rem;
  column-gap: 0.75rem;
  height: 67vh;
  overflow-y: auto;
`;

export const ExtraGapContainer = styled.div`
  height: 3.15rem;
`;
