import styled from 'styled-components';

export const RecoveryContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
`;

export const RecoveryCodesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const RecoveryCode = styled.span`
  font-weight: bold;
  font-size: 1.1rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
`;

export const RecoveryActionsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
`;

export const RecoveryAction = styled.span`
  font-weight: bold;
  cursor: pointer;

  svg {
    margin-right: 0.5rem;
  }
`;
