import React, { useState, useEffect, useContext } from 'react';
import Mousetrap from 'mousetrap';
import SearchBar from '../../common/searchBar/SearchBar';
import ListTransition from '../secrets/styles/TransitionStyle';
import { CSSTransition } from 'react-transition-group';
import FooterLink from '../../common/links/FooterLink';
import { FooterTagWrap } from '../../common/links/styles/FooterLinkStyle';
import Table from '../table/Table';
import { ViewChest } from './ViewChest';
import { ViewSecret } from '../secrets/ViewSecret';
import { TableWrap } from '../table/styles/TableStyles';
import { ChestTableData } from '../../api/chests/ChestApiFunctions';
import LogoutRedirect from '../../common/nav/LogoutRedirect';
import { scrollEnded, focus } from '../../utils/commonFunctions';
import { TableBottomLoading } from '../table/subComponents/styles/TableBottomLoading';
import Loading from '../../images/loading_02.gif';
import { DashboardChestContext } from '../../context/DashboardContext';
import { UserContext } from '../../context/UserProvider';
import debounce from 'lodash.debounce';
import LocalStore from '../../api/LocalStore';

const Chests = ({ stateObject }) => {
  const { display, setDisplay, searchText, setSearchText, setAddSecret, addSecret } = stateObject;
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(false);
  const [viewChest, setViewChest] = useState();
  const [viewSecret, setViewSecret] = useState();
  const [tableData, setTableData] = useState([]);
  const [offset, setOffset] = useState(0);
  const { user } = useContext(UserContext);
  const [secretData, setSecretData] = useState();
  const { administrator } = user;
  const store = new LocalStore();
  const jsonLimit = 20;
  const headers = {
    Name: 'name',
    Secrets: 'secrets',
    Updated: 'updated',
    Actions: 'chestActions',
  };
  const columnWidths = ['25%', '25%', '25%', '25%'];
  if (searchText !== '' && display === false) setDisplay(true);

  const dashboardChestTable = {
    tableData: [tableData, setTableData],
    searchText: searchText,
    jsonLimit: jsonLimit,
  };

  useEffect(() => {
    setSearchText('');
  }, [addSecret, setSearchText]);

  useEffect(() => {
    if (viewChest) setViewChest();
    if (viewSecret) setViewSecret();
  }, [display, setViewChest, setViewSecret, searchText]);

  const lastChest = store.get('lastChest');
  if (lastChest && !viewChest) {
    store.remove('lastChest');
    setViewChest(lastChest);
  }

  const setSearch = debounce(setSearchText, process.env.REACT_APP_DEBOUNCE_SEARCH_TIMER_VALUE);

  useEffect(() => {
    const getTableData = async () => {
      const res = await ChestTableData({
        filter: searchText,
        limit: jsonLimit,
        offset: 0,
        options: ['teams'],
        everything: user['administrator'],
      });
      setTableData(res);
      setLoading(false);
    };
    if (display === true) getTableData();
  }, [loading, setLoading, searchText, display]);

  const handleScrollToBottom = async e => {
    const target = e.target;
    if (scrollEnded(target)) {
      const tableTotal = tableData[0] && tableData[0]['total'];
      const newOffset = offset + jsonLimit;
      if (newOffset < tableTotal) {
        setDataLoading(true);
        setOffset(newOffset);
        const data = await ChestTableData({
          filter: searchText,
          limit: jsonLimit,
          offset: newOffset,
          options: ['teams'],
          everything: user['administrator'],
        });
        data.length !== 0 ? setTableData([...tableData, ...data]) : setOffset(newOffset - jsonLimit);
        setDataLoading(false);
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      Mousetrap.bind('i', () => focus('searchbar'), 'keyup');
    }
    return () => {
      isMounted = false;
      Mousetrap.unbind('i', 'keyup');
    };
  });

  const renderSecret = () => (
    <TableWrap className={'view'}>
      <ViewSecret
        secretData={secretData}
        setSecretData={setSecretData}
        secret={viewSecret}
        setViewSecret={setViewSecret}
        isAdmin={administrator}
      />
    </TableWrap>
  );

  const renderChest = () => {
    return viewSecret ? (
      renderSecret()
    ) : (
      <ViewChest chest={viewChest} setViewSecret={setViewSecret} setViewChest={setViewChest} />
    );
  };

  const handleChestSet = chest => {
    setViewChest(chest);
    store.set('lastChest', chest);
  };

  const renderTable = () => (
    <CSSTransition classNames="content" in={display} timeout={200} unmountOnExit>
      <TableWrap role={'table-wrap'} onScroll={e => handleScrollToBottom(e)}>
        <Table
          columnWidths={columnWidths}
          headers={headers}
          data={tableData}
          chests
          setViewChest={handleChestSet}
          params={{ loading, setLoading }}
        />
      </TableWrap>
    </CSSTransition>
  );

  const renderSearchBar = () => (
    <SearchBar placeholder="Search for a Chest" setSearchText={e => setSearch(e)} setViewSecret={setViewSecret} />
  );

  const renderChestTableView = () => {
    if (display && viewChest) {
      return renderChest();
    } else if (display && !viewChest) {
      return renderTable();
    }
  };

  if (!tableData) return <LogoutRedirect />;
  return (
    <DashboardChestContext.Provider value={dashboardChestTable}>
      {(!viewChest || !display || viewSecret) && renderSearchBar()}
      <ListTransition>
        {renderChestTableView()}
        {dataLoading && (
          <TableBottomLoading>
            <img className="small-loading" src={Loading} alt="Loading..." />
          </TableBottomLoading>
        )}
        <FooterTagWrap>
          <FooterLink text={'Show all chests'} display={display} setDisplay={setDisplay} setAddSecret={setAddSecret} />
        </FooterTagWrap>
      </ListTransition>
    </DashboardChestContext.Provider>
  );
};

export default Chests;
