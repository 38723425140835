import React from 'react';
import { Icon } from '../../../../../common/Icon';
import { LockCellWrap } from './styles/LockCellStyles';

const LockCell = () => {
  return (
    <LockCellWrap className="locked-icon-area">
      <Icon name="locked" width="21px" />
    </LockCellWrap>
  );
};

export default LockCell;
