import styled from 'styled-components';

export const CellArea = styled.div`
  font-size: 16px;
  color: ${props => props.theme.textColor};
  display: flex;
  justify-content: flex-start !important;
  align-items: center;
  min-height: 50px;

  p {
    margin: 0 0 0 1.25rem;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

export const MenuOptions = styled.div`
  position: absolute;
  top: 3.15rem;
  display: flex;
  flex-direction: column;
  border-radius: 0px 0px 5px 5px;
  background: ${props => props.theme.fieldBgColor};
  transition: all 0.5s ease-in-out;
  border-radius: 0 0 5px 5px;
  z-index: 500;
  min-width: 200px;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);
`;

export const DropDownOption = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  color: ${props => props.theme.textColor};
  line-height: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: ${props => props.theme.highlightColor};
    color: ${props => props.theme.hoverRowColor};
  }

  &.highlighted {
    background-color: ${props => props.theme.highlightColor};
    color: ${props => props.theme.hoverRowColor};
  }
`;
