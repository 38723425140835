// Urls passed should be prefixed with '/'
// Example '/api/v1/profile'

class Url {
  url: string;

  constructor(url: string) {
    this.url = url;
  }

  getFull(): string {
    return `${process.env.REACT_APP_API_URL}${this.url}`;
  }

  addParams(params: any): string {
    if (!params) return '';
    const paramKeys = Object.keys(params);
    if (paramKeys.length === 0) return '';
    const urlParams = new URLSearchParams();
    paramKeys.map(key => urlParams.append(key, params[key]));
    return `${this.getFull()}?${urlParams.toString()}`;
  }
}

export default Url;
