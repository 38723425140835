import styled from 'styled-components';

export const Clickable = styled.div`
  flex: 1;
  &::before {
    display: initial;
    content: '';
    position: absolute;
    width: 10%;
    height: 7%;
    z-index: 2;
    margin: -0.6rem 0 0 0;
  }
`;

export const SecretClickable = styled.div`
  &::before {
    content: '';
    position: absolute;
    width: 12.5vw;
    height: 3rem;
    z-index: 2;
    margin: -1.6rem 0 0 0;
  }
`;

export const AdminClickable = styled.div`
  &::before {
    content: '';
    position: absolute;
    width: 20vw;
    height: 3rem;
    z-index: 2;
    margin: -1.6rem 0 0 0;
  }
`;
