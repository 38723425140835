import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import GenericLoading from '../../../common/genericLoading/GenericLoading';
import { getHeaders } from '../adminHeader/AdminHeaderInfo';
import Table from '../../table/Table';
import { goToLocation } from '../adminFunctions/redirectByUrl';
import { SecretTableData } from '../../../api/secrets/SecretApiFunctions';
import { Wrap, TableWrap, Header, Divider } from './styles/DeletedSecretsStyles';
import { AdminTableHeader } from './subComponents/AdminTableHeader';
import StripedHeader from './subComponents/StripedHeader';

const DeletedSecrets = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();

  const history = useHistory();
  const { location } = history;
  const { pathname } = location;
  const url = goToLocation('Secrets', pathname);
  const headers = getHeaders('DeletedSecrets')();
  const columnWidths = ['25%', '10%', '15%', '30%', '15%', '5%'];

  const redirectTo = row =>
    history.push({
      pathname: url,
      row,
      context: 'Secrets',
      prevPathname: pathname,
    });

  const filterChests = () => data.filter(secret => secret['chest']['id'] === location['row']['id']);
  const secrets = () => (location['row'] ? filterChests() : data);

  useEffect(() => {
    let isMounted = true;
    SecretTableData({ deleted: true, options: ['*'] }).then(res => {
      if (isMounted) {
        setLoading(false);
        setData(res);
      }
    });
    return () => (isMounted = false);
  }, [loading]);

  if (loading || !data) return <GenericLoading adminTable />;

  return (
    <Wrap>
      {location['row'] ? (
        <Fragment>
          <AdminTableHeader row={location['row']} deleted={true} />
          <Header>Deleted Secrets</Header>
          <Divider />
        </Fragment>
      ) : (
        <StripedHeader title={'Deleted Secrets'} icon={'bin'} />
      )}
      <TableWrap id="Table">
        <Table
          columnWidths={columnWidths}
          headers={headers}
          data={secrets()}
          params={{ setLoading }}
          onClick={redirectTo}
        />
      </TableWrap>
    </Wrap>
  );
};

export default DeletedSecrets;
