import styled from 'styled-components';

export const Wrap = styled.div`
  font-family: 'Lato';
  font-size: 1rem;
  color: ${props => props.theme.textColor};
  height: 100%;

  .audit-wrapper {
    line-height: 150%;
    margin-left: -1.5rem;
    width: calc(100% + 3rem);
    > div > div:last-of-type > div {
      padding: 0.5rem 0;
    }
    > div:last-of-type > div {
      max-height: 54.5vh;
      min-height: 54.5vh;
    }
  }

  @media screen and (max-width: 767px) {
    max-height: unset;
  }
`;

export const AuditLogTableWrap = styled.div`
  overflow-y: scroll;
  height: 100%;
  background-color: ${props => props.theme.tableBgColor};

  @media screen and (max-height: 1300px) {
    height: 90%;
  }
  @media screen and (max-height: 1000px) {
    height: 80%;
  }
  @media screen and (max-height: 900px) {
    height: 75%;
  }
  @media screen and (max-height: 800px) {
    height: 70%;
  }
  @media screen and (max-height: 700px) {
    height: 60%;
  }
  @media screen and (max-height: 600px) {
    height: 50%;
  }
`;
