export const goToLocation = (context, pathname) => keyUrlPairs[context][pathname] || '/admin';

const _chestRedirects = {
  '/admin/chests': '/admin/chests/show',
  '/admin/chests/show': '/admin/chests/secret',
};

const _secretRedirects = {
  '/admin/chests': '/admin/chests/secret',
  '/admin/chests/deleted': '/admin/chests/secret',
};

const _teamRedirects = {
  '/admin/teams': '/admin/teams/show',
  '/admin/teams/show': '/admin/teams/user',
};

const _userRedirects = {
  '/admin/teams': '/admin/teams/user',
  '/admin/teams/show': '/admin/teams/user',
};

const _goBackRedirects = {
  '/admin/chests/secret': '/admin/chests',
};

const _goBackSpecificRedirects = {
  '/admin/chests/secret': '/admin/chests/show',
};

const _chestRowTagRedirects = {
  '/admin/chests': '/admin/chests/show',
  '/admin/chests/show': '/admin/chests/secret',
};

const _secretRowTagRedirects = {
  '/admin/chests': '/admin/chests/secret',
};

const _teamRowTagRedirects = {
  '/admin/teams': '/admin/chests/show',
  '/admin/teams/show': '/admin/teams/show',
};

const _userRowTagRedirects = {
  '/admin/teams': '/admin/teams/show',
  '/admin/teams/show': '/admin/teams/show',
};

const keyUrlPairs = {
  Chests: _chestRedirects,
  Secrets: _secretRedirects,
  Teams: _teamRedirects,
  Users: _userRedirects,
  GoBack: _goBackRedirects,
  GoBackSpecific: _goBackSpecificRedirects,
  ChestsRowTag: _chestRowTagRedirects,
  SecretsRowTag: _secretRowTagRedirects,
  TeamsRowTag: _teamRowTagRedirects,
  UsersRowTag: _userRowTagRedirects,
};
