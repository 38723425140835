import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { WelcomeContext } from '../../../context';
import {
  ProfileModalInfoArea,
  ProfileModalTextArea,
  ProfileModalTitle,
  ProfileSuccessIcon,
  ButtonContainer,
} from './styles/ProfileModalStyles';
import { PrimaryButton } from '../../../common/buttons/styles/PrimaryButton';
import { checkOnboardingState } from '../../../utils/commonFunctions';
import { UserContext } from '../../../context/UserProvider';

const ChangePasswordSuccess = () => {
  const welcomeDetails = useContext(WelcomeContext);
  const { update } = useContext(UserContext);
  const history = useHistory();
  useEffect(() => {
    checkOnboardingState(welcomeDetails.welcome, update, history);
  }, [welcomeDetails.welcome]);

  const handleClick = () => {
    if (welcomeDetails) welcomeDetails.setWelcome({ ...welcomeDetails.welcome, password: true });
    Object.values({ ...welcomeDetails.welcome, password: true }).every(item => item)
      ? history.push('/')
      : history.push('/welcome');
  };

  return (
    <ProfileModalInfoArea>
      <ProfileSuccessIcon className="fas fa-check-circle fa-3x" />
      <ProfileModalTextArea>
        <ProfileModalTitle className="success">Password Updated</ProfileModalTitle>
      </ProfileModalTextArea>
      <ButtonContainer>
        <PrimaryButton role={'change-password-button'} onClick={handleClick}>
          Click to continue
        </PrimaryButton>
      </ButtonContainer>
    </ProfileModalInfoArea>
  );
};

export default ChangePasswordSuccess;
