import XLSX from 'xlsx';
import { DecryptSecret } from '../../../utils/secretFunctions';

export const DecryptAll = secrets => {
  return Array.prototype.map.call(secrets, secret => {
    return DecryptSecret(secret);
  });
};

export const OrganiseSecrets = (results, secrets, chests) => {
  const data = {};
  const headers = ['Name', 'Category', 'JSON'];

  chests.forEach(chest => {
    results.forEach((text, n) => {
      let secret = secrets[n];
      if (!data[chest.name]) data[chest.name] = [headers];
      if (chest.id === secret.vault.id) {
        data[chest.name].push([secret.name, secret.category, JSON.stringify(text)]);
      }
    });
  });
  return data;
};

export const ExportText = data => {
  const book = XLSX.utils.book_new();
  const col_styles = ColStyles();
  Object.keys(data).forEach(chest => {
    const sheet = XLSX.utils.aoa_to_sheet(data[chest]);
    sheet['!cols'] = col_styles;
    XLSX.utils.book_append_sheet(book, sheet, chest.slice(0, 31));
  });
  XLSX.writeFile(book, 'secrets.xlsx');
};

const ColStyles = () => [{ wch: 30 }, { wch: 15 }, { wch: 90 }];
