import React, { useState, useEffect } from 'react';
import { CollectiveWrapper } from './styles/ProtocolInputStyle';
import { FullWrapperFieldset, Legend, InnerWrapper, Input } from '../../../common/forms/styles/TextInputStyles';
import { NewDropdown } from '../../../common/forms/NewDropdown';

const ProtocolAddressInput = ({ handleChange, value, edit }) => {
  const [address, setAddress] = useState('');
  const [dropdownVisibility, setDropdownVisibility] = useState(false);
  const [showProtocol, toggleShowProtocol] = useState(false);
  const [optionSelected, setOptionSelected] = useState();
  const [dropdownOptions, setDropdownOptions] = useState([]);

  useEffect(() => {
    const protocolOptions = [
      { id: 1, value: 'https://', label: 'HTTPS' },
      { id: 2, value: 'ssh://', label: 'SSH' },
      { id: 3, value: 'http://', label: 'HTTP' },
      { id: 4, value: 'ftp://', label: 'FTP' },
      { id: 5, value: 'sftp://', label: 'SFTP' },
      // This is required
      { id: 'blank', value: '', label: '' },
    ];
    if (value !== '' && edit) {
      const index = value.indexOf('://');
      if (!value.split('').includes('$')) {
        value = value.substr(0, index + 3) + '$' + value.substr(index + 3, value.length);
      }
      const splitValue = value.split('$');
      const newProtocol = protocolOptions.filter(option => option.value === splitValue[0])[0];
      setOptionSelected(newProtocol);
      setAddress(splitValue.slice(1).join('$'));
      setDropdownOptions(protocolOptions);
    } else {
      setDropdownOptions(protocolOptions);
      setOptionSelected(protocolOptions[0]);
    }
  }, [value]);

  const handleAddressChange = value => {
    if (
      // If user backspaces/deletes protocol
      optionSelected.value !== '' &&
      (value === 'https:/' || value === 'http:/' || value === 'ftp:/' || value === 'sftp:/' || value === 'ssh:/')
    ) {
      setOptionSelected({ id: 'blank', label: '', value: '' });
      setAddress('');
      handleChange('$', 'url');
    } else if (
      // if value includes protocol (i.e. standard input)
      value.toLowerCase().startsWith(optionSelected.value.toLowerCase()) &&
      optionSelected.value !== ''
    ) {
      // Splits off protocolValue
      const actualInput = value.split(optionSelected.value).filter(splitValues => splitValues !== '');
      setAddress(actualInput);
      handleChange(`${optionSelected.value}$${actualInput}`, 'url');
    } else if (
      // If user types in protocol when empty, i.e. https, http:, ftp:, sftp, and ssh
      value.toLowerCase() === 'ssh:' ||
      value.toLowerCase() === 'https' ||
      value.toLowerCase() === 'http:' ||
      value.toLowerCase() === 'ftp:' ||
      value.toLowerCase() === 'sftp:'
    ) {
      const findProtocol = dropdownOptions.filter(protocol => protocol.value.startsWith(value.toLowerCase()))[0];
      setOptionSelected(findProtocol);
      setAddress('');
      handleChange(`${findProtocol.value}$`, 'url');
    } else if (
      // If user tweaks an existing protocol
      value.toLowerCase() === 'https://' ||
      value.toLowerCase() === 'http://' ||
      value.toLowerCase() === 'ftp://' ||
      value.toLowerCase() === 'sftp://' ||
      value.toLowerCase() === 'ssh://'
    ) {
      const findProtocol = dropdownOptions.filter(protocol => protocol.value === value.toLowerCase())[0];
      setOptionSelected(findProtocol);
      setAddress('');
      handleChange(`${findProtocol.value}$`, 'url');
    } else {
      // If user types in stuff without protocol, e.g. just normal letters
      setAddress(value);
      handleChange(`${optionSelected.value}$${value}`, 'url');
    }
  };

  const handleDropdownChange = protocol => {
    setOptionSelected(protocol);
    handleChange(`${protocol.value}$${address}`, 'url');
  };

  const renderDropdown = () => (
    <NewDropdown
      label="Protocol"
      role={'protocol'}
      dropdownOptions={dropdownOptions}
      handleDropdownChange={handleDropdownChange}
      setDropdownVisibility={setDropdownVisibility}
      dropdownVisibility={dropdownVisibility}
      optionSelected={optionSelected}
      id="protocol-dropdown"
    />
  );

  return (
    <CollectiveWrapper>
      {optionSelected && renderDropdown()}
      <FullWrapperFieldset className="no-right-margin">
        <Legend className={address === '' && 'empty'}>URL / Address</Legend>
        <InnerWrapper>
          <Input
            placeholder="Address"
            value={showProtocol && optionSelected ? `${optionSelected.value}${address}` : address}
            onChange={e => handleAddressChange(e.target.value)}
            onFocus={() => toggleShowProtocol(true)}
            onBlur={() => toggleShowProtocol(false)}
          />
        </InnerWrapper>
      </FullWrapperFieldset>
    </CollectiveWrapper>
  );
};
export default ProtocolAddressInput;
