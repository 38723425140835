import styled from 'styled-components';

export const PassInput = styled.input`
  position: relative;
  max-width: 20vw;
  width: 20vw;
  background-color: ${({ theme }) => theme.fieldBgColor};
  border: 1px solid ${({ theme }) => theme.highlightColor};
  border-radius: 3px;
  padding: 0.75rem 1.25rem;
  margin: 1rem auto 0 auto;

  ::placeholder {
    color: ${props => props.theme.placeholderColor};
  }
`;

export const Error = styled.div`
  position: relative;
  color: ${({ theme }) => theme.error};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  margin: 1rem 0 0 0;
`;

export const LoadingText = styled.div``;
