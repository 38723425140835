export const determineColumnSort = (headers, selectedHeader, data) => {
  const current = headers[selectedHeader['header']];
  if (!_determineNum(current, data).includes(NaN)) return 'number';
  if (!_determineDate(current, data).includes(NaN)) return 'date';
  return 'string';
};

export const updatePrevPathname = history => {
  const { location } = history;
  const { pathname } = location;
  history.push({ prevPathname: pathname });
};

const _determineNum = (current, data) => {
  return current === 'adminStrength' ? data.map(row => +row['strength']) : data.map(row => +row[current]);
};

const _determineDate = (current, data) => data.map(row => Date.parse(row[current]));
