import styled from 'styled-components';

export const FirstSecretWrapper = styled.div`
  position: relative;
  width: 50%;
  max-height: 70vh;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  margin: 0 auto;

  @media screen and (max-width: 1439px) {
    margin-top: 0;
    width: 100%;
    max-width: 400px;
    padding-top: 0;
    box-sizing: border-box;
  }
`;
