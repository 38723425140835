import React from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from '../../Icon';
import BubbleDropdown from '../buttonBar/BubbleDropdown';
import { ContentCell, Label, Link, LinkCell, NameCell, Wrap } from './sTableHeader';

interface IProps {
  row: any; // Usual row found in location
  rowTypes?: RecryptEntity[]; // The json key of the arrays to display i.e. ['teams', 'users']
  icon?: string; // Icon string for <Icon />
  onRemove?: (selRow: any, rowType: RecryptEntity) => any; // OnRemove refers to the X next to a dropdown
}

// TODO: This component was only to be used within the context of the admin chest
//       shouldn't be hard to make it work with teams too
// TODO: Create a "No {entity/entities} linked to this chest"
// TODO: Add functionality e.g. link onclick then move onto the above
const TableHeader = ({ row, rowTypes = ['teams', 'users'], icon = 'team', onRemove }: IProps) => {
  const { push } = useHistory();
  const formattedText = rowTypes.join(' & ');
  const urlStarts = {
    chests: '/admin/chests/show',
    secrets: '/admin/chests/secret',
    users: '/admin/teams/user',
    teams: '/admin/teams/show',
  };
  const redirectTo = (selRow: any, type: RecryptEntity) =>
    push({ pathname: `${urlStarts[type]}`, state: { row: selRow } });

  const generateBubbles = () =>
    rowTypes.map((type: RecryptEntity, i: number) => (
      <BubbleDropdown key={i} data={row[type]} rowType={type} onClick={redirectTo} onRemove={onRemove} />
    ));

  const handleDeletedClick = () =>
    push({
      pathname: '/admin/chests/deleted',
      state: { row },
    });

  const handleEditClick = () =>
    push({
      pathname: '/admin/chests/edit',
      state: { row },
    });

  return (
    <Wrap>
      <NameCell>
        <Icon name={icon} width={'24px'} />
        <Label>{row['name']}</Label>
      </NameCell>
      <ContentCell>
        {rowTypes && <Label role={'row-types'}>{formattedText}:</Label>}
        {generateBubbles()}
      </ContentCell>
      <LinkCell role={'link-cell'}>
        <Link role={'link-0'} onClick={handleEditClick}>
          {'Edit Chest & Permissions'}
        </Link>
        <Link role={'link-1'} className={'deleted'} onClick={handleDeletedClick}>
          {'Deleted Secrets'}
        </Link>
      </LinkCell>
    </Wrap>
  );
};

export default TableHeader;
