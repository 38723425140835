import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  flex: 12;
  flex-direction: column;
  background-color: ${props => props.theme.tableBgColor};
  &.form-footer {
    border-radius: 0 0 5px 5px;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  &.admin-chests {
    flex-direction: column;
  }
`;

export const TableWrap = styled.div`
  flex: 11;
  overflow-y: auto;
  background-color: ${props => props.theme.tableBgColor};

  &.modal-open {
    overflow-y: hidden;
  }
`;

export const Header = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  color: ${props => props.theme.fieldTextColor};
  background-color: ${props => props.theme.tableBgColor};
  padding-left: 1.25rem;
  padding-right: 1.25rem;
`;

export const Divider = styled.hr`
  margin: 0;
  padding: 0;
  color: ${props => props.theme.fieldTextColor};
  width: 100%;
  opacity: 0.1;
`;
