import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Tutorial } from '../tutorial/Tutorial';
import { FirstSecret } from '../firstSecret/FirstSecret';
import TwoStep from '../twoStep/TwoStep';
import WelcomeMenu from '../welcome/WelcomeMenu';
import WelcomePassword from '../welcome/WelcomePassword';

const WelcomeRoutes = () => {
  return (
    <Switch>
      <Route path="/welcome" component={WelcomeMenu} exact={true} />
      <Route path="/welcome/tutorial" component={Tutorial} exact={true} />
      <Route path="/welcome/personal_password" component={WelcomePassword} exact={true} />
      <Route path="/welcome/twofactor" component={TwoStep} exact={true} />
      <Route path="/welcome/first_secret" component={FirstSecret} exact={true} />
    </Switch>
  );
};

export default WelcomeRoutes;
