import React, { useState, useContext, Fragment, useEffect, useMemo } from 'react';
import { AdminChestsFormWrap } from './styles/AdminChestsWrap';
import { ChestForm } from '../../forms/ChestForm';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import { AddChestOptions } from './subComponents/AddChestOptions';
import { AdminChestAddWrap, FakeArea } from './styles/AdminChestAddStyles';
import { SaveFooter } from '../adminFooter/footers/SaveFooter';
import { ViewChestData, ChestTableData } from '../../../api/chests/ChestApiFunctions';
import { getValidation } from '../../../utils/validationSuite/validationIndex';
import { useNotification } from '../../../common/notification/Notification';
import { TeamTableData } from '../../../api/teams/TeamApiFunctions';
import { UserTableData, PromoteUser, DemoteUser } from '../../../api/users/UserApiFunctions';
import BaseApi from '../../../api/BaseApi';
import LogoutRedirect from '../../../common/nav/LogoutRedirect';
import { reshareData } from '../../../api/secretKeys/SecretKeyApiFunctions';
import GenericFormLoading from '../../../common/genericLoading/GenericFormLoading';
import { AdminContext } from '../../../context/AdminContext';

/* Component for the editing of chests, assigments and permissions using
  the chest form */
const AdminChestsEdit = () => {
  const [users, setUsers] = useState([]);
  const [usersReady, setUsersReady] = useState(false);
  const [teams, setTeams] = useState([]);
  const [chestName, setChestName] = useState('');
  const [prevAssignments, setPrevAssignments] = useState([]);
  const [userPermissions, setUserPermissions] = useState({});
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const location = useLocation();
  const row = useMemo(() => {
    if (location['row']) return location['row'];
    if (location['state'] && location['state']['row']) return location['state']['row'];
    return [];
  }, [location]);
  const history = useHistory();
  const setChestTags = useContext(AdminContext).chestTags[1];

  const { setNotificationText } = useNotification();

  /* useEffect responsible for preparing the teams linked already to the chest
    and the name of the chest */
  useEffect(() => {
    const checkUser = (user, teamArray) => {
      let validUser = false;
      const checkFunc = team => {
        if (user.teams.filter(userTeam => userTeam.id === team.id).length !== 0 && user.permissions !== 'Admin') {
          validUser = true;
        }
      };
      const addedTeams = teamArray.filter(team => team.added === true);
      addedTeams.some(checkFunc);
      return validUser;
    };
    // Filters users based on users assigned to the chests (teams memberships).
    const filterUsers = userArray => {
      userPermissions.length > 0 &&
        userPermissions.forEach(userPermission => {
          const userIndex = userArray.findIndex(user => user.id === userPermission.id);
          if (userIndex >= 0) {
            userArray[userIndex].edit_access = userPermission.manager;
            // we should only show Users that belong to
            // Teams that only currently have Read Access according to the current state of the form
            userArray[userIndex].added = !userPermission.manager;
          }
        });
      setUsers(userArray.filter(user => user.edit_access === false)); //filter users with only read access
    };
    // Function for filtering the teams based on the existing assignments.
    const filterTeams = (assignments, teamArray) => {
      assignments.forEach(assignment => {
        const teamIndex = teamArray.findIndex(team => team.id === assignment.team_id);
        teamArray[teamIndex].edit_access = assignment.edit_access;
        teamArray[teamIndex].added = true;
      });
      setTeams(teamArray);
    };
    if (row.length !== 0) {
      ViewChestData(row.id).then(async chest => {
        if (chest) {
          setChestName(chest.name);
          setPrevAssignments(chest.assignments);
          setUserPermissions(chest.users);
          const allTeams = await TeamTableData({ everything: true });
          const teamArray = allTeams.map(team => {
            return {
              id: team.id,
              name: team.name,
              users: team.users,
              added: false,
              selected: false,
              edit_access: false,
            };
          });
          filterTeams(chest.assignments, teamArray);
          setUsersReady(true);
          // solution: nested api call to use team array to checkUsers to fix bug
          UserTableData({ everything: true }).then(allUsers => {
            if (allUsers) {
              let usersArray = [];
              allUsers.forEach(user => {
                if (checkUser(user, teamArray)) {
                  usersArray.push({
                    id: user.id,
                    name: user.name,
                    email: user.email,
                    added: false,
                    selected: false,
                    edit_access: false,
                  });
                }
              });
              filterUsers(usersArray);
            } else {
              setRedirect(true);
            }
          });
        } else {
          setRedirect(true);
        }
      });
    }
    Mousetrap.bind('enter', enterSC);

    return () => {
      Mousetrap.unbind('enter');
    };
  }, [row, usersReady]);
  const enterSC = () => {
    if (document.activeElement === document.getElementsByClassName('footer-button')[0]) {
      updateChest();
    } else if (document.activeElement === document.getElementsByClassName('footer-button')[1]) {
      history.goBack();
    }
  };

  // Function for prepping assignment JSON data for the update.
  const updateAssignments = addedTeams => {
    let teamData = [];
    prevAssignments.forEach(assignment => {
      const teamIndex = addedTeams.findIndex(team => team.id === assignment.team_id);
      if (!addedTeams[teamIndex]) {
        teamData.push({
          id: assignment.id,
          _destroy: 1,
        });
      } else {
        teamData.push({
          id: assignment.id,
          edit_access: addedTeams[teamIndex].edit_access,
        });
      }
      addedTeams.splice(teamIndex, 1);
    });
    addedTeams.forEach(team => {
      teamData.push({
        team_id: team.id,
        edit_access: team.edit_access,
      });
    });
    return teamData;
  };

  /* Function that handles the chest update API call, the validation,
    the promotion and demotion of users and the resharing of secrets. */
  const updateChest = () => {
    const validationId = 'ChestForm';
    const addedTeams = teams.filter(team => team.added === true);
    const addedUsers = users.filter(user => user.added === true);
    const params = {
      data: { chestName, addedTeams, addedUsers },
      setNotificationText,
    };
    if (getValidation(validationId)(params)) {
      setLoading(true);
      const teamData = updateAssignments(addedTeams);
      const data = {
        vault: {
          name: chestName,
          assignments_attributes: teamData,
        },
        options: ['users'],
      };
      BaseApi.update('vaults', row.id, data)
        .then(async result => {
          if (result.users) {
            result.users.forEach(async user => {
              try {
                await reshareData(user.id, null);
              } catch (error) {
                setNotificationText(`${error}`);
                setLoading(false);
              }
            });
            addedUsers.forEach(async user => {
              if (user.edit_access) {
                const res = await PromoteUser(result.id, user.id);
                // 400 error was not being thrown to the catch block res is a string so need to check it
                if (res === 'User already has management access') {
                  setLoading(false);
                  setNotificationText(res);
                }
              } else {
                const res = await DemoteUser(result.id, user.id);
                if (res === 'User is not a manager') {
                  setLoading(false);
                  setNotificationText(res);
                }
              }
            });
            setChestTags(await ChestTableData({ everything: true }));
            history.push({
              pathname: '/admin/chests',
              prevPathname: location.pathname,
            });
            setLoading(false);
          } else {
            //this catches the 500 error from BaseApi.update api.
            setNotificationText('An Error has occurred while trying to update your chest');
            setLoading(false);
          }
        })
        .catch(() => {
          // catch not hit during a 500 from BaseApi.update api. A result is returned but not users
          setNotificationText('An Error has occurred while trying to update your chest');
          setLoading(false);
        });
    }
  };

  if (row.length === 0) return <Redirect to={'/admin/chests'} />;
  if (redirect) return <LogoutRedirect />;
  return (
    <Fragment>
      {loading ? (
        <GenericFormLoading />
      ) : (
        <Fragment>
          <AdminChestsFormWrap className="form-footer team-form">
            <AdminChestAddWrap>
              <ChestForm
                chestName={chestName}
                setChestName={setChestName}
                teams={teams}
                setTeams={setTeams}
                users={users}
                setUsers={setUsers}
                usersReady={usersReady}
                setUsersReady={setUsersReady}
              />
              <SaveFooter handleSubmit={() => updateChest()} edit={true} />
            </AdminChestAddWrap>
            <AddChestOptions />
          </AdminChestsFormWrap>
          <FakeArea></FakeArea>
        </Fragment>
      )}
    </Fragment>
  );
};

export default AdminChestsEdit;
