import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 5rem;

  @media screen and (max-width: 767px) {
    padding: 0 2%;
    width: 100%;
    height: auto;
  }
`;
export const HeaderContainer = styled.div`
  display: flex;
  border-bottom: 1px solid ${props => props.theme.squaredButtonBgColor};
  margin-bottom: 2rem;
`;
export const ContentWrap = styled.div``;
