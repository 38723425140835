import styled from 'styled-components';

export const Wrap = styled.div`
  flex: 0 1 10%;
  display: flex;
  position: absolute;
  top: ${({ escapeModal }) => (escapeModal ? '-2.5rem' : '0.8rem')};

  right: 1rem;
  cursor: pointer;
  position: absolute;
  top: -2.5rem;
  right: 0;
  color: #ffffff;

  .st0,
  .st1 {
    fill: white;
  }

  p,
  div {
    color: white;
  }
`;

export const Esc = styled.div`
  line-height: 34px;
`;
