import styled from 'styled-components';

export const TableButtonWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;
`;

export const TableButton = styled.div`
  display: block;
  height: 30px;
  line-height: 29px;
  font-size: 0.9rem;
  cursor: pointer;
  margin-right: 0.5rem;
  max-width: 7.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid ${props => props.theme.fieldBorderColor};
  color: ${props => props.theme.fieldBorderColor};
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: capitalize;
  transition: all 0.3s ease-in-out;
  z-index: 11;

  :hover {
    opacity: 0.7;
  }

  :focus {
    opacity: 0.7;
    outline: 1px solid ${props => props.theme.highlightColor};
  }

  &.dropdown-button {
    line-height: 140%;
    transition: all 0.3s ease-in-out;
    line-height: 30px;
  }

  @media (-webkit-device-pixel-ratio: 1.5) {
    height: 21px;
    line-height: 21px;
  }
`;

export const TableDropdown = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;

  &:hover {
    .dropdown-button span {
      border-radius: 5px 5px 0 0;
    }
    .dropdown-content {
      display: flex;
      flex-direction: column;
    }
  }

  &:focus {
    .dropdown-button span {
      border-radius: 5px 5px 0 0;
    }
    .dropdown-content {
      display: flex;
      flex-direction: column;
    }
  }

  &:focus-within {
    .dropdown-button span {
      border-radius: 5px 5px 0 0;
    }
    .dropdown-content {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const TableDropdownContent = styled.div`
  display: none;
  position: absolute;
  top: 100%;
  min-width: 150px;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1);
  z-index: 12;
  border-radius: 5px;
  overflow: hidden;
  background-color: ${props => props.theme.tableBgColor};

  span {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: ${props => props.theme.textColor};
    padding: 0.75rem 1.25rem;
    line-height: 120%;
    text-decoration: none;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: ${props => props.theme.bgColor};
      color: ${props => props.theme.hoverColor};
    }

    &:focus {
      background-color: ${props => props.theme.bgColor};
      color: ${props => props.theme.hoverColor};
    }

    div {
      cursor: pointer;

      &:hover {
        color: ${({ theme }) => theme.highlightColor};
      }
    }
  }
`;
