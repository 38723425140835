import React, { useState, useContext, useEffect, Fragment } from 'react';
import Mousetrap from 'mousetrap';
import { OptionsModalContext } from '../../../../../context/OptionsModalContext';
import { GetChestUsers } from '../../../../../api/chests/ChestApiFunctions';
import { PromoteUser, DemoteUser } from '../../../../../api/users/UserApiFunctions';
import { CellArea, MenuOptions, DropDownOption } from './styles/UserPermissionsCellStyles';
import { AdminUserContext } from '../../../../../context/AdminContext';

export const UserPermissionsCell = ({ row }) => {
  const [userPermissions, setUserPermissions] = useState('Checking...');
  const [optionsVisible, setOptions] = useContext(OptionsModalContext);
  const [highlightedOption, setHighlightedOption] = useState();
  const userContext = useContext(AdminUserContext);

  useEffect(() => {
    GetChestUsers(row.id).then(users => {
      const viewedUser = users.filter(user => user.id === userContext.id)[0];
      if (viewedUser.administrator) {
        setUserPermissions('Administrator');
      } else if (viewedUser.manager) {
        setUserPermissions('Read & Write');
      } else {
        setUserPermissions('Read only');
      }
    });
  }, [row.id, userContext.id]);

  const menuOptions = [
    {
      text: 'Read Only',
      handleClick: () => updatePermissions('Read only'),
      id: 'read',
    },
    {
      text: 'Read & Write',
      handleClick: () => updatePermissions('Read & Write'),
      id: 'write',
    },
  ];

  const updatePermissions = async permission => {
    if (permission === 'Read only') DemoteUser(row.id, userContext.id);
    if (permission === 'Read & Write') PromoteUser(row.id, userContext.id);
    setOptions('');
    setUserPermissions(permission);
  };

  const handleOptions = row => {
    return optionsVisible.row !== row.id
      ? (setOptions({ row: row.id }), setHighlightedOption())
      : (setOptions(''), setHighlightedOption());
  };

  const returnOptions = options => {
    return options.map(option => (
      <DropDownOption id={option.id} key={option.text} onClick={() => option.handleClick()}>
        {option.text}
      </DropDownOption>
    ));
  };

  // Keyboard shortcuts
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      document.getElementById('Table').classList.add('modal-open');
      Mousetrap.bind('up', () => optionsVisible.row && handleUpKey());
      Mousetrap.bind('down', () => optionsVisible.row && handleDownKey());
    }
    return () => {
      isMounted = false;
      const tableEl = document.getElementById('Table');
      if (tableEl) tableEl.classList.remove('modal-open');
      Mousetrap.unbind('up');
      Mousetrap.unbind('down');
    };
  });

  const handleUpKey = () => {
    if (highlightedOption) {
      const activeIndex = menuOptions.findIndex(option => option.text === highlightedOption.text);
      let newIndex = activeIndex - 1;
      if (activeIndex > 0) {
        setHighlightedOption(menuOptions[newIndex]);
        document.getElementById(menuOptions[newIndex].id).classList.add('highlighted');
        document.getElementById(menuOptions[activeIndex].id).classList.remove('highlighted');
      }
      if (activeIndex === 0) {
        setOptions({});
        setHighlightedOption('');
      }
    }
  };

  const handleDownKey = () => {
    if (highlightedOption) {
      const activeIndex = menuOptions.findIndex(option => option.text === highlightedOption.text);
      let newIndex = activeIndex + 1;
      if (activeIndex < menuOptions.length - 1) {
        setHighlightedOption(menuOptions[newIndex]);
        document.getElementById(menuOptions[newIndex].id).classList.add('highlighted');
        document.getElementById(menuOptions[activeIndex].id).classList.remove('highlighted');
      }
    } else {
      setHighlightedOption(menuOptions[0]);
      document.getElementById(menuOptions[0].id).classList.add('highlighted');
    }
  };

  const handleEnterKey = () => {
    return optionsVisible.row !== row.id
      ? handleOptions(row)
      : (setOptions(''), highlightedOption && highlightedOption.handleClick());
  };

  return (
    <Fragment>
      {userContext.permissions === 'Admin' ? (
        <span>Administrator</span>
      ) : (
        <div style={{ position: 'relative' }} onMouseLeave={() => setOptions('')}>
          <CellArea
            role={'Cell'}
            onClick={() => handleOptions(row)}
            className="icon-area"
            tabIndex="0"
            onKeyPress={e => (e.key === 'Enter' ? handleEnterKey() : undefined)}
            onBlur={(() => setOptions(''), () => setHighlightedOption())}
          >
            <i className="fas fa-bars"></i>
            <p>{userPermissions}</p>
          </CellArea>
          {optionsVisible.row === row.id && <MenuOptions>{returnOptions(menuOptions)}</MenuOptions>}
        </div>
      )}
    </Fragment>
  );
};
