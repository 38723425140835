import { emailRegex, numberRegex } from './validationRegex';
import { capitalize } from '../commonFunctions';
import { getUniqueMessage } from './uniqueKeys/uniqueKeys';

export const isNotEmpty = (key, str) => {
  const isEmpty = _empty(str);
  if (!isEmpty && `${str}`.length > 0) return null;
  const message = getUniqueMessage(key);
  if (message) return message;
  return { field: key, error: `Required field ${capitalize(key)} is empty. ` };
};

export const isAnEmail = (key, str) => {
  const isEmpty = _empty(str);
  const isEmail = emailRegex.test(str);
  if (isEmpty || isEmail) return null;
  const message = getUniqueMessage(key);
  if (message) return message;
  return {
    field: key,
    error: `Field ${capitalize(key)} is not a valid email. `,
  };
};

export const isANumber = (key, str) => {
  const isEmpty = _empty(str);
  const isANum = numberRegex.test(str);
  if (isEmpty || isANum) return null;
  const message = getUniqueMessage(key);
  if (message) return message;
  return {
    field: key,
    error: `Field ${capitalize(key)} must contain only numeric characters. `,
  };
};

export const isLongerThan = (key, str, length) => {
  const isEmpty = _empty(str);
  const isShorter = str.length >= length;
  if (isEmpty || isShorter) return null;
  return {
    field: key,
    error: `Field ${capitalize(key)} requires a minimum of ${length} characters.`,
  };
};

export const isShorterThan = (key, str, length) => {
  const isEmpty = _empty(str);
  const isLonger = str.length <= length;
  if (isEmpty || isLonger) return null;
  return {
    field: key,
    error: `Field ${capitalize(key)} exceeds the maximum of ${length} characters.`,
  };
};

const _empty = str => str === undefined || str === '' || str === null;
