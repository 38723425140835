import styled from 'styled-components';

export const DividerContainer = styled.p`
  color: ${props => props.theme.textColor};
  font-size: 1.5rem;
  border: none;
  margin: ${({ margin }) => margin};
  align-self: center;

  @media screen and (max-width: 767px) {
    margin-right: 1rem;
    margin-left: 1rem;
  }
`;
