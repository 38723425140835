import { ChestTableData } from '../../../api/chests/ChestApiFunctions';
import { SecretTableData } from '../../../api/secrets/SecretApiFunctions';
import { TeamTableData } from '../../../api/teams/TeamApiFunctions';
import { UserTableData } from '../../../api/users/UserApiFunctions';

export const getCall = key => keyFuncPair[key];

const _getChestCall = params => ChestTableData(params);
const _getSecretCall = params => SecretTableData(params);
const _getTeamCall = params => TeamTableData(params);
const _getUserCall = params => UserTableData(params);

const keyFuncPair = {
  Chests: _getChestCall,
  Secrets: _getSecretCall,
  Teams: _getTeamCall,
  Users: _getUserCall,
};
