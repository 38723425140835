import React from 'react';
import { HeaderWrap } from './styles/HeaderWrap';
import { useLocation } from 'react-router-dom';
import { Icon } from '../../../../common/Icon';

export const EditHeader = () => {
  const location = useLocation();
  const { name } = location['row'] || (location['state'] && location['state']['row']) || { name: 'N/A' };
  const returnIcon = () => {
    if (location.pathname.includes('secret/edit')) return <Icon name="unlocked" width="18px" />;
    if (location.pathname.includes('chests/edit')) return <Icon name="chest" width="18px" />;
    if (location.pathname.includes('teams/edit')) return <Icon name="team" width="18px" />;
    if (location.pathname.includes('user/edit')) return <Icon name="user" width="18px" />;
  };

  return (
    <HeaderWrap>
      {returnIcon()}
      <div>{name}</div>
    </HeaderWrap>
  );
};
