import { Cryptification } from './internal/cryptification';
import { Conversion } from './internal/conversion';

// Anything related to symmetric encryption/decryption lives here:
export class Symmetric {
  static encrypt(aes_key, iv, value) {
    var config = { name: 'AES-CBC', iv: Conversion.txt2bin(iv) };
    return Cryptification.encrypt(aes_key, value, config);
  }

  static decrypt(aes_key, iv, value) {
    var config = { name: 'AES-CBC', iv: Conversion.txt2bin(iv) };
    return Cryptification.decrypt(aes_key, value, config);
  }
}
