import { isNotEmpty, isAnEmail, isANumber, isLongerThan, isShorterThan } from './validationLibrary';

export const testIf = key => keyTestPairs[key] || keyTestPairs['default'];

const _areTheseEmpty = (data, keys) =>
  Object.keys(data).map(key => (keys.includes(key) ? isNotEmpty(key, data[key]) : null));

const _areTheseEmails = (data, keys) =>
  Object.keys(data).map(key => (keys.includes(key) ? isAnEmail(key, data[key]) : null));

const _areTheseNumbers = (data, keys) =>
  Object.keys(data).map(key => (keys.includes(key) ? isANumber(key, data[key]) : null));

const _areTheseLongerThan = (data, keys, length = 255) =>
  Object.keys(data).map(key => (keys.includes(key) ? isLongerThan(key, data[key], length) : null));

const _areTheseShorterThan = (data, keys, length = 255) =>
  Object.keys(data).map(key => (keys.includes(key) ? isShorterThan(key, data[key], length) : null));

const keyTestPairs = {
  empty: _areTheseEmpty,
  email: _areTheseEmails,
  number: _areTheseNumbers,
  longerThan: _areTheseLongerThan,
  shorterThan: _areTheseShorterThan,
  default: [],
};
