import styled from 'styled-components';

export const Content = styled.div`
  outline: none;
`;

export const Option = styled.div`
  padding: 0.5rem 2rem;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.bgColor};
  }

  &.selected {
    color: ${({ theme }) => theme.highlightColor};
    background-color: ${({ theme }) => theme.bgColor};
  }

  &.keyHover {
    background-color: ${({ theme }) => theme.settingsBgColor};
  }
`;
