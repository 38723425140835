import React from 'react';
import { ToggleContainer } from './styles/ToggleSwitchStyle';

const ToggleSwitch = ({ disabled, onClick, checked, id }) => (
  <ToggleContainer>
    <div
      className="switch"
      role={'switch'}
      disabled={disabled}
      onClick={() => onClick()}
      onKeyPress={!disabled ? e => (e.key === 'Enter' ? onClick() : null) : undefined}
    >
      <input id={id} className="mousetrap" type="checkbox" role={'Toggle'} checked={checked} onChange={() => null} />
      <span className="slider"></span>
    </div>
  </ToggleContainer>
);
export default ToggleSwitch;
