import styled from 'styled-components';

export const AdminSidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 0 2rem 0 0;

  @media screen and (max-width: 767px) {
    padding: 0;
  }
`;
