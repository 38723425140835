import { Password } from '../steganography/password';
import { AES } from '../steganography/aes';
import { Symmetric } from '../steganography/symmetric';
import LocalStore from '../api/LocalStore';
import Request from '../api/Request';

export const ReencryptAndSubmit = async (oldEmail, oldPass, newEmail, newPass) => {
  const store = new LocalStore();
  const oldHash = await Password.prehash(oldEmail, oldPass);
  const newHash = await Password.prehash(newEmail, newPass);

  const oldKey = await Password.masterKey(oldEmail, oldPass);
  const newKey = await Password.masterKey(newEmail, newPass);

  const oldIv = store.get('iv');
  const newIv = AES.randomIV();

  const oldEncryptedPk = store.get('privateKey');
  const privateKey = await Symmetric.decrypt(oldKey, oldIv, oldEncryptedPk);
  const newEncryptedPk = await Symmetric.encrypt(newKey, newIv, privateKey);

  return {
    email: newEmail,
    password: newHash,
    current_password: oldHash,
    encrypted_private_key: newEncryptedPk,
    private_key_iv: newIv,
  };
};

export const CheckPassword = async (oldEmail, password) => {
  const store = new LocalStore();
  const key = await Password.masterKey(oldEmail, password);
  const iv = store.get('iv');
  const encryptedPk = store.get('privateKey');
  try {
    await Symmetric.decrypt(key, iv, encryptedPk);
    return 1;
  } catch {
    return 0;
  }
};

export const ChangeAccountPassword = async (oldPass, newPass, email, action) => {
  const _getRaw = async (email, password) => {
    const key = await Password.masterKey(email, password);
    return await AES.exportKey(key);
  };

  const store = new LocalStore();
  const result = await ReencryptAndSubmit(email, oldPass, email, newPass);
  const params = {
    user: {
      password: result.password,
      current_password: result.current_password,
      encrypted_private_key: result.encrypted_private_key,
      private_key_iv: result.private_key_iv,
      must_change_password: false,
    },
  };
  const { json } = await new Request('PATCH', '/users.json', params).perform();
  const { error, errors = [] } = json;

  if (error === 'Current password is invalid' || error === 'Could not send email successfully.' || errors.length > 0) {
    if (errors.length > 0) action(errors[0]);
    if (error) action(error);
    return error || errors[0];
  } else {
    const raw = await _getRaw(email, newPass);
    store.set('raw', raw);
    store.set('privateKey', result.encrypted_private_key);
    store.set('iv', result.private_key_iv);
    action();
  }
};
