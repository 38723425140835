import styled from 'styled-components';

export const SecretOptionsWrap = styled.div`
  display: flex;
  flex-direction: column;
  color: ${props => props.theme.fieldTextColor};
  flex: 1;
  padding: 2.5rem;
  background-color: ${props => props.theme.settingsBgColor};

  @media screen and (max-width: 767px) {
    &:empty {
      display: none;
    }
  }
`;

export const OptionsTitle = styled.p`
  font-size: 0.8rem;
`;

export const OptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .switch {
    margin-right: 2rem;
  }
`;

export const OptionsMainText = styled.div`
  font-size: 1rem;
  i {
    padding-right: 0.75rem;
  }
  svg {
    padding-right: 0.75rem;
  }
`;

export const OptionsSubText = styled.p`
  font-size: 0.7rem;
  margin: 1rem 0 0;
`;
