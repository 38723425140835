import styled from 'styled-components';

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px 20px 20px;
  font-size: 13px;

  @media screen and (max-width: 767px) {
    padding: 0;
    flex-direction: row;
    margin: 20px;

    > p {
      display: none;
    }
    > div {
      width: 100%;
      justify-content: center;
      button,
      p {
        display: none;
      }
    }
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  color: ${({ theme }) => theme.textColor};
  background-color: ${({ theme }) => theme.bgColor};
`;

export const Help = styled.a`
  display: inherit;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.textColor};
  cursor: pointer;

  g {
    fill: ${({ theme }) => theme.textColor};
  }

  &:hover {
    color: ${({ theme }) => theme.highlightColor};
    g {
      fill: ${({ theme }) => theme.highlightColor};
    }
  }
`;

export const HelpText = styled.div`
  margin: 0 0 0 10px;
  line-height: 0px;
`;

export const DownloadLink = styled.a`
  display: inherit;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.textColor};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.highlightColor};
  }
`;

export default Footer;
