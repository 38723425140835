import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  flex: 1;
  align-items: stretch;
  color: ${({ theme }) => theme.textColor};
  height: 3.5rem;
`;

export const GenericCell = styled.div`
  display: flex;
  flex: 2;
  align-items: center;
  padding: 0 18px;
  background-color: ${({ theme }) => theme.formFooterBgColor};
`;

export const NameCell = styled(GenericCell)`
  flex: 3;
  height: inherit;
  svg {
    margin: 0 14px 0 0;
  }

  g {
    fill: ${({ theme }) => theme.textColor};
  }
`;

export const ContentCell = styled(GenericCell)`
  flex: 6;

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const LinkCell = styled(GenericCell)`
  flex: 4;
  padding: 0;
  text-align: center;
`;

export const Label = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.textColor};
  text-transform: capitalize;
  margin: 0 14px 0 0;
`;

export const Link = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  padding: 0 10px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.fieldHoverColor};
  }

  &.deleted {
    background: repeating-linear-gradient(
      135deg,
      ${({ theme }) => theme.deletedSecretsDefaultStripe} 3px,
      ${({ theme }) => theme.deletedSecretsDefaultStripe} 3px,
      ${({ theme }) => theme.deletedSecretsDefaultBg} 5px,
      ${({ theme }) => theme.deletedSecretsDefaultBg} 10px
    );

    &:hover {
      background: repeating-linear-gradient(
        135deg,
        ${({ theme }) => theme.deletedSecretsHoverStripe} 3px,
        ${({ theme }) => theme.deletedSecretsHoverStripe} 3px,
        ${({ theme }) => theme.deletedSecretsHoverBg} 5px,
        ${({ theme }) => theme.deletedSecretsHoverBg} 10px
      );
    }
  }
`;
