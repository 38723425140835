import React from 'react';
import { BillingSummarySection } from '../subComponents/BillingSummarySection';
import { PaymentsSection } from '../subComponents/PaymentsSection';
import { AccountsSection } from '../subComponents/AccountsSection';
import { BillingWrapper } from './styles/BillingStyles';
import { formatPayment } from './subFunctions/billingFunctions';

export const BillingSummary = ({
  setView,
  paymentMethod,
  setFormContext,
  subscription,
  setSubscription,
  accounts,
  setLoading,
}) => {
  const paymentDetails = formatPayment(paymentMethod);

  if (!accounts) return null;
  return (
    <BillingWrapper>
      <BillingSummarySection
        setView={setView}
        accounts={accounts}
        setFormContext={setFormContext}
        subscription={subscription}
        setSubscription={setSubscription}
      />
      <PaymentsSection
        setView={setView}
        paymentDetails={paymentDetails}
        setFormContext={setFormContext}
        setLoading={setLoading}
        subscription={subscription}
      />
      {typeof subscription === 'string' ? null : (
        <AccountsSection setView={setView} accounts={accounts} setLoading={setLoading} />
      )}
    </BillingWrapper>
  );
};
