import React from 'react';
import { useHistory } from 'react-router-dom';
import { AdminFooterContainer } from '../styles/AdminFooterStyle';
import { FooterTagBar, FooterButton } from '../../../../common/links/styles/FooterLinkStyle';
import { Icon } from '../../../../common/Icon';
import { Divider } from '../../../../common/divider/Divider';
import { ButtonContainer } from '../../../forms/styles/FormFooterStyle';

export const SaveFooter = ({ handleSubmit, first, edit }) => {
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  const handleSave = e => {
    handleSubmit(e);
    if (first) first();
  };

  return (
    <AdminFooterContainer className="leftSplit">
      <FooterTagBar>
        <ButtonContainer>
          <FooterButton role={'FooterButton'} tabIndex="0" className="footer-button" onClick={e => handleSave(e)}>
            <i className="fas fa-check-circle" />
            {edit ? 'Confirm Changes' : 'Save'}
          </FooterButton>
          <Divider />
          <FooterButton role={'FooterButton'} tabIndex="0" className="footer-button" onClick={() => goBack()}>
            Cancel
          </FooterButton>
        </ButtonContainer>
        <ButtonContainer>
          <FooterButton>
            Shift + <Icon name="key-s" width="18px" />
          </FooterButton>
          <Divider />
          <FooterButton>
            <Icon name="esc" className="esc-key" width="48px" />
          </FooterButton>
        </ButtonContainer>
      </FooterTagBar>
    </AdminFooterContainer>
  );
};
