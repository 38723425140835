import React from 'react';
import { DeletedTitle, IconWrap, Title } from './styles/StripedHeaderStyles';
import { Icon } from '../../../../common/Icon';

const StripedHeader = ({ title, icon }) => {
  return (
    <DeletedTitle>
      <IconWrap>
        <Icon name={icon} width={'20px'} />
      </IconWrap>
      <Title>{title}</Title>
    </DeletedTitle>
  );
};

export default StripedHeader;
