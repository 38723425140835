import React, { useState } from 'react';
import { LoginInputArea, PasswordWrap, IconWrap, Icon } from './styles/LoginInputStyles';

const LoginInput = ({ handleChange, mode, autoFocus }) => {
  const [typePassword, setTypePassword] = useState('password');

  const passwordLogin = () => setTypePassword(typePassword === 'text' ? 'password' : 'text');

  const renderEmail = () => (
    <LoginInputArea
      role={'login-email-input'}
      name="email"
      type="text"
      placeholder="Email Address"
      onChange={e => handleChange(e, mode)}
      autoFocus={autoFocus}
    />
  );

  const renderPassword = () => (
    <PasswordWrap>
      <LoginInputArea
        role={'login-password-input'}
        name="password"
        type={typePassword}
        placeholder="Password"
        onChange={e => handleChange(e, mode)}
      />
      <IconWrap role={'icon-password-button'} onClick={passwordLogin}>
        <Icon className={typePassword === 'password' ? 'far fa-eye' : 'far fa-eye-slash'} />
      </IconWrap>
    </PasswordWrap>
  );

  const renderCode = () => (
    <LoginInputArea
      role={'login-code-input'}
      name="otp-code"
      type="text"
      placeholder="OTP code"
      onChange={e => handleChange(e, mode)}
    />
  );

  const renderRecovery = () => (
    <LoginInputArea
      role={'login-recovery-input'}
      name="otp-code"
      type="text"
      placeholder="Recovery code"
      onChange={e => handleChange(e, mode)}
    />
  );

  if (mode === 'email') return renderEmail();
  if (mode === 'password') return renderPassword();
  if (mode === 'otp_attempt') return renderCode();
  if (mode === 'recovery_code') return renderRecovery();
  return null;
};

export default LoginInput;
