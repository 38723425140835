import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Mousetrap from 'mousetrap';
import { AdminFooterContainer } from '../styles/AdminFooterStyle';
import FooterTag from '../../../../common/links/FooterTag';
import { Icon } from '../../../../common/Icon';
import { isAnInput } from '../../../../utils/commonFunctions';

export const MainFooter = ({
  fontIcon = 'fas fa-plus-circle',
  icon = <Icon name="key-n" width="18px" />,
  keyText = 'Shift +',
  context,
}) => {
  const history = useHistory();
  const { pathname } = history.location;

  const footerText = () => {
    if (pathname === '/admin/chests/show' || context === 'Secrets') return 'Add a secret to Recrypt';
    if (pathname === '/admin/teams/show') return 'Add a new user to Team';
    if (context === 'Users') return 'Add a user to Recrypt';
    if (context === 'Chests') return 'Add a chest to Recrypt';
    if (context === 'Teams') return 'Add a team to Recrypt';
  };

  const handleClick = () => {
    if (pathname === '/admin/chests/show' || context === 'Secrets')
      history.push({
        pathname: '/admin/chests/secret/new',
        prevPathname: pathname,
      });
    if (pathname === '/admin/teams/show')
      history.push({
        pathname: '/admin/teams/user/new',
        prevPathname: pathname,
        passedTeamId: history.location.row.id,
      });
    if (context === 'Users')
      history.push({
        pathname: '/admin/teams/user/new',
        prevPathname: pathname,
      });
    if (context === 'Chests' && pathname === '/admin/chests')
      history.push({ pathname: '/admin/chests/new', prevPathname: pathname });
    if (context === 'Teams' && pathname === '/admin/teams')
      history.push({ pathname: '/admin/teams/new', prevPathname: pathname });
  };

  const shiftN = () => {
    return isAnInput() ? null : handleClick();
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      Mousetrap.bind('shift+n', shiftN, 'keyup');
    }
    return () => {
      isMounted = false;
      Mousetrap.unbind('shift+n', 'keyup');
    };
  });

  return (
    <AdminFooterContainer role={'MainFooter'}>
      <FooterTag onClick={handleClick} fontIcon={fontIcon} text={footerText()} keyText={keyText} icon={icon} />
    </AdminFooterContainer>
  );
};
