import React, { Fragment, useEffect } from 'react';
import { getSecretFormat } from '../subFunctions/FormFunctions';
import { errorCheck } from '../../../utils/formFunctions';
import NoteArea from '../../../common/forms/NoteArea';

const Note = ({ secret, data, setData, notificationText, edit }) => {
  const { note } = getSecretFormat(secret);

  useEffect(() => {
    setData({ note });
  }, [setData, note]);

  const handleChange = (value, key) => {
    setData(prevData => {
      return {
        ...prevData,
        [key]: value,
      };
    });
  };

  return (
    <Fragment>
      <span className="custom-title">Note</span>
      <NoteArea
        placeholder="Note"
        label="Note"
        value={data.note || ''}
        onChange={e => handleChange(e.target.value, 'note')}
        className={errorCheck('note', notificationText)}
        edit={edit}
      />
    </Fragment>
  );
};

export default Note;
