import React, { useEffect, useState, useRef } from 'react';
import Mousetrap from 'mousetrap';
import { DropDownOptions, DropDownOption, DropDownOptionsWrap } from './styles/DropdownOptionsStyles';

export const DropdownOptions = ({
  dropdownOptions,
  handleDropdownChange,
  choices,
  setChoices,
  customText,
  setCustomText,
  multiple,
  className,
}) => {
  const optionsRef = useRef(null);
  const [highlightedOption, setHighlightedOption] = useState();
  // This removes overflow:auto whilst dropdown open
  useEffect(() => {
    let isMounted = true;
    const formBodies = document.getElementsByClassName('form-body');
    if (isMounted) {
      for (let form of formBodies) {
        form.classList.add('dropdown-open');
      }
    }
    return () => {
      isMounted = false;
      for (let form of formBodies) {
        form.classList.remove('dropdown-open');
      }
    };
  });

  // Keyboard shortcuts
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      Mousetrap.bind('up', handleUpKey);
      Mousetrap.bind('down', handleDownKey);
      Mousetrap.bind('enter', handleEnterKey);
    }
    return () => {
      isMounted = false;
      Mousetrap.unbind('up');
      Mousetrap.unbind('down');
      Mousetrap.unbind('enter');
    };
  });

  useEffect(() => {
    const highlightedEl = document.getElementById('highlighted-id');
    if (highlightedEl) {
      const { top, bottom, height } = highlightedEl.getBoundingClientRect();
      const { top: containerTop, bottom: containerBottom } = optionsRef.current.getBoundingClientRect();
      if (bottom > containerBottom) {
        optionsRef.current.scrollTop += height;
      }
      if (top < containerTop) {
        optionsRef.current.scrollTop -= height;
      }
    }
  }, [highlightedOption]);

  const handleUpKey = e => {
    e.preventDefault();
    if (highlightedOption) {
      const activeIndex = dropdownOptions.findIndex(option => option.id === highlightedOption.id);
      let newIndex = activeIndex - 1;
      if (activeIndex > 0) {
        setHighlightedOption(dropdownOptions[newIndex]);
      }
    }
  };

  const handleDownKey = e => {
    e.preventDefault();
    if (highlightedOption) {
      const activeIndex = dropdownOptions.findIndex(option => option.id === highlightedOption.id);
      let newIndex = activeIndex + 1;
      if (activeIndex < dropdownOptions.length - 1) {
        if (dropdownOptions[newIndex].id === 'blank') {
          return null;
        }
        setHighlightedOption(dropdownOptions[newIndex]);
      }
    } else {
      setHighlightedOption(dropdownOptions[0]);
    }
  };

  const handleEnterKey = () => {
    if (highlightedOption) {
      multiple ? handleMultiple(highlightedOption) : handleSingle(highlightedOption);
    }
  };

  // This handles multiple choices
  const handleMultiple = option => {
    const newChoices = [...choices, option];
    setChoices(newChoices);
    handleDropdownChange(newChoices);
  };

  // This handles single choices
  const handleSingle = option => {
    handleDropdownChange(option);
    setCustomText('');
  };

  // This returns dropdown options and applies filter if necessary
  const returnOptions = () => {
    if (customText !== '') {
      const filteredOptions = dropdownOptions.filter(option =>
        option.label.toLowerCase().includes(customText.toLowerCase()),
      );
      return filteredOptions.map(option => (
        <DropDownOption
          role={`DropDownOptionCustomText${option.id}`}
          className={highlightedOption && highlightedOption.id === option.id && 'highlighted'}
          id={highlightedOption && highlightedOption.id === option.id ? 'highlighted-id' : undefined}
          key={option.id}
          onClick={multiple ? () => handleMultiple(option) : () => handleSingle(option)}
        >
          {option.label}
        </DropDownOption>
      ));
    }
    return dropdownOptions.map(option => (
      <DropDownOption
        role={`DropDownOption${option.id}`}
        className={highlightedOption && highlightedOption.id === option.id && 'highlighted'}
        id={highlightedOption && highlightedOption.id === option.id ? 'highlighted-id' : undefined}
        key={option.id}
        onClick={multiple ? () => handleMultiple(option) : () => handleSingle(option)}
      >
        {option.label}
      </DropDownOption>
    ));
  };

  return (
    <DropDownOptionsWrap className={className}>
      <DropDownOptions role={'DropDownOptions'} ref={optionsRef}>
        {returnOptions()}
      </DropDownOptions>
    </DropDownOptionsWrap>
  );
};
