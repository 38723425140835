import React from 'react';

export const AdminContext = React.createContext(null);
export const AdminUserContext = React.createContext({});
export const AdminChestContext = React.createContext({
  adminChestTable: [null, () => null],
});
export const AdminTeamContext = React.createContext({
  adminTeamTable: [null, () => null],
});
