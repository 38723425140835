import React, { useMemo } from 'react';
import Request from '../../../api/Request';
import { Bubble, Cross, DropdownOption, DropdownWrap, OptionLabel, Wrap } from './sBubbleDropdown';
import { placeholderFunc } from '../../../utils/commonFunctions';

interface IProps {
  data: any; // An array of data arrays that contain team/user/secret/chest info
  rowType: RecryptEntity; // Example: 'teams', plurality is a plus
  onClick: (selRow: any, rowType: RecryptEntity) => any;
  onRemove?: (selRow: any, rowType: RecryptEntity) => any;
}

const BubbleDropdown = ({ data, rowType, onClick, onRemove = () => placeholderFunc() }: IProps) => {
  const handleRemove = (e: any, row: any) => {
    e.stopPropagation();
    onRemove(row, rowType);
  };

  const handleClick = async (row: any, rowType: RecryptEntity) => {
    const url = {
      chests: `/api/v1/vaults/${row['id']}`,
      secrets: `/api/v1/secrets/${row['id']}`,
      teams: `/api/v1/teams/${row['id']}`,
      users: `/api/v1/users/${row['id']}`,
    };
    const handler = new Request('GET', url[rowType], { options: ['*'] });
    const { json } = await handler.perform();
    onClick(json, rowType);
  };

  if (!data || data.length === 0) return null;
  if (!Array.isArray(data) || data.length === 1) {
    return (
      <Wrap>
        <Bubble role={'single-bubble'} onClick={() => handleClick(data[0], rowType)}>
          {data[0]['name']}
        </Bubble>
      </Wrap>
    );
  }

  const genDropdown = useMemo(
    () =>
      data.map((row: any, i: number) => (
        <DropdownOption role={'drop-down-option'} key={i} onClick={() => handleClick(row, rowType)}>
          <OptionLabel>{row['name']}</OptionLabel>
          {['teams', 'chests'].includes(rowType) && (
            <Cross
              data-testid={'RemoveIcon'}
              role={'Cross'}
              className={'fas fa-times'}
              onClick={e => handleRemove(e, row)}
            />
          )}
        </DropdownOption>
      )),
    [data],
  );

  return (
    <Wrap>
      <Bubble>
        {data.length} {rowType}
      </Bubble>
      <DropdownWrap>{genDropdown}</DropdownWrap>
    </Wrap>
  );
};

export default BubbleDropdown;
