import React, { useState, useEffect, useContext } from 'react';
import { BillingSummary } from './billing/BillingSummary';
import { BillingHistory } from './billing/BillingHistory';
import { PaymentDetails } from './billing/PaymentDetails';
import StripeApi from '../../../api/stripe/StripeApi';
import GenericLoading from '../../../common/genericLoading/GenericLoading';
import { UserTableData } from '../../../api/users/UserApiFunctions';
import { formatInvoices } from './billing/subFunctions/billingFunctions';
import BillingPlans from './billing/BillingPlans';
import { AdminContext } from '../../../context/AdminContext';

export const BillingSettings = () => {
  const [loading, setLoading] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState();
  const { subState } = useContext(AdminContext);
  const { subscription, setSubscription } = subState;
  const [accounts, setAccounts] = useState();
  const [billingHistory, setBillingHistory] = useState();
  const [formContext, setFormContext] = useState();
  const [view, setView] = useState('summary');

  useEffect(() => {
    let isMounted = true;
    StripeApi.getPaymentMethod().then(res => {
      if (isMounted) setPaymentMethod(res);
      UserTableData({ everything: true }).then(res => {
        if (isMounted) setAccounts(res);
        StripeApi.getInvoices().then(res => {
          if (isMounted) setBillingHistory(formatInvoices(res));
          setLoading(false);
        });
      });
    });
    return () => (isMounted = false);
  }, [view, loading]);

  const renderView = () => {
    if (loading) return <GenericLoading />;
    if (view === 'summary')
      return (
        <BillingSummary
          subscription={subscription}
          setSubscription={setSubscription}
          setView={setView}
          accounts={accounts}
          paymentMethod={paymentMethod}
          formContext={formContext}
          setFormContext={setFormContext}
          setLoading={setLoading}
        />
      );
    if (view === 'payment')
      return (
        <PaymentDetails
          setView={setView}
          paymentMethod={paymentMethod}
          formContext={formContext}
          setFormContext={setFormContext}
        />
      );
    if (view === 'history') return <BillingHistory setView={setView} billingHistory={billingHistory} />;
    if (view === 'plan') return <BillingPlans setView={setView} />;
  };

  return <div role={'BillingSettings'}>{renderView()}</div>;
};
