import React, { useState, useEffect } from 'react';
import AdminTeamsWrap from './styles/AdminTeamsWrap';
import { getHeaders } from '../adminHeader/AdminHeaderInfo';
import Table from '../../table/Table';
import AdminTableWrap from '../adminTable/styles/AdminTableWrap';
import { goToLocation } from '../adminFunctions/redirectByUrl';
import LogoutRedirect from '../../../common/nav/LogoutRedirect';
import { AdminTeamContext } from '../../../context/AdminContext';
import { getCall } from '../adminFunctions/getApiCall';
import { TableBottomLoading } from '../../table/subComponents/styles/TableBottomLoading';
import Loading from '../../../images/loading_02.gif';

const AdminTeams = ({ searchText, context, history }) => {
  const [data, setData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [prevContext, setPrevContext] = useState('');
  const headers = getHeaders(context)();
  const { location } = history;
  const { pathname } = location;
  const url = goToLocation(context, pathname);

  const jsonLimit = 20;

  const redirectTo = row => history.push({ pathname: url, row, context, prevPathname: pathname });

  const teamColumnWidths = ['25%', '15%', '30%', '12.5%', '12.5%', '5%'];
  const userColumnWidths = ['25%', '30%', '25%', '15%', '5%'];

  const adminTable = {
    adminTeamTable: [data, setData],
  };

  if (context !== prevContext) {
    setData([]);
    setPrevContext(context);
  }

  useEffect(() => {
    // Bug fix: P4SS-1543
    setDataLoading(true);
    getCall(context)({
      everything: true,
      filter: searchText,
      limit: jsonLimit,
      offset: 0,
    }).then(result => {
      setData(result);
      setDataLoading(false);
    });
  }, [context, searchText, history]);

  if (!data) return <LogoutRedirect />;
  return (
    <AdminTeamContext.Provider value={adminTable}>
      <AdminTeamsWrap>
        <AdminTableWrap id="Table">
          <Table
            columnWidths={context === 'Teams' ? teamColumnWidths : userColumnWidths}
            headers={headers}
            data={data}
            onClick={redirectTo}
            context={context}
          />
          {dataLoading && (
            <TableBottomLoading role={'table-bottom-loading'}>
              <img className="small-loading" src={Loading} alt="Loading..." />
            </TableBottomLoading>
          )}
        </AdminTableWrap>
      </AdminTeamsWrap>
    </AdminTeamContext.Provider>
  );
};

export default AdminTeams;
