import styled from 'styled-components';

export const CardContainer = styled.div`
  height: 168px;
  flex: 1 390px;
  max-width: 390px;
  background-color: ${props => props.theme.tableBgColor};
  color: ${props => props.theme.textColor};

  @media screen and (max-width: 767px) {
    max-width: 350px;
  }
  @media screen and (min-width: 1800px) {
    max-width: 420px;
  }
`;
export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  height: 2rem;
  padding: 0.25rem 1rem;
  border-left: solid 0.5rem ${props => props.theme.recryptLogoBlue};
  background-color: ${props => props.theme.formFooterBgColor};
  &.all-time {
    border-left: solid 0.5rem ${props => props.theme.textColor};
  }
`;
export const MainTextContainer = styled.div`
  padding: 0.5rem 1.5rem;
  align-items: center;
`;
export const StatisticsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
`;
export const CurrencyContainer = styled.div`
  font-weight: bold;
  font-size: 1.75rem;
  margin-top: 0.5rem;
  transform: translateX(-3px);
`;
export const StatisticsTextWrap = styled.div`
  font-size: 3.75rem;
  font-weight: 700;
`;
export const SubTextContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 300;
  i {
    margin-right: 0.25rem;
  }
`;
export const IconTextWrap = styled.div`
  font-weight: 400;
  margin-right: 0.25rem;
  color: ${({ important, theme }) => (important ? theme.adminDashboardGreen : theme.adminDashboardRed)};
  svg path {
    fill: ${({ important, theme }) => (important ? theme.adminDashboardGreen : theme.adminDashboardRed)};
  }
  .up,
  .down {
    width: 18px;
    margin-right: 0.25rem;
  }
`;
