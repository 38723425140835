import BaseApi from '../BaseApi';

export const TeamTableData = async (params = {}) => {
  const teamData = await BaseApi.all('teams', params);
  const chestData = await BaseApi.all('vaults', params);
  if (!teamData || !chestData) return 0;
  const teams = teamData.items;
  const chests = chestData.items;
  return teams.map(team => ({
    id: team.id,
    name: team.name,
    default: team.default_for_new_users,
    users: Object.keys(team.memberships).length,
    chests: GetTeamChests(team, chests),
    added: new Date(team.created_at).toLocaleDateString('en-gb'),
    updated: new Date(team.updated_at).toLocaleDateString('en-gb'),
  }));
};

export const ViewTeamData = async id => {
  const team = await BaseApi.show('teams', id);
  return team;
};

export const SingleTeamTableData = async (id, params = {}) => {
  const team = await BaseApi.show('teams', id);
  const chestData = await BaseApi.all('vaults', params);
  if (!chestData) return 0;
  const chests = chestData.items;
  return {
    id: team.id,
    name: team.name,
    chests: GetTeamChests(team, chests),
  };
};

const GetTeamChests = (team, chests) => {
  const teamChests = [];
  team.assignments.forEach(assignment => {
    const matchedChest = chests?.find(chest => chest.id === assignment.vault_id);
    if (matchedChest !== undefined) teamChests.push({ id: matchedChest['id'], name: matchedChest['name'] });
  });
  return teamChests;
};

export const GetChestTeam = secret => {
  if (secret === null) return;
  const chestTeams = [];
  secret.vault.teams.forEach(team => {
    chestTeams.push({ id: team.id, name: team.name });
  });
  return chestTeams;
};

export const CreateTeam = async data => {
  const request = await BaseApi.create('teams', data);
  return request;
};

export const GetAddedTeams = async user => {
  const teamData = await BaseApi.all('teams', { everything: true });
  if (!teamData) return 0;
  return teamData.items.map(team => ({
    id: team.id,
    name: team.name,
    default: team.default_for_new_users,
    added: CheckAdded(user, team),
    selected: false,
    userCount: Object.keys(team.memberships).length,
    membership: team.memberships.find(membership => membership.user_id === user.id),
  }));
};

const CheckAdded = (user, team) => {
  const matchedTeam = team.memberships.find(membership => membership.user_id === user.id);
  if (matchedTeam !== undefined) return true;
  return false;
};
