import React from 'react';
import { FooterTagBar, FooterButton, LinkWrap } from './styles/FooterLinkStyle';

const ExpandableFooterTag = ({ tagArr, keyText, icon }) => {
  const generateLinks = () =>
    tagArr &&
    tagArr.map(({ fontIcon, text, onClick, tabIndex }, i) => (
      <FooterButton role={'FooterButton'} tabIndex={tabIndex} key={i} className="footer-button" onClick={onClick}>
        <i className={fontIcon} />
        {text}
      </FooterButton>
    ));

  return (
    <FooterTagBar role={'ExpandableFooterTag'}>
      <LinkWrap>{generateLinks()}</LinkWrap>
      <FooterButton className={'expandable'}>
        {keyText}
        {icon}
      </FooterButton>
    </FooterTagBar>
  );
};

export default ExpandableFooterTag;
