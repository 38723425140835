import React, { useState, useEffect, useContext } from 'react';
import {
  SettingStyle,
  SettingName,
  SettingArea,
  SettingInputArea,
  SettingInput,
  SettingText,
  SettingButton,
} from './styles/SettingStyles';
import { NewDropdown } from '../../common/forms/NewDropdown';
import { errorCheck } from '../../utils/formFunctions';
import { NotificationContext } from '../../context/index';

const Setting = ({
  name,
  activeSetting,
  setActiveSetting,
  type,
  text,
  setText,
  buttonName,
  onClick,
  readOnly = false,
  inactivityLength,
  selectedOption,
  setSelectedOption,
  options,
}) => {
  const [buttonEdit, setButtonEdit] = useState(false);
  const [settingsDropdownVisibility, setSettingsDropdownVisibility] = useState(false);
  const { notificationText } = useContext(NotificationContext);
  useEffect(() => {
    if (activeSetting !== null && activeSetting !== name) {
      setButtonEdit(false);
    }
  }, [activeSetting, name]);

  const handleChange = e => {
    setText(e.target.value);
  };

  const setEdit = () => {
    if (!readOnly) setButtonEdit(true);
    else onClick();
    setActiveSetting(name);
  };

  const activateSetting = () => {
    setActiveSetting(name);
    onClick();
  };
  const returnOptions = options => {
    return (
      selectedOption && (
        <NewDropdown
          label={selectedOption.label}
          handleDropdownChange={option => {
            setSelectedOption(option);
          }}
          dropdownOptions={options}
          dropdownVisibility={settingsDropdownVisibility}
          setDropdownVisibility={setSettingsDropdownVisibility}
          optionSelected={inactivityLength}
          id="activity-dropdown"
          className={errorCheck('dropdown', notificationText)}
        />
      )
    );
  };
  const AreaField = () => {
    if (type === 'input')
      return (
        <SettingInputArea>
          <SettingInput disabled={!buttonEdit} onChange={handleChange} value={text || ''} />
        </SettingInputArea>
      );
    if (type === 'dropDown')
      return (
        <SettingInputArea>
          <SettingText>{text}</SettingText>
          {returnOptions(options)}
        </SettingInputArea>
      );
    return <SettingText>{text}</SettingText>;
  };
  return (
    <SettingStyle role={'Settings'}>
      <SettingName role={'SettingName'}>{name}</SettingName>
      <SettingArea>
        {AreaField()}
        {type === 'input' ? (
          !buttonEdit ? (
            <SettingButton onClick={setEdit}>{buttonName}</SettingButton>
          ) : (
            <SettingButton
              className={text === '' ? 'disable' : ''}
              onClick={() => {
                setButtonEdit(false);
                onClick();
              }}
            >
              Save
            </SettingButton>
          )
        ) : null}
        {type === 'text' ? <SettingButton onClick={activateSetting}>{buttonName}</SettingButton> : null}
        {type === 'dropDown' ? (
          <SettingButton
            className={text === '' ? 'disable' : ''}
            onClick={() => {
              onClick();
            }}
          >
            Save
          </SettingButton>
        ) : null}
      </SettingArea>
    </SettingStyle>
  );
};

export default Setting;
