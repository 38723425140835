import styled from 'styled-components';

export const DropdownButtonIconWrapper = styled.div`
  display: inline-flex;
  background-color: inherit;
  flex: 1;
  height: 3rem;
  border-radius: 5px 0 0 5px;
  justify-content: center;
  padding: 0.85rem 0.75rem 0.85rem 0.75rem;
  box-sizing: border-box;

  svg {
    display: block;
    width: 100%;
    height: 100%;
    path {
      fill: ${props => props.theme.fieldTextColor};
    }
  }
`;
