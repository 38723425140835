import { AES } from './aes';
import { Asymmetric } from './asymmetric';
import { Keypair } from './keypair';
import { Symmetric } from './symmetric';

// The general logic for encrypting/decrypting secrets.
export class Secret {
  // Encrypt a string of text, and create encrypted-per-user "unlocks" for it:
  static encrypt(json, users) {
    var aes_key = AES.randomKey();
    var aes_iv = AES.randomIV();
    var encrypted_json = Symmetric.encrypt(aes_key, aes_iv, json);

    // Generate an unlock for each user:
    var aes_key_as_text = AES.exportKey(aes_key);
    var promises = users.map(function (user) {
      var public_key = Keypair.importPublic(user.public_key);
      return Asymmetric.encrypt(public_key, aes_key_as_text);
    });

    // Organise the results into a more user-friendly format:
    promises.push(encrypted_json);
    promises.push(aes_iv);
    return Promise.all(promises).then(function (results) {
      var iv = results.pop();
      var data = results.pop();
      var unlocks = results.map(function (encrypted_key, i) {
        return { user_id: users[i].id, encrypted_value: encrypted_key };
      });
      return { encryptedData: data, iv: iv, unlocks: unlocks };
    });
  }

  // Decrypt a previously-encrypted string of text using our own personal key:
  static decrypt(encrypted_json, data) {
    var private_key = Keypair.importPrivate(data.master, data.privateKeyIv, data.encryptedPrivateKey);
    var unlock_txt = Asymmetric.decrypt(private_key, data.encryptedSecretKey);
    var unlock_key = AES.importKey(unlock_txt);
    return Symmetric.decrypt(unlock_key, data.secretKeyIv, encrypted_json);
  }
}
