import React from 'react';
import { IconMenuButtonPrefixStyle } from '../styles/IconMenuButtonStyles';
import { Icon } from '../../Icon';

const IconMenuButtonPrefix = ({ icon }) => (
  <IconMenuButtonPrefixStyle>
    <Icon name={icon} width="90%" />
  </IconMenuButtonPrefixStyle>
);

export default IconMenuButtonPrefix;
