import styled from 'styled-components';

export const SuccessSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const SuccessContent = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 3rem;
`;

export const SuccessTitle = styled.h2`
  font-weight: normal;
  color: white;
  font-size: 1.6rem;
  text-align: center;
`;

export const SuccessFooter = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
`;

export const SuccessButton = styled.button`
  margin-bottom: 2.65rem;
  height: 2.75rem;
  width: 100%;
  background-color: #40dae1;
  color: #ffffff;
  border: 1px solid #40dae1;
  border-radius: 0.5rem;
  cursor: pointer;
  font-family: 'Lato';
  font-size: 1rem;
  appearance: none;
  outline: none;
  margin-top: 1rem;

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.bgColor};
    color: ${({ theme }) => theme.highlightColor};
  }
`;

export const SecurityWrap = styled.div`
  .st0 {
    fill: white;
  }
`;
