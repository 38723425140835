import styled from 'styled-components';
import GenericTag from '../../../../links/styles/GenericTag';

export const Wrap = styled.div`
  display: flex;
  flex: 8;
  flex-direction: row;
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: scroll;

  scroll-padding: 50px 0 0 50px;
  margin: 1rem -2rem;
  padding: 0.5rem 2rem 1rem 2rem;
`;

export const BubbleWrap = styled.div`
  flex: 12;
  display: flex;
  flex-direction: column;
`;

export const BubbleRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 0 0.5rem 0;

  &:last-child div {
    margin: 0 0 0.5rem 0;
  }
`;

export const BubbleTag = styled(GenericTag)`
  width: min-content;
  text-align: left;
  background-color: ${({ selected, theme }) => (selected ? theme.highlightColor : theme.tableBgColor)};
  color: ${({ selected, theme }) => (selected ? theme.hoverRowColor : theme.textColor)};
  border-color: ${({ selected, theme }) => (selected ? 'transparent' : theme.textColor)};
  outline: none;
  cursor: pointer;
  font-size: 0.96rem;
  height: auto;

  &:hover {
    opacity: 0.7;
  }
`;

export const TagText = styled.div`
  flex: 9;
  line-height: 30px;
  text-align: right;
  font-size: 15px;
  font-style: italic;
`;
