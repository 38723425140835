import styled from 'styled-components';

export const BillingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: hidden;

  .not-first-section {
    margin-top: 2.5rem;
    .accounts-table {
      margin-bottom: 2rem;
    }
  }
`;

export const SectionHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  color: ${props => props.theme.textColor};

  &.billing-heading {
    margin-top: 2em;
    margin-bottom: 4em;

    p {
      margin: 0 1rem 5px 1rem;
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;

      svg {
        order: -1;
        margin-bottom: 1rem;
      }

      + div {
        flex-direction: column;
      }
    }
  }

  &.payments-heading {
    justify-content: flex-start;
    margin-bottom: 1rem;

    > p {
      margin: 0 1rem 5px 1rem;
    }
  }

  &.accounts-heading {
    justify-content: flex-start;

    span {
      margin-left: 1.25rem;
      font-size: 1rem;
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const SectionHR = styled.hr`
  color: ${props => props.theme.textColor};
  width: 100%;

  &#payment-details {
    border: none;
    border-top: 1px solid ${props => props.theme.bgColor};
    margin: 0.75rem 0 1rem 0;
  }
`;

export const BackButton = styled.div`
  border: 1px solid ${props => props.theme.textColor};
  border-radius: 5px;
  color: ${props => props.theme.textColor};
  width: 3rem;
  line-height: 100%;
  font-size: 1rem;
  padding: 0.5rem;
  margin-right: 1.25rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-weight: 400;

  &:hover {
    background-color: ${props => props.theme.highlightColor};
    color: ${props => props.theme.hoverRowColor};
    border-color: transparent;
  }
`;

export const BackWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.2rem;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  font-weight: 700;
`;

export const BillingTable = styled.div`
  font-size: 1rem;
  background-color: ${props => props.theme.formBgColor};
  line-height: 50px;

  @media (-webkit-device-pixel-ratio: 1.5) {
    line-height: 35px;
  }
`;
