import React, { useContext } from 'react';
import {
  Wrap,
  TextWrap,
  SmallBold,
  PlanTitle,
  FeatureWrap,
  FeatureText,
  GetStartedButton,
} from './styles/AdminAdvertStyles';
import { AdminContext } from '../../../context/AdminContext';

const AdminAdvert = ({ history }) => {
  const { subState } = useContext(AdminContext);
  const { subscription } = subState;
  const handleClick = () => history.push('/plans');

  if (!subscription || typeof subscription === 'object') return null;
  return (
    <Wrap>
      <TextWrap>
        <SmallBold>Switch to</SmallBold>
        <PlanTitle>Team Plan</PlanTitle>
        <FeatureWrap>
          <FeatureText>Up to 50 users</FeatureText>
          <FeatureText>Unlimited secrets</FeatureText>
          <FeatureText>Unlimited chests / team sharing</FeatureText>
        </FeatureWrap>
        <GetStartedButton role={'get-started-button'} onClick={handleClick}>
          Get Started
        </GetStartedButton>
      </TextWrap>
    </Wrap>
  );
};

export default AdminAdvert;
