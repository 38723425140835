import { mergeArrays } from '../../mergeFunctions';
import { testIf } from '../validationFunctions';
import { formatResults, successState } from '../validationHelpers';

export const validateNoteSecret = ({ data, setNotificationText }) => {
  const emptyRes = testIf('empty')(data, ['name', 'vault_id', 'note']);
  const lengthRes = testIf('shorterThan')(data, ['name'], 50);

  const testResults = mergeArrays([emptyRes, lengthRes]);
  if (testResults.length === 0) return successState(setNotificationText);
  setNotificationText(formatResults(testResults));
};
