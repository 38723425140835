import React, { useState, useContext, useEffect, useRef } from 'react';
import Mousetrap from 'mousetrap';
import { IconArea } from './styles/BurgerMenuDropdownStyles';
import { OptionsModalContext } from '../../../../../context/OptionsModalContext';
import { useHistory } from 'react-router-dom';
import BaseApi from '../../../../../api/BaseApi';
import { AdminChestContext, AdminContext } from '../../../../../context/AdminContext';
import { ChestTableData } from '../../../../../api/chests/ChestApiFunctions';
import { BurgerMenuDropdown } from './BurgerMenuDropdown';
import { ModalContext } from '../../../../../context';

// Component that handles the AdminChest actions menu column functions.
export const AdminChestActions = ({ row, highlightedRow }) => {
  const [optionsVisible, setOptions] = useContext(OptionsModalContext);
  const [highlightedOption, setHighlightedOption] = useState();
  const history = useHistory();
  const adminContext = useContext(AdminContext);
  const setChestTags = adminContext && adminContext.chestTags[1];
  const setData = useContext(AdminChestContext).adminChestTable[1];
  const modalContext = useContext(ModalContext);
  const setModal = modalContext && modalContext.setModal;
  const [toggle, setToggle] = useState(false);
  const dropdownRef = useRef();

  // useEffect for keyboard shortcuts
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      Mousetrap.bind('right', e => handleOptions(highlightedRow, e));
    }
    return () => {
      isMounted = false;
      Mousetrap.unbind('right');
    };
  });

  // useEffect for toggling the dropdown menu
  useEffect(() => {
    document.addEventListener('mousedown', toggleDropdown);
    return () => document.removeEventListener('mousedown', toggleDropdown);
  }, [toggle]);

  const toggleDropdown = e => {
    if (toggle && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setOptions({});
      setHighlightedOption('');
      setToggle(false);
    }
  };

  // Refreshes the table with up to date data.
  const refreshTable = async () => {
    const chestData = await ChestTableData({ everything: true });
    setChestTags(chestData);
    setData(chestData);
  };

  const menuOptions = [
    {
      text: 'Add Secret',
      handleClick: () => addSecret(),
      styleId: 'chest-add-secret',
    },
    {
      text: 'Edit Chest',
      handleClick: () => editChest(),
      styleId: 'chest-edit',
    },
    {
      text: 'Remove Chest',
      handleClick: () => deletePopUp(),
      styleId: 'chest-remove',
    },
  ];

  const addSecret = () => {
    setOptions({});
    history.push({
      pathname: '/admin/chests/secret/new',
      prevPathname: history.location.pathname,
    });
  };

  const editChest = () => {
    setOptions({});
    history.push({
      pathname: '/admin/chests/edit',
      row,
      prevPathname: history.location.pathname,
    });
  };

  // Destroys a chest when the delete confirmation is clicked..
  const handleDeleteModalConfirm = () => {
    setModal();
    BaseApi.destroy('vaults', row.id).then(async () => {
      refreshTable();
    });
  };

  const deletePopUp = () =>
    setModal({
      key: 'Confirm',
      params: {
        icon: 'alert',
        question: `Are you sure you want to delete ${row['name']}?`,
        subtext: 'The chest and all secrets inside will be deleted permanently.',
        confirmText: `Yes, delete ${row['name']}`,
        cancelText: `No, keep ${row['name']}`,
        confirmAction: handleDeleteModalConfirm,
        cancelAction: () => setModal(),
      },
    });

  const handleOptions = (row, e) => {
    setHighlightedOption('');
    optionsVisible.row !== row?.id ? setOptions({ row: row.id, above: calculateOptionsPosition(e) }) : setOptions({});
  };

  const calculateOptionsPosition = e => {
    return e.clientY > window.innerHeight * 0.7 || window.innerHeight - e.clientY < 200 ? true : false;
  };

  return (
    <div style={{ position: 'relative' }} ref={dropdownRef} onClick={() => setToggle(!toggle)}>
      <IconArea data-testid={'icon'} onClick={e => handleOptions(row, e)} className="icon-area">
        <i className="fas fa-bars"></i>
      </IconArea>
      {optionsVisible.row === row.id && (
        <BurgerMenuDropdown
          menuOptions={menuOptions}
          highlightedOption={highlightedOption}
          setHighlightedOption={setHighlightedOption}
        />
      )}
    </div>
  );
};
