import React from 'react';
import { TableHeaderWrap } from './styles/TableHeaderStyles';
import { TableTr } from '../styles/TableStyles';
import { generateHeader } from '../subFunctions/headerFunctions';
import { getNodeText } from '../../../utils/commonFunctions';
import { getHeaderCell } from './uniqueHeaders/UniqueHeaders';

const TableHeader = ({ headers, selectedHeader, setSelectedHeader, headerWidths }) => {
  const handleHeaderClick = e => {
    const hStr = getNodeText(e);
    setSelectedHeader(generateHeader(hStr, selectedHeader));
  };

  const generateRows = () => {
    return headers.map((col, idx) => {
      const headerWidth = headerWidths[idx];
      const params = {
        idx,
        col,
        selectedHeader,
        handleHeaderClick,
        headerWidth,
      };
      return getHeaderCell(col)(params);
    });
  };

  return (
    <TableHeaderWrap>
      <TableTr>{generateRows()}</TableTr>
    </TableHeaderWrap>
  );
};

export default TableHeader;
