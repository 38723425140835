import ApiRequest from '../ApiRequest';

class OrganisationApi {
  static show() {
    return ApiRequest('GET', `api/v1/organisation.json`);
  }

  static update(params) {
    return ApiRequest('PUT', `api/v1/organisation.json`, params);
  }

  static delete() {
    return ApiRequest('DELETE', `api/v1/organisation`);
  }

  static transfer(params) {
    return ApiRequest('PATCH', `api/v1/organisation/transfer`, params);
  }
}

export default OrganisationApi;
