import React, { useState, useContext } from 'react';
import { AdminChestsWrap } from '../adminChests/styles/AdminChestsWrap';
import SecretForm from '../../forms/SecretForm';
import ToggleSwitch from '../../../common/checkboxes/ToggleSwitch';
import {
  SecretOptionsWrap,
  OptionsTitle,
  OptionWrapper,
  OptionsMainText,
  OptionsSubText,
} from '../adminChests/styles/EditSecretsOptions';
import { AdminSecretsEditWrap } from './styles/AdminSecretsEditWrap';
import { AdminContext } from '../../../context/AdminContext';
import { ChestTableData } from '../../../api/chests/ChestApiFunctions';

export const AdminSecretAdd = () => {
  const [hideSecret, toggleHide] = useState(false);
  const setChestTags = useContext(AdminContext).chestTags[1];

  const refreshSide = () => {
    ChestTableData({ everything: true }).then(result => {
      setChestTags(result);
    });
  };

  return (
    <AdminChestsWrap className="form-footer team-form">
      <AdminSecretsEditWrap>
        <SecretForm admin={true} refresh={refreshSide} />
      </AdminSecretsEditWrap>
      <SecretOptionsWrap>
        <OptionsTitle>MORE OPTIONS</OptionsTitle>
        <OptionWrapper>
          <OptionsMainText>
            <i className="far fa-eye-slash"></i>HIDE SECRET
          </OptionsMainText>
          <ToggleSwitch id="hide-secret-toggle" onClick={() => toggleHide(!hideSecret)} checked={hideSecret} />
        </OptionWrapper>
        <OptionsSubText>Don't show this secret to users</OptionsSubText>
      </SecretOptionsWrap>
    </AdminChestsWrap>
  );
};
