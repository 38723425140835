import styled from 'styled-components';

export const OptionWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  min-width: 170px;
  top: 100%;
  right: 0;
  border-radius: 0px 0px 5px 5px;
  background: ${props => props.theme.fieldBgColor};
  transition: all 0.5s;
  z-index: 2;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);
  padding-top: 0;
`;

export const MenuOption = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  font-size: 1rem;
  font-weight: 400;
  color: ${props => props.theme.textColor};
  line-height: 1rem;
  cursor: pointer;
  transition: all 0.5s;
  padding: 0 1rem;
  outline: none;

  svg {
    margin-right: 1rem;
    path {
      transition: all 0.3s ease-in-out;
    }
    .st2 {
      fill: ${props => props.theme.textColor} !important;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: ${props => props.theme.highlightColor};
    color: ${props => props.theme.hoverRowColor};
    .st2 {
      fill: ${props => props.theme.hoverRowColor} !important;
    }
  }
`;
