import styled from 'styled-components';

export const TableTh = styled.div`
  background-color: inherit;
  width: ${props => props.headerWidth};
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  padding-left: 1.75rem;
  text-align: left;
  font-family: 'Lato';
  font-size: 0.9rem;
  font-weight: 400;
  letter-spacing: 0px;
  color: #bac2d7;
  cursor: pointer;
  white-space: nowrap;
`;
