import styled from 'styled-components';

const IconButton = styled.div`
  display: inline-flex;
  padding: 0.9rem;
  margin-right: 0.1rem;
  vertical-align: middle;
  border: 1px ${props => props.theme.fieldBgColor} solid;
  border-radius: 5px;
  background-color: ${props => props.theme.tableBgColor};
  background-image: url(${props => props.icon});
  background-repeat: no-repeat;
  background-position: center;
  &:hover {
    background-color: ${props => props.theme.hoverButtonColor};
  }
  z-index: 10;
`;

export default IconButton;
