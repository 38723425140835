class LocalStore {
  store;

  constructor() {
    this.store = window.localStorage;
  }

  set(key, item) {
    this.store.setItem(key, JSON.stringify(item));
  }

  get(key) {
    const item = this.store.getItem(key);
    return item && JSON.parse(item);
  }

  clear() {
    this.store.clear();
  }

  remove(key) {
    this.store.removeItem(key);
  }
}

export default LocalStore;
