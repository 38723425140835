import React from 'react';
import { ReactComponent as FourSquares } from '../../images/recrypt_4_squares.svg';
import { ReactComponent as GmailIcon } from '../../images/recrypt_icons_gmail.svg';
import { ReactComponent as OutlookIcon } from '../../images/recrypt_icons_outlook.svg';
import { ReactComponent as YahooIcon } from '../../images/recrypt_icons_yahoo.svg';
import { Heading, Subheading, InfoWrapper, EmailLink, TextLink } from './styles/ComponentStyles';

export const EmailConfirmation = ({ email }) => {
  return (
    <InfoWrapper role={'email-confirmation-wrapper'}>
      <FourSquares width="90px" role={'email-confirmation-four-squares'} />
      <Heading role={'email-confirmation-heading'}>Check your mailbox</Heading>
      <Subheading className="text-center" role={'email-confirmation-subheading'}>
        An email was sent to <b>{email}</b>. Please click on the link to get started.
      </Subheading>
      <EmailLink href={`${process.env.REACT_APP_GMAIL_URL}`} target="_blank" role={'email-confirmation-email-link'}>
        <GmailIcon width="24px" role={'email-confirmation-gmail-icon'} />
        Open Gmail
      </EmailLink>
      <EmailLink href={`${process.env.REACT_APP_OUTLOOK_URL}`} target="_blank" role={'email-confirmation-email-link'}>
        <OutlookIcon width="24px" role={'email-confirmation-outlook-icon'} />
        Open Outlook
      </EmailLink>
      <EmailLink href={`${process.env.REACT_APP_YAHOOMAIL_URL}`} target="_blank" role={'email-confirmation-email-link'}>
        <YahooIcon width="24px" role={'email-confirmation-yahoo-icon'} />
        Open Yahoo
      </EmailLink>
      <TextLink role={'email-confirmation-text-link'}>Resend email</TextLink>
    </InfoWrapper>
  );
};
