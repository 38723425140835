import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Mousetrap from 'mousetrap';
import { CSSTransition } from 'react-transition-group';
import AdminDropdown from './adminDropdown/AdminDropdown';
import { AdminSidebarContainer } from './styles/AdminSidebarStyles';
import IconMenuButton from '../../../common/buttons/IconMenuButton';
import AdminAdvert from './AdminAdvert';
import { ThemeContext } from 'styled-components';
import LockedSidebar from './lockedSidebar/LockedSidebar';
import { AdminContext } from '../../../context/AdminContext';

export const AdminSidebar = ({ chestTags, teamTags, setContext, context }) => {
  const { pathname } = useLocation();
  const themeContext = useContext(ThemeContext);
  const { subState } = useContext(AdminContext);
  const { subscription } = subState;
  const userSubscribed = !subscription || typeof subscription === 'object';
  const history = useHistory();

  const [optionsVisible, toggleOptions] = useState('');

  const currentActiveTag = history['location']['row'] || '';

  const chestOptions = [
    {
      handleClick: () => {
        history.push({
          pathname: '/admin/chests/secret/new',
          prevPathname: pathname,
        });
        toggleOptions('');
        setContext('Secrets');
      },
      text: 'Add a Secret',
    },
    {
      handleClick: () => {
        history.push({ pathname: '/admin/chests/new', prevPathname: pathname });
        toggleOptions('');
        setContext('Chests');
      },
      text: 'Add a Chest',
    },
  ];

  const teamOptions = [
    {
      handleClick: () => {
        history.push({
          pathname: '/admin/teams/user/new',
          prevPathname: pathname,
          addNewUser: () => null,
        });
        toggleOptions('');
        setContext('Users');
      },
      text: 'Add a User',
    },
    {
      handleClick: () => {
        history.push({ pathname: '/admin/teams/new', prevPathname: pathname });
        toggleOptions('');
        setContext('Teams');
      },
      text: 'Add a Team',
    },
  ];

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      Mousetrap.bind('shift+down', handleShiftDown);
      Mousetrap.bind('shift+up', handleShiftUp);
    }
    return () => {
      isMounted = false;
      Mousetrap.unbind('shift+down');
      Mousetrap.unbind('shift+up');
    };
  });

  const navigateToSettings = () => {
    history.push({ pathname: '/admin/settings', prevPathname: pathname });
    setContext('Settings');
  };

  const handleShiftUp = () => {
    if (pathname.includes('teams')) {
      history.push({ pathname: '/admin/chests', prevPathname: pathname });
      setContext('Chests');
      toggleOptions('');
    }
    if (pathname.includes('settings')) {
      setContext('Teams');
      history.push({ pathname: '/admin/teams', prevPathname: pathname });
      toggleOptions('');
    }
  };

  const handleShiftDown = () => {
    if (pathname.includes('chests')) {
      history.push({ pathname: '/admin/teams', prevPathname: pathname });
      setContext('Teams');
      toggleOptions('');
    }
    if (pathname.includes('teams')) {
      navigateToSettings();
    }
  };

  const renderTeams = () =>
    userSubscribed ? (
      <AdminDropdown
        barText={'Teams & Users'}
        linkTo={'/admin/teams'}
        tags={teamTags}
        icon={'team'}
        setContext={setContext}
        context={context}
        options={teamOptions}
        optionsVisible={optionsVisible}
        toggleOptions={toggleOptions}
        currentActiveTag={currentActiveTag}
        active={context === 'Teams' || context === 'Users'}
      />
    ) : (
      <LockedSidebar icon={'team'} barText={'Teams & Users'} history={history} />
    );

  return (
    <CSSTransition in={pathname.includes('/admin')} timeout={500} classNames="side" appear>
      <AdminSidebarContainer>
        <AdminDropdown
          barText={'Chests & Secrets'}
          linkTo={'/admin/chests'}
          tags={chestTags}
          icon={
            context === 'Chests' || context === 'Secrets' || themeContext.scheme === 'dark' ? 'chest_white' : 'chest'
          }
          setContext={setContext}
          context={context}
          options={chestOptions}
          optionsVisible={optionsVisible}
          toggleOptions={toggleOptions}
          currentActiveTag={currentActiveTag}
          active={context === 'Chests' || context === 'Secrets'}
        />
        {renderTeams()}
        <IconMenuButton
          text={'Settings'}
          icon={'settings'}
          onClick={navigateToSettings}
          active={context === 'Settings'}
        />
        <AdminAdvert history={history} />
      </AdminSidebarContainer>
    </CSSTransition>
  );
};
