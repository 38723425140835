import React from 'react';
import { TableButton, TableDropdown, TableDropdownContent } from '../styles/TableButton';

const TableButtonDropdown = ({ array, index, onClick, identifier = '', onRemove }) => {
  const numberOfCells = array.length - index;
  const handleClose = e => {
    e.stopPropagation();
    onRemove(e);
  };

  const generateDropdown = index =>
    array.map((arrayItem, i) => {
      if (i >= index)
        return (
          <div className={'dropdownRowWrap'} key={i}>
            <span
              key={arrayItem.id}
              onClick={() => onClick(arrayItem.id)}
              tabIndex="0"
              onKeyDown={e => (e.key === 'Enter' ? onClick(arrayItem.id) : null)}
              className="table-button-dropdown"
              role={'dropdown-row'}
            >
              {arrayItem['name']}
              {onRemove && (
                <div role={'close-dropdown'} onClick={handleClose}>
                  X
                </div>
              )}
            </span>
          </div>
        );
      return null;
    });

  return (
    <TableDropdown role={'table-button-dropdown'} tabIndex="0">
      <TableButton className="table-button dropdown-button" key={index}>
        {!identifier && <span role={'no-identifier-span'}>...</span>}
        {identifier && (
          <span role={'identifier-span'}>
            + {numberOfCells} {numberOfCells === 1 ? identifier.slice(0, -1) : identifier}
          </span>
        )}
      </TableButton>
      <TableDropdownContent className="dropdown-content">{generateDropdown(index)}</TableDropdownContent>
    </TableDropdown>
  );
};

export default TableButtonDropdown;
