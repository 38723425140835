import styled from 'styled-components';

const ListTransition = styled.div`
  .content-enter {
    max-height: 0;
    overflow: hidden;
  }

  .content-enter-active {
    max-height: 400px;
    transition: max-height 0.2s ease-in;
  }

  .content-exit {
    max-height: 400px;
  }

  .content-exit-active {
    max-height: 0;
    transition: max-height 0.2s ease-out;
    overflow: hidden;
  }
`;

export default ListTransition;
