export const getSecretFormat = secret => {
  if (secret['type'] === 'password' && secret['username'] === undefined) return getFormat('userPass')(secret);
  if (secret['type'] === 'note') return getFormat('noteOnly')(secret);
  if (secret['type'] === 'address') return getFormat('addressFull')(secret);
  if (secret['CountryCode'] !== undefined) return getFormat('badFormatNumber')(secret);
  if (secret['type'] === 'telephone') return getFormat('telephoneNum')(secret);
  return getFormat('userWithNote')(secret);
};

const getFormat = key => keyFormatPair[key] || _userPass;

const _userPass = secret => ({
  password: secret['Password'],
  username: secret['Username'],
});

const _noteOnly = secret => ({
  note: secret['note'],
});

const _addressFull = secret => ({
  addressOne: secret['addressOne'],
  addressTwo: secret['addressTwo'],
  addressThree: secret['addressThree'],
  addressFour: secret['addressFour'],
  postalCode: secret['postalCode'],
  country: secret['country'],
  note: secret['note'],
});

const _badFormatNumber = secret => ({
  country: secret['CountryCode'],
  number: secret['Number'],
  extension: secret['Extension'],
  note: secret['Note'],
});

const _telephoneNum = secret => ({
  country: secret['country'],
  number: secret['number'],
  extension: secret['extension'],
  note: secret['note'],
});

const _userWithNote = secret => ({
  password: secret['password'],
  username: secret['username'],
  url: secret['url'],
  note: secret['note'],
});

const keyFormatPair = {
  userPass: _userPass,
  noteOnly: _noteOnly,
  addressFull: _addressFull,
  badFormatNumber: _badFormatNumber,
  telephoneNum: _telephoneNum,
  userWithNote: _userWithNote,
};
