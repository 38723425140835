import React, { useState, Fragment, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Mousetrap from 'mousetrap';
import { AdminChestsWrap } from '../adminChests/styles/AdminChestsWrap';
import { AdminChestAddWrap, FakeArea } from '../adminChests/styles/AdminChestAddStyles';
import { SaveFooter } from '../adminFooter/footers/SaveFooter';
import { UserForm } from '../../forms/UserForm';
import { CreateUser, UserTableData } from '../../../api/users/UserApiFunctions';
import { TeamTableData } from '../../../api/teams/TeamApiFunctions';
import BaseApi from '../../../api/BaseApi';
import { getValidation } from '../../../utils/validationSuite/validationIndex';
import { useNotification } from '../../../common/notification/Notification';
import LogoutRedirect from '../../../common/nav/LogoutRedirect';
import SecretKeyApi from '../../../api/secretKeys/SecretKeyApi';
import { ReshareSecret } from '../../../utils/secretFunctions';
import {
  LoadingFormArea,
  LoadingFormImage,
  LoadingFormText,
  LoadingFakeArea,
  LoadingIconArea,
  LoadingBackButton,
  LoadingOrderedList,
  LoadingListItem,
} from '../../../common/forms/styles/Loading';
import { Icon } from '../../../common/Icon';
import { AdminContext } from '../../../context/AdminContext';
import { generatePassword, checkActiveElement } from '../../../utils/commonFunctions';
import loadingIcon from '../../../images/loading_01-1.gif';
import { AdminUserFormWrap } from './styles/AdminUserWrapStyles';
import { UserContext } from '../../../context/UserProvider';

/* Component for handling the admin process of inviting the user
  to the system, updating permissions and resharing secrets */
export const AdminUserAdd = () => {
  const [teams, setTeams] = useState([]);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [admin, setAdmin] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState('FORM');
  const [loadingText, setLoadingText] = useState('');
  const [defaultTeams, setDefaultTeams] = useState();
  const [password, setPassword] = useState('');
  const setTeamTags = useContext(AdminContext).teamTags[1];
  const { setNotificationText } = useNotification();
  const history = useHistory();
  const { addNewUser, passedTeamId } = history.location;
  const { user } = useContext(UserContext);

  // useEffect for setting the team information for the form.
  useEffect(() => {
    addNewUser && changeStateToDefault();
    TeamTableData({ everything: true }).then(result => {
      if (result) {
        const defaultTeamObject = result.filter(team => team.default === true);
        const additionalTeams = result.filter(team => team.default === false);
        setDefaultTeams(defaultTeamObject);
        const teamsArray = additionalTeams.map(team => {
          return {
            id: team.id,
            name: team.name,
            added: team.id === passedTeamId ? true : false,
            selected: team.id === passedTeamId ? true : false,
            userCount: team.users,
          };
        });
        setTeams(teamsArray);
      } else {
        setRedirect(true);
      }
    });
    return;
  }, [addNewUser]);

  // useEffect for keyboard shortcuts
  useEffect(() => {
    Mousetrap.bind('shift+s', shiftS);
    Mousetrap.bind('esc', esc);
    Mousetrap.bind('enter', enterSC);
    return () => {
      Mousetrap.unbind('shift+s');
      Mousetrap.unbind('esc');
      Mousetrap.unbind('enter');
    };
  });
  const changeStateToDefault = () => {
    setLoading('FORM');
    setUserName('');
    setUserEmail('');
    setTeams([]);
    setLoadingText('');
    setPassword('');
  };
  const enterSC = () => {
    if (document.activeElement === document.getElementsByClassName('footer-button')[0]) {
      createUser();
    } else if (document.activeElement === document.getElementsByClassName('footer-button')[1]) {
      history.goBack();
    }
  };
  const shiftS = () => {
    return checkActiveElement('user-name-input') || checkActiveElement('user-email-input') ? null : createUser();
  };
  const esc = () => {
    history.goBack();
  };
  // Function that handles the creation when save is clicked.
  const createUser = () => {
    const validationId = 'UserForm';
    const addedTeams = teams.filter(team => team.added === true);
    const params = {
      data: { userName, userEmail, admin, addedTeams },
      setNotificationText,
    };
    if (getValidation(validationId)(params)) {
      const teamData = addedTeams.map(team => ({ team_id: team.id }));
      const newPassword = generatePassword(true, true, true, true, 12);
      setPassword(newPassword);
      setLoadingText(`Adding ${userName} to Recrypt...`);
      setLoading('CREATE');
      const data = {
        name: userName,
        email: userEmail,
        password: newPassword,
      };
      CreateUser(data)
        // This is required whilst mailgun sandbox returns a 500, else newUser can be replaced with response
        .then(async result => {
          if (result === 'Users exceed subscription limit.') {
            setLoading('FORM');
            setNotificationText({ type: 'error', text: [result] });
          } else if (result === 'Email has already been taken') {
            setLoading('FORM');
            setNotificationText({ type: 'error', text: [result] });
          } else {
            await addMemberships(teamData, result.id);
            await reshareData(result);
            setTeamTags(await TeamTableData({ everything: true }));
            setLoading('FINISH');
            setLoadingText(`${userName} has been added to recrypt`);
          }
        });
    }
  };

  /* Function for adding users to a team, and setting permissions. */
  const addMemberships = async (teamData, id) => {
    //FOR WHEN MAILGUN 500 -- TAKE OUT WHEN MAILGUN EXPANDED
    if (id === undefined) {
      const users = await UserTableData({ everything: true });
      const newUser = users.filter(user => user.email === userEmail)[0];
      id = newUser.id;
      defaultTeams.forEach(team => {
        teamData.push({ team_id: team.id });
      });
      await BaseApi.update('users', id, {
        memberships_attributes: teamData,
        administrator: admin,
      });
      //--------------------------------------------------------
    } else {
      if (teamData.length !== 0) {
        await BaseApi.update('users', id, {
          memberships_attributes: teamData,
          administrator: admin,
        });
        defaultTeams.forEach(team => {
          teamData.push({ team_id: team.id });
        });
      } else {
        await BaseApi.update('users', id, {
          administrator: admin,
        });
      }
    }
  };

  /* Function for resharing secrets to the new user */
  const reshareData = async user => {
    const reshareParams = {
      id: user.id,
    };
    const reshareInfo = await SecretKeyApi.reshareUser(reshareParams);
    if (reshareInfo.keyshare !== null) {
      setLoadingText(`Granting Access To Secrets...`);
      const reshareJson = await ReshareSecret(reshareInfo.keyshare);
      await SecretKeyApi.reshare(reshareJson);
    }
  };

  // Component for the form view.
  const renderForm = () => (
    <Fragment>
      <AdminUserFormWrap className="form-footer team-form">
        <AdminChestAddWrap>
          <UserForm
            edit={true}
            userName={userName}
            setUserName={setUserName}
            userEmail={userEmail}
            setUserEmail={setUserEmail}
            admin={admin}
            setAdmin={setAdmin}
            teams={teams}
            setTeams={setTeams}
            user={user}
          />
          <SaveFooter handleSubmit={createUser} />
        </AdminChestAddWrap>
      </AdminUserFormWrap>
      <FakeArea />
    </Fragment>
  );

  // Component for the loading and on create success view.
  const renderCreate = () => (
    <Fragment>
      <LoadingFormArea role={'loading-form-area'}>
        {loading === 'CREATE' ? (
          <LoadingFormImage src={loadingIcon} alt="Loading" />
        ) : (
          <LoadingIconArea>
            <Icon name="asterisk" width="75px" />
          </LoadingIconArea>
        )}
        <LoadingFormText>{loadingText}</LoadingFormText>
        {loading === 'FINISH' && (
          <Fragment>
            <LoadingFormText>
              Their Password is: <span>{password}</span>
            </LoadingFormText>
            <LoadingFormText className="instructions-text" role={'instructions-text'}>
              In order for this user to successfully set up their account, they must:
              <LoadingOrderedList>
                <LoadingListItem>
                  Log into Recrypt.app on the main login screen using their designated email and the newly generated
                  password above. Please make sure to send this to them!
                </LoadingListItem>
                <LoadingListItem>
                  Follow the onboarding process by setting a new personal password and setting up 2FA (if applicable).
                </LoadingListItem>
              </LoadingOrderedList>
            </LoadingFormText>
          </Fragment>
        )}
        {loading === 'FINISH' && (
          <LoadingBackButton
            onClick={() =>
              history.push({
                pathname: '/admin/teams',
                prevPathname: history.location.pathname,
              })
            }
          >
            Back to Teams
          </LoadingBackButton>
        )}
      </LoadingFormArea>
      <LoadingFakeArea />
    </Fragment>
  );

  if (redirect) return <LogoutRedirect />;
  return <Fragment>{loading === 'FORM' ? renderForm() : renderCreate()}</Fragment>;
};
