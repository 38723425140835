import React from 'react';
import SettingsHeader from './headers/SettingsHeader';
import UserShowHeader from './headers/UserShowHeader';
import { EditHeader } from './headers/EditHeader';
import { CreateHeader } from './headers/CreateHeader';

export const getHeaderComponent = url => urlHeaderPairs[url] || urlHeaderPairs['default'];

const _settingsHeader = () => <SettingsHeader />;
const _userShowHeader = () => <UserShowHeader />;
const _editHeader = () => <EditHeader />;
const _createHeader = () => <CreateHeader />;

const urlHeaderPairs = {
  '/admin/settings': _settingsHeader,
  '/admin/teams/user': _userShowHeader,
  '/admin/chests/secret/new': _createHeader,
  '/admin/chests/secret/edit': _editHeader,
  '/admin/chests/edit': _editHeader,
  '/admin/chests/new': _createHeader,
  '/admin/teams/new': _createHeader,
  '/admin/teams/edit': _editHeader,
  '/admin/teams/user/new': _createHeader,
  '/admin/teams/user/edit': _editHeader,
  default: null,
};
