import styled from 'styled-components';
import Advert from '../../../../images/plan_image.png';

export const Wrap = styled.div`
  background-color: ${props => props.theme.fieldBgColor};
  margin: 1rem 0 0 0;
  padding: 4rem 2rem 2rem 2rem;
  border-radius: 4px;
  position: relative;
  &::before {
    content: '';
    background: url(${Advert});
    background-size: cover;
    width: 208px;
    height: 208px;
    display: block;
    position: absolute;
    top: 0;
    right: 0;

    @media (-webkit-device-pixel-ratio: 1.5) {
      width: 130px;
      height: 130px;
    }
  }
  @media screen and (min-width: 767px) and (max-width: 1919px) {
    padding-top: 0;
    &::before {
      position: relative;
      width: 100%;
      height: 200px;
      margin-bottom: 1rem;
      margin-left: 2rem;
    }
  }
`;

export const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: ${props => props.theme.textColor};
`;

export const SmallBold = styled.div`
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
`;

export const PlanTitle = styled.div`
  font-size: 2rem;
  font-weight: bolder;
  margin: 0.75rem 0 1.25rem 0;
`;

export const FeatureWrap = styled.div`
  margin: 0 0 0.6rem 0;
`;

export const FeatureText = styled.div`
  margin: 0 0 0.75rem 0;
  font-size: 1rem;
`;

export const GetStartedButton = styled.div`
  padding: 0.75rem;
  text-align: center;
  border-radius: 5px;
  border: 1px solid ${props => props.theme.textColor};
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-weight: 700;
  margin-top: 1rem;

  &:hover {
    background-color: ${props => props.theme.highlightColor};
    color: ${props => props.theme.enabledButtonColor};
    border-color: transparent;
  }
`;
