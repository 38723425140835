import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { InfoModal } from './styles/InfoModal';
import { Icon } from '../Icon';
import { ModalTable, ModalTitle, ModalText } from './styles/ShortcutsModalStyles';
import { getShortcuts } from '../../utils/keyboardShortcutsMap';
import { UserContext } from '../../context/UserProvider';

export const ShortcutsModal = () => {
  const { pathname } = useLocation();
  const { user } = useContext(UserContext);
  const { administrator } = user;

  const renderShortcutInfo = () => {
    let shortcutsArray = administrator ? getShortcuts(pathname)(administrator) : getShortcuts(pathname)();

    return shortcutsArray
      .filter(sc => sc !== undefined)
      .map((obj, i) => {
        return (
          <tr key={i}>
            <ModalText role={'modal-text'}>{obj.text}</ModalText>
            <ModalText>
              {obj.modifier && obj.modifier}
              {(obj.icon && obj.icon === 'arrows') || obj.icon === 'esc' || obj.icon === 'tab' ? (
                <Icon name={obj.icon} width="46px" />
              ) : (
                <Icon name={obj.icon} width="18px" />
              )}
              {obj.iconTwo && 'or'}
              {obj.iconTwo && <Icon name={obj.iconTwo} width="18px" />}
            </ModalText>
          </tr>
        );
      });
  };

  return (
    <InfoModal role={'shortcuts-modal'}>
      <ModalTable>
        <ModalTitle>Keyboard Shortcuts</ModalTitle>
        <tbody>{renderShortcutInfo()}</tbody>
      </ModalTable>
    </InfoModal>
  );
};
