import React, { useState } from 'react';

export const OptionsModalContext = React.createContext([{}, () => null]);

export const OptionsModalProvider = props => {
  const [optionsVisible, setOptions] = useState('');

  return (
    <OptionsModalContext.Provider value={[optionsVisible, setOptions]}>{props.children}</OptionsModalContext.Provider>
  );
};
