import styled from 'styled-components';

export const SettingsNavContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
`;

export const SettingsNavBar = styled.div`
  display: flex;
  flex: 1;
`;

export const SettingsList = styled.ul`
  flex: 1;
  list-style: none;
  padding-left: 0;
  margin: 0 auto;
  .active {
    background-color: ${props => props.theme.settingsSelectColor};
    color: ${props => props.theme.hoverRowColor};
  }
`;

export const ListElement = styled.li`
  color: ${props => props.theme.settingsTextColor};
  font-size: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.25rem;
  cursor: pointer;
  border-left: 3px solid transparent;
  transition: all 0.3s ease-in-out;
  outline: none;

  &:hover {
    border-left-color: ${props => props.theme.settingsSelectColor};
    opacity: 0.7;
  }
`;
