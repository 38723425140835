import styled from 'styled-components';

export const TwoStepWrap = styled.div`
  display: flex;
  flex: 12;
  flex-direction: column;
  align-items: center;
`;

export const TwoStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5vh 0 0 0;
  padding: 1rem 3rem 0rem 3rem;
  width: 20vw;
  border-radius: 0.2rem;
  background-color: ${props => props.theme.tableBgColor};
  color: ${props => props.theme.textColor};
  overflow-y: auto;

  @media screen and (max-width: 1919px) {
    margin-top: 0;
    width: 100%;
    max-width: 385px;
    padding-top: 0;
    box-sizing: border-box;
  }

  &.success {
    background-color: #40dae1;
  }

  &.recover {
    margin: 0 0 0 0;
  }

  .bold {
    font-weight: 700;
    margin-bottom: 5px;
    display: inline-block;

    + span {
      display: block;
      margin-bottom: 0.5rem;
    }
  }
`;
