import styled from 'styled-components';

export const LoadingFormArea = styled.div`
  display: flex;
  flex: 8;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: inherit;
`;

export const LoadingFormImage = styled.img`
  height: 7.5rem;
  width: 7.5rem;
`;

export const LoadingFormText = styled.span`
  color: ${props => props.theme.textColor};
  font-size: 1.5rem;

  span {
    line-height: 1rem;
    background-color: ${props => props.theme.bgColor};
  }

  &.instructions-text {
    margin-top: 1rem;
    padding: 0 1rem;
  }
  @media screen and (min-width: 1024px) {
    &.instructions-text {
      padding: 0 5rem;
    }
  }
  @media screen and (min-width: 1440px) {
    &.instructions-text {
      padding: 0 10rem;
      font-size: 1.25rem;
    }
  }
`;

export const LoadingBackButton = styled.button`
  background-color: ${props => props.theme.tableBgColor};
  color: ${props => props.theme.textColor};
  font-size: 1rem;
  font-style: bold;
  border: 1px solid ${props => props.theme.textColor};
  line-height: 2rem;
  cursor: pointer;
  margin-top: 1rem;

  &:hover {
    background-color: ${props => props.theme.highlightColor};
    color: #000000;
    border-color: ${props => props.theme.highlightColor};
  }
`;

export const LoadingFakeArea = styled.div`
  background-color: ${props => props.theme.bgColor};
  flex: 4;
`;

export const LoadingIconArea = styled.div`
  margin-bottom: 1rem;
`;

export const LoadingOrderedList = styled.ol`
  margin-top: 0.5rem;
`;

export const LoadingListItem = styled.li`
  &:first-of-type {
    margin-bottom: 0.5rem;
  }
`;
