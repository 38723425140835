import styled from 'styled-components';

export const AdminPage = styled.div`
  display: flex;
  width: 100vw;
  box-sizing: border-box;
  padding: 0 2rem;
  overflow-y: auto;
  overflow-x: hidden;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    padding: 0 2.5%;
    width: 100%;
    height: auto;
    min-height: 75vh;
  }
`;

export const AdminSideBar = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 23%;

  > div {
    transform: translate(0, 0);
    opacity: 0;
    &.side-enter-done {
      opacity: 1;
    }
  }

  .side-enter-done {
    animation: sideenter 0.15s forwards 1;
  }

  @keyframes sideenter {
    0% {
      transform: translate(-75%, 0%);
      opacity: 0;
    }
    100% {
      transform: translate(0, 0);
      opacity: 1;
    }
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: auto;
    margin-bottom: 3rem;
  }
`;

export const AdminMainArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 77%;
  height: 75vh;

  @media screen and (max-width: 767px) {
    width: 100%;
    height: auto;
  }
`;
