import ApiRequest from '../ApiRequest';

class StripeApi {
  static getSubscription() {
    return ApiRequest('GET', 'api/v1/billing_api/subscriptions');
  }

  static createSubscription(paymentMethod) {
    const params = {
      stripeId: paymentMethod['id'],
      coupon: paymentMethod['coupon'],
    };
    return ApiRequest('POST', 'api/v1/billing_api/subscriptions', params);
  }

  static updateSubscription(stripeDetails) {
    const params = {
      stripeDetails,
    };
    return ApiRequest('PATCH', 'api/v1/billing_api/subscriptions', params);
  }

  static deleteSubscription() {
    return ApiRequest('DELETE', 'api/v1/billing_api/subscriptions');
  }

  static promotionUpdate(params) {
    return ApiRequest('POST', 'api/v1/billing_api/subscriptions/promotion_update', params);
  }

  static createTrial() {
    return ApiRequest('POST', 'api/v1/billing_api/subscriptions');
  }

  static getCustomer() {
    return ApiRequest('GET', 'api/v1/billing_api/customers');
  }

  static getInvoices() {
    return ApiRequest('GET', 'api/v1/billing_api/invoices');
  }

  static getPaymentMethod() {
    return ApiRequest('POST', 'api/v1/billing_api/customers/payment_method');
  }

  static getPlans() {
    return ApiRequest('GET', 'api/v1/billing_api/prices');
  }

  static updatePaymentMethod(paymentMethod) {
    const params = {
      stripeId: paymentMethod['id'],
    };
    return ApiRequest('POST', 'api/v1/billing_api/customers/update_payment_method', params);
  }

  static destroyPaymentMethods() {
    return ApiRequest('POST', 'api/v1/billing_api/customers/destroy_payment_method');
  }
}

export default StripeApi;
