import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { OptionsModalContext } from '../../../../../context/OptionsModalContext';
import { IconArea, MenuOptions, DropDownOption } from './styles/BillingAccountsActionsStyles';
import { ModalContext } from '../../../../../context';
import { DeleteUser } from '../../../../../api/users/UserApiFunctions';
import { UserContext } from '../../../../../context/UserProvider';
import ClickAwayListener from 'react-click-away-listener';

export const BillingAccountsActions = ({ row, params }) => {
  const { setModal } = useContext(ModalContext);
  const { setLoading } = params;
  const [optionsVisible, setOptions] = useContext(OptionsModalContext);
  const { user } = useContext(UserContext);
  const history = useHistory();

  const menuOptions = () => {
    // P4SS-1597: prevent user deleting themselves or the owner
    if (row.id === user.owner_id || row.id === user.id) {
      return [{ text: 'User Details', handleClick: () => userDetails() }];
    } else {
      return [
        { text: 'User Details', handleClick: () => userDetails() },
        { text: 'Remove User', handleClick: () => deletePopUp() },
      ];
    }
  };

  const userDetails = () => {
    history.push({
      pathname: '/admin/teams/user',
      row,
      prevPathname: history.location.pathname,
    });
    setOptions({});
  };

  const handleDeleteModalConfirm = () => {
    DeleteUser(row.id).then(() => {
      setModal();
      setLoading(true);
    });
  };

  const deletePopUp = () =>
    setModal({
      key: 'Confirm',
      params: {
        icon: 'alert',
        question: `Are you sure you want to delete ${row['name']}?`,
        subtext: 'This user will not be recoverable.',
        confirmText: `Yes, delete ${row['name']}`,
        cancelText: `No, keep ${row['name']}`,
        confirmAction: handleDeleteModalConfirm,
        cancelAction: () => setModal(),
      },
    });

  const handleOptions = (row, e) => {
    optionsVisible.row !== row.id ? setOptions({ row: row.id, above: calculateOptionsPosition(e) }) : setOptions({});
  };

  const calculateOptionsPosition = e => {
    return e.clientY > window.innerHeight * 0.7 || window.innerHeight - e.clientY < 270 ? true : false;
  };

  const returnOptions = options => {
    return options.map(option => (
      <DropDownOption className="dd-option" key={option.text} onClick={() => option.handleClick()}>
        {option.text}
      </DropDownOption>
    ));
  };

  return (
    <div style={{ position: 'relative' }}>
      <IconArea role={'Icon'} onClick={e => handleOptions(row, e)} className="icon-area billing-actions">
        <i className="fas fa-bars"></i>
      </IconArea>
      {optionsVisible.row === row.id && (
        <ClickAwayListener onClickAway={() => setOptions({})}>
          <MenuOptions role={'menu-options'} className={optionsVisible.above && 'above'}>
            {returnOptions(menuOptions())}
          </MenuOptions>
        </ClickAwayListener>
      )}
    </div>
  );
};
