import React from 'react';
import UserActiveLogWrap from './styles/UserActiveLogWrap';

const UserActiveLogHeader = () => {
  return (
    <UserActiveLogWrap>
      <div>User Activity Log</div>
    </UserActiveLogWrap>
  );
};

export default UserActiveLogHeader;
