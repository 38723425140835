import styled from 'styled-components';

export const LoginBody = styled.div`
  margin: 0;
`;

export const LoginButton = styled.button`
  font-family: 'Lato';
  font-size: 1rem;
  background-color: #98a2bb;
  font-weight: 400;
  color: #ffffff;
  position: relative;
  border-radius: 5px;
  outline: none;
  padding: 1rem 1.25rem;
  border: 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &.reverse {
    background-color: transparent;
    margin: 0.5rem 0 -1rem 0;
  }

  &.active {
    background-color: ${({ theme }) => theme.blueText};
  }

  &:active {
    background-color: ${props => props.theme.blueText};
  }
`;

export const LoginContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.bgColor};
  color: white;
  height: 100vh;
  position: relative;

  .logo {
    margin-bottom: 4rem;

    @media (-webkit-device-pixel-ratio: 1.5) {
      width: 175px;
    }
  }
  .login-copyright {
    position: absolute;
    bottom: 1rem;
    color: ${props => props.theme.textColor};
    font-size: 0.9rem;
    opacity: 0.5;
  }
`;

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const LoginFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  background-color: ${props => props.theme.tableBgColor};
  padding: 3rem;
  box-sizing: border-box;
  border-radius: 0.3rem;

  @media (-webkit-device-pixel-ratio: 1.5) {
    max-width: 35rem;
  }
`;

export const LoginText = styled.span`
  font-size: 1rem;
  line-height: 140%;
  padding: 0 0 2rem 0;
  color: ${props => props.theme.textColor};

  .link {
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.highlightColor};
    }
  }

  > span {
    font-weight: bold;
    text-decoration: underline;
  }
`;

export const LoginTitle = styled.div`
  font-size: 1.6rem;
  margin: 0 0 2rem 0;
  color: ${props => props.theme.textColor};
`;

export const RegisterLinkArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1.5rem;
  margin-bottom: -0.5rem;
  color: ${props => props.theme.textColor};
  text-align: center;

  span {
    &.forgot-pass {
      margin: 1rem 0 0 0;
    }
  }
`;

export const RegisterLink = styled.span`
  border-bottom: 1px solid;
  transition: all 0.3s ease-in-out;
  &:hover {
    cursor: pointer;
    color: #40dae1;
  }
`;
