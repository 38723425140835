import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { SectionHeading, SectionHR } from '../../admin/adminSettings/billing/styles/BillingStyles';
import {
  PaymentForm,
  DetailsWrapper,
  Input,
  Label,
  HalfWrapper,
  QuarterWrapper,
  FormBreak,
  Button,
  CardElementWrap,
} from '../../../components/admin/adminSettings/subComponents/styles/FormStyles';
import { useNotification } from '../../../common/notification/Notification';
import { getValidation } from '../../../utils/validationSuite/validationIndex';
import StripeApi from '../../../api/stripe/StripeApi';
import GenericLoading from '../../../common/genericLoading/GenericLoading';
import PlanPaymentHeader from '../../plans/subComponents/PlanPaymentHeader';
import { NewDropdown } from '../../../common/forms/NewDropdown';
import { StripeCountryList } from './StripeCountryList';

const InjectedPaymentForm = ({ setView, data, formContext, planFlag }) => {
  const [loading, setLoading] = useState(false);
  const [dropdownVisibility, setDropdownVisibility] = useState(false);
  const [countrySelected, setCountrySelected] = useState({
    id: 'blank',
    value: '',
    label: '',
  });
  const { setNotificationText } = useNotification();
  const theme = useContext(ThemeContext);
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();

  const handlePayment = async e => {
    e.preventDefault();
    if (!stripe || !elements) return;

    const params = {
      data: details,
      setNotificationText,
    };
    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        address: {
          city: details.city || null,
          country: details.country || null,
          line1: details.line1 || null,
          line2: details.line2 || null,
        },
        name: details.fullName || null,
      },
    });

    if (error) setNotificationText({ type: 'error', text: [error['message']] });
    if (!error && getValidation('paymentForm')(params)) {
      setLoading(true);
      if (formContext === 'Pay') return paymentSubmit(paymentMethod);
      updateSubmit(paymentMethod);
    }
  };

  const paymentSubmit = pm =>
    StripeApi.createSubscription(Object.assign(pm, { coupon: details['coupon'] })).then(res => {
      if (typeof res === 'string') {
        setLoading(false);
        setNotificationText({ type: 'error', text: `${res}` });
      }
      if (typeof res === 'object') planFlag ? history.push('/admin/settings', { fromPlans: true }) : setView('summary');
    });

  const updateSubmit = pm =>
    StripeApi.updatePaymentMethod(pm).then(() => {
      setNotificationText('Billing information has been successfully updated');
      planFlag ? history.push('/admin/settings', { fromPlans: true }) : setView('summary');
    });

  const [details, setDetails] = useState({
    fullName: data ? data.name : '',
    line1: data ? data['address'].line1 : '',
    line2: data ? data['address'].line2 : '',
    city: data ? data['address'].city : '',
    country: data ? data['address'].country : '',
    coupon: '',
  });

  const cardOptions = {
    style: {
      base: {
        color: `${theme.textColor}`,
        '::placeholder': {
          color: `${theme.textColor}`,
        },
      },
    },
  };

  const handleDDChange = (option, key) => {
    updateField(key, option.value);
    setCountrySelected(option);
  };

  // This sets the dropdown to the correct value if card details exist
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (details.country) {
        const detailsCountry = StripeCountryList.filter(country => country.value === details.country)[0];
        setCountrySelected(detailsCountry);
      }
    }
    return () => (isMounted = false);
  }, [details.country]);

  const updateField = (field, val) => {
    setDetails({ ...details, [field]: val });
  };

  if (loading) return <GenericLoading />;

  return (
    <PaymentForm>
      {planFlag ? <PlanPaymentHeader setView={setView} /> : null}
      <DetailsWrapper>
        <HalfWrapper>
          <Label>Card Details</Label>
          <CardElementWrap>
            <CardElement options={cardOptions} />
          </CardElementWrap>
        </HalfWrapper>
      </DetailsWrapper>
      <FormBreak>
        <SectionHeading>Billing Address</SectionHeading>
        <SectionHR />
      </FormBreak>
      <DetailsWrapper>
        <HalfWrapper>
          <Label>Full Name</Label>
          <Input
            role={'injected-payment-form-full-name-input'}
            placeholder="Enter full name"
            onChange={e => updateField('fullName', e.target.value)}
            value={details.fullName}
          />
        </HalfWrapper>
        <HalfWrapper>
          <Label>Address Line 1</Label>
          <Input
            role={'injected-payment-form-address-1-input'}
            placeholder="Enter first line of address"
            onChange={e => updateField('line1', e.target.value)}
            value={details.line1}
          />
        </HalfWrapper>
        <HalfWrapper>
          <Label>Address Line 2</Label>
          <Input
            role={'injected-payment-form-address-2-input'}
            placeholder="Enter second line of address"
            onChange={e => updateField('line2', e.target.value)}
            value={details.line2}
          />
        </HalfWrapper>
        <HalfWrapper>
          <Label>City</Label>
          <Input
            role={'injected-payment-form-city-input'}
            placeholder="Enter City"
            onChange={e => updateField('city', e.target.value)}
            value={details.city}
          />
        </HalfWrapper>
        <HalfWrapper>
          <Label>Country</Label>
          <NewDropdown
            label="Country"
            dropdownOptions={StripeCountryList}
            dropdownVisibility={dropdownVisibility}
            setDropdownVisibility={setDropdownVisibility}
            handleDropdownChange={option => handleDDChange(option, 'country')}
            optionSelected={countrySelected}
            id="payment-country-dropdown"
            className="country-code"
            hideLegend
          />
        </HalfWrapper>
        {formContext === 'Pay' && (
          <QuarterWrapper>
            <Label>Promo Code (Optional)</Label>
            <Input
              placeholder="Enter Code"
              onChange={e => updateField('coupon', e.target.value)}
              value={details.coupon}
            />
          </QuarterWrapper>
        )}
        <QuarterWrapper>
          <Label className="hidden-label">Update</Label>
          <Button role={'injected-payment-form-pay-button'} onClick={e => handlePayment(e)}>
            {formContext === 'Pay' ? 'Pay' : 'Update'}
          </Button>
        </QuarterWrapper>
      </DetailsWrapper>
    </PaymentForm>
  );
};

export default InjectedPaymentForm;
