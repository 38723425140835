import styled from 'styled-components';

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.tableBgColor};
  height: fit-content;
  width: 50%;
  margin: 0 auto;
  color: ${props => props.theme.textColor};
`;

export const ProfileHeader = styled.div`
  padding-top: 1.25rem;
  padding-right: 0.05rem;

  hr {
    margin-top: 1rem;
    width: 100%;
    color: ${props => props.theme.textColor};
  }
`;

export const ProfileHeadText = styled.span`
  padding-left: 1.5rem;
  font-size: 1.6rem;
  font-weight: 500;

  svg {
    padding-right: 1rem;
  }
`;

export const ProfileBody = styled.div`
  margin: 1rem 3rem 1rem 3rem;

  hr {
    width: 100%;
    color: ${props => props.theme.textColor};
  }
`;

export const ProfileFooter = styled.div`
  background-color: ${props => props.theme.formFooterBgColor};
`;
