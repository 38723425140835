import React, { useState } from 'react';
import { RightContainer } from './styles/LayoutStyles';
import { InfoBox } from './styles/ComponentStyles';
import { InitialDetails } from './InitialDetails';
import { PasswordCreation } from './PasswordCreation';
import { EmailConfirmation } from './EmailConfirmation';

export const RightScreen = ({ invitedUser }) => {
  const [display, setDisplay] = useState('initial');
  const [data, setData] = useState({
    companyName: invitedUser ? invitedUser.companyName : '',
    userName: invitedUser ? invitedUser.userName : '',
    userEmail: invitedUser ? invitedUser.userEmail : '',
    password: '',
    invited: invitedUser ? true : false,
    invitedEmail: invitedUser ? invitedUser.invitedEmail : null,
    termsOfService: false,
    marketingOptIn: false,
  });

  const renderDisplay = () => {
    if (display === 'initial') return <InitialDetails setDisplay={setDisplay} setData={setData} data={data} />;
    if (display === 'password') return <PasswordCreation setDisplay={setDisplay} setData={setData} data={data} />;
    if (display === 'emailconfirmation') return <EmailConfirmation email={data.userEmail} />;
  };

  return (
    <RightContainer>
      <InfoBox>{renderDisplay()}</InfoBox>
      <div></div>
    </RightContainer>
  );
};
