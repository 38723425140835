import React, { Fragment, useState } from 'react';
import LoginInput from '../../components/login/LoginInput';
import { LoginInputLegend, LoginInputWrap } from '../../components/login/styles/LoginInputStyles';
import { LoginBody, LoginButton, LoginForm, LoginText, LoginTitle } from '../styles/LoginStyles';

export const getForm = key => _keyValuePair[key];

const _login = ({ loginDetails, handleChange, getLogin }) => {
  const { email, password } = loginDetails;
  const enableForm = !!email + !!password === 2; // Because true plus true evaluate to 2

  return (
    <LoginBody>
      <LoginForm role={'login-form'} onSubmit={getLogin}>
        <LoginInputWrap role={'login-input-wrap'} className={email && 'active'}>
          <LoginInputLegend>Email Address</LoginInputLegend>
          <LoginInput handleChange={handleChange} mode="email" required autoFocus />
        </LoginInputWrap>
        <LoginInputWrap role={'login-input-wrap'} className={password && 'active'}>
          <LoginInputLegend>Password</LoginInputLegend>
          <LoginInput handleChange={handleChange} mode="password" required />
        </LoginInputWrap>
        <LoginButton type="submit" disabled={!enableForm} className={enableForm ? 'active' : ''}>
          Login
        </LoginButton>
      </LoginForm>
    </LoginBody>
  );
};

const _twoFactor = ({
  loginDetails,
  handleChange,
  getLogin,
  setForm,
  verifyButtonDisabled,
  setVerifyButtonDisabled,
}) => {
  const { otp_attempt } = loginDetails;

  return (
    <Fragment>
      <LoginTitle>Two-factor verification</LoginTitle>
      <LoginBody>
        <LoginForm
          onSubmit={event => {
            !verifyButtonDisabled && getLogin(event);
            setVerifyButtonDisabled(true);
          }}
        >
          <LoginText>
            Open the two-step verification app on your mobile device to get your verification code. Don't have access to
            your mobile device? Enter a recovery code{' '}
            <span role={'recovery-code-button'} className={'link'} onClick={() => setForm('Recovery')}>
              here.
            </span>
          </LoginText>
          <LoginInputWrap role={'login-input-wrap'} className={otp_attempt && 'active'}>
            <LoginInputLegend>Otp Code</LoginInputLegend>
            <LoginInput
              handleChange={event => {
                setVerifyButtonDisabled(false);
                handleChange(event, 'otp_attempt');
              }}
              mode="otp_attempt"
              required
              autoFocus
            />
          </LoginInputWrap>
          <LoginButton
            role={'verify-button'}
            type="submit"
            disabled={verifyButtonDisabled}
            className={verifyButtonDisabled ? '' : 'active'}
          >
            Verify
          </LoginButton>
        </LoginForm>
      </LoginBody>
    </Fragment>
  );
};

const _recovery = ({ loginDetails, handleChange, getLogin, setForm }) => (
  <Fragment>
    <LoginTitle>Bypass two-step verification</LoginTitle>
    <LoginBody>
      <LoginForm onSubmit={getLogin}>
        <LoginText>Please enter any recovery code you recieved when you setup your two-step verification.</LoginText>
        <LoginInputWrap role={'login-input-wrap'} className={loginDetails.recovery_code && 'active'}>
          <LoginInputLegend>Recovery Code</LoginInputLegend>
          <LoginInput handleChange={handleChange} mode="otp_attempt" required autoFocus />
        </LoginInputWrap>
        <LoginButton type="submit">Login</LoginButton>
        <LoginButton className={'reverse'} onClick={() => setForm('2FA')}>
          Cancel
        </LoginButton>
      </LoginForm>
    </LoginBody>
  </Fragment>
);

const _keyValuePair = {
  Login: _login,
  '2FA': _twoFactor,
  Recovery: _recovery,
};
