import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { WelcomeContext } from '../../context/index';
import { WelcomeWrap } from './styles/WelcomeMenuStyles';
import WelcomeContainer from './WelcomeContainer';
import WelcomeButton from './WelcomeButton';
import { Icon } from '../../common/Icon';
import { CSSTransition } from 'react-transition-group';
import { DashboardContent } from '../../pages/styles/DashboardStyle';
import WelcomeSkip from './WelcomeSkip';
import useCycle from '../../utils/hooks/shortcuts/useCycle';
import { UserContext } from '../../context/UserProvider';

const WelcomeMenu = () => {
  const { user } = useContext(UserContext);
  const { administrator } = user;
  const history = useHistory();
  const { welcome, setWelcome } = useContext(WelcomeContext);

  const exploreIcon = <Icon name="asterisk" width="25px" />;
  const twoFactorIcon = <Icon name="security" width="25px" />;
  const addSecretIcon = <Icon name="addvertwo" width="25px" />;

  useEffect(() => {
    if (!administrator && welcome.password && welcome.twofactor && !welcome.tutorial) {
      history.push('/welcome/tutorial');
    }
  }, [administrator, welcome, history]);

  useCycle('tutorialButton');

  const changeSetup = stage => {
    if (stage === '') {
      setWelcome({
        tutorial: true,
        twofactor: true,
        secret: true,
      });
      history.push('/');
    } else {
      history.push(stage);
    }
  };
  return (
    <WelcomeWrap>
      <DashboardContent role={'WelcomeMenu'}>
        <CSSTransition in timeout={500} classNames="dashboard" appear>
          <WelcomeContainer title={'Welcome'} subtext={'Just a few steps to help get you started.'}>
            {administrator && (
              <WelcomeButton
                disabled={welcome.tutorial}
                optional={true}
                icon={exploreIcon}
                text={"Let's explore Recrypt"}
                onClick={() => changeSetup('/welcome/tutorial')}
              />
            )}
            {user['must_change_password'] || !administrator ? (
              <WelcomeButton
                disabled={welcome.password}
                optional={welcome.password}
                icon={exploreIcon}
                text={'Setup a personal password'}
                onClick={() => changeSetup('/welcome/personal_password')}
              />
            ) : null}
            <WelcomeButton
              disabled={welcome.twofactor}
              optional={administrator ? true : false}
              icon={twoFactorIcon}
              text={'Setup Two-Step Verification'}
              onClick={() => changeSetup('/welcome/twofactor')}
            />
            {administrator && (
              <WelcomeButton
                disabled={welcome.secret}
                optional={false}
                icon={addSecretIcon}
                text={'Add your first Secret'}
                onClick={() => changeSetup('/welcome/first_secret')}
              />
            )}
          </WelcomeContainer>
        </CSSTransition>
        {administrator && <WelcomeSkip onClick={() => changeSetup('')}>Skip</WelcomeSkip>}
      </DashboardContent>
    </WelcomeWrap>
  );
};

export default WelcomeMenu;
