export const darkTheme = {
  scheme: 'dark',
  bgColor: '#252A41',
  hoverColor: '#FFFFFF',
  hoverRowColor: '#252A41',
  hoverRowBgColor: '#40DAE1',
  hoverButtonColor: '#252A41',
  searchBgColor: '#4C5374',
  searchBorderColor: '#4C5374',
  searchColor: '#98A2BB',
  sButtonBgColor: '#3F4663',
  tableBgColor: '#3F4663',
  tableColor: '#FFFFFF',
  tableButColor: '#98A2BB',
  textColor: '#98A2BB',
  fieldColor: '#FFFFFF',
  fieldBgColor: '#4C5374',
  fieldHoverColor: '#252A41',
  fieldBorderColor: '#98A2BB',
  placeholderColor: '#98A2BB',
  formBgColor: '#4C5374',
  formFooterBgColor: '#363C58',
  highlightColor: '#40DAE1',
  textOutlineColor: '#98A2BB',
  fieldTextColor: '#FFFFFF',
  settingsSelectColor: '#40DAE1',
  settingsTextColor: '#FFFFFF',
  settingsBgColor: '#2D304E',
  enabledButtonColor: '#252A41',
  welcomeButtonHover: '#252A41',
  blueText: '#40DAE1',
  dotHighlight: '#ffffff',
  error: '#e23b3b',
  adminSelectionColor: '#486e8a',
  tableHeaderBorder: '#4C5374',
  altTableRow: '#424967',
  themeBg: '#4C5373',
  modelLGColor: ' #D3D7DD',
  deletedSecretsDefaultBg: '#303752',
  deletedSecretsDefaultStripe: '#4C5374',
  deletedSecretsHoverBg: '#363C58',
  deletedSecretsHoverStripe: '#4C5374',
  redButtonTextColor: '#FFFFFF',
  redButtonBgColor: '#FF4136',
  scrollBarBgColor: '#464D6D',
  recryptLogoBlue: '#34DAE2',
  adminDashboardGreen: '#37C3A1',
  adminDashboardRed: '#FD3C3C',
  squaredButtonBgColor: '#4C5374',
  squaredButtonColor: '#FFFFFF',
  squaredButtonActiveColor: '#486E8A',
  changePasswordBgColor: '#3F4663',
  EULAPPLinkTextColor: '#34DAE2', //both light & dark the same until hardcoded colours are removed
};

export const lightTheme = {
  scheme: 'light',
  bgColor: '#E7E9EE',
  hoverColor: '#000000',
  hoverRowColor: '#252A41',
  hoverRowBgColor: '#40DAE1',
  hoverButtonColor: '#252A41',
  searchBgColor: '#FFFFFF',
  searchBorderColor: '#F1F2F5',
  searchColor: '#252A41',
  sButtonBgColor: '#f7f7f7',
  tableBgColor: '#FFFFFF',
  tableColor: '#070F58',
  tableButColor: '#98A2BB',
  textColor: '#6A6E7F',
  fieldColor: '#252A41',
  fieldBgColor: '#FFFFFF',
  fieldHoverColor: '#40DAE1',
  fieldBorderColor: '#98A2BB',
  placeholderColor: '#6A6E7F',
  formBgColor: '#FFFFFF',
  formFooterBgColor: '#F1F2F5',
  highlightColor: '#40DAE1',
  textOutlineColor: '#98A2BB',
  fieldTextColor: '#6A6E7F',
  settingsSelectColor: '#40DAE1',
  settingsTextColor: '#252A41',
  settingsBgColor: '#F1F2F5',
  enabledButtonColor: '#252A41',
  welcomeButtonHover: '#E7E9EE',
  blueText: '#40DAE1',
  dotHighlight: '#252A41',
  error: '#e23b3b',
  adminSelectionColor: '#4c5274',
  tableHeaderBorder: '#F1F2F5',
  altTableRow: '#fbfbfb',
  themeBg: '#6A6E7F',
  modelLGColor: ' #D3D7DD',
  deletedSecretsDefaultBg: '#DFE0E2',
  deletedSecretsDefaultStripe: '#FFFFFF',
  deletedSecretsHoverBg: '#D1D1D3',
  deletedSecretsHoverStripe: '#F1F2F5',
  redButtonTextColor: '#FFFFFF',
  redButtonBgColor: '#FF4136',
  scrollBarBgColor: '#BAC2D7',
  recryptLogoBlue: '#34DAE2',
  adminDashboardGreen: '#37C3A1',
  adminDashboardRed: '#FD3C3C',
  squaredButtonBgColor: '#D1D5DC',
  squaredButtonColor: '#FFFFFF',
  squaredButtonActiveColor: '#6A6E7F',
  changePasswordBgColor: '#f7f7f7',
  EULAPPLinkTextColor: '#34DAE2',
};
