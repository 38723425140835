import React from 'react';
import { placeholderFunc } from '../../../../utils/commonFunctions';
import { RoundedButtonStyle, IconStyle } from '../styles/AdminSearchStyles';

const RoundedButton = ({ icon = '+', text = 'default', onClick = placeholderFunc, context = '' }) => (
  <RoundedButtonStyle role={'rounded-button'} context={context === text} onClick={onClick}>
    <IconStyle role={'icon'} context={context === text}>
      {icon}
    </IconStyle>
    <div>{text}</div>
  </RoundedButtonStyle>
);

export default RoundedButton;
