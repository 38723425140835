import styled from 'styled-components';

export const DropdownButtonPlusWrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  > svg {
    max-width: 24px;
    min-width: 16px;

    @media (-webkit-device-pixel-ratio: 1.5) {
      max-width: 18px;
    }
  }
`;
