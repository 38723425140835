import { Keypair } from '../../../../../steganography/keypair';
import { Password } from '../../../../../steganography/password';

export const resetPassword = async (email, newPassword) => {
  const user = _generateNewUser(email, newPassword);
  return user;
};

const _generateNewUser = async (email, newPassword) => {
  const masterKey = await Password.masterKey(email, newPassword);
  const preHash = await Password.prehash(email, newPassword);
  const { iv, publicKey, encryptedPrivateKey } = await Keypair.generate(masterKey);
  return {
    public_key: publicKey,
    encrypted_private_key: encryptedPrivateKey,
    private_key_iv: iv,
    password: preHash,
    password_confirmation: preHash,
  };
};
