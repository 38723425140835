import styled from 'styled-components';

export const PlanWrapper = styled.div`
  display: flex;
  height: 50vh;
  justify-content: space-evenly;
  margin: 1rem 0 0 0;

  @media screen and (max-width: 767px) {
    height: auto;
    flex-direction: column;
  }
`;

export const Wrap = styled.div`
  display: flex;
  flex: 6;
  flex-direction: column;
  padding: 1rem;
  max-width: 25%;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => (props['selected'] ? props.theme.highlightColor : props.theme.textColor)};
  border-radius: 4px;

  button {
    justify-content: flex-end;
  }

  @media screen and (max-width: 767px) {
    max-width: unset;
    width: 100%;
    margin-bottom: 1rem;
    box-sizing: border-box;
  }
`;

export const TitleWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  line-height: 25px;
  margin: 0 0 1rem 0;
`;

export const TitleIcon = styled.div`
  margin: 0 0.5rem 0 0;
  svg {
    g {
      .st0 {
        fill: ${props => props.theme.highlightColor};
      }
    }
  }
`;

export const Title = styled.div`
  color: ${props => props.theme.textColor};
  font-size: 22px;
`;

export const PriceWrap = styled.div`
  display: flex;
  justify-content: center;
  color: ${props => props.theme.textColor};
  font-size: 22px;
  margin: 0 0 1rem 0;
`;

export const Currency = styled.div``;

export const Price = styled.div`
  font-size: 80px;
`;

export const PriceUnit = styled.div`
  align-self: flex-end;
`;

export const SubtextWrap = styled.div`
  text-align: center;
  margin: 0 0 1rem 0;
`;

export const Subtext = styled.div`
  font-size: 0.9rem;
`;

export const Divider = styled.hr`
  width: 100%;
  color: ${props => props.theme.highlightColor};
  opacity: 0.8;
  margin: 1.25rem 0;
`;

export const FeatureWrap = styled.div`
  flex: 1;
  flex-direction: column;
  text-align: center;
  margin: 0 0 1rem 0;
`;

export const FeatureText = styled.div`
  font-size: 0.9rem;
`;

export const PlanButton = styled.button`
  width: max-content;
  margin: 0 auto;
  font-family: 'Lato';
  font-size: 0.9rem;
  background-color: ${props => (props['selected'] ? props.theme.settingsBgColor : '#98A2BB')};
  color: ${props => props.theme.fieldColor};
  border-radius: 5px;
  outline: none;
  padding: 0.75rem 1.25rem;
  border-width: ${props => (props['selected'] ? '1px' : '0px')};
  border-color: ${props => (props['selected'] ? props.theme.highlightColor : 'none')};
  border-style: ${props => (props['selected'] ? 'solid' : 'none')};
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.highlightColor};
  }
`;
