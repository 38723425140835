import styled from 'styled-components';

export const IconWrap = styled.div`
  display: flex;
  flex: 4;
  justify-content: center;
  margin: 0 0 0.5rem 0;

  .st0 {
    fill: ${({ theme }) => theme.highlightColor};
  }

  .st1 {
    fill: ${({ theme }) => theme.highlightColor};
  }
`;

export const SubText = styled.div`
  flex: 6;
  margin: 0 4rem;
`;

export const ButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

const BaseButton = styled.div`
  flex: 6;
  margin: 1rem;
  background-color: ${({ theme }) => theme.fieldBgColor};
  border-radius: 5px;
  line-height: 50px;
  border: none;
  color: ${({ theme }) => theme.fieldTextColor};
  cursor: pointer;
`;

export const Confirm = styled(BaseButton)`
  background-color: ${({ theme }) => theme.fieldBgColor};
  color: ${({ theme }) => theme.highlightColor};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 12rem;
  padding: 0 1rem;

  &:hover {
    background-color: ${({ theme }) => theme.highlightColor};
    color: ${({ theme }) => theme.fieldTextColor};
  }
`;

export const Cancel = styled(BaseButton)`
  background-color: ${({ theme }) => theme.fieldBgColor};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 12rem;
  padding: 0 1rem;

  &:hover {
    background-color: ${({ theme }) => theme.bgColor};
  }
`;
