import styled from 'styled-components';

export const FormFooter = styled.div`
  position: relative;
  padding: 1.1rem 1.75rem 1.1rem 1.75rem;
  width: 100%;
  border: 0px solid ${props => props.theme.formFooterBgColor};
  border-radius: 0 0 5px 5px;
  background: ${props => props.theme.formFooterBgColor} 0% 0% no-repeat padding-box;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    margin: 0 1rem;
    padding: 0 0 5px 0;
    opacity: 0.5;
    line-height: 100%;
  }
`;

export const FormButton = styled.button`
  background: ${props => props.theme.formFooterBgColor};
  border: none;
  color: ${props => props.theme.textColor};
  font-size: 1rem;
  font-family: 'Lato';
  cursor: pointer;
  padding: 0;

  > i {
    padding-right: 10px;
  }
`;

export const FormShortcut = styled.p`
  color: ${props => props.theme.textColor};
  font-size: 1rem;
  text-align: right;
  color: #98a2bb;
  margin: 0;
  display: flex;
  align-items: center;

  > svg {
    margin-left: 0.35rem;

    @media (-webkit-device-pixel-ratio: 1.5) {
      max-width: 12px;
      &.esc-key {
        max-width: 38px;
      }
    }
  }
`;
