import React, { useContext, useEffect } from 'react';
import { Route } from 'react-router-dom';
import LocalStore from '../../api/LocalStore';
import { UserContext } from '../../context/UserProvider';

const ProtectedRoute = ({ component: Component, path, exact, searchText = '', context = '', history }) => {
  const { logout } = useContext(UserContext);
  const store = new LocalStore();
  useEffect(() => {
    !store.get('token') && logout();
  }, [logout]);

  return (
    <Route
      exact={exact}
      path={path}
      render={() => <Component searchText={searchText} context={context} history={history} />}
    />
  );
};

export default ProtectedRoute;
