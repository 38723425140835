import React from 'react';
import { DropdownButtonIconWrapper } from './styles/DropdownButtonIconStyles';
import { Icon } from '../../../../../common/Icon';

const DropdownButtonIcon = ({ icon = '+' }) => {
  return (
    <DropdownButtonIconWrapper role={'dropdown-button-icon'}>
      <Icon role={'dropdown-button-icon'} name={icon} width="90%" />
    </DropdownButtonIconWrapper>
  );
};

export default DropdownButtonIcon;
