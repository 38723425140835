import React, { useContext } from 'react';
import IconButton from '../../../../../common/buttons/styles/IconButton';
import Pen from '../../../../../images/pen.svg';
import Bin from '../../../../../images/bin.svg';
import Move from '../../../../../images/move.svg';
import { placeholderFunc } from '../../../../../utils/commonFunctions';
import { IconArea } from './styles/MySecretActionsStyles';
import { Clickable } from './styles/Clickable';
import { ModalContext } from '../../../../../context';

export const MySecretActions = ({
  row,
  params = {
    setEditSecret: placeholderFunc,
    handleDelete: placeholderFunc,
  },
  onClick,
}) => {
  const { setEditSecret, handleDelete } = params;
  const { setModal } = useContext(ModalContext);
  const _testFunc = () => console.log('Row', row);
  const deleteSecret = () => {
    setModal();
    handleDelete(row);
  };
  const editRow = () => setEditSecret(row);
  const deleteRow = () =>
    setModal({
      key: 'Confirm',
      params: {
        icon: 'alert',
        question: `Are you sure you want to delete ${row['name']}?`,
        subtext: 'This secret will be moved to the "Deleted Secrets" folder.',
        confirmText: `Yes, delete ${row['name']}`,
        cancelText: `No, keep ${row['name']}`,
        confirmAction: deleteSecret,
        cancelAction: () => setModal(),
      },
    });

  return (
    <IconArea className="icon-area">
      <Clickable role={'clickable'} onClick={onClick} />
      <IconButton role={'editIcon'} onClick={editRow} icon={`${Pen}`} />
      <IconButton role={'deleteIcon'} onClick={deleteRow} icon={`${Bin}`} />
      <IconButton role={'testIcon'} onClick={_testFunc} icon={`${Move}`} />
    </IconArea>
  );
};
