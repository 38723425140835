import React, { useEffect, useContext } from 'react';
import Mousetrap from 'mousetrap';
import { useLocation, useHistory } from 'react-router-dom';
import { SearchWrap } from './styles/AdminSearchStyles';
import RoundedButton from './subComponents/RoundedButton';
import SquaredButton from './subComponents/SquaredButton';
import AdminSearchBar from './subComponents/AdminSearchBar';
import { getButtonText } from './AdminSearchFunctions';
import { Icon } from '../../../common/Icon';
import { AdminContext } from '../../../context/AdminContext';
import { blur, focus } from '../../../utils/commonFunctions';
import debounce from 'lodash.debounce';

const AdminSearch = ({ placeholder = 'Search for a secret', setSearchText, context, setContext, searchBarRef }) => {
  const setSearchPlaceholder = useContext(AdminContext).searchPlaceholder[1];
  const history = useHistory();
  const { pathname } = useLocation();
  const buttonText = getButtonText(pathname);
  const handleClick = text => {
    setContext(text);
    let url;
    if (text === 'Chests') setSearchPlaceholder('Search for a chest');
    if (text === 'Teams') setSearchPlaceholder('Search for a team');
    if (text === 'Secrets') setSearchPlaceholder('Search for a secret');
    if (text === 'Users') setSearchPlaceholder('Search for a user');
    if (text === 'Chests' || text === 'Secrets') url = '/admin/chests';
    if (text === 'Teams' || text === 'Users') url = '/admin/teams';
    history.push({ pathname: url, prevPathname: pathname });
  };

  useEffect(() => {
    Mousetrap.bind('shift+right', () => handleClick(buttonText['right']));
    Mousetrap.bind('shift+left', () => handleClick(buttonText['left']));
    Mousetrap.bind('esc', () => blur('admin-seachbar'));
    Mousetrap.bind('i', () => focus('admin-searchbar'), 'keyup');

    return () => {
      Mousetrap.unbind('shift+right');
      Mousetrap.unbind('shift+left');
      Mousetrap.unbind('esc');
      Mousetrap.unbind('i', 'keyup');
    };
  });

  const renderLeftIcon = () => {
    return pathname.includes('chests') ? (
      context === 'Chests' ? (
        <Icon name="chest_white" width="21px" />
      ) : (
        <Icon name="chest" width="21px" />
      )
    ) : context === 'Teams' ? (
      <Icon name="team_white" width="21px" />
    ) : (
      <Icon name="team" width="21px" />
    );
  };

  const setSearch = debounce(setSearchText, process.env.REACT_APP_DEBOUNCE_SEARCH_TIMER_VALUE);

  const renderRightIcon = () => {
    return pathname.includes('chests') ? (
      context === 'Chests' ? (
        <Icon name="locked" width="21px" />
      ) : (
        <Icon name="locked_white" width="21px" />
      )
    ) : context === 'Teams' ? (
      <Icon name="user" width="21px" />
    ) : (
      <Icon name="user_white" width="21px" />
    );
  };

  return (
    <SearchWrap>
      <RoundedButton
        text={buttonText['left']}
        onClick={() => handleClick(buttonText['left'])}
        context={context}
        icon={renderLeftIcon()}
      />
      <SquaredButton
        text={buttonText['right']}
        onClick={() => handleClick(buttonText['right'])}
        context={context}
        icon={renderRightIcon()}
      />
      <AdminSearchBar
        placeholder={placeholder}
        setSearchText={e => {
          setSearch(e);
        }}
        ref={searchBarRef}
      />
    </SearchWrap>
  );
};

export default AdminSearch;
