import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  color: ${props => props.theme.fieldTextColor};
  background-color: ${props => props.theme.formFooterBgColor};
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  min-height: 3.3rem;
  justify-content: space-between;
`;

export const TagWrap = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  z-index: 10;

  > div {
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin-left: 0.75rem;
  }

  .info-tag {
    display: block;
    min-width: 20px;
    height: 20px;
    font-size: 0.9rem;
    line-height: 20px;
    margin-left: 0.5rem;
    cursor: pointer;
    background: none;

    &:hover {
      opacity: 0.7;
    }
  }
`;

export const NameWrap = styled.div`
  display: flex;
  align-items: center;
  min-width: 20%;
  max-width: 35%;
  overflow: hidden;
  svg {
    padding-right: 0.75rem;

    @media (-webkit-device-pixel-ratio: 1.5) {
      max-width: 16px;
    }
  }

  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const SettingsWrap = styled.button`
  font-family: 'Lato';
  background-color: inherit;
  padding: 0;
  border: none;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-left: 1px solid ${props => props.theme.fieldTextColor};
  padding-left: 1.25rem;
  margin-left: 1.25rem;
  font-size: 0.9rem;
  cursor: pointer;
  color: ${props => props.theme.fieldTextColor};

  &:first-of-type {
    border-left: none;
  }

  &.deleted-secrets-icon {
    display: flex;
    align-items: center;
    svg {
      margin-right: 0.5rem;
    }
    .a {
      fill: ${props => props.theme.fieldTextColor};
    }

    @media (-webkit-device-pixel-ratio: 1.5) {
      svg {
        width: 16px;
        height: 14px;
      }
    }
  }

  &:focus {
    color: ${props => props.theme.highlightColor};
    outline-color: ${props => props.theme.highlightColor};
  }

  @media screen and (max-width: 700px) {
    display: none;
  }
`;

export const ButtonWrap = styled.div`
  max-width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
