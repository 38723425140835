import React, { useContext } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import InjectedPaymentForm from './subComponents/InjectedPaymentForm';
import { StripeContext } from '../../context';

const StripePayment = ({ setView, data, formContext, planFlag }) => {
  const stripePromise = useContext(StripeContext);

  return (
    <div>
      <Elements stripe={stripePromise}>
        <InjectedPaymentForm setView={setView} data={data} formContext={formContext} planFlag={planFlag} />
      </Elements>
    </div>
  );
};

export default StripePayment;
