import React from 'react';
import ViewFooter from './footers/ViewFooter';
import { CloseFooter } from './footers/CloseFooter';
import { MainFooter } from './footers/MainFooter';

export const getFooterComponent = (url, context) => {
  const _viewFooter = () => <ViewFooter />;
  const _closeFooter = () => <CloseFooter />;
  const _mainFooter = () => <MainFooter context={context} />;
  const _noFooter = () => null;

  const urlFooterPairs = {
    '/admin/chests/secret': _viewFooter,
    '/admin/teams/user': _closeFooter,
    '/admin/chests/show': _mainFooter,
    '/admin/teams/show': _mainFooter,
    '/admin/chests': _mainFooter,
    '/admin/teams': _mainFooter,
    default: _noFooter,
  };

  return urlFooterPairs[url] || urlFooterPairs['default'];
};
