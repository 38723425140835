import React, { useState, useEffect } from 'react';
import Mousetrap from 'mousetrap';
import { CellArea, LabelWrap, MenuOptions, DropDownOption } from './styles/PermissionsCellStyles';

export const PermissionsCell = ({ object, handlePermissions }) => {
  const [optionsVisible, setOptions] = useState('');
  const [highlightedOption, setHighlightedOption] = useState();

  const menuOptions = [
    {
      text: 'Read',
      handleClick: () => {
        handlePermissions(object, false);
      },
      id: 'read',
    },
    {
      text: 'Write',
      handleClick: () => {
        handlePermissions(object, true);
      },
      id: 'write',
    },
  ];

  const handleOptions = e => {
    const rect = e.target.getBoundingClientRect();
    return optionsVisible.id !== object.id ? setOptions({ id: object.id, rect }) : setOptions('');
  };

  const returnOptions = options => (
    <MenuOptions>
      {options.map(option => (
        <DropDownOption
          className={highlightedOption && highlightedOption.id === option.id && 'highlighted'}
          key={option.text}
          onMouseDown={option.handleClick}
        >
          {option.text}
        </DropDownOption>
      ))}
    </MenuOptions>
  );

  // Keyboard shortcuts
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      Mousetrap.bind('up', handleUpKey);
      Mousetrap.bind('down', handleDownKey);
    }
    return () => {
      isMounted = false;
      Mousetrap.unbind('up');
      Mousetrap.unbind('down');
    };
  });

  const handleUpKey = () => {
    if (highlightedOption) {
      const activeIndex = menuOptions.findIndex(option => option.id === highlightedOption.id);
      let newIndex = activeIndex - 1;
      if (activeIndex > 0) {
        setHighlightedOption(menuOptions[newIndex]);
      }
      if (activeIndex === 0) {
        setHighlightedOption('');
        setOptions('');
      }
    }
  };

  const handleDownKey = () => {
    if (highlightedOption) {
      const activeIndex = menuOptions.findIndex(option => option.id === highlightedOption.id);
      let newIndex = activeIndex + 1;
      if (activeIndex < menuOptions.length - 1) {
        setHighlightedOption(menuOptions[newIndex]);
      }
    } else {
      setHighlightedOption(menuOptions[0]);
    }
  };

  const handleEnterKey = e => {
    const rect = e.target.getBoundingClientRect();
    return optionsVisible.id !== object.id
      ? setOptions({ id: object.id, rect })
      : highlightedOption === undefined || highlightedOption.handleClick === undefined
      ? setOptions('')
      : (setOptions(''), highlightedOption.handleClick());
  };

  return (
    <CellArea
      onClick={e => handleOptions(e)}
      className={optionsVisible !== '' && 'selected'}
      tabIndex="0"
      onKeyPress={e => (e.key === 'Enter' ? handleEnterKey(e) : null)}
      onBlur={() => setOptions('')}
    >
      <LabelWrap>
        <i className="fas fa-bars"></i>
        <p>{object['edit_access'] ? 'Write' : 'Read'}</p>
      </LabelWrap>
      {optionsVisible.id === object.id && returnOptions(menuOptions)}
    </CellArea>
  );
};
