import React from 'react';
import { placeholderFunc } from '../../utils/commonFunctions';
import { WelcomeSkipWrap, SkipText } from './styles/WelcomeSkipStyles';

const WelcomeSkip = ({ onClick = placeholderFunc }) => (
  <WelcomeSkipWrap>
    <SkipText role={'WelcomeSkip'} onClick={onClick} tabIndex={1}>
      Skip
    </SkipText>
  </WelcomeSkipWrap>
);

export default WelcomeSkip;
