import styled from 'styled-components';

export const PlanTitleWrap = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  margin: 0 0 0.5rem 0;
  align-items: center;
`;

export const PlanIcon = styled.div`
  color: ${props => props.theme.highlightColor};

  svg g {
    fill: ${props => props.theme.highlightColor};
  }
`;

export const PlanChoice = styled.div`
  margin: 0 0 0 0.5rem;
  font-size: 1.2rem;
  font-weight: 700;
`;

export const PlanSubtitle = styled.div`
  font-size: 1rem;
`;

export const PlanLink = styled.div`
  cursor: pointer;
  font-size: 1rem;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: ${props => props.theme.highlightColor};
  }
`;
