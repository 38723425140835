import React from 'react';
import { HeaderWrap } from './styles/HeaderWrap';
import { useLocation } from 'react-router-dom';
import { Icon } from '../../../../common/Icon';
import { UsersName, SubText } from './styles/UserShowHeaderStyles';

const UserShowHeader = () => {
  const { row, state } = useLocation();
  const user = row || (state && state['row']) || { name: 'N/A', email: 'N/A' };
  const { name, email } = user;
  return (
    <HeaderWrap>
      <Icon name="user" width="21px" />
      <UsersName>{name}</UsersName>
      <SubText>{email}</SubText>
    </HeaderWrap>
  );
};

export default UserShowHeader;
