import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  width: 100%;
  color: ${({ theme }) => theme.fieldTextColor};

  .area:empty + .legend {
    display: none;
  }
`;

export const FieldLegend = styled.legend`
  position: absolute;
  top: -10px;
  left: 12px;
  background-color: ${({ theme, edit }) => (edit ? theme.sButtonBgColor : theme.tableBgColor)};
  color: ${props => props.theme.fieldBorderColor};
  padding: 0 10px;
  z-index: 1;
`;

export const TextArea = styled.textarea`
  color: ${({ theme }) => theme.fieldTextColor};
  background-color: inherit;
  width: 100%;
  height: 120px;
  box-sizing: border-box;
  font-family: 'Lato';
  border: none;
  padding: 0.75rem 1.25rem;
  margin: 0 0 1rem 0;
  font-size: 1rem;
  outline: none;
  border: 1px solid ${props => props.theme.fieldBorderColor};
  border-radius: 5px;
  ::placeholder {
    color: ${props => props.theme.placeholderColor};
  }
`;
