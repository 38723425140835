import styled from 'styled-components';

export const DropdownWrapper = styled.div`
  width: 45%;
  & label {
    background-color: ${props => props.theme.sButtonBgColor};

    @media screen and (max-width: 767px) {
      left: 0; 
    }
  }
}
`;

export const CollectiveWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: inherit;

  @media screen and (max-width: 767px) {
    box-sizing: border-box;
  }
`;
