import React, { useState, useContext, useEffect, useRef } from 'react';
import Mousetrap from 'mousetrap';
import { OptionsModalContext } from '../../../../../context/OptionsModalContext';
import { useHistory } from 'react-router-dom';
import { ChestTableData, GetChestSecrets } from '../../../../../api/chests/ChestApiFunctions';
import { UpdateSecretData, DeleteSecret, SecretTableData } from '../../../../../api/secrets/SecretApiFunctions';
import { IconArea } from './styles/BurgerMenuDropdownStyles';
import { AdminChestContext, AdminContext } from '../../../../../context/AdminContext';
import BaseApi from '../../../../../api/BaseApi';
import { ModalContext } from '../../../../../context';
import { BurgerMenuDropdown } from './BurgerMenuDropdown';

const AdminSecretActions = ({ row, highlightedRow, deleted, params, owner = false }) => {
  const modalContext = useContext(ModalContext);
  const setModal = modalContext && modalContext.setModal;
  const [optionsVisible, setOptions] = useContext(OptionsModalContext);
  const [highlightedOption, setHighlightedOption] = useState();
  const history = useHistory();
  const { location } = history;
  const { pathname } = location;
  const adminContext = useContext(AdminContext);
  const setChestTags = adminContext && adminContext.chestTags[1];
  const setData = useContext(AdminChestContext).adminChestTable[1];
  const [toggle, setToggle] = useState(false);
  const dropdownRef = useRef();

  // useEffect for keyboard shortcuts
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      Mousetrap.bind('right', e => handleOptions(highlightedRow, e));
    }
    return () => {
      isMounted = false;
      Mousetrap.unbind('right');
    };
  });

  // useEffect for toggling the dropdown menu
  useEffect(() => {
    const toggleDropdown = e => {
      if (toggle && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setOptions({});
        setHighlightedOption('');
        setToggle(false);
      }
    };
    document.addEventListener('mousedown', toggleDropdown);
    return () => document.removeEventListener('mousedown', toggleDropdown);
  }, [toggle]);

  const getMenuOptions = () => {
    if (deleted) {
      if (owner)
        return [
          {
            text: 'Restore',
            handleClick: () => restoreSecret(),
            styleId: 'deleted-secret-restore',
          },
          {
            text: 'Delete Permanently',
            handleClick: () => ownerDeletePopUp(),
            styleId: 'deleted-secret-destroy',
          },
        ];

      if (!owner)
        return [
          {
            text: 'Restore',
            handleClick: () => restoreSecret(),
            styleId: 'deleted-secret-restore',
          },
        ];
    }
    return [
      {
        text: 'Move to another Chest',
        handleClick: () => movePopUp(),
        styleId: 'secret-move',
      },
      {
        text: 'Edit Secret',
        handleClick: () => editSecret(),
        styleId: 'secret-edit',
      },
      {
        text: 'Remove',
        handleClick: () => deletePopUp(),
        styleId: 'secret-delete',
      },
    ];
  };

  const menuOptions = getMenuOptions();

  const refreshTable = async () => {
    setChestTags(await ChestTableData({ everything: true, options: ['*'] }));
    if (pathname === '/admin/chests')
      setData(await SecretTableData({ limit: 20, offset: 0, everything: true, options: ['*'] }));
    if (pathname === '/admin/chests/show') setData(await GetChestSecrets(row['adminChest'].id, null, true));
    if (pathname === '/admin/chests/deleted') params.setLoading(true);
  };

  const editSecret = () => {
    setOptions({});
    history.push({
      pathname: '/admin/chests/secret/edit',
      row,
      prevPathname: pathname,
    });
  };

  const restoreSecret = () =>
    BaseApi.restore(row['id']).then(() => {
      refreshTable();
      setModal();
    });

  const ownerDeleteSecret = () =>
    BaseApi.destroy('secrets', row['id']).then(() => {
      refreshTable();
      setModal();
    });

  const handleMoveModalConfirm = (secret, currChest) =>
    UpdateSecretData(secret.id, {
      secret: { vault_id: currChest.id },
    }).then(() => {
      setModal();
      refreshTable();
    });

  const handleDeleteModalConfirm = () => {
    DeleteSecret(row.id).then(() => {
      refreshTable();
      setModal();
    });
  };

  const movePopUp = () => {
    ChestTableData({ everything: true }).then(result => {
      setModal({
        key: 'Select',
        params: {
          action: 'Move',
          name: `${row['name']}`,
          row: row,
          subtitle: 'Select Chest',
          objList: result,
          confirmAction: handleMoveModalConfirm,
          cancelAction: () => setModal(),
          escape,
        },
      });
    });
  };

  const deletePopUp = () =>
    setModal({
      key: 'Confirm',
      params: {
        icon: 'alert',
        question: `Are you sure you want to delete ${row['name']}?`,
        subtext: 'This secret will be moved to "Deleted Secrets".',
        confirmText: `Yes, delete ${row['name']}`,
        cancelText: `No, keep ${row['name']}`,
        confirmAction: handleDeleteModalConfirm,
        cancelAction: () => setModal(),
      },
    });

  const ownerDeletePopUp = () =>
    setModal({
      key: 'Confirm',
      params: {
        icon: 'alert',
        question: `Are you sure you want to permanently delete ${row['name']}?`,
        subtext: 'This secret will be irrecoverable.',
        confirmText: `Yes, delete ${row['name']}`,
        cancelText: `No, keep ${row['name']}`,
        confirmAction: ownerDeleteSecret,
        cancelAction: () => setModal(),
      },
    });

  const handleOptions = (row, e) => {
    setHighlightedOption('');
    optionsVisible.row !== row?.id ? setOptions({ row: row?.id, above: calculateOptionsPosition(e) }) : setOptions({});
  };

  const calculateOptionsPosition = e => {
    return e.clientY > window.innerHeight * 0.7 || window.innerHeight - e.clientY < 200 ? true : false;
  };

  return (
    <div
      data-testid={'actions-wrapper'}
      style={{ position: 'relative' }}
      ref={dropdownRef}
      onClick={() => setToggle(!toggle)}
    >
      <IconArea onClick={e => handleOptions(row, e)} data-testid={'icon'} className="icon-area">
        <i className="fas fa-bars"></i>
      </IconArea>
      {optionsVisible.row === row.id && (
        <BurgerMenuDropdown
          menuOptions={menuOptions}
          highlightedOption={highlightedOption}
          setHighlightedOption={setHighlightedOption}
        />
      )}
    </div>
  );
};

export default AdminSecretActions;
