import Request from '../Request';
import BaseController from './BaseController';

interface CheckParams {
  email: string;
  password: string;
}

class TwoFactorController extends BaseController {
  // Checks whether the account required 2FA or not
  // Returns: { '2faRequired': null | true | false }
  async check(params: CheckParams) {
    const newParams = await this.prehash(params);
    const handler = new Request('POST', '/api/v1/two-factor-check', newParams);
    return await handler.perform();
  }
}

export default TwoFactorController;
