import styled from 'styled-components';

export const LoadingContent = styled.div`
  display: flex;
  flex: 12;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    height: 10rem;
    width: 10rem;
  }
`;
