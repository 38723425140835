import React from 'react';
import { ArrowWrap, ArrowStyle } from './styles/ArrowStyles';

const arrowPos = arrowState => (arrowState ? 'down' : 'up');

export const Arrow = ({ arrowState, important }) => (
  <ArrowWrap>
    <ArrowStyle role={'Arrow'} className={arrowPos(arrowState)} important={important} />
  </ArrowWrap>
);
