import styled from 'styled-components';

const MeterBar = styled.div`
  height: 3px;
  background: ${props => props.color};
  border-radius: 2px;
  flex: 5;
  margin-right: 5px;

  &:last-of-type {
    margin-right: 0;
  }
`;

export default MeterBar;
