import React from 'react';
import { Icon } from '../../../../../common/Icon';
import { IconArea } from './styles/ChestSecretActionsStyles';
import { SecretClickable } from './styles/Clickable';

export const ChestSecretActions = ({ type, onClick }) => {
  return (
    <IconArea className="icon-area">
      <SecretClickable onClick={onClick} />
      <span>{type === 'chest' ? 'Select to open chest' : 'Select'}</span>
      <Icon name="enter" width="18px" />
    </IconArea>
  );
};
