import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Mousetrap from 'mousetrap';
import { AdminViewFooterContainer, AdminFooterContainer } from '../styles/AdminFooterStyle';
import FooterTag from '../../../../common/links/FooterTag';
import { Icon } from '../../../../common/Icon';
import { goToLocation } from '../../adminFunctions/redirectByUrl';

const ViewFooter = () => {
  const history = useHistory();
  const { location } = history;
  const { pathname, prevRow, row, prevPathname } = location;

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      Mousetrap.bind('shift+e', handleEdit);
      Mousetrap.bind('enter', handleEdit);
      Mousetrap.bind('esc', redirectTo);
    }
    return () => {
      isMounted = false;
      Mousetrap.unbind('shift+e');
      Mousetrap.unbind('enter');
      Mousetrap.bind('esc', redirectTo);
    };
  });

  let url;
  if (prevPathname && prevPathname.includes('deleted')) url = prevPathname;
  if (!url && prevPathname && !prevPathname.includes('show')) url = goToLocation('GoBack', pathname);
  if (!url) url = goToLocation('GoBackSpecific', pathname);
  const redirectTo = () => history.push({ pathname: url, row: prevRow, prevPathname: pathname });

  const handleEdit = () => {
    if (document.activeElement === document.getElementsByClassName('footer-button')[0]) {
      redirectTo();
    } else if (document.activeElement === document.getElementsByClassName('footer-button')[1]) {
      history.push({
        pathname: '/admin/chests/secret/edit',
        row,
        prevPathname: pathname,
      });
    }
  };

  return (
    <AdminViewFooterContainer role={'ViewFooter'}>
      <AdminFooterContainer className="sub-foot">
        <FooterTag
          onClick={redirectTo}
          fontIcon="fas fa-minus-circle"
          text="Close"
          keyText=""
          icon={<Icon name="esc" className="esc-key" width="48px" />}
        />
      </AdminFooterContainer>
      <AdminFooterContainer className="sub-foot">
        <FooterTag onClick={() => handleEdit()} fontIcon="fas fa-pen" text="Edit Secret" keyText="Shift + E" />
      </AdminFooterContainer>
    </AdminViewFooterContainer>
  );
};

export default ViewFooter;
