import React, { useState } from 'react';
import copy from 'copy-to-clipboard';
import { SecretHeaderContainer, CopyText, IconButton, TitleWrapper } from './styles/SecretHeaderStyles';
import { Icon } from '../../../common/Icon';

export const SecretHeader = ({ name }) => {
  const [copied, setCopy] = useState(false);

  const writeToClipboard = text => {
    copy(text);
    setCopy(true);
    setTimeout(() => setCopy(false), 1000);
  };

  const renderCopy = () => {
    return copied ? (
      <CopyText>Copied</CopyText>
    ) : (
      <IconButton role={'icon-button'} className="fas fa-copy" onClick={() => writeToClipboard(name)}></IconButton>
    );
  };

  return (
    <SecretHeaderContainer>
      <TitleWrapper>
        <Icon name="unlocked" width="18px" />
        {`${name}`.slice(0, 40)}
      </TitleWrapper>
      {renderCopy()}
    </SecretHeaderContainer>
  );
};
