import styled from 'styled-components';

export const ConfirmContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ConfirmTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10rem;

  h2 {
    text-align: center;
  }
`;

export const ConfirmButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 10rem;
  width: 100%;
`;

export const ConfirmButton = styled.button`
  height: 2.75rem;
  width: 100%;
  border-radius: 0.5rem;
  cursor: pointer;
  font-family: 'Lato';
  font-size: 1rem;
  appearance: none;
  outline: none;
  margin-bottom: 1rem;

  &.success {
    background-color: #40dae1;
    color: #ffffff;
    border: 1px solid #40dae1;
  }

  &.back {
    background-color: ${props => props.theme.tableBgColor};
    color: ${props => props.theme.textColor};
    border: 1px solid ${props => props.theme.textColor};
  }

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.bgColor};
    color: ${({ theme }) => theme.highlightColor};
  }
`;
