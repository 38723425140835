import React, { useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { UserContext } from '../../context/UserProvider';

const LogoutRedirect = () => {
  const { logout } = useContext(UserContext);

  useEffect(() => {
    logout();
  }, []);

  return <Redirect to={{ pathname: '/login' }} />;
};

export default LogoutRedirect;
