import styled from 'styled-components';

export const PageContent = styled.div`
  display: flex;
  flex: 12;
  flex-direction: column;
  justify-content: center;

  .page {
    width: 100%;
    transform: translateY(0);
    position: relative;
    overflow: hidden;

    @media screen and (max-width: 767px) {
      position: relative;
      top: auto;
      transform: none;
      padding-top: 2rem;

      .page {
        margin-top: -2rem;
      }
    }
    margin-bottom: 5rem;
  }

  .page-enter {
    animation: enter 0.5s forwards 1;
  }
  .page-exit {
    display: none;
  }

  @keyframes enter {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;
