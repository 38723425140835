import { testIf } from '../validationFunctions';
import { mergeArrays } from '../../mergeFunctions';
import { successState, formatResults } from '../validationHelpers';

export const validateUserForm = ({ data, setNotificationText }) => {
  const emptyRes = testIf('empty')(data, ['userName', 'userEmail']);
  const emailRes = testIf('email')(data, ['userEmail']);
  const testResults = mergeArrays([emptyRes, emailRes]);
  if (testResults.length === 0) return successState(setNotificationText);
  setNotificationText(formatResults(testResults));
};
