import styled from 'styled-components';

export const DropdownOptionStyle = styled.div`
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  color: ${props => props.theme.fieldTextColor};
  cursor: pointer;
  margin: 0 0 0.5rem 0;
  align-items: center;
  padding: 0 1.3rem 0 1.1rem;
  box-sizing: border-box;
`;

export const DropdownAltText = styled.div`
  font-size: 0.9rem;
  color: ${props => props.theme.textColor};
  margin-top: 0;
`;
