import styled from 'styled-components';

export const CopyText = styled.div`
  color: ${props => props.theme.highlightColor};
  font-size: 0.9rem;
  margin-right: 1.25rem;
`;

export const IconButton = styled.i`
  color: ${props => props.theme.textOutlineColor};
  margin-right: 1.25rem;
  cursor: pointer;
  transition: color 0.3s ease-in-out;

  &:active,
  &:hover {
    color: ${props => props.theme.highlightColor};
    visibility: visible;
  }
`;
