import React, { Fragment, useEffect, useState } from 'react';
import { NewDropdown } from '../../../common/forms/NewDropdown';
import { CountryList } from './Countries';
import { TextInput } from '../../../common/forms/TextInput';
import { SplitWrapper } from '../../../common/forms/styles/SplitTextStyles';
import { getSecretFormat } from '../subFunctions/FormFunctions';
import { errorCheck, removeError } from '../../../utils/formFunctions';
import NoteArea from '../../../common/forms/NoteArea';

const Address = ({ secret, data, setData, notificationText, setNotificationText }) => {
  const { addressOne, addressTwo, addressThree, addressFour, postalCode, country, note } = getSecretFormat(secret);

  const [countryOptions, setCountryOptions] = useState([]);
  const [dropdownVisibility, setDropdownVisibility] = useState(false);
  const [countrySelected, setCountrySelected] = useState({
    id: 'blank',
    value: '',
    label: '',
  });

  useEffect(() => {
    let isMounted = true;
    const countryArray = CountryList.map(country => ({
      ...country,
      label: country['value'],
    }));
    if (isMounted) {
      setCountryOptions(countryArray);
      if (data.country) {
        const dataCountry = countryArray.filter(country => country.value === data.country)[0];
        setCountrySelected(dataCountry);
      }
    }
    return () => (isMounted = false);
  }, [data.country]);

  useEffect(() => {
    setData({
      addressOne,
      addressTwo,
      addressThree,
      addressFour,
      postalCode,
      country,
      note,
    });
  }, [setData, addressOne, addressTwo, addressThree, addressFour, postalCode, country, note]);

  const handleChange = (value, key) => {
    if (notificationText) setNotificationText(removeError(key, notificationText));
    setData(prevData => {
      return {
        ...prevData,
        [key]: value,
      };
    });
  };

  const handleDDChange = (option, key) => {
    handleChange(option.value, key);
    setCountrySelected(option);
  };

  return (
    <Fragment>
      <span className="custom-title">Address</span>
      <SplitWrapper>
        <TextInput
          placeholder="Address Line 1"
          label="Address Line 1"
          value={data.addressOne || ''}
          handleChange={value => handleChange(value, 'addressOne')}
          className={errorCheck('addressOne', notificationText)}
        />
        <TextInput
          placeholder="Address Line 2"
          label="Address Line 2"
          value={data.addressTwo || ''}
          handleChange={value => handleChange(value, 'addressTwo')}
          className="no-right-margin"
        />
      </SplitWrapper>
      <SplitWrapper>
        <TextInput
          placeholder="Address Line 3"
          label="Address Line 3"
          value={data.addressThree || ''}
          handleChange={value => handleChange(value, 'addressThree')}
        />
        <TextInput
          placeholder="Address Line 4"
          label="Address Line 4"
          value={data.addressFour || ''}
          handleChange={value => handleChange(value, 'addressFour')}
          className="no-right-margin"
        />
      </SplitWrapper>
      <SplitWrapper>
        <TextInput
          placeholder="Postal Code"
          label="Postal Code"
          value={data.postalCode || ''}
          handleChange={value => handleChange(value, 'postalCode')}
          className={errorCheck('postalCode', notificationText)}
        />
        <NewDropdown
          label="Country"
          handleDropdownChange={option => handleDDChange(option, 'country')}
          dropdownOptions={countryOptions}
          dropdownVisibility={dropdownVisibility}
          setDropdownVisibility={setDropdownVisibility}
          optionSelected={countrySelected}
          id="country-dropdown"
          className={`no-right-margin ${errorCheck('username', notificationText)}`}
        />
      </SplitWrapper>
      <NoteArea
        placeholder="Note"
        label="Note"
        value={data.note || ''}
        onChange={e => handleChange(e.target.value, 'note')}
      />
    </Fragment>
  );
};

export default Address;
