import ApiRequest from '../ApiRequest';

class DataDashboardApi {
  static getDateRange() {
    return ApiRequest('GET', `api/v1/date_range_data_records.json`);
  }
  static getAllTime() {
    return ApiRequest('GET', `api/v1/all_time_data_records.json`);
  }
}

export default DataDashboardApi;
