import React from 'react';
import { useHistory } from 'react-router-dom';
import { PlanWrap, BillingDivider, BillingButton, BillingSubText } from '../styles/BillingSummarySectionStyles';
import { PlanTitleWrap, PlanChoice, PlanIcon, PlanSubtitle } from './styles/TeamStatusStyles';
import { Icon } from '../../../../../common/Icon';
import { getPlanObj } from './subFunctions/PlanStatusFunctions';

const PlanStatus = ({ subscription }) => {
  const history = useHistory();
  const plan = subscription['plan'] && subscription['plan']['nickname'];
  const { icon, name, price } = getPlanObj(plan);

  const goToPlans = () => history.push('/plans');

  return (
    <PlanWrap>
      <PlanTitleWrap>
        <PlanIcon>
          <Icon name={icon} width={'21px'} />
        </PlanIcon>
        <PlanChoice>{name}</PlanChoice>
      </PlanTitleWrap>
      <PlanSubtitle>{price}</PlanSubtitle>
      <BillingDivider />
      <BillingSubText>
        <BillingButton onClick={goToPlans}>Change Plan</BillingButton>
      </BillingSubText>
    </PlanWrap>
  );
};

export default PlanStatus;
