import styled from 'styled-components';

export const AdminSecretsEditWrap = styled.div`
  display: flex;
  background-image: linear-gradient(
    ${props => props.theme.fieldBgColor} 50%,
    ${props => props.theme.sButtonBgColor} 50%
  );
  flex: 2;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow-y: auto;
  justify-content: flex-start;
  border-radius: 0 0 0 5px;

  @media screen and (max-width: 767px) {
    overflow-y: visible;
  }
`;
