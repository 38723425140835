import React, { useState, useContext, useEffect } from 'react';
import { SettingHR, SettingTitle } from '../../../common/settings/styles/SettingStyles';
import SecretKeyApi from '../../../api/secretKeys/SecretKeyApi';
import { ReshareSecret } from '../../../utils/secretFunctions';
import Setting from '../../../common/settings/Setting';
import { ModalContext } from '../../../context';
import OrganisationApi from '../../../api/organisation/OrganisationApi';
import { useNotification } from '../../../common/notification/Notification';
import { InactivityTimeoutContext } from '../../../context/InactivityTimeoutContextManagment';

const GeneralSettings = () => {
  const [activeSetting, setActiveSetting] = useState('');
  const { inactivityLength, setInactivityLength } = useContext(InactivityTimeoutContext);
  const [inactivityLengthLoading, setInactivityLengthLoading] = useState(false);
  const { setNotificationText } = useNotification();
  const modalContext = useContext(ModalContext);
  const [selectedOption, setSelectedOption] = useState();
  const options = [
    { id: 0, label: 'Never', value: -1 },
    { id: 1, label: '5 minutes', value: 5 },
    { id: 2, label: '10 minutes (default)', value: 10 },
    { id: 3, label: '15 minutes', value: 15 },
    { id: 4, label: '20 minutes', value: 20 },
    { id: 5, label: '30 minutes', value: 30 },
    { id: 6, label: '1 hour', value: 60 },
    { id: 7, label: '2 hours', value: 120 },
  ];
  const setModal = modalContext && modalContext.setModal;

  useEffect(() => {
    OrganisationApi.show().then(result => {
      setInactivityLength(result.inactivity_length);
    });
    setSelectedOption(autoSelect());
  }, [inactivityLength]);
  const reshareMissingKeys = () => {
    confirmReshare('Sharing Missing Secrets to Users in Recrypt...');
    SecretKeyApi.reshareAll().then(async result => {
      if (result.keyshare !== null) {
        const reshareJson = await ReshareSecret(result.keyshare);
        await SecretKeyApi.reshare(reshareJson);
      }
      confirmReshare('No Missing Keys in Recrypt!', false);
    });
  };
  const confirmReshare = (text, loading = true) => {
    setModal({
      key: 'Confirm',
      params: {
        loading: loading,
        icon: 'asterisk',
        question: text,
        confirmText: 'close',
        confirmAction: () => {
          setModal();
        },
      },
    });
  };
  const autoSelect = () => {
    const selectedValue = options.filter(option => {
      return option.value === inactivityLength;
    })[0];
    return selectedValue;
  };
  const openReshareModal = () => {
    reshareMissingKeys();
  };
  const editActiveSetting = () => {
    setInactivityLengthLoading(true);
    const params = {
      organisation: {
        inactivity_length: selectedOption.value,
      },
    };
    setInactivityLength(selectedOption.value);
    OrganisationApi.update(params).then(() => {
      setSelectedOption(selectedOption);
      setModal();
      setNotificationText('Auto logout updated successfully');
    });
    setInactivityLengthLoading(false);
  };
  return (
    <div role={'GeneralSettings'}>
      <SettingTitle>General</SettingTitle>
      <SettingHR />
      <Setting
        name="Reshare Missing Keys"
        type="text"
        text="Create a personal unlock for any users who currently 
              do not have one for the secret(s) they should have access to."
        buttonName="Reshare Missing Keys"
        activeSetting={activeSetting}
        setActiveSetting={setActiveSetting}
        onClick={() => openReshareModal()}
      />
      <Setting
        options={options}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        name="Auto Logout"
        inactivityLength={inactivityLength}
        setInactivityLength={setInactivityLength}
        type="dropDown"
        text={'Recrypt automatically logs out after the selected time period.'}
        setModal={setModal}
        onClick={() =>
          setModal({
            key: 'Confirm',
            params: {
              loading: inactivityLengthLoading,
              icon: 'alert',
              question: 'Do you want to save these changes?',
              confirmText: 'Yes',
              cancelText: 'No',
              confirmAction: editActiveSetting,
              cancelAction: () => {
                setSelectedOption(autoSelect());
                setModal();
              },
            },
          })
        }
      />
    </div>
  );
};

export default GeneralSettings;
