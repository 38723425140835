import ApiRequest from '../ApiRequest';

class UserApi {
  static change(params) {
    return ApiRequest('PATCH', `users.json`, params);
  }

  static reset_password(id, params) {
    return ApiRequest('PATCH', `api/v1/users/${id}/reset_password`, params);
  }

  static lock(id) {
    return ApiRequest('GET', `api/v1/users/${id}/lock.json`);
  }

  static unlock(id) {
    return ApiRequest('GET', `api/v1/users/${id}/unlock.json`);
  }
}

export default UserApi;
