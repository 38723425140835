import styled from 'styled-components';

export const DeletedTitle = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  color: ${props => props.theme.fieldTextColor};
  background: repeating-linear-gradient(
    135deg,
    ${({ theme }) => theme.fieldBgColor} 2px,
    ${({ theme }) => theme.fieldBgColor} 3px,
    ${({ theme }) => theme.formFooterBgColor} 4px,
    ${({ theme }) => theme.formFooterBgColor} 10px
  );
  padding-left: 1.25rem;
  padding-right: 1.25rem;
`;

export const IconWrap = styled.div`
  svg {
    height: 18px;
    margin: 0 0.75rem 0 0;
    .a {
      fill: ${({ theme }) => theme.fieldTextColor};
    }
    @media (-webkit-device-pixel-ratio: 1.5) {
      height: 14px;
    }
  }
`;

export const Title = styled.div`
  font-size: 1.1rem;
  margin-top: -2px;
`;
