import styled from 'styled-components';

export const Wrap = styled.div`
  display: inline-flex;
  flex: 1;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.fieldBgColor};
  color: ${({ theme }) => theme.fieldTextColor};
  max-height: 3rem;
  margin: 0 0 1rem 0;
  overflow: hidden;
`;

export const IconWrap = styled.div`
  flex: 0;
  padding: 1rem;
  opacity: 0.5;

  g {
    fill: ${({ theme }) => theme.fieldTextColor};
  }
`;

export const BarWrap = styled.div`
  display: flex;
  flex: 7;
  line-height: 3rem;
`;

export const Title = styled.div`
  flex: 6;
  opacity: 0.5;
  white-space: normal;
`;

export const UnlockWrap = styled.div`
  display: flex;
  flex: 4;
  cursor: pointer;
  justify-content: flex-end;
  margin: 0 1rem 0 0;

  &:hover {
    color: ${({ theme }) => theme.highlightColor};
    .st0 {
      fill: ${({ theme }) => theme.highlightColor};
    }
  }

  .st0 {
    fill: ${({ theme }) => theme.fieldTextColor};
    margin: 0 0.5rem 0 0;
  }
`;

export const Unlock = styled.div`
  margin: 0 0 0 0.5rem;
`;
