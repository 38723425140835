import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NavWrap = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  line-height: 28px;
  margin-bottom: 1.5rem;
`;

export const Subtitle = styled.div``;

export const Subtext = styled.div`
  margin: 1rem 0;
`;
