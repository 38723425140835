import React from 'react';
import { Divider } from '../../../../common/divider/Divider';
import { Icon } from '../../../../common/Icon';
import { SectionHeading } from '../billing/styles/BillingStyles';
import { BillingHistoryLink, BillingSummaryWrap } from './styles/BillingSummarySectionStyles';
import SubscriptionStatus from './subComponents/SubscriptionStatus';
import PlanStatus from './subComponents/PlanStatus';

export const BillingSummarySection = ({ subscription, setSubscription, setView, accounts, setFormContext }) => {
  if (!subscription) return null;
  return (
    <div>
      <SectionHeading className="billing-heading">
        <SectionHeading>
          <b>Billing Summary</b>
          <Divider />
          <BillingHistoryLink onClick={() => setView('history')}>See billing history</BillingHistoryLink>
        </SectionHeading>
        <Icon className="stripe-logo" name="stripe" width="120px" />
      </SectionHeading>
      <BillingSummaryWrap>
        <PlanStatus subscription={subscription} />
        <SubscriptionStatus
          setFormContext={setFormContext}
          setView={setView}
          accounts={accounts}
          subscription={subscription}
          setSubscription={setSubscription}
        />
      </BillingSummaryWrap>
    </div>
  );
};
