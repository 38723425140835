import styled from 'styled-components';

export const IconMenuButtonStyle = styled.div`
  display: inline-flex;
  background-color: ${props => props.theme.fieldBgColor};
  color: ${props => props.theme.fieldTextColor};
  flex-wrap: nowrap;
  flex-direction: row;
  margin: 0;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;

  &.active {
    background-color: ${props => props.theme.adminSelectionColor};
    color: #ffffff;

    > div > svg {
      path {
        fill: #ffffff;
      }
    }
  }
`;

export const IconMenuButtonPrefixStyle = styled.div`
  display: inline-flex;
  background-color: inherit;
  flex: 1;
  height: 3rem;
  border-radius: 5px 0 0 5px;
  justify-content: center;
  padding: 0.85rem 0.75rem 0.85rem 0.75rem;
  box-sizing: border-box;

  svg {
    display: block;
    width: 100%;
    height: 100%;
    path {
      fill: ${props => props.theme.fieldTextColor};
    }
  }
`;

export const IconMenuButtonBarStyle = styled.div`
  display: inline-flex;
  background-color: inherit;
  flex: 8;
  height: 3rem;
  border-radius: 0 5px 5px 0;
  justify-content: flex-start;
  align-items: center;
  color: inherit;
  > div {
    line-height: 46px;
  }
`;
