import React, { Fragment, useState, useEffect, useRef, useContext } from 'react';
import { Icon } from '../../common/Icon';
import {
  ModalOverlay,
  ToastWrapper,
  Confirm,
  LogOut,
  ButtonContainer,
  InactivityHeaderText,
  InactivityText,
} from './styles/InactivityModalStyles';
import { NotificationContext } from '../../context/index';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../context/UserProvider';

const InactivityModal = ({ onConfirm }) => {
  const { logout, user } = useContext(UserContext);
  const [time, setTime] = useState(60);
  const [timeOnMount] = useState(Date.now());

  const { setNotificationText } = useContext(NotificationContext);
  const setUser = user[1];
  const history = useHistory();
  let timer = useRef(false);

  const checkTime = () => {
    let timeElapsedMillis = Date.now() - timeOnMount;
    let timeElapsed = Math.floor(timeElapsedMillis / 1000);
    return timeElapsed;
  };
  useEffect(() => {
    time > 0 && (timer.current = setTimeout(() => setTime(60 - checkTime()), 1000));
    time <= 0 && logout();
  }, [time, history, setNotificationText, setUser]);

  const handleClick = () => {
    clearTimeout(timer.current);
    onConfirm();
  };
  const confirmLogout = () => {
    logout();
  };
  return (
    <Fragment>
      <ModalOverlay />
      <ToastWrapper role={'InactivityModal'}>
        <Icon name="alert" width="50px" />
        <InactivityHeaderText>Session Expire Warning</InactivityHeaderText>
        <InactivityText>You will be logged out in {time} seconds.</InactivityText>
        <ButtonContainer>
          <Confirm onClick={handleClick} tabIndex="0" id="ignore-button" role={'confirm-button'}>
            Stay Logged In
          </Confirm>
          <LogOut onClick={confirmLogout} tabIndex="0" id="confirm-button" role={'confirm-button'}>
            Logout
          </LogOut>
        </ButtonContainer>
      </ToastWrapper>
    </Fragment>
  );
};

export default InactivityModal;
