import ApiRequest from '../ApiRequest';

// Class responsible for the Api calls related to Secret Key.
class SecretKeyApi {
  static reshareAll() {
    return ApiRequest('GET', `api/v1/secret_keys/reshare_all.json`);
  }

  static reshareTeam(params) {
    return ApiRequest('POST', `api/v1/secret_keys/reshare_team.json`, params);
  }

  static reshareUser(params) {
    return ApiRequest('POST', `api/v1/secret_keys/reshare_user.json`, params);
  }

  static reshare(params) {
    return ApiRequest('POST', `api/v1/secret_keys/reshare.json`, params);
  }

  static purgeKeys(params) {
    return ApiRequest('POST', `api/v1/secret_keys/purge_keys.json`, params);
  }
}

export default SecretKeyApi;
