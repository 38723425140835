import styled from 'styled-components';

export const WelcomeSkipWrap = styled.div`
  display: flex;
  flex: 4;
  margin: 3rem 0 0 0;
  justify-content: center;
  text-transform: uppercase;
`;

export const SkipText = styled.div`
  cursor: pointer;
  text-decoration: underline;
  color: ${props => props.theme.fieldTextColor};
  outline: none;
  border: none;

  &:focus {
    color: ${({ theme }) => theme.highlightColor};
  }
`;
