import styled from 'styled-components';

export const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const InputWrapper = styled.div`
  width: 50%;
  display: flex;
  position: relative;

  > input {
    padding: 1.25rem 1.75rem;
    font-size: 1.6rem;
    font-weight: 400;
    font-family: 'Lato';
    width: 100%;
    box-sizing: border-box;
    color: ${props => props.theme.searchColor};
    background: none;
    border: 1px solid ${props => props.theme.searchBgColor};
    border-radius: 5px 5px 0px 0px;
    appearance: none;
    outline: none;
    transition: border-color 0.3s ease-in-out;
    background: ${props => props.theme.searchBgColor} 0% 0% no-repeat padding-box;

    ::placeholder {
      opacity: 0.5;
      color: ${props => props.theme.searchColor};
    }
    &:focus {
      border: 1px solid ${props => props.theme.searchColor};
    }
  }

  > i {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 24px;
    font-size: 24px;
    color: ${props => props.theme.placeholderColor};
    position: absolute;
    right: 1.75rem;
    top: 0;
    height: 100%;

    @media (-webkit-device-pixel-ratio: 1.5) {
      min-width: 18px;
      font-size: 18px;
    }
  }

  @media screen and (max-width: 767px) {
    width: 95%;

    > input {
      font-size: 1.3rem;
    }
  }
`;
