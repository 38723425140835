import styled from 'styled-components';

export const HeaderWrap = styled.div`
  background: ${props => props.theme.fieldBgColor};
  padding: 0.25rem 1.25rem;
  border-radius: 5px 5px 0 0;
  display: inline-flex;
  flex: 1;
  color: ${props => props.theme.fieldTextColor};
  font-size: 1.2rem;
  align-items: center;
  max-height: 50px;
  line-height: 50px;
  border-bottom: 1px ${props => props.theme.bgColor} solid;

  svg {
    margin-right: 0.75rem;
    path {
      fill: ${props => props.theme.fieldTextColor} !important;
    }
  }

  @media (-webkit-device-pixel-ratio: 1.5) {
    line-height: 3rem;
    font-size: 1.1rem;

    svg {
      width: 16px;
    }
  }
`;
