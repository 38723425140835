import styled from 'styled-components';

const GenericTag = styled.div`
  display: block;
  border-radius: 5px;
  border-color: ${props => {
    if (props['currentActive']) return props['theme']['hoverRowColor'];
    else return props['theme']['fieldBorderColor'];
  }};
  border-width: 1px;
  border-style: solid;
  background-color: ${props => props.theme.bgColor};
  max-width: ${props => props.maxWidth};
  overflow: hidden;
  height: 1.25rem;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  padding: 0.3rem 0.75rem;
  color: ${props => {
    if (props['currentActive']) return props['theme']['hoverRowColor'];
    else return props['theme']['textColor'];
  }};
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
  background-color: ${props => {
    if (props['currentActive']) return props['theme']['adminSelectionColor'];
  }};

  color: ${props => {
    if (props['currentActive']) return '#FFFFFF';
  }};

  &:hover {
    opacity: 0.7;
  }
`;

export default GenericTag;
