import { AES } from './aes';
import { Symmetric } from './symmetric';
import { Conversion } from './internal/conversion';

// Handles anything directly related to keypair generation (not en/decryption):
export class Keypair {
  // PRIVATE: Import a public or private key.
  static _import(plaintext_value, for_public_key) {
    var config = { name: 'RSA-OAEP', hash: { name: 'SHA-1' } };
    var format = for_public_key ? 'spki' : 'pkcs8';
    var usages = for_public_key ? ['encrypt'] : ['decrypt'];
    var key = Conversion.txt2bin(plaintext_value);
    return window.crypto.subtle.importKey(format, key, config, false, usages);
  }

  // Import a public key from a string of text:
  static importPublic(plaintext_key) {
    return this._import(plaintext_key, true);
  }

  // Import an encrypted private key from a string of text:
  static importPrivate(aes_key, iv, encrypted_key) {
    return Symmetric.decrypt(aes_key, iv, encrypted_key).then(this._import);
  }

  // Generate a (plain) public key and (encrypted) private key.
  static generate(aes_key) {
    var keypair = this._random();
    var public_key = this._exportPublic(keypair);
    var private_key = this._exportPrivate(keypair);

    var iv = AES.randomIV();
    var encrypted_key = Symmetric.encrypt(aes_key, iv, private_key);

    return Promise.all([public_key, encrypted_key])
      .then(function (result) {
        return { publicKey: result[0], encryptedPrivateKey: result[1], iv: iv };
      })
      .catch(function (exception) {
        console.log(exception.message); //TODO: This should be removed/replaced with an analytics call
      });
  }

  // PRIVATE: Generate a random keypair.
  static _random() {
    var rsa_config = {
      name: 'RSA-OAEP',
      modulusLength: 2048,
      publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
      hash: { name: 'SHA-1' },
    };

    return window.crypto.subtle.generateKey(rsa_config, true, ['encrypt', 'decrypt']);
  }

  // PRIVATE: Extract the public key and convert it into a displayable string:
  static _exportPublic(keypair_promise) {
    return keypair_promise
      .then(function (keypair) {
        return window.crypto.subtle.exportKey('spki', keypair.publicKey);
      })
      .then(function (buffer) {
        return Conversion.bin2txt(buffer);
      });
  }

  // PRIVATE: Extract the private key and convert it into a displayable string:
  static _exportPrivate(keypair_promise) {
    return keypair_promise
      .then(function (keypair) {
        return window.crypto.subtle.exportKey('pkcs8', keypair.privateKey);
      })
      .then(function (buffer) {
        return Conversion.bin2txt(buffer);
      });
  }
}
