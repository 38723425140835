import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { placeholderFunc } from '../../utils/commonFunctions';
import { ValidationWrap } from './styles/ValidationHeaderStyles';

export const ValidationHeader = ({ notificationText, setNotificationText = placeholderFunc }) => {
  const { pathname } = useLocation();

  const formatError = () => {
    const { text, error, errors } = notificationText;
    if (errors) return errors[0]['error'];
    if (error) return error;
    if (text) return text[0];
    return notificationText;
  };

  useEffect(() => {
    let timer;
    if (notificationText) {
      timer = setTimeout(() => setNotificationText(null), 8000);
    }
    return () => clearTimeout(timer);
  }, [notificationText]);

  if (notificationText && pathname.includes('register'))
    return (
      <ValidationWrap
        role={'ValidationHeader'}
        onClick={() => setNotificationText()}
        error={notificationText['type'] === 'error'}
      >
        {formatError()}
      </ValidationWrap>
    );
  return null;
};
