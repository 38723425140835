import styled from 'styled-components';

export const DropdownWrap = styled.div`
  flex-wrap: nowrap;
  flex-direction: row;
  width: 100%;
  margin: 0 0 1rem;
`;

export const DropdownButtonWrap = styled.div`
  display: flex;
  &.dd-active {
    svg .st2 {
      fill: white;
    }
  }
`;

export const DropdownButtonBar = styled.div`
  display: inline-flex;
  width: 80%;
  border-radius: 5px 0 0 5px;
  cursor: pointer;
  background-color: ${props => props.theme.fieldBgColor};
  color: ${props => props.theme.fieldTextColor};

  &.active {
    background-color: ${props => props.theme.adminSelectionColor};
    color: #ffffff;

    > div > svg {
      path {
        fill: #ffffff;
      }
    }
  }
`;

export const OptionsContainer = styled.div`
  position: relative;
  flex: 1;
  background-color: ${props => props.theme.fieldBgColor};
  border-left: 1px ${props => props.theme.fieldBorderColor} solid;
  border-radius: 0 5px 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  &.active {
    background-color: ${props => props.theme.adminSelectionColor};
    color: #ffffff;
  }
`;

export const DropdownOptionsWrap = styled.div`
  margin-top: 1rem;

  max-height: 50vh;
  overflow-y: auto;

  &:empty {
    display: none;
  }
`;
