import styled from 'styled-components';

export const PrimaryButton = styled.button`
  font-family: 'Lato';
  font-size: 0.9rem;
  background: ${props => props.theme.fieldBorderColor};
  color: ${props => props.theme.redButtonTextColor};
  position: relative;
  border-radius: 5px;
  outline: none;
  margin-left: 1.25rem;
  padding: 0.75rem 1.25rem;
  border: 0;
  cursor: pointer;

  &.disable {
    pointer-events: none;
    opacity: 0.5;
  }
  &:focus {
    background-color: ${props => props.theme.hoverRowBgColor};
  }
  &:hover {
    background-color: ${props => props.theme.hoverRowBgColor};
  }
  &:active {
    background-color: ${props => props.theme.highlightColor};
  }
`;
