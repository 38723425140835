import React, { Fragment, useContext, useState, useRef, useEffect } from 'react';
import Dashboard from './Dashboard';
import { Admin } from './Admin';
import ProtectedPage from '../common/nav/ProtectedPage';
import { PageContent } from './styles/PageStyle';
import Welcome from './Welcome';
import Profile from './Profile';
import InactivityModal from '../common/modals/InactivityModal';
import Plan from './Plan';
import { InactivityTimeoutContext } from '../context/InactivityTimeoutContextManagment';
import { addGlobalActivityEventListener, removeGlobalActivityEventListener } from '../utils/globalEventListeners';
import OrganisationApi from '../api/organisation/OrganisationApi';
import DataDashboard from './DataDashboard';

const PageSwitch = ({ displayDashboard, setDisplayDashboard }) => {
  const routes = [
    { path: '/plans', Component: Plan, exact: false },
    { path: '/profile', Component: Profile, exact: false },
    { path: '/admin', Component: Admin, exact: false },
    { path: '/welcome', Component: Welcome, exact: false },
    { path: '/datadashboard', Component: DataDashboard, exact: false },
    { path: '/', Component: Dashboard, exact: true },
  ];

  // Inactivity timer
  const { inactivityLength, setInactivityLength } = useContext(InactivityTimeoutContext);
  const [modal, setModal] = useState(false);
  const timer = useRef(false);
  const setTimer = () => {
    if (inactivityLength) {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        setModal(true);
      }, inactivityLength * 60000);
    } else {
      // if inactivityLength is undefined fetch latest inactivity value from the backend
      try {
        OrganisationApi.show().then(result => {
          // if api returns a result but no inactivity length OR is NOT a number, fall back to default of 10 minutes
          const minutes = typeof result.inactivity_length === 'number' ? result.inactivity_length : 10;
          setInactivityLength(minutes);
          clearTimeout(timer.current);
          timer.current = setTimeout(() => {
            setModal(true);
          }, minutes * 60000);
        });
      } catch {
        // if api fails, fall back to default of 10 minutes
        setInactivityLength(10);
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          setModal(true);
        }, 10 * 60000);
      }
    }
  };

  useEffect(() => {
    inactivityLength !== -1 && setTimer();
    addGlobalActivityEventListener(() => {
      clearTimeout(timer.current);
      inactivityLength !== -1 && setTimer();
    });
    OrganisationApi.show().then(result => {
      setInactivityLength(result?.inactivity_length || 10);
    });
    return () => {
      clearTimeout(timer.current);
      removeGlobalActivityEventListener();
    };
  }, []);

  const handleClick = () => {
    clearTimeout(timer.current);
    setModal(false);
    inactivityLength !== -1 && setTimer();
  };
  return (
    <Fragment>
      {modal && <InactivityModal onConfirm={handleClick} />}
      <PageContent role={'PageContent'}>
        {routes.map(({ path, Component, exact }) => (
          <ProtectedPage key={path} path={path} exact={exact}>
            <div className="page">
              <Component displayDashboard={displayDashboard} setDisplayDashboard={setDisplayDashboard} />
            </div>
          </ProtectedPage>
        ))}
      </PageContent>
    </Fragment>
  );
};

export default PageSwitch;
