import React from 'react';
import { placeholderFunc } from '../../../../utils/commonFunctions';
import { SquaredButtonStyle, TextWrap } from './styles/SquaredButtonStyles';

const SquaredButton = ({ text = 'default', onClick = placeholderFunc, activeView = '', className }) => (
  <SquaredButtonStyle
    role={'squared-button'}
    className={activeView === text ? `${className} active` : className}
    onClick={onClick}
  >
    <TextWrap>{text}</TextWrap>
  </SquaredButtonStyle>
);

export default SquaredButton;
