import React from 'react';

const CheckboxSwitch = ({ id, defaultChecked, onChange }) => (
  <div role={'checkbox-switch'} className="toggle-switch">
    <input
      role={defaultChecked ? 'CheckboxSwitchChecked' : 'CheckboxSwitchUnChecked'}
      type="checkbox"
      className="toggle-switch-checkbox"
      id={id}
      checked={defaultChecked}
      onChange={onChange}
    />
    {id ? (
      <label role={'label-check-box-switch'} className="toggle-switch-label" htmlFor={id}>
        <span className="toggle-switch-inner" />
        <span className="toggle-switch-switch" />
      </label>
    ) : null}
  </div>
);

export default CheckboxSwitch;
