import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  color: ${props => props.theme.fieldTextColor};
`;

export const Title = styled.div`
  flex: 1;
  font-size: 2.6rem;
  margin-bottom: 1rem;
  font-weight: bold;
`;

export const Subtitle = styled.div`
  flex: 1;
  margin: 0 10%;
  font-size: 1.1rem;
  line-height: 150%;
`;

export const PlanContainerWrap = styled.div`
  display: flex;
  flex: 9;
  justify-content: center;
  margin: 2rem 0;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ButtonWrap = styled.div`
  flex: 1;
  margin: 0 auto;
`;

export const CancelButton = styled.button`
  font-family: 'Lato';
  background-color: inherit;
  width: fit-content;
  padding: 0.8rem 1.5rem 0.8rem 1.5rem;
  border: 1px solid ${props => props.theme.textColor};
  color: ${props => props.theme.textColor};
  border-radius: 5px;
  font-size: 1.1rem;
  cursor: pointer;
  margin-top: 1rem;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: ${props => props.theme.highlightColor};
    color: ${props => props.theme.enabledButtonColor};
    border-color: transparent;
  }
`;
