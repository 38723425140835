import React, { Fragment, useState, useRef } from 'react';
import { TBody } from './styles/TableBodyStyles';
import { generateRows } from '../subFunctions/bodyFunctions';
import useCycle from '../../../utils/hooks/shortcuts/useCycle';

const TableBody = ({ handleRow, data, jsonKeys, params, columnWidths, isAdmin, role = 'table', headers, owner }) => {
  const tableRef = useRef();
  const [highlightedRow, setHighlight] = useState();
  useCycle('table-row');

  const enterPress = event => {
    if (!document.activeElement.className.includes('table-button') && event.key === 'Enter') {
      return handleRow && !isAdmin ? handleRow(data[highlightedRow]) : event.target.click();
    }
  };

  const onHighlight = rowIndex => {
    setHighlight(rowIndex);
  };

  const highlight = data[highlightedRow];

  return (
    <Fragment>
      <TBody id={'table'} role={role} ref={tableRef} onKeyDown={event => enterPress(event)}>
        {generateRows(jsonKeys, data, handleRow, onHighlight, params, columnWidths, highlight, role, headers, owner)}
      </TBody>
    </Fragment>
  );
};

export default TableBody;
