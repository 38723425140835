import styled from 'styled-components';

export const ViewSecretContainer = styled.div`
  display: flex;
  flex: 12;
  height: 100%;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    height: auto;
  }
`;

export const SecretWrapper = styled.div`
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  flex: 1.5;
  padding: 2.5rem 1.25rem 1.25rem;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  line-height: 1;
  background-color: ${props => props.theme.tableBgColor};

  button:first-of-type {
    margin-left: 0;
  }

  @media screen and (max-width: 767px) {
    border-radius: 0 0 5px 5px;
  }
`;

export const SecretsWrap = styled.div`
  width: 100%;
  line-height: 3rem;
`;
