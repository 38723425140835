import React from 'react';
import { Wrap } from './styles/TableLoadingStyles';
import Loading from '../../../images/loading_02.gif';

const TableLoading = () => {
  return (
    <Wrap role={'table-loading'}>
      <img role={'small-loading'} className="small-loading" src={Loading} alt="Loading..." />
    </Wrap>
  );
};

export default TableLoading;
