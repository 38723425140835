import React from 'react';
import { useHistory } from 'react-router-dom';
import { TableButtonWrap, TableButton } from './styles/TableButton';
import TableButtonDropdown from './subComponents/TableButtonDropdown';
import BaseApi from '../../api/BaseApi';
import { SingleTeamTableData } from '../../api/teams/TeamApiFunctions';
import { AdminClickable } from '../../components/table/subComponents/uniqueCells/subComponents/styles/Clickable';

const TableButtonBar = ({ array, tags, onClick, identifier = '', onRemove }) => {
  const limit = 2;
  const history = useHistory();
  const { location } = history;
  const { pathname } = location;
  const redirectTo = async id => {
    if (tags === 'chests') {
      const dataRow = await BaseApi.show('vaults', id);
      history.push({
        pathname: '/admin/chests/show',
        row: dataRow,
        prevPathname: pathname,
      });
    }
    if (tags === 'teams') {
      const dataRow = await SingleTeamTableData(id, { everything: true });
      history.push({
        pathname: '/admin/teams/show',
        row: dataRow,
        prevPathname: pathname,
      });
    }
  };

  if (array === undefined) return null;
  return (
    <TableButtonWrap role={'TableButtonWrap'}>
      <AdminClickable role={'AdminClickable'} onClick={onClick} />
      {array.map((arrayItem, i) => {
        if (!arrayItem) return null;
        if (i < limit)
          return (
            <TableButton
              role={'table-button'}
              className="table-button"
              key={arrayItem.id}
              onClick={() => redirectTo(arrayItem.id)}
              tabIndex="0"
              onKeyDown={e => (e.key === 'Enter' ? redirectTo(arrayItem.id) : null)}
            >
              {arrayItem['name']}
            </TableButton>
          );
        if (i === limit)
          return (
            <TableButtonDropdown
              key={i}
              array={array}
              index={i}
              onClick={redirectTo}
              tabIndex="0"
              identifier={identifier}
              onRemove={onRemove}
            />
          );
        return null;
      })}
    </TableButtonWrap>
  );
};

export default TableButtonBar;
