import styled from 'styled-components';

export const NotificationContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2vh 6vw;
  background-color: ${props => (props.error ? props.theme.error : props.theme.blueText)};
  justify-content: center;
  cursor: pointer;
  line-height: 1.5rem;
  box-sizing: border-box;
  font-size: 1.1rem;
  text-align: center;
  color: ${props => (props.error ? 'white' : props.theme.hoverRowColor)};
`;

export const Cross = styled.div`
  position: absolute;
  right: 36px;
  font-size: 33px;

  &:hover {
    color: ${({ theme }) => theme.sButtonBgColor};
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;
