import React from 'react';
import { FullText } from '../../components/secrets/subComponents/FullText';
import { SplitWrapper } from './styles/SplitTextStyles';

export const SplitText = ({ label1, text1, label2, text2 }) => {
  return (
    <SplitWrapper role={'split-text'}>
      <FullText text={text1} label={label1} />
      <FullText text={text2} label={label2} />
    </SplitWrapper>
  );
};
