import styled from 'styled-components';

export const TableTd = styled.div`
  width: ${props => props.cellWidth};
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 0 0 1.75rem;
  box-sizing: border-box;
  line-height: 150%;

  &:hover {
    white-space: pre-wrap;
  }

  @media screen and (max-width: 1440px) {
    font-size: 0.9rem;
  }

  .password-meter {
    height: 100%;
    width: 80%;
    div {
      align-self: center;
    }
  }
`;

export const MenuTd = styled.div`
  width: ${props => props.cellWidth};
  padding: 0 0 0 1.75rem;
  box-sizing: border-box;
`;
