import BaseApi from '../BaseApi';

export const ChestTableData = async (params = {}) => {
  const data = await BaseApi.all('vaults', params);
  if (!data || !data['items']) return 0;
  const chests = data.items;
  return chests.map(chest => ({
    id: chest.id,
    name: chest.name,
    teams: chest.teams,
    users: chest.users,
    assignments: chest.assignments,
    secrets: chest.secret_count,
    added: new Date(chest.created_at).toLocaleDateString('en-gb'),
    updated: new Date(chest.updated_at).toLocaleDateString('en-gb'),
    total: data.total,
  }));
};

export const ViewChestData = async id => {
  const chest = await BaseApi.show('vaults', id, { options: ['users'] });
  return chest;
};

export const GetSecretChest = secret => {
  if (secret === null) return [];
  if (secret && secret.vault) return [{ id: secret.vault.id, name: secret.vault.name }];
};

export const GetUserChests = async id => {
  const data = await BaseApi.all('vaults', {
    everything: true,
    options: ['users'],
  });
  if (!data) return 0;
  const chests = data.items;
  let userChests = [];
  chests.forEach(chest => {
    const result = chest.users.filter(user => user.id === id);
    if (result.length > 0) {
      userChests.push({ id: chest.id, name: chest.name });
    }
  });
  return userChests;
};

export const GetFormChests = async (params = {}, userAdmin = false) => {
  const data = await BaseApi.all('vaults', params);
  if (!data) return 0;
  const chests = userAdmin
    ? data.items
    : data.items.filter(chest => {
        if (!chest) return false;
        if (!chest.assignments || chest.assignments.length === 0) return false;
        const assigns = chest.assignments.filter(assign => assign.edit_access === true);
        return assigns.length > 0;
      });
  return chests.map(chest => ({
    id: chest.id,
    value: chest.name,
  }));
};

export const GetChestSecrets = async (id, userID, admin = false) => {
  const data = await BaseApi.show('vaults', id, { options: ['*'] });
  if (!data) return 0;
  const newFormat = data.secrets.map(secret => {
    if (admin) {
      return {
        adminChest: {
          id: data.id,
          name: data.name,
        },
        id: secret.id,
        name: secret.name,
        type: secret.category,
        teams: data.teams,
        strength: secret.strength,
      };
    }
    return {
      chest: {
        id: data.id,
        name: data.name,
        manager: CheckRights(userID, data),
      },
      id: secret.id,
      name: secret.name,
      type: secret.category,
      teams: data.teams,
      strength: secret.strength,
    };
  });
  return newFormat;
};

export const ManagerChestData = async id => {
  const data = await BaseApi.all('vaults', { options: '*' });
  if (!data) return 0;
  const chests = data.items;
  return chests.map(chest => ({
    id: chest.id,
    name: chest.name,
    secrets: chest.secret_count,
    manager: CheckRights(id, chest),
  }));
};

const CheckRights = (id, data) => {
  const matchedUser = data.users.filter(user => user.id === id);
  return matchedUser[0]?.manager || false; // unit test shows if matchedUser = [], would cause a crash.
};

export const GetChestUsers = async id => {
  const data = await BaseApi.show('vaults', id, { options: ['users'] });
  if (!data) return 0;
  return data.users;
};

export const CreateChest = async data => {
  const request = await BaseApi.create('vaults', data);
  return request;
};
