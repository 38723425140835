import styled from 'styled-components';

export const UrlWrapper = styled.input`
  width: 100%;
  background-color: inherit;
  font-family: 'Lato';
  border: none;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  outline: none;
  height: 1.25rem;
  line-height: 1.25rem;
  color: ${props => props.theme.fieldTextColor};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
