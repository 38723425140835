import React from 'react';
import { IconMenuButtonBarStyle } from '../styles/IconMenuButtonStyles';

const IconMenuButtonBar = ({ text }) => (
  <IconMenuButtonBarStyle>
    <div role={'icon-menu-buttonBar'}>{text}</div>
  </IconMenuButtonBarStyle>
);

export default IconMenuButtonBar;
