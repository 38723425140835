import React from 'react';
import {
  CardContainer,
  MainTextContainer,
  HeaderContainer,
  SubTextContainer,
  StatisticsContainer,
  IconTextWrap,
  CurrencyContainer,
  StatisticsTextWrap,
} from './styles/StatisticsCardStyles';
import { ReactComponent as ArrowTrendUp } from '../../../../images/arrow-trend-up.svg';
import { ReactComponent as ArrowTrendDown } from '../../../../images/arrow-trend-down.svg';
import _ from 'lodash';

const StatisticsCard = ({ data, dateRange, className }) => {
  const renderArrow = data => {
    return data.increase ? (
      <ArrowTrendUp className="up" role={'up'} />
    ) : (
      <ArrowTrendDown className="down" role={'down'} />
    );
  };

  const renderStatistics = data => {
    return (
      <StatisticsContainer>
        {data.currency && <CurrencyContainer>£</CurrencyContainer>}
        <StatisticsTextWrap>{data.stat}</StatisticsTextWrap>
      </StatisticsContainer>
    );
  };

  if (data === null || _.isEmpty(data)) return null;

  return (
    <CardContainer role="statistics-card">
      <HeaderContainer className={className}>{data.title}</HeaderContainer>
      <MainTextContainer>
        {renderStatistics(data)}
        <SubTextContainer>
          <IconTextWrap important={data.increase} role="percentage-icon">
            {renderArrow(data)}
            {data.percentage}%
          </IconTextWrap>
          <span>compared to {className === 'all-time' ? 'previous month' : `previous ${dateRange}`}</span>
        </SubTextContainer>
      </MainTextContainer>
    </CardContainer>
  );
};

export default StatisticsCard;
