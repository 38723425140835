import styled from 'styled-components';

export const DashboardContent = styled.div`
  overflow-y: auto;
  .dashboard-enter,
  .dashboard-appear {
    opacity: 0.01;
    transform: translateY(-150%);
  }

  .dashboard-enter-active,
  .dashboard-appear-active {
    opacity: 1;
    transform: translateY(0%);
    transition: opacity 500ms, transform 500ms;
  }

  .dashboard-exit {
    opacity: 1;
    transform: translateY(0%);
  }

  .dashboard-exit-active {
    opacity: 0.01;
    transform: translateY(-150%);
    transition: opacity 500ms, transform 500ms;
  }
`;

export const DashboardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto 1.5rem auto;
  width: 50%;

  @media screen and (max-width: 767px) {
    width: 90%;
  }
`;

export const DashboardLink = styled.span`
  color: ${props => props.theme.textColor};
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: ${props => props.theme.highlightColor};
  }

  i {
    padding-right: 0.5rem;
  }
`;
