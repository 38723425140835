import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem 5rem;
  box-sizing: border-box;
  max-height: 110px;
  align-items: center;
  background-color: #e6e8ed;

  .signInButton {
    font-family: 'Lato';
    font-size: 0.9rem;
    background-color: #252a41;
    color: #ffffff;
    border-radius: 5px;
    outline: none;
    padding: 0.75rem 1.75rem;
    border: 0;
    cursor: pointer;
    text-decoration: none;
  }

  @media screen and (min-width: 1024px) and (max-width: 1600px) {
    padding: 2rem 2rem;
  }
  @media screen and (max-width: 1023px) {
    padding: 2.5rem 5%;
  }
`;

export const LeftContainer = styled.div`
  flex: 6;
  position: relative;

  @media screen and (max-width: 1023px) {
    display: none;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex: 6;
  align-items: center;
  justify-content: center;
  background-color: #e6e8ed;
  padding: 20px;
`;

export const LeftHeader = styled.div`
  background-color: #e6e8ed;
  width: 200px;
  height: 47px;

  svg {
    position: relative;
    z-index: 10;
    display: block;
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 1023px) {
    width: 150px;
    height: 35px;
  }
  @media (-webkit-device-pixel-ratio: 1.5) {
    width: 135px;
    height: 31px;
  }
`;

export const LeftBackground = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

export const PageWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
`;

export const Content = styled.div`
  display: flex;
  flex: 10;
  flex-direction: row;
`;
