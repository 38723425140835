import React from 'react';
import { Wrap } from './styles/PlanPaymentStyles';
import StripePayment from '../forms/StripePayment';

const PlanPayment = ({ setPage, paymentMethod }) => {
  const data =
    typeof paymentMethod === 'string' || paymentMethod['data'][0] === undefined
      ? null
      : paymentMethod['data'][0]['billing_details'];
  return (
    <Wrap>
      <StripePayment data={data} setView={setPage} formContext={'Pay'} planFlag />
    </Wrap>
  );
};

export default PlanPayment;
