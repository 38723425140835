import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AdminSettingsWrap } from './styles/AdminSettingsStyles';
import { SettingsContainer, NavContainer, OptionWrap } from './styles/AdminSettingsStyles';
import GeneralSettings from './GeneralSettings';
import SettingsNav from '../../nav/SettingsNav';
import AuditLogSettings from './AuditLogSettings';
import { BillingSettings } from './BillingSettings';
import OwnerActionsSettings from './OwnerActionsSettings';
import { OptionContainer } from '../../../common/settings/styles/SettingStyles';

const AdminSettings = () => {
  const { state } = useLocation();
  const plansFlag = state && state['fromPlans'];
  const startingLocation = () => (plansFlag ? 'Billing' : 'General');
  const [settings, setSettings] = useState(startingLocation());

  const SettingOptions = () => {
    if (settings === 'General') return <GeneralSettings />;
    if (settings === 'Audit Log') return <AuditLogSettings />;
    if (settings === 'Billing') return <BillingSettings />;
    if (settings === 'Owner Actions') return <OwnerActionsSettings />;
  };

  return (
    <AdminSettingsWrap>
      <SettingsContainer>
        <NavContainer>
          <SettingsNav activeSettings={settings} setSettings={setSettings} />
        </NavContainer>
        <OptionWrap className={settings === 'Audit Log' && 'noScroll'}>
          <OptionContainer>{SettingOptions()}</OptionContainer>
        </OptionWrap>
      </SettingsContainer>
    </AdminSettingsWrap>
  );
};

export default AdminSettings;
