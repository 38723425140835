import styled from 'styled-components';

export const TextButton = styled.button`
  background: ${props => props.theme.bgColor};
  color: ${props => props.theme.textColor};
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  cursor: pointer;
  font-family: 'Lato';
  white-space: nowrap;

  svg {
    margin-left: 0.25rem;
    margin-right: -1rem;
  }

  &:focus {
    outline: none;
  }

  @media (-webkit-device-pixel-ratio: 1.5) {
    font-size: 0.9rem;

    svg {
      width: 12px;
    }
  }
`;
