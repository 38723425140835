import Password from '../../images/tutorialImages/Artboard1.png';
import Search from '../../images/tutorialImages/Artboard2.png';
import Navigation from '../../images/tutorialImages/Artboard3.png';

export const slides = [
  {
    heading: 'Never forget another password',
    text: "Recrypt is a password manager built for developers, by developers. Keep all of your credentials under lock and key, using the search and navigation area. Lock your secrets away in 'Chests', helping keep you organised and secure",
    image: `${Password}`,
  },
  {
    heading: 'Search Bar',
    text: 'Access everything you need all from one screen with Recrypt’s modern UI. Whether that be searching for a secret or adding a secret, you don’t need to move off of the page. Your most visited secrets will always be listed at the top of the search bar for quick and easy access to your credentials.',
    image: `${Search}`,
  },
  {
    heading: 'Navigation',
    text: 'Time is of the essence and that’s why Recrypt can be navigated completely using the keyboard. We’ve provided a list of keyboard shortcuts so you can access any part of Recrypt from anywhere within the app – it’s that simple. Don’t worry though, you can still explore Recrypt using your mouse.',
    image: `${Navigation}`,
  },
];
