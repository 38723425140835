import React from 'react';
import { TableTh } from './styles/UniqueHeadersStyles';
import { Arrow } from '../../../../common/arrow/Arrow';

export const getHeaderCell = key => keyHeaderPairs[key] || keyHeaderPairs['default'];

const _noTitleHeader = ({ idx, col, selectedHeader, handleHeaderClick, headerWidth }) => (
  <TableTh headerWidth={headerWidth} key={idx} onClick={e => handleHeaderClick(e)}>
    {generateArrow(col, selectedHeader)}
  </TableTh>
);

const _defaultHeaderCell = ({ idx, col, selectedHeader, handleHeaderClick, headerWidth }) => (
  <TableTh
    role={'default-header-cell'}
    className={col === 'Added'}
    headerWidth={headerWidth}
    key={idx}
    onClick={e => handleHeaderClick(e)}
  >
    {col}
    {generateArrow(col, selectedHeader)}
  </TableTh>
);

const keyHeaderPairs = {
  Icon: _noTitleHeader,
  Actions: _noTitleHeader,
  AdminActions: _noTitleHeader,
  BillingActions: _noTitleHeader,
  AccountsActions: _noTitleHeader,
  default: _defaultHeaderCell,
};

const generateArrow = (col, selectedHeader) => {
  if (col !== selectedHeader['header']) return;
  if (selectedHeader['header'] === null) return;
  return <Arrow arrowState={selectedHeader['reverse']} />;
};
