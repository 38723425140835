import styled from 'styled-components';

export const FormHeader = styled.div`
  flex-shrink: 0;
  color: ${props => props.theme.textColor};
  background: ${props => props.theme.formBgColor};
  border-bottom: 1px solid ${props => props.theme.searchBorderColor};
  border-radius: 5px 5px 0 0;
  font-size: 1.6rem;
  font-weight: 400;
  padding: 1.25rem 1.75rem 1.25rem 1.75rem;
`;
