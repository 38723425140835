import { Conversion } from './internal/conversion';

// Handles anything directly related to a plain-text, user-provided password:
export class Password {
  static _generate(email, password, extractable) {
    var aes_config = { name: 'AES-CBC', length: 256 };

    var pbkdf2_config = {
      name: 'PBKDF2',
      salt: Conversion.str2buf(email.toLowerCase()),
      iterations: extractable ? 5001 : 5000,
      hash: 'SHA-256',
    };

    return window.crypto.subtle
      .importKey('raw', Conversion.str2buf(password), { name: 'PBKDF2' }, false, ['deriveKey'])
      .then(function (base_key) {
        return window.crypto.subtle.deriveKey(pbkdf2_config, base_key, aes_config, true, ['encrypt', 'decrypt']);
      });
  }

  // Generate the master (un)lock key from an email/password combination:
  static masterKey(email, password) {
    return this._generate(email, password, false);
  }

  // Pre-hash a password before sending it to the server (for further hashing):
  static prehash(email, password) {
    return this._generate(email, password, true)
      .then(function (hash) {
        return window.crypto.subtle.exportKey('raw', hash);
      })
      .then(function (data) {
        return Conversion.bin2txt(data);
      });
  }
}
