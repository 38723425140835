import React, { Fragment, useState, useEffect, useContext } from 'react';
import Mousetrap from 'mousetrap';
import { SettingsNavContent, SettingsNavBar, SettingsList, ListElement } from './styles/SettingsNavStyle';
import OrganisationApi from '../../api/organisation/OrganisationApi';
import GenericLoading from '../../common/genericLoading/GenericLoading';
import { UserContext } from '../../context/UserProvider';

const SettingsNav = ({ activeSettings, setSettings }) => {
  const { user } = useContext(UserContext);
  const [owner, setOwner] = useState(false);
  const [loading, setLoading] = useState(false);

  const settingsArray = ['General', 'Audit Log', 'Billing', 'Owner Actions'];

  useEffect(() => {
    let isMounted = true;
    if (isMounted) setLoading(true);
    OrganisationApi.show().then(result => {
      if (user.id === result.owner_id) {
        if (isMounted) setOwner(true);
      }
      if (isMounted) setLoading(false);
    });
    return () => (isMounted = false);
  }, [user]);

  // useEffect for keyboard shortcuts.
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      Mousetrap.bind('up', handleUp);
      Mousetrap.bind('down', handleDown);
    }
    return () => {
      isMounted = false;
      Mousetrap.unbind('up');
      Mousetrap.unbind('down');
    };
  });

  const handleUp = () => {
    const activeIndex = settingsArray.findIndex(setting => setting === activeSettings);
    let newIndex = activeIndex - 1;
    if (activeIndex > 0) setSettings(settingsArray[newIndex]);
  };

  const handleDown = () => {
    const activeIndex = settingsArray.findIndex(setting => setting === activeSettings);
    let newIndex = activeIndex + 1;
    if (!owner && activeIndex < settingsArray.length - 2) setSettings(settingsArray[newIndex]);
    if (activeIndex < settingsArray.length - 1) setSettings(settingsArray[newIndex]);
  };

  return (
    <Fragment>
      {loading ? (
        <GenericLoading />
      ) : (
        <SettingsNavContent>
          <SettingsNavBar>
            <SettingsList>
              {settingsArray.map(setting => {
                return setting === 'Owner Actions' && owner !== true ? null : (
                  <ListElement
                    role={'ListElement'}
                    key={setting}
                    className={activeSettings === setting ? 'active' : 'non-active'}
                    onClick={() => setSettings(setting)}
                  >
                    {setting}
                  </ListElement>
                );
              })}
            </SettingsList>
          </SettingsNavBar>
        </SettingsNavContent>
      )}
    </Fragment>
  );
};

export default SettingsNav;
