import React, { useState } from 'react';
import { CopyText, IconButton } from './styles/FullTextStyles';
import copy from 'copy-to-clipboard';
import { TextInput } from '../../../common/forms/TextInput';

export const FullText = ({ label, text }) => {
  const [copied, setCopy] = useState(false);
  const [visibility, toggleVisibility] = useState(false);

  const writeToClipboard = text => {
    copy(text);
    setCopy(true);
    setTimeout(() => setCopy(false), 1000);
  };

  const renderCopy = () => {
    return copied ? (
      <CopyText>Copied</CopyText>
    ) : (
      <IconButton role={'icon-button'} className="fas fa-copy" onClick={() => writeToClipboard(text)}></IconButton>
    );
  };

  const renderEye = () => (
    <IconButton
      role={'render-eye-button'}
      onClick={() => toggleVisibility(!visibility)}
      className={visibility ? 'far fa-eye-slash' : 'far fa-eye'}
    ></IconButton>
  );

  return (
    <TextInput
      value={text}
      label={label}
      readOnly
      type={label === 'Password' || label === 'password' ? (visibility ? 'text' : 'password') : 'text'}
      rightIcon={renderCopy}
      leftIcon={label === 'Password' || label === 'password' ? renderEye : null}
    />
  );
};
