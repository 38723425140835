import { FetchInfo } from './Header';
import LocalStore from './LocalStore';

const ApiRequest = async (type, path, params) => {
  const url = `${process.env.REACT_APP_API_URL}/${path}`;
  const authorization = new LocalStore().get('token');
  const response = await fetch(url, FetchInfo(type, params, authorization));
  if (`${response.status}`.slice(0, 2) === '20') {
    if (type === 'DELETE') return 1;
    const json = await response.json();
    return json;
  } else if (response.status === 401) {
    const json = await response.json();
    if (json.errors) return json.errors[0];
    if (typeof json === 'string') return json;
    return json;
  } else if (response.status === 400) {
    const json = await response.json();
    if (json.errors) return json.errors[0];
    if (typeof json === 'string') return json;
  }
  return 1;
};

export default ApiRequest;
