import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ProtectedRoute from '../../common/nav/ProtectedRoute';
import AdminTeams from '../admin/adminTeams/AdminTeams';
import AdminChests from '../admin/adminChests/AdminChests';
import AdminSettings from '../admin/adminSettings/AdminSettings';
import UnknownRoute from '../../common/nav/UnknownRoute';
import AdminChestsShow from '../admin/adminChests/AdminChestsShow';
import { AdminChestsAdd } from '../admin/adminChests/AdminChestsAdd';
import { AdminTeamAdd } from '../admin/adminTeams/AdminTeamAdd';
import AdminTeamEdit from '../admin/adminTeams/AdminTeamEdit';
import AdminSecretsShow from '../admin/adminSecrets/AdminSecretsShow';
import { AdminSecretsEdit } from '../admin/adminSecrets/AdminSecretsEdit';
import { AdminSecretAdd } from '../admin/adminSecrets/AdminSecretAdd';
import { AdminUserAdd } from '../admin/adminUsers/AdminUserAdd';
import AdminTeamsShow from '../admin/adminTeams/AdminTeamsShow';
import AdminUsersShow from '../admin/adminUsers/AdminUsersShow';
import AdminChestsEdit from '../admin/adminChests/AdminChestsEdit';
import { AdminUserEdit } from '../admin/adminUsers/AdminUserEdit';
import DeletedSecrets from '../admin/adminChests/DeletedSecrets';

const AdminRoutes = ({ searchText = '', context = '', history }) => (
  <Switch>
    <ProtectedRoute
      component={AdminSettings}
      exact
      path="/admin/settings"
      searchText={searchText}
      context={context}
      history={history}
    />
    <ProtectedRoute
      component={AdminChestsShow}
      exact
      path="/admin/chests/show"
      searchText={searchText}
      context={context}
      history={history}
    />
    <ProtectedRoute
      component={AdminChestsAdd}
      exact
      path="/admin/chests/new"
      searchText={searchText}
      context={context}
      history={history}
    />
    <ProtectedRoute
      component={AdminChestsEdit}
      exact
      path="/admin/chests/edit"
      searchText={searchText}
      context={context}
      history={history}
    />
    <ProtectedRoute
      component={AdminSecretsShow}
      exact
      path="/admin/chests/secret"
      searchText={searchText}
      context={context}
      history={history}
    />
    <ProtectedRoute
      component={AdminSecretAdd}
      exact
      path="/admin/chests/secret/new"
      searchText={searchText}
      context={context}
    />
    <ProtectedRoute
      component={AdminSecretsEdit}
      exact
      path="/admin/chests/secret/edit"
      searchText={searchText}
      context={context}
    />
    <ProtectedRoute
      component={DeletedSecrets}
      exact
      path="/admin/chests/deleted"
      searchText={searchText}
      context={context}
      history={history}
    />
    <ProtectedRoute
      component={AdminTeamAdd}
      exact
      path="/admin/teams/new"
      searchText={searchText}
      context={context}
      history={history}
    />
    <ProtectedRoute
      component={AdminTeamEdit}
      exact
      path="/admin/teams/edit"
      searchText={searchText}
      context={context}
      history={history}
    />
    <ProtectedRoute
      component={AdminTeamsShow}
      exact
      path="/admin/teams/show"
      searchText={searchText}
      context={context}
      history={history}
    />
    <ProtectedRoute
      component={AdminUsersShow}
      exact
      path="/admin/teams/user"
      searchText={searchText}
      context={context}
      history={history}
    />
    <ProtectedRoute
      component={AdminUserAdd}
      exact
      path="/admin/teams/user/new"
      searchText={searchText}
      context={context}
    />
    <ProtectedRoute
      component={AdminUserEdit}
      exact
      path="/admin/teams/user/edit"
      searchText={searchText}
      context={context}
    />
    <ProtectedRoute
      component={AdminChests}
      exact
      path="/admin/chests"
      searchText={searchText}
      context={context}
      history={history}
    />
    <ProtectedRoute
      component={AdminTeams}
      exact
      path="/admin/teams"
      searchText={searchText}
      context={context}
      history={history}
    />
    <Route exact path="/admin">
      <Redirect to="/admin/chests" />
    </Route>
    <Route component={UnknownRoute} />
  </Switch>
);

export default AdminRoutes;
