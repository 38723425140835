import BaseApi from '../BaseApi';

export const SecretTableData = async (params = {}) => {
  const data = await BaseApi.all('secrets', params);
  if (!data) return 0;
  if (!data.items) return 0;
  const secrets = data.items;
  const attr = params['everything'] ? 'adminChest' : 'chest';
  return secrets.map(secret => ({
    id: secret.id,
    name: secret.name,
    type: secret.category,
    [attr]: secret.vault,
    teams: secret.vault.teams,
    strength: secret.strength,
    total: data.total,
  }));
};

export const ViewSecretData = async id => {
  const secret = await BaseApi.show('secrets', id, { options: ['*'] });
  return secret;
};

export const UpdateSecretData = async (id, params) => {
  const secret = await BaseApi.update('secrets', id, params);
  return secret;
};

export const DeleteSecret = async id => {
  const secret = await BaseApi.softDestroy(id);
  return secret;
};
