import React from 'react';
import { PageWrap, Content } from '../components/register/styles/LayoutStyles';
import { RegisterHeader } from '../components/register/registerHeader';
import { LeftScreen } from '../components/register/LeftScreen';
import { RightScreen } from '../components/register/RightScreen';

export const Register = () => {
  return (
    <PageWrap>
      <RegisterHeader />
      <Content>
        <LeftScreen />
        <RightScreen />
      </Content>
    </PageWrap>
  );
};
