import styled from 'styled-components';

export const IconArea = styled.div`
  visibility: hidden;
  display: flex;
  align-items: center;

  > svg {
    margin: 0 0.75rem;
    min-width: 18px;

    @media (-webkit-device-pixel-ratio: 1.5) {
      min-width: 0;
      max-width: 12px;
    }
  }

  span {
    white-space: nowrap;
    font-size: 0.9rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
