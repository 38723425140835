import React, { Fragment } from 'react';
import { MainContentContainer, ExtraGapContainer } from './styles/AllTimeViewStyles';
import StatisticsCard from './subComponents/StatisticsCard';

const AllTimeView = ({ data }) => {
  return (
    <Fragment>
      <ExtraGapContainer />
      <MainContentContainer role="all-time-view">
        {data.length > 0 &&
          data.map(item => {
            return <StatisticsCard key={item.id} data={item} className="all-time" />;
          })}
      </MainContentContainer>
    </Fragment>
  );
};

export default AllTimeView;
