import styled from 'styled-components';

export const TextWrapper = styled.div`
  margin-bottom: 1.5rem;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border: ${({ error, theme }) => (!error ? `1px solid ${theme.textOutlineColor}` : `1px solid ${theme.error}`)};
  border-radius: 5px;
  align-items: center;
  transition: border-color 0.3s ease-in-out;

  &:focus-within {
    border-color: ${props => props.theme.highlightColor};
    outline: none;
  }

  &#password {
    background-color: ${props => props.theme.fieldBgColor};
    border-bottom: 1px solid ${props => props.theme.fieldTextColor};
    border-radius: 5px 5px 0 0;
  }

  &.addSecretName {
    flex-grow: 0;
  }

  &.admin-form-name {
    margin-right: 1.25rem;
  }

  @media screen and (max-width: 767px) {
    /* flex-direction: column; */

    &#password {
      border-bottom: 0;
    }
  }
`;
