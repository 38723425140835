import styled from 'styled-components';

export const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: inherit;
  width: 100%;
  box-sizing: border-box;
  color: ${props => props.theme.fieldTextColor};

  &.header-row {
    color: ${props => props.theme.placeholderColor};
  }

  &.selected {
    background-color: ${props => props.theme.highlightColor};
    color: ${props => props.theme.hoverRowColor};
  }

  &.highlight:hover {
    background-color: ${props => props.theme.highlightColor};
    color: ${props => props.theme.hoverRowColor};
  }
`;

export const CellName = styled.div`
  width: 50%;
  height: 3rem;
  background-color: inherit;
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
`;

export const CellPermissions = styled.div`
  width: 45%;
  height: 3rem;
  background-color: inherit;
  display: flex;
  align-items: center;
  box-sizing: border-box;

  &.padding-left {
    padding-left: 0.75rem;
  }
`;

export const CellX = styled.div`
  width: 5%;
  height: 3rem;
  background-color: inherit;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;

  &:focus {
    color: ${props => props.theme.highlightColor};
    outline: none;
  }
`;
