import BaseApi from '../BaseApi';
import { formatDate, formatAuditLogDate, formatUser } from '../../utils/commonFunctions';

// Roughly only 10% of audit log entries have a request !== null / user_string !== '[SYSTEM]
export const UserLogs = async (searchData, jsonLimit, offset) => {
  const auditLogs = await BaseApi.all('audit_log_entries', {
    filter: searchData,
    limit: jsonLimit,
    offset: offset,
    order: 'desc',
  });
  if (auditLogs.items.length === 0) return [];
  const auditData = auditLogs.items;
  return auditData.map(auditLog => {
    return {
      date: formatDate(auditLog.created_at),
      activity: auditLog.action,
      id: auditLog.id,
      total: auditLogs.total,
    };
  });
};

export const AllUserLogs = async (jsonLimit, offset) => {
  const auditLogs = await BaseApi.all('audit_log_entries', {
    limit: jsonLimit,
    offset: offset,
    order: 'desc',
    everything: true,
  });

  const items = auditLogs.items.map(auditLog => {
    return {
      date: formatAuditLogDate(auditLog.created_at),
      activity: auditLog.action,
      user: formatUser(auditLog.user_string),
    };
  });
  return { total: auditLogs.total, items: items };
};
