import React, { useContext, useState, useEffect, useRef } from 'react';
import Mousetrap from 'mousetrap';
import { useHistory } from 'react-router-dom';
import { OptionsModalContext } from '../../../../../context/OptionsModalContext';
import { IconArea } from './styles/BurgerMenuDropdownStyles';
import { AdminTeamContext, AdminContext } from '../../../../../context/AdminContext';
import { UserTableData, DeleteUser } from '../../../../../api/users/UserApiFunctions';
import { TeamTableData } from '../../../../../api/teams/TeamApiFunctions';
import { BurgerMenuDropdown } from './BurgerMenuDropdown';
import { ModalContext } from '../../../../../context';
import { UserContext } from '../../../../../context/UserProvider';

export const AdminUserActions = ({ row, highlightedRow }) => {
  const { user } = useContext(UserContext);
  const [optionsVisible, setOptions] = useContext(OptionsModalContext);
  const [highlightedOption, setHighlightedOption] = useState();
  const { setModal } = useContext(ModalContext);
  const history = useHistory();
  const setTeamTags = useContext(AdminContext).teamTags[1];
  const setData = useContext(AdminTeamContext).adminTeamTable[1];
  const [toggle, setToggle] = useState(false);
  const dropdownRef = useRef();
  const [owner, setOwner] = useState(false);

  useEffect(() => {
    if (row.id === user.owner_id) setOwner(true);
  });
  // useEffect for keyboard shortcuts
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      Mousetrap.bind('right', e => handleOptions(highlightedRow, e));
    }
    return () => {
      isMounted = false;
      Mousetrap.unbind('right');
    };
  });

  // useEffect for toggling the dropdown menu
  useEffect(() => {
    const toggleDropdown = e => {
      if (toggle && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setOptions({});
        setHighlightedOption('');
        setToggle(false);
      }
    };
    document.addEventListener('mousedown', toggleDropdown);
    return () => document.removeEventListener('mousedown', toggleDropdown);
  }, [toggle]);

  const menuOptions = () => {
    return user.id === row.id || owner
      ? [
          { text: 'Edit User', handleClick: () => handleMenuOptionClick('edit'), styleId: 'user-edit' },
          { text: 'Show User', handleClick: () => handleMenuOptionClick('show'), styleId: 'user-show' },
        ]
      : [
          { text: 'Edit User', handleClick: () => handleMenuOptionClick('edit'), styleId: 'user-edit' },
          { text: 'Show User', handleClick: () => handleMenuOptionClick('show'), styleId: 'user-show' },
          {
            text: 'Remove',
            handleClick: () => deletePopUp(),
            styleId: 'user-remove',
          },
        ];
  };

  const refreshTable = async () => {
    setTeamTags(await TeamTableData({ everything: true }));
    setData(await UserTableData({ everything: true }));
  };

  const handleMenuOptionClick = option => {
    setOptions({});
    const path = option === 'edit' ? '/admin/teams/user/edit' : '/admin/teams/user';
    history.push({
      pathname: path,
      row,
      prevPathname: history.location.pathname,
    });
  };

  const handleDeleteModalConfirm = () => {
    DeleteUser(row.id).then(() => {
      refreshTable();
      setModal();
    });
  };

  const deletePopUp = () =>
    setModal({
      key: 'Confirm',
      params: {
        icon: 'alert',
        question: `Are you sure you want to delete ${row['name']}?`,
        subtext: 'This user will not be recoverable.',
        confirmText: `Yes, delete ${row['name']}`,
        cancelText: `No, keep ${row['name']}`,
        confirmAction: handleDeleteModalConfirm,
        cancelAction: () => setModal(),
      },
    });

  const handleOptions = (row, e) => {
    setHighlightedOption('');
    optionsVisible.row !== row.id ? setOptions({ row: row.id, above: calculateOptionsPosition(e) }) : setOptions({});
  };

  const calculateOptionsPosition = e => {
    return e.clientY > window.innerHeight * 0.7 || window.innerHeight - e.clientY < 200 ? true : false;
  };

  return (
    <div style={{ position: 'relative' }} role={'dropdown-toggle'} ref={dropdownRef} onClick={() => setToggle(!toggle)}>
      <IconArea role={'Icon'} onClick={e => handleOptions(row, e)} className="icon-area">
        <i className="fas fa-bars"></i>
      </IconArea>
      {optionsVisible.row === row.id && (
        <BurgerMenuDropdown
          menuOptions={menuOptions()}
          highlightedOption={highlightedOption}
          setHighlightedOption={setHighlightedOption}
        />
      )}
    </div>
  );
};
