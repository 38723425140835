import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Mousetrap from 'mousetrap';
import { TextInput } from '../../common/forms/TextInput';
import { FormWrapper, SectionWrapper, SectionHeadingWrapper } from './styles/AdminFormStyle.jsx';
import { Icon } from '../../common/Icon';
import { blur, focus, checkActiveElement } from '../../utils/commonFunctions';
import { ModalContext } from '../../context';
import LinkFormSection from './subComponents/LinkFormSection';
import { reverseAll } from '../../utils/arrayFunctions';

export const ChestForm = ({ chestName, setChestName, teams, setTeams, users, setUsers }) => {
  const { setModal } = useContext(ModalContext);
  const history = useHistory();
  const addedObj = arr => arr.filter(obj => obj.added === true);
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      Mousetrap.bind('esc', escShortcut);
      Mousetrap.bind('i', () => focus('chest-name-input'), 'keyup');
    }
    return () => {
      isMounted = false;
      Mousetrap.unbind('esc');
      Mousetrap.unbind('i', 'keyup');
    };
  });

  const handleTeamsModalConfirm = array => {
    setTeams(reverseAll(teams, array, 'added'));
    setModal();
  };

  const handleUsersModalConfirm = array => {
    setUsers(reverseAll(users, array, 'added'));
    setModal();
  };

  const escShortcut = () =>
    checkActiveElement('chest-name-input')
      ? blur('chest-name-input')
      : history.push({
          pathname: '/admin/chests',
          prevPathname: history.location.pathname,
        });

  const renderLinkedTeams = () => <LinkFormSection selectedArr={teams} setSelectedArr={setTeams} name={'Team'} />;

  const renderChestUsers = () => <LinkFormSection selectedArr={users} setSelectedArr={setUsers} name={'User'} />;

  const teamLinkModal = () =>
    setModal({
      key: 'Link',
      params: {
        headerText: 'Link: Teams',
        data: teams,
        confirmAction: handleTeamsModalConfirm,
        cancelAction: () => setModal(),
      },
    });

  const userLinkModal = () =>
    setModal({
      key: 'Link',
      params: {
        headerText: 'Link: Users',
        data: users,
        confirmAction: handleUsersModalConfirm,
        cancelAction: () => setModal(),
      },
    });

  return (
    <FormWrapper id="form-wrapper">
      <SectionWrapper className="input-padding">
        <TextInput
          placeholder="Chest Name"
          label="Chest Name"
          value={chestName}
          handleChange={value => setChestName(value)}
          autoFocus
          mousetrap
          id="chest-name-input"
          maxLength="25"
        />
      </SectionWrapper>
      <SectionWrapper>
        <SectionHeadingWrapper
          tabIndex="0"
          onClick={teamLinkModal}
          onKeyPress={e => (e.key === 'Enter' ? teamLinkModal() : undefined)}
        >
          <Icon name="add" width="21px" />
          Add teams to this chest
        </SectionHeadingWrapper>
        {addedObj(teams).length > 0 && renderLinkedTeams()}
      </SectionWrapper>
      <SectionWrapper>
        <SectionHeadingWrapper
          role={'SectionHeadingWrapper'}
          className={!addedObj(teams).length > 0 && 'off'}
          onClick={userLinkModal}
          tabIndex={addedObj(teams).length > 0 ? '0' : ''}
          onKeyPress={e => (e.key === 'Enter' ? userLinkModal() : undefined)}
        >
          <Icon name="add" width="21px" />
          Edit user permissions
        </SectionHeadingWrapper>
        {addedObj(users).length > 0 && renderChestUsers()}
      </SectionWrapper>
    </FormWrapper>
  );
};
