import styled from 'styled-components';

export const TableHeaderWrap = styled.div`
  position: -webkit-sticky; /* Safari */
  z-index: 12;
  position: sticky;
  top: 0;
  background-color: ${props => props.theme.tableBgColor};
  color: ${props => props.theme.textColor};
  border-bottom: 1px solid ${props => props.theme.tableHeaderBorder};
  div:hover {
    background-color: ${props => props.theme.tableBgColor};
  }
`;
