import React, { useState, Fragment, useContext, useEffect, useMemo } from 'react';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import Mousetrap from 'mousetrap';
import { AdminChestAddWrap, FakeArea } from '../adminChests/styles/AdminChestAddStyles';
import { SaveFooter } from '../adminFooter/footers/SaveFooter';
import { UserForm } from '../../forms/UserForm';
import { ViewUserData } from '../../../api/users/UserApiFunctions';
import { GetAddedTeams, TeamTableData } from '../../../api/teams/TeamApiFunctions';
import BaseApi from '../../../api/BaseApi';
import { getValidation } from '../../../utils/validationSuite/validationIndex';
import { useNotification } from '../../../common/notification/Notification';
import LogoutRedirect from '../../../common/nav/LogoutRedirect';
import { reshareData } from '../../../api/secretKeys/SecretKeyApiFunctions';
import OrganisationApi from '../../../api/organisation/OrganisationApi';
import GenericFormLoading from '../../../common/genericLoading/GenericFormLoading';
import { AdminContext } from '../../../context/AdminContext';
import { checkActiveElement } from '../../../utils/commonFunctions';
import { AdminUserFormWrap } from './styles/AdminUserWrapStyles';
import { UserContext } from '../../../context/UserProvider';

/* Component for handling the admin process of inviting the user
  to the system, updating permissions and resharing secrets */
export const AdminUserEdit = () => {
  const [teams, setTeams] = useState([]);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [owner, setOwner] = useState(false);
  const [loading, setLoading] = useState(false);
  const [prevMemberships, setPrevMemberships] = useState([]);
  const setTeamTags = useContext(AdminContext).teamTags[1];
  const { setNotificationText } = useNotification();
  const location = useLocation();
  const { user } = useContext(UserContext);
  const [userId, setUserId] = useState('');
  const row = useMemo(() => location['row'] || (location['state'] && location['state']['row']) || [], [location]);
  const history = useHistory();

  useEffect(() => {
    if (row.length !== 0) {
      ViewUserData(row.id).then(async user => {
        if (user) {
          setUserName(user.name);
          setUserEmail(user.email);
          setUserId(user.id);
          setAdmin(user.administrator);
          const org = await OrganisationApi.show();
          if (user.id === org.owner_id) {
            setOwner(true);
          }
          const teamData = await GetAddedTeams(user);
          const additionalTeams = teamData.filter(team => team.default === false);
          const addedTeams = additionalTeams.filter(team => team.added === true);
          setPrevMemberships(addedTeams);
          setTeams(additionalTeams);
        } else {
          setRedirect(true);
        }
      });
    }
  }, [row]);

  // useEffect for keyboard shortcuts
  useEffect(() => {
    Mousetrap.bind('shift+s', shiftS);
    Mousetrap.bind('enter', enterSC);

    return () => {
      Mousetrap.unbind('shift+s');
      Mousetrap.unbind('enter');
    };
  });
  const enterSC = () => {
    if (document.activeElement === document.getElementsByClassName('footer-button')[0]) {
      updateUser();
    } else if (document.activeElement === document.getElementsByClassName('footer-button')[1]) {
      history.goBack();
    }
  };
  const shiftS = () => {
    return checkActiveElement('user-name-input') || checkActiveElement('user-email-input') ? null : updateUser();
  };

  // Function that handles the user update when save is clicked.
  const updateUser = () => {
    const validationId = 'UserForm';
    const addedTeams = teams.filter(team => team.added === true);
    const params = {
      data: { userName, addedTeams },
      setNotificationText,
    };
    if (getValidation(validationId)(params)) {
      setLoading(true);
      const teamData = updateMemberships(addedTeams);
      const data = {
        name: userName,
        administrator: admin,
        memberships_attributes: teamData,
      };
      BaseApi.update('users', row.id, data).then(async () => {
        await reshareData(row.id);
        setTeamTags(await TeamTableData({ everything: true }));
        history.push({
          pathname: '/admin/teams',
          prevPathname: location.pathname,
        });
      });
    }
  };

  const updateMemberships = addedTeams => {
    let teamData = [];
    prevMemberships.forEach(prevTeam => {
      const teamIndex = addedTeams.findIndex(team => team.id === prevTeam.id);
      if (!addedTeams[teamIndex]) {
        teamData.push({
          id: prevTeam.membership.id,
          _destroy: 1,
        });
      } else {
        teamData.push({ edit_access: addedTeams[teamIndex].edit_access || false, id: prevTeam.membership.id });
      }
      addedTeams.splice(teamIndex, 1);
    });
    addedTeams.forEach(team => {
      teamData.push({ edit_access: team.edit_access || false, team_id: team.id });
    });
    return teamData;
  };
  if (row.length === 0) return <Redirect to={'/admin/teams'} />;
  if (redirect) return <LogoutRedirect />;
  return (
    <Fragment>
      {loading ? (
        <GenericFormLoading />
      ) : (
        <Fragment>
          <AdminUserFormWrap className="form-footer team-form">
            <AdminChestAddWrap>
              <UserForm
                edit={false}
                owner={owner}
                userName={userName}
                setUserName={setUserName}
                userEmail={userEmail}
                setUserEmail={setUserEmail}
                admin={admin}
                setAdmin={setAdmin}
                teams={teams}
                setTeams={setTeams}
                user={user}
                userId={userId}
              />
              <SaveFooter handleSubmit={() => updateUser()} edit={true} />
            </AdminChestAddWrap>
          </AdminUserFormWrap>
          <FakeArea />
        </Fragment>
      )}
    </Fragment>
  );
};

export default AdminUserEdit;
