import React, { useContext, useEffect } from 'react';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import Mousetrap from 'mousetrap';
import { HeaderBar, HeaderContent, HeaderLinks, HeaderImageWrap, AdminIcon } from './styles/HeaderStyle';
import { UsernameText, UserWrapper, UserOptions, DropDownOption } from './styles/UserDropdown';
import { Divider } from '../../common/divider/Divider';
import { Icon } from '../../common/Icon';
import { ModalContext } from '../../context/index';
import { isAnInput } from '../../utils/commonFunctions';
import LocalStore from '../../api/LocalStore';
import { UserContext } from '../../context/UserProvider';

const Header = ({ setDisplayDashboard, userDropdown, toggleUserDropdown }) => {
  const { user } = useContext(UserContext);
  const { logout } = useContext(UserContext);
  const { setModal } = useContext(ModalContext);
  const { administrator, name } = user;
  const history = useHistory();
  const { pathname } = useLocation();
  const store = new LocalStore();

  const accessUser = () => {
    history.push({ pathname: '/profile', prevPathname: pathname });
  };

  const userOptions = [
    { text: 'Account Settings', handleClick: accessUser },
    { text: 'Log Out', handleClick: logout },
  ];

  const handleIconClick = () => {
    // In chests.jsx a value is set in localstore to preserve a chest so it can be
    // saved, this causes the closing action to just revert to the 'lastChest' value
    const lastChest = store.get('lastChest');
    if (lastChest) store.remove('lastChest');
    const searchEl = document.getElementById('searchbar');
    if (searchEl) {
      if (searchEl.value.length > 0) searchEl.value = '';
    }
    history.push({ pathname: '/', prevPathname: pathname });
    setDisplayDashboard(false);
  };

  // TODO: header closes the secrets table thing on dashbaord
  const headerImage = () => (
    <div role={'header-image-button'} onClick={handleIconClick} style={{ cursor: 'pointer' }}>
      <Icon name="logo" width="200px" />
    </div>
  );

  const linkTo = where => {
    return isAnInput() ? null : history.push({ pathname: where, prevPathname: pathname });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      Mousetrap.bind('shift+/', () => history.push({ pathname: '/profile', prevPathname: pathname }));
      Mousetrap.bind('shift+l', handleShiftL);
      if (administrator) {
        Mousetrap.bind('shift+d', () => linkTo('/'), 'keyup');
        Mousetrap.bind('shift+a', () => linkTo('/admin'));
      }
    }

    return () => {
      isMounted = false;
      Mousetrap.unbind('shift+/');
      Mousetrap.unbind('shift+d', 'keyup');
      Mousetrap.unbind('shift+a');
    };
  });

  const adminLink = () => {
    if (pathname.includes('/admin'))
      return <NavLink to={{ pathname: '/', prevPathname: pathname }}>Back to dashboard</NavLink>;
    return (
      <NavLink role={'admin-nav-link'} to={{ pathname: '/admin/chests', prevPathname: pathname }}>
        <AdminIcon role={'admin-icon'}>
          <Icon name={'settings'} width={'20px'} />
        </AdminIcon>
      </NavLink>
    );
  };

  const userLink = name => (
    <UserWrapper role={'user-link-wrapper'} onClick={() => toggleUserDropdown(!userDropdown)}>
      <UsernameText role={'user-name-text'}>{name}</UsernameText>
      <Icon name="avatar" width="28px" />
      {userDropdown && (
        <UserOptions role={'user-options'} onMouseLeave={() => toggleUserDropdown(false)}>
          {returnOptions(userOptions)}
        </UserOptions>
      )}
    </UserWrapper>
  );

  const returnOptions = options => {
    return options.map(option => (
      <DropDownOption
        role={'drop-down-option'}
        className="dd-option"
        key={option.text}
        onClick={() => option.handleClick()}
      >
        {option.text}
      </DropDownOption>
    ));
  };

  const handleShiftL = () => {
    return isAnInput() ? null : logOutModal();
  };
  const logOutModal = () =>
    setModal({
      key: 'Confirm',
      params: {
        icon: 'alert',
        question: `Are you sure you want to logout?`,
        confirmText: `Yes, log me out.`,
        cancelText: `Stay logged in`,
        confirmAction: logout,
        cancelAction: () => setModal(),
      },
    });

  return (
    <HeaderBar role={'header-bar'}>
      {store.get('token') && (
        <HeaderContent role={'header-content'}>
          <HeaderImageWrap role={'header-image-wrap'}>{headerImage()}</HeaderImageWrap>
          <HeaderLinks role={'header-links'}>
            {administrator && adminLink()}
            {administrator && <Divider />}
            {userLink(name)}
          </HeaderLinks>
        </HeaderContent>
      )}
    </HeaderBar>
  );
};

export default Header;
