import styled from 'styled-components';

export const NavContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  @media screen and (max-width: 767px) {
    width: auto;
    justify-content: center;
    > div span:last-of-type {
      margin-right: 0;
    }
  }
`;

export const NavBar = styled.div`
  span {
    color: ${props => props.theme.textColor};
    text-decoration: none;
    margin-right: 2.5rem;
    font-size: 1.1rem;
    padding-bottom: 7px;
    border-bottom: 2px solid transparent;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: ${props => props.theme.hoverColor};
      cursor: pointer;
    }
  }

  .active {
    color: ${props => props.theme.hoverColor};
    border-bottom: 2px solid #2eced5;
  }

  @media screen and (max-width: 767px) {
    font-size: 1rem;
  }
`;
