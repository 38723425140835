import React, { useState } from 'react';
import { SettingTitle, SettingHR } from '../../../common/settings/styles/SettingStyles';
import { Wrap, AuditLogTableWrap } from './styles/AuditLogSettingsStyles';
import Table from '../../table/Table';
import { ModalSearchbar } from '../../forms/subComponents/styles/ModalStyles';
import { UserLoadingWrap, UserLogsText } from '../adminUsers/styles/AdminUsersShowStyles';
import GenericLoading from '../../../common/genericLoading/GenericLoading';
import useAuditLogs from '../../../api/hooks/useAuditLogs';
import debounce from 'lodash.debounce';

const AuditLogSettings = () => {
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState('');
  const auditData = useAuditLogs({ filter: search, limit: 200, everything: true });
  const headers = {
    Time: 'date',
    User: 'user',
    Activity: 'activity',
  };
  const columnWidths = ['30%', '20%', '45%'];

  const handleLoad = () => {
    if (!auditData) {
      return (
        <UserLoadingWrap>
          <GenericLoading />
        </UserLoadingWrap>
      );
    } else if (auditData && auditData['items'].length > 0) {
      return (
        <Table
          columnWidths={columnWidths}
          headers={headers}
          data={auditData['items']}
          searchText={search}
          searchable={['User', 'Activity']}
        />
      );
    } else {
      return <UserLogsText>No Recent Logs</UserLogsText>;
    }
  };

  const handleScroll = e => {
    if (e.target.scrollTop === e.target.scrollHeight - e.target.offsetHeight && e.target.scrollHeight > 200) {
      e.target.scrollTop = 0;
      return setOffset(offset + 50);
    }
  };

  const searchAudit = debounce(e => setSearch(e.target.value), 300);

  const handleSearch = e => {
    e.persist();
    searchAudit(e);
  };

  return (
    <Wrap>
      <SettingTitle>Audit Log</SettingTitle>
      <SettingHR />
      <div className="audit-wrapper">
        <ModalSearchbar>
          <i className="fas fa-search"></i>
          <input onChange={handleSearch} placeholder={'Search'} />
        </ModalSearchbar>
        <AuditLogTableWrap onScroll={handleScroll}>{handleLoad()}</AuditLogTableWrap>
      </div>
    </Wrap>
  );
};

export default AuditLogSettings;
