import styled from 'styled-components';

export const UsernameText = styled.p`
  padding-right: 1rem;
`;

export const UserWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  color: ${props => props.theme.textColor};
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.hoverColor};
    cursor: pointer;
  }

  > i {
    padding-left: 1rem;
    font-size: 25px;
  }

  @media (-webkit-device-pixel-ratio: 1.5) {
    svg {
      width: 21px;
    }
  }
`;

export const UserOptions = styled.div`
  position: absolute;
  display: block;
  width: 200px;
  top: 100%;
  right: 0.1rem;
  border-radius: 5px;
  background: ${props => props.theme.sButtonBgColor};
  border: 1px solid ${props => props.theme.bgColor};
  color: ${props => props.theme.textColor};
  transition: all 0.5s;
  z-index: 2;
`;

export const DropDownOption = styled.div`
  position: relative;
  display: block;
  padding: 0 22px 0 22px;
  font-size: 1rem;
  font-weight: 400;
  color: ${props => props.theme.textColor};
  line-height: 3rem;
  cursor: pointer;
  transition: all 0.5s;
  text-align: right;

  &:hover {
    cursor: pointer;
    background-color: ${props => props.theme.highlightColor};
    color: ${props => props.theme.hoverRowColor};

    :nth-child(1) {
      border-radius: 5px 5px 0 0;
    }

    :nth-child(2) {
      border-radius: 0 0 5px 5px;
    }
  }
`;
