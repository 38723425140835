import React from 'react';
import MeterBar from './styles/MeterBar';
import PasswordMeterWrap from './styles/PasswordMeterWrap';
import { SecretClickable } from '../../components/table/subComponents/uniqueCells/subComponents/styles/Clickable';

const chooseColor = strength => {
  if (strength <= 1) return '#FD3C3C';
  if (strength <= 3) return '#FFCE22';
  if (strength > 3) return '#37C3A1';
};

const PasswordMeter = ({ strength, onClick }) => {
  return (
    <PasswordMeterWrap role={'password-meter'} className="password-meter">
      {onClick ? <SecretClickable role={'secret-clickable-button'} onClick={onClick} /> : null}
      {[...Array(5)].map((_, idx) => {
        if (idx >= parseInt(strength)) return <MeterBar key={idx} color={'#DCE5F6'} />;
        return <MeterBar role={'meter-bar'} key={idx} color={chooseColor(strength)} />;
      })}
    </PasswordMeterWrap>
  );
};

export default PasswordMeter;
