import styled from 'styled-components';

export const Bubble = styled.div`
  font-size: 14px;
  text-transform: capitalize;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: none;
  padding: 3px 12px;
  color: ${({ theme }) => theme.textColor};
  border: 1px solid ${({ theme }) => theme.textColor};
  border-radius: 6px;
  outline: none;
  cursor: pointer;
`;

export const DropdownWrap = styled.div`
  display: none;
  position: absolute;
  flex-direction: column;
  background-color: ${({ theme }) => theme.tableBgColor};
  box-shadow: 5px 5px 2px 0px rgba(0, 0, 0, 0.35);
  border: 1px solid ${({ theme }) => theme.fieldHoverColor};
  width: 14vw;
  border-radius: 4px;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 999;
`;

export const DropdownOption = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  padding: 8px 16px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.fieldHoverColor};
  }
`;

export const Wrap = styled.div`
  position: relative;
  white-space: nowrap;
  margin: 3px 10px 0 0;

  &:hover ${DropdownWrap} {
    display: flex;
  }
`;

export const OptionLabel = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Cross = styled.div`
  &:hover {
    color: ${({ theme }) => theme.highlightColor};
  }
`;
