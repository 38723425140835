import React, { forwardRef, useEffect } from 'react';
import Mousetrap from 'mousetrap';
import { SearchContainer, InputWrapper } from './styles/SearchBarStyles';
import { blur, focus } from '../../utils/commonFunctions';

const SearchBar = ({ placeholder, setSearchText, setViewSecret }, ref) => {
  const handleChange = e => {
    setSearchText(e.target.value);
    setViewSecret(false);
  };

  useEffect(() => {
    Mousetrap.bind('esc', () => blur('searchbar'));
    Mousetrap.bind('keydown', () => focus('searchbar'));

    return () => {
      Mousetrap.unbind('esc');
      Mousetrap.unbind('keydown');
    };
  });

  return (
    <SearchContainer>
      <InputWrapper>
        <input
          role={'searchbar'}
          type="text"
          placeholder={placeholder}
          onChange={e => handleChange(e)}
          id="searchbar"
          autoFocus
          className="mousetrap"
          ref={ref}
          autoComplete={'off'}
        />
        <i className="fas fa-search"></i>
      </InputWrapper>
    </SearchContainer>
  );
};

export default forwardRef(SearchBar);
