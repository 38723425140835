import { AES } from '../steganography/aes';
import { Secret } from '../steganography/secret';
import { Keypair } from '../steganography/keypair';
import { Asymmetric } from '../steganography/asymmetric';
import { ViewSecretData } from '../api/secrets/SecretApiFunctions';
import { GetChestUsers } from '../api/chests/ChestApiFunctions';
import BaseApi from '../api/BaseApi';
import LocalStore from '../api/LocalStore';

export const DecryptSecret = async secret => {
  const store = new LocalStore();
  const data = {};
  const rawKey = store.get('raw');
  const secretKey = secret['secret_key'];

  data.master = await AES.importKey(rawKey);
  data.encryptedPrivateKey = store.get('privateKey');
  data.privateKeyIv = store.get('iv');
  data.encryptedSecretKey = secretKey['encrypted_value'];
  data.secretKeyIv = secret['encryption_iv'];
  return JSON.parse(await Secret.decrypt(secret['encrypted_data'], data));
};

export const ReshareSecret = async keyData => {
  const store = new LocalStore();
  const rawKey = store.get('raw');
  const master = await AES.importKey(rawKey);
  const privateKey = await Keypair.importPrivate(master, store.get('iv'), store.get('privateKey'));

  const encryptedKeys = await EncryptSecretValue(keyData, privateKey);
  const prepData = BuildSecretKey(keyData, encryptedKeys).flat(2);

  return BuildKeySharer(prepData);
};

const EncryptSecretValue = async (keyData, privateKey) =>
  await Promise.all(
    keyData.map(async data => {
      return await Promise.all(
        Object.keys(data.encryptedSecretKeys).map(async key => {
          const plainAes = await Asymmetric.decrypt(privateKey, data.encryptedSecretKeys[key].value);
          const userKey = Object.keys(data.publicKey)[0];
          const publicKey = await Keypair.importPublic(data.publicKey[userKey]);
          return await Asymmetric.encrypt(publicKey, plainAes);
        }),
      );
    }),
  );

const BuildSecretKey = (keyData, encryptedKeys) => {
  return Array.prototype.map.call(keyData, (data, index) => {
    let secretKeyInfo = [];
    const userValues = encryptedKeys[index];
    Object.keys(data.encryptedSecretKeys).forEach((key, index) => {
      const keyObject = {};
      const id = `${Object.keys(data.publicKey)[0]}${key}`;
      keyObject[id] = {
        user_id: Object.keys(data.publicKey)[0],
        secret_id: key,
        version: data.encryptedSecretKeys[key].version,
        encrypted_value: userValues[index],
      };
      secretKeyInfo.push(keyObject);
    });
    return secretKeyInfo;
  });
};

const BuildKeySharer = prepData => {
  const keySharer = {
    key_reshare_handler: {
      secret_keys_attributes: {},
    },
  };
  prepData.forEach(secret => {
    keySharer.key_reshare_handler.secret_keys_attributes[Object.keys(secret)[0]] = secret[Object.keys(secret)[0]];
  });
  return keySharer;
};

export const ShareSecret = async (secretID, vaultID) => {
  const secret = await ViewSecretData(secretID);
  const secretData = await DecryptSecret(secret);
  const users = await GetChestUsers(vaultID);
  const encryptedSecret = await Secret.encrypt(JSON.stringify(secretData), users);
  const sharedSecret = {
    secret: {
      vault_id: vaultID,
      name: secret.name,
      category: secret.type,
      encrypted_data: encryptedSecret.encryptedData,
      encryption_iv: encryptedSecret.iv,
      secret_keys_attributes: encryptedSecret.unlocks,
    },
  };
  await BaseApi.create('secrets', sharedSecret);
};
