import React from 'react';
import { Icon } from '../Icon';
import FooterTag from './FooterTag';

const FooterLink = ({ text, display, setDisplay, setAddSecret, onClick = () => null }) => {
  if (display) {
    const handleClick = () => {
      onClick();
      setAddSecret(true);
    };
    return (
      <FooterTag
        onClick={handleClick}
        fontIcon="fas fa-plus-circle"
        text="Add a Secret to Recrypt"
        keyText="Shift + "
        icon={<Icon name="key-n" width="18px" />}
      />
    );
  }

  const handleClick = () => {
    onClick();
    setDisplay(!display);
  };
  return (
    <FooterTag
      onClick={handleClick}
      fontIcon="fas fa-arrow-alt-circle-down"
      text={text}
      keyText="Shift + "
      icon={<Icon name="keydown" width="18px" />}
    />
  );
};

export default FooterLink;
