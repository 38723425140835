import React, { forwardRef } from 'react';
import { AdminSearchBarWrap } from '../styles/AdminSearchStyles';

const handleChange = (e, setSearchText) => setSearchText(e.target.value);

const AdminSearchBar = ({ placeholder, setSearchText }, ref) => (
  <AdminSearchBarWrap>
    <input
      role={'admin-searchbar-input'}
      type="text"
      placeholder={placeholder}
      onChange={e => handleChange(e, setSearchText)}
      id="admin-searchbar"
      className="mousetrap"
      ref={ref}
      autoFocus
    />
    <i className="fas fa-search"></i>
  </AdminSearchBarWrap>
);

export default forwardRef(AdminSearchBar);
