import React, { useEffect } from 'react';
import Mousetrap from 'mousetrap';
import { BackButton, BackWrapper, BillingTable } from './styles/BillingStyles';
import Table from '../../../table/Table';
import GenericLoading from '../../../../common/genericLoading/GenericLoading';

export const BillingHistory = ({ setView, billingHistory }) => {
  const headers = {
    Date: 'date',
    Amount: 'amount',
    Status: 'status',
    Invoice: 'billingHistoryActions',
  };

  const columnWidths = ['25%', '25%', '25%', '25%'];

  const renderTable = () => {
    return <Table columnWidths={columnWidths} headers={headers} data={billingHistory} />;
  };

  const renderContent = () => {
    if (billingHistory === undefined) return <GenericLoading />;
    if (billingHistory.length < 1 || billingHistory === 1) return <div>You have no invoices</div>;
    return renderTable();
  };

  // useEffect for keyboard shortcuts.
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      Mousetrap.bind('esc', () => setView('summary'));
    }
    return () => {
      isMounted = false;
      Mousetrap.unbind('esc');
    };
  });

  return (
    <div>
      <BackWrapper>
        <BackButton onClick={() => setView('summary')}>{'< Back'}</BackButton>
        Billing Summary
      </BackWrapper>
      <BillingTable>{renderContent()}</BillingTable>
    </div>
  );
};
