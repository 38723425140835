import styled from 'styled-components';

export const AdminChestAddWrap = styled.div`
  display: flex;
  flex: 2;
  padding: 0;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow-y: hidden;
  justify-content: space-between;
  border-radius: 0 0 0 5px;
`;

export const TeamDropdownWrapper = styled.div`
  box-sizing: border-box;
  width: 49.5%;
  min-width: 250px;
  height: 70px;
  cursor: pointer;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const DropdownsWrapper = styled.div`
  display: flex;
  overflow-y: visible;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const SubHeading = styled.div`
  color: ${props => props.theme.textColor};
  margin-bottom: 1.5rem;
`;

export const FakeArea = styled.div`
  background-color: ${props => props.theme.bgColor};
  flex: 15;
`;
