import { Password } from '../steganography/password';
import { Keypair } from '../steganography/keypair';

export const CreateOwner = async ({ companyName, userEmail, password, userName, termsOfService, marketingOptIn }) => {
  const url = `${process.env.REACT_APP_API_URL}/users`;
  const aes_key = await Password.masterKey(userEmail, password);
  const keys = await Keypair.generate(aes_key);
  const prehashPassword = await Password.prehash(userEmail, password);

  let data = new FormData();
  data.append('user[email]', userEmail);
  data.append('user[name]', userName);
  data.append('user[password]', prehashPassword);
  data.append('user[public_key]', keys.publicKey);
  data.append('user[encrypted_private_key]', keys.encryptedPrivateKey);
  data.append('user[private_key_iv]', keys.iv);
  data.append('user[owned_organisation_attributes][name]', companyName);
  data.append('user[termsOfService]', termsOfService);
  data.append('user[marketingOptIn]', marketingOptIn);

  const response = await fetch(url, {
    method: 'POST',
    body: data,
  });

  return response;
};
