import React, { useContext, useState, useEffect } from 'react';
import { WelcomeContext } from '../context/index';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import WelcomeRoutes from '../components/nav/WelcomeRoutes';
import { UserContext } from '../context/UserProvider';
import { checkOnboardingState } from '../utils/commonFunctions';

const Welcome = () => {
  const [welcome, setWelcome] = useState({
    tutorial: false,
    twofactor: false,
    secret: false,
    password: false,
  });
  const { user, update } = useContext(UserContext);
  const { first_time } = user;
  const email = user.email;
  const hasSecret = user.has_secret;
  const isAdmin = user.administrator;
  const { state } = useLocation();
  const history = useHistory();

  useEffect(() => {
    const welcomeCheck = () => {
      if (isAdmin) {
        setWelcome({
          password: !user['must_change_password'],
          tutorial: user['tutorial'] || false,
          twofactor: true,
        });
        if (hasSecret)
          setWelcome({
            password: !user['must_change_password'],
            tutorial: user['tutorial'] || false,
            secret: true,
          });
      } else {
        setWelcome({
          password: !user['must_change_password'],
          tutorial: true,
          twofactor: user['otp_required_for_login'],
        });
      }
    };
    welcomeCheck();
  }, [email, hasSecret, isAdmin, state, history]);

  useEffect(() => {
    checkOnboardingState(welcome, update, history);
  }, [welcome, state]);

  if (!first_time) return <Redirect to={{ pathname: '/' }} />;

  return (
    <WelcomeContext.Provider value={{ welcome, setWelcome }}>
      <WelcomeRoutes />
    </WelcomeContext.Provider>
  );
};

export default Welcome;
