import styled from 'styled-components';
import { TextWrapper } from './TextWrapper';

export const SplitWrapper = styled(TextWrapper)`
  justify-content: space-between;
  margin-bottom: 0%;
  margin-right: 0%;
  border: none;
  padding-left: 0%;
  padding-right: 0%;
  background-color: inherit;

  > div {
    width: 100%;
    margin-right: 1.5rem;

    &:last-of-type {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 767px) {
    > div {
      width: 100%;
    }
  }
`;
