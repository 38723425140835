import ApiRequest from './ApiRequest';
import { formatParams } from './apiHelpers';

class BaseApi {
  static all(table, params = {}) {
    return ApiRequest('GET', `api/v1/${table}.json${formatParams(params)}`);
  }

  static show(table, id, params = {}) {
    return ApiRequest('GET', `api/v1/${table}/${id}.json${formatParams(params)}`);
  }

  static create(table, params) {
    return ApiRequest('POST', `api/v1/${table}.json`, params);
  }

  static update(table, id, params) {
    return ApiRequest('PUT', `api/v1/${table}/${id}.json`, params);
  }

  static destroy(table, id) {
    return ApiRequest('DELETE', `api/v1/${table}/${id}.json`);
  }

  static softDestroy(id) {
    return ApiRequest('POST', `api/v1/secrets/${id}/mark_for_deletion`);
  }

  static restore(id) {
    return ApiRequest('POST', `api/v1/secrets/${id}/unmark_for_deletion`);
  }
}

export default BaseApi;
