import React from 'react';
import { Wrap } from './styles/GenericLoadingStyles';
import Loading from '../../images/loading_01-1.gif';
import SmallLoading from '../../images/loading_02.gif';

const GenericLoading = ({ loadingGif, adminTable = false }) => {
  return (
    <Wrap role={'GenericLoading'} adminTable={adminTable}>
      {loadingGif === 'small' ? (
        <img role={'small-loading-img'} className="small-loading" src={SmallLoading} alt="Loading..." />
      ) : (
        <img role={'loading-img'} src={Loading} alt="Loading..." />
      )}
    </Wrap>
  );
};

export default GenericLoading;
