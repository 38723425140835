import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../images/recrypt_logo_02_lightmode.svg';
import { LeftHeader, Header } from './styles/LayoutStyles';

export const RegisterHeader = () => {
  return (
    <Header role={'register-header'}>
      <LeftHeader role={'register-lef-header'}>
        <Logo width="200px" role={'register-left-logo-header'} />
      </LeftHeader>
      <Link to="/login" className="signInButton" role={'register-signin-link'}>
        SIGN IN
      </Link>
    </Header>
  );
};
