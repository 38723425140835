import styled from 'styled-components';

export const ChoiceIcon = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 1rem;

  @media screen and (min-width: 1440px) and (max-width: 1919px) {
    margin-bottom: 0;
  }
  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    margin-bottom: 0;
    svg {
      width: 60px !important;
    }
  }

  svg {
    width: 80px;
  }
`;

export const ChoiceTitle = styled.h2`
  font-weight: 400;
  font-size: 1.6rem;
  margin-bottom: 0.5rem;
  text-align: center;

  + span {
    text-align: center;
  }
`;

export const ChoiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

export const ChoiceLink = styled.a`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  height: 3.5rem;
  border: 1px solid ${props => props.theme.fieldBorderColor};
  border-radius: 0.5rem;
  outline: none;
  font-size: 1rem;
  background-color: transparent;
  color: ${props => props.theme.textColor};
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s ease-in-out;

  &:hover,
  &:focus {
    background-color: ${props => props.theme.welcomeButtonHover};
  }

  svg path {
    fill: ${props => props.theme.textColor};
  }

  span {
    margin-left: 1rem;
    line-height: 100%;
  }
`;
