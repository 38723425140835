import React from 'react';
import { Modal, ModalOverlay, Wrap, SquaredWrap, LargeSquaredWrap } from './styles/GenericStyles';
import { placeholderFunc } from '../../../utils/commonFunctions';
import { getPadding } from './subFunctions/getModalPadding';

const ModalWrap = ({ children, modalStyle = 'default', cancelAction = placeholderFunc, padType }) => (
  <Modal>
    <ModalOverlay onClick={cancelAction} />
    {modalStyle === 'largeSquared' && <LargeSquaredWrap padding={getPadding(padType)}>{children}</LargeSquaredWrap>}
    {modalStyle === 'squared' && <SquaredWrap padding={getPadding(padType)}>{children}</SquaredWrap>}
    {modalStyle === 'default' && <Wrap padding={getPadding(padType)}>{children}</Wrap>}
  </Modal>
);

export default ModalWrap;
