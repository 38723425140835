import { Conversion } from './internal/conversion';

// Anything related to symmetric encryption/decryption lives here:
export class AES {
  static randomKey() {
    var aes = { name: 'AES-CBC', length: 256 };
    return window.crypto.subtle.generateKey(aes, true, ['encrypt', 'decrypt']);
  }

  static randomIV() {
    var iv = new Uint8Array(16);
    window.crypto.getRandomValues(iv);
    return Conversion.bin2txt(iv);
  }

  static exportKey(key) {
    return Promise.all([key])
      .then(function (results) {
        return window.crypto.subtle.exportKey('raw', results[0]);
      })
      .then(function (buffer) {
        return Conversion.bin2txt(buffer);
      });
  }

  static importKey(txt) {
    var config = { name: 'AES-CBC' };
    var usages = ['encrypt', 'decrypt'];

    return Promise.all([txt]).then(function (results) {
      var data = Conversion.txt2bin(results[0]);
      return window.crypto.subtle.importKey('raw', data, config, false, usages);
    });
  }
}
