import React from 'react';
import { Wrap, IconWrap, Text } from './styles/DeletedSecretsButtonStyles';
import { Icon } from '../../../../../common/Icon';

const DeletedSecretsButton = ({ onClick }) => {
  return (
    <Wrap onClick={onClick}>
      <IconWrap>
        <Icon name={'bin'} width={'16px'} />
      </IconWrap>
      <Text>Deleted Secrets</Text>
    </Wrap>
  );
};

export default DeletedSecretsButton;
