import { capitalize, secondsToDate, numToPounds } from '../../../../../utils/commonFunctions';

export const formatPayment = paymentMethod => {
  if (
    paymentMethod === undefined ||
    typeof paymentMethod === 'string' ||
    paymentMethod === 1 ||
    paymentMethod['data'][0] === undefined
  )
    return;
  const { card, billing_details } = paymentMethod['data'][0];
  const { brand, last4, exp_month, exp_year } = card;
  const { address } = billing_details;
  const date = _formatCardDate(exp_month, exp_year);
  return {
    cardType: capitalize(brand),
    cardEnd: last4,
    cardExpire: date,
    billingAddress: _formatAddress(address),
  };
};

export const formatInvoices = billingHistory => {
  if (typeof billingHistory === 'string') return 1;
  if (_checkValidHistory(billingHistory)) return 1;
  return billingHistory.map(invoice => ({
    id: invoice['id'],
    date: secondsToDate(invoice['status_transitions']['paid_at']),
    amount: numToPounds(invoice['total']),
    status: invoice['status'],
    invoicePdf: invoice['invoice_pdf'],
  }));
};

const _formatAddress = address => {
  const { city, country, line1, line2, postal_code } = address;
  return `${capitalize(line1)}, ${capitalize(line2)}, ${country.toUpperCase()}, ${capitalize(
    city,
  )}, ${postal_code.toUpperCase()}`;
};

const _formatCardDate = (month, year) => {
  const formattedMonth = _formatCardMonth(month);
  return `${formattedMonth}/${year}`;
};

const _formatCardMonth = month => {
  if (month > 10) return month;
  return `0${month}`;
};

const _checkValidHistory = billingHistory => !billingHistory || billingHistory === 1;
