import React, { useState } from 'react';
import { placeholderFunc } from '../utils/commonFunctions';
import { searchFunction } from '../utils/searchFunctions';
import { Arrow } from './arrow/Arrow';
import { ArrowWrap, Content, Option, Search, Wrap } from './styles/SearchableDropdownStyles';

const SearchableDropdown = ({
  data,
  setValue = placeholderFunc,
  displayValue = 'name',
  placeholder = 'Select a value...',
}) => {
  const [focused, setFocused] = useState(false);
  const [searchText, setSearchText] = useState('');

  const headers = [`${displayValue}`];
  const generateOptions = () =>
    data &&
    searchFunction(headers, data, searchText).map((opt, i) => (
      <Option role={`option-${i}`} key={i} onClick={() => handleClick(opt)} tabIndex="0">
        {opt[displayValue]}
      </Option>
    ));

  const handleClick = opt => {
    setValue(opt);
    setFocused(false);
    setSearchText(opt[displayValue]);
  };

  const handleChange = e => {
    setSearchText(e.target.value);
    setFocused(true);
  };

  const handleBlur = e => {
    e.relatedTarget === null && setFocused(false);
    e.relatedTarget && e.relatedTarget.tagName !== 'DIV' && setFocused(false);
  };

  return (
    <Wrap>
      <Search
        role={focused ? 'search-input-focused' : 'search-input-unfocused'}
        type={'text'}
        placeholder={placeholder}
        autoComplete={'new-password'}
        open={focused}
        value={searchText}
        onClick={() => setSearchText('')}
        onChange={e => handleChange(e)}
        onBlur={e => handleBlur(e)}
        onFocus={() => setFocused(!focused)}
      />
      <ArrowWrap>
        <Arrow arrowState={focused} />
      </ArrowWrap>
      <Content closed={!focused}>{generateOptions()}</Content>
    </Wrap>
  );
};

export default SearchableDropdown;
