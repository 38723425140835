import styled from 'styled-components';

export const ModalOverlay = styled.div`
  position: fixed;
  background-color: #000000;
  opacity: 0.2;
  height: 100vh;
  width: 100vw;
  overflow-y: hidden;
  z-index: 9;
`;

export const ToastWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.theme.bgColor};
  border-radius: 5px;
  color: ${props => props.theme.fieldTextColor};
  position: fixed;
  top: 50%;
  left: 45%;
  margin: -100px 0 0 -150px;
  z-index: 10;
  padding: 1.25rem;
  font-size: 0.9rem;
  text-align: center;
  line-height: 1.5rem;
  flex-wrap: nowrap;
`;

export const InactivityText = styled.p`
  font-size: 0.9rem;
  line-height: 150%;
  width: 100%;
  flex: 3;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;
export const InactivityHeaderText = styled.p`
  font-size: 1.2rem;
  line-height: 150%;
  width: 100%;
  flex: 3;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;
const BaseButton = styled.div`
  flex: 6;
  margin: 1rem;
  background-color: ${({ theme }) => theme.fieldBgColor};
  border-radius: 5px;
  line-height: 50px;
  border: none;
  color: ${({ theme }) => theme.formBgColor};
  cursor: pointer;
`;
export const Confirm = styled(BaseButton)`
  background-color: ${({ theme }) => theme.highlightColor};
  color: ${({ theme }) => theme.formBgColor};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 30rem;
  padding: 0 1rem;
`;

export const LogOut = styled(BaseButton)`
  background-color: ${({ theme }) => theme.modelLGColor};
  color: ${({ theme }) => theme.formBgColor};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 10rem;
  padding: 0 1rem;

  &:hover {
    background-color: ${({ theme }) => theme.bgColor};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: 'row';
`;
