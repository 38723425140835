import SecretKeyApi from './SecretKeyApi';
import { ReshareSecret } from '../../utils/secretFunctions';

/* Function that handles the resharing of secret keys, whenever an add/edit 
    with a team, chest or a user occurs, automatically. */
export const reshareData = async id => {
  const reshareParams = {
    id: id,
  };
  const reshareInfo = await SecretKeyApi.reshareUser(reshareParams);
  if (reshareInfo.keyshare.length > 0) {
    const reshareJson = await ReshareSecret(reshareInfo.keyshare);
    await SecretKeyApi.reshare(reshareJson);
  }
};
