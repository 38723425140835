import React from 'react';
import { PrimaryButton } from '../../../common/buttons/styles/PrimaryButton';
import { OptionsWrapper } from './styles/OptionsButtonsStyles';

export const OptionsButtons = ({ onClickPrimary, primaryLabel, onClickSecondary, secondaryLabel }) => {
  return (
    <OptionsWrapper>
      {onClickSecondary && (
        <PrimaryButton role={'OptionsButtons'} onClick={() => onClickSecondary()}>
          {secondaryLabel}
        </PrimaryButton>
      )}
      <PrimaryButton role={'OptionsButtons'} onClick={() => onClickPrimary()}>
        {primaryLabel}
      </PrimaryButton>
    </OptionsWrapper>
  );
};
