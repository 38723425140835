import styled from 'styled-components';

export const Header = styled.div`
  text-align: left;
  font-size: 24px;
  flex: 1;
  margin: 0 0 0.5rem 0;
`;

export const SearchWrap = styled.div`
  display: flex;
  flex: 1;
  margin: 0 -2rem 0.5rem -2rem;
  padding: 0 1rem;
  background-color: ${props => props.theme.tableBgColor};
  border-top: 1px solid ${props => props.theme.textColor};
  border-bottom: 1px solid ${props => props.theme.textColor};
  border-left: none;
  border-right: none;
  max-height: 2rem;

  > svg {
    flex: 1;
    margin: 0 0.5rem;
    .st0 {
      fill: ${({ theme }) => theme.textColor};
    }
  }
`;

export const Search = styled.input`
  box-sizing: border-box;
  flex: 11;
  color: ${props => props.theme.textColor};
  padding: 0.25rem;
  font-size: 0.9rem;
  background-color: ${props => props.theme.tableBgColor};
  border: none;

  &::placeholder {
    color: ${props => props.theme.placeholderColor};
  }
`;

export const SelectWrap = styled.div`
  flex: 9;
  margin: 0 -2rem;
  background-color: ${({ theme }) => theme.tableBgColor};
  text-align: left;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Footer = styled.div`
  flex: 1;
  border-top: 1px solid ${({ theme }) => theme.textColor};
  margin: 0 -2rem -2rem -2rem;
`;
