import { testIf } from '../validationFunctions';
import { cleanArray, mergeArrays } from '../../mergeFunctions';
import { successState, formatResults } from '../validationHelpers';

export const validateTelephoneSecret = ({ data, setNotificationText }) => {
  const emptyRes = cleanArray(testIf('empty')(data, ['name', 'vault_id']));
  const numResults = cleanArray(testIf('number')(data, ['country', 'number', 'extension']));
  const lengthRes = testIf('shorterThan')(data, ['name'], 50);

  const testResults = mergeArrays([emptyRes, numResults, lengthRes]);
  if (testResults.length === 0) return successState(setNotificationText);
  setNotificationText(formatResults(testResults));
};
