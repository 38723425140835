import styled from 'styled-components';

export const CodeInputWrap = styled.fieldset`
  display: flex;
  min-height: 1.5rem;
  margin: 0 0 1rem 0;
  border-radius: 5px;
  border: 1px solid ${props => props.theme.hoverRowBgColor};
`;

export const CodeInputLegend = styled.legend`
  background-color: ${props => props.theme.tableBgColor};
  color: ${props => props.theme.hoverRowBgColor};
  bottom: 85%;
  font-size: 0.9rem;
  width: fit-content;
  line-height: normal;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;

export const CodeInput = styled.input`
  background-color: inherit;
  font-family: 'Lato';
  width: 100%;
  border: none;
  padding: 0 0 0 0.5rem;
  font-size: 1rem;
  color: ${props => props.theme.fieldColor};
  appearance: none;
  outline: none;

  ::placeholder {
    color: ${props => props.theme.placeholderColor};
  }

  &:focus {
    border-color: ${props => props.theme.highlightColor};
    outline: none;
  }

  &:focus ~ label {
    color: ${props => props.theme.highlightColor};
  }

  &:focus ~ i {
    visibility: visible;
    color: ${props => props.theme.highlightColor};
  }
`;

export const CodeImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 198px;
  min-width: 293px;
  margin: 0 0 1rem 0;
  background-color: ${props => props.theme.bgColor};
`;

export const CodeImage = styled.svg`
  &.resized {
    width: 55%;
    max-width: 160px;
    height: 81%;
    background-color: #ffffff;
    border: 5px solid #ffffff;
  }
`;

export const CodeError = styled.span`
  font-size: 0.9rem;
  color: ${props => props.theme.error};
  margin-top: -0.5rem;
  margin-bottom: 1rem;
`;
