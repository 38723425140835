import React, { useContext } from 'react';
import { HeaderWrap } from './styles/HeaderWrap';
import { Icon } from '../../../../common/Icon';
import { ThemeContext } from 'styled-components';

const SettingsHeader = () => {
  const themeContext = useContext(ThemeContext);
  return (
    <HeaderWrap>
      {themeContext.scheme === 'dark' ? (
        <Icon name="settings_white" width="21px" />
      ) : (
        <Icon name="settings" width="21px" />
      )}
      <div>Settings</div>
    </HeaderWrap>
  );
};

export default SettingsHeader;
